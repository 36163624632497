import {
  updateProfileClassification,
  userOnboardingAddPreferences,
  userOnboardingAssociateCompany,
  userOnboardingCreateCompany,
} from '../../features/userOnboardingSlice';

export const STEPS_MAP = Object.freeze({
  0: 'professionalDetails',
  1: 'offerings',
  2: 'preferences',
  3: 'currentPlanDetails',
});

// --------V1 CALLS--------
export const getOfferingsIAmABodyV1 = (e) => {
  switch (e?.iAmI) {
    case 'broker':
      return {
        broker: {
          openToBrokers: e?.broker_open_to_broker,
        },
      };
    case 'seller':
      return {
        seller: [
          {
            openToBrokers: e?.seller_open_to_broker,
          },
        ],
      };
    case 'buyer':
      return {
        buyer: {
          openToBrokers: e?.buyer_open_to_broker,
        },
      };

    case 'coworking':
      return {
        coworking: [
          {
            openToBrokers: e?.coworking_openToBrokers,
          },
        ],
      };
    default:
      console.warn('offer type not found');
  }
};
export const getPayloadTemplateV1 = ({
  e,
  current,
  allCompanyList,
  allIndustriesList,
  userOnboardingData,
  createCompany,
}) => {
  switch (STEPS_MAP?.[current]) {
    case 'professionalDetails':
      return {
        pageName: 'professionalDetails',
        body: {
          companyName: createCompany
            ? e?.addCompanyName
            : allCompanyList?.find((elem) => elem?.value === e?.companyName)?.label,
          industry: allIndustriesList?.find((elem) =>
            createCompany ? elem?.value === e?.industryName : elem?.label === e?.industry,
          )?.value
            ? allIndustriesList?.find((elem) =>
                createCompany ? elem?.value === e?.industryName : elem?.label === e?.industry,
              )?.value
            : '--',
          otherIndustry: e?.otherIndustry || userOnboardingData?.otherIndustry,
          companyEmailId: e?.representativeEmail || userOnboardingData?.email,
          designation: e?.designation || userOnboardingData?.professionalDetails?.designation,
          experience: e.yearsOfExperience || userOnboardingData?.professionalDetails?.experience,
          keySkills: e.keySkills || userOnboardingData?.professionalDetails?.keySkills,
          companyCIN: createCompany ? e?.CIN : allCompanyList?.find((elem) => elem?.value === e?.companyName)?.cin,
        },
      };
    case 'preferences':
      return {
        pageName: 'preferences',
        body: {
          userSells: e?.preference_userSells,
          userTargetAudience: e?.preference_userTargetAudience,
          userWouldBuy: e?.preference_userWouldBuy,
        },
      };
    case 'offerings':
      return {
        pageName: 'profileDescription',
        body: {
          text: e?.profileDescription,
        },
      };
    default:
      console.warn('type not found');
  }
};

export const getCrmPayload = (e, industryType, CIN) => {
  return {
    orgName: e?.professionalDetails?.companyName,
    name: e?.personalDetails?.name,
    email: e?.professionalDetails?.companyEmailId,
    phone: e?.phone,
    industryType: industryType,
    role: e?.role,
    companyCIN: CIN,
  };
};
export const getFmsPayload = (e) => {
  return {
    name: e?.personalDetails?.name,
    email: e?.professionalDetails?.companyEmailId,
    phone: e?.phone,
  };
};

export const getOkrPayload = (e) => {
  return {
    name: e?.personalDetails?.name,
    email: e?.professionalDetails?.companyEmailId,
  };
};
// ----------V1 CALLS-------

// ----------------V2 Calls------------
export const getOfferingsIAmABodyV2 = (e, selectedIAmI) => {
  const openToBroker = (openToBroker) => {
    return openToBroker?.[0]?.toLowerCase() === 'yes' ? true : false;
  };
  const offeringPayloadMap = {
    broker: {
      openToBroker: openToBroker(e?.broker_open_to_broker),
    },
    seller: {
      openToBroker: openToBroker(e?.seller_open_to_broker),
    },
    buyer: {
      openToBroker: openToBroker(e?.buyer_open_to_broker),
    },
    coworking: {
      openToBroker: openToBroker(e?.coworking_openToBrokers),
    },
  };
  return {
    openToBroker: offeringPayloadMap?.[selectedIAmI]?.openToBroker,
    profileClassification: selectedIAmI,
  };
};

export const getPayloadTemplateV2 = ({ e, current, selectedIAmI, createCompany, selectedCompanyId, industry }) => {
  const stepAPIMapping = {
    professionalDetails: createCompany
      ? userOnboardingCreateCompany({
          name: e?.addCompanyName,
          industryTypeId: e?.industryName,
          industryType: industry,
          cin: e?.CIN,
          website: e?.websiteLink,
          address: {
            addressLine1: e?.address,
            locality: e?.locality,
            city: e?.city,
            state: e?.state,
            pin: parseInt(e?.pinCode),
          },
        })
      : userOnboardingAssociateCompany({ companyId: selectedCompanyId, industryId: e?.industry }),
    offerings: updateProfileClassification({
      payload: getOfferingsIAmABodyV2(e, selectedIAmI),
    }),
    preferences: userOnboardingAddPreferences({
      userSells: e?.preference_userSells,
      userTargetAudience: e?.preference_userTargetAudience,
      userWouldBuy: e?.preference_userWouldBuy,
      dreamClients: [],
      interest: [],
    }),
  };
  return stepAPIMapping?.[STEPS_MAP?.[current]];
};
