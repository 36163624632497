import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { ExternalError } from '../../utils/error/error';
import {
  addUserPreferences,
  createCompany,
  associateCompany,
  fetchUserPoints as fetchPoints,
  fetchUserOnboardingDataAPI,
  getCompanyDetail,
  getOfferingData,
  getUserPreferences,
  postOnboardingData as pOData,
  postOnboardingDataForLinkedIn as pODataLinkedIn,
  getAllCompanyDetails,
  getAllCompanyDetailsBySearch,
  getAllIndustriesDetails,
  getUserPreferenceList,
  getCompanyFollowDetails,
  getIndustryDetails,
  postSelectPlanData,
  registerCrm as registerCrmUser,
  registerFms as registerFmsUser,
  registerOkr as registerOkrUser,
  updateProfileClassificationAPI,
  getCompanyDirectorDetail,
  addCompanyDirector,
  updateCompanyDirector,
  updateCompanyRERA,
  fetchCompanyReraDetails as fetchCompanyRera,
} from '../services/userOnboardingAPI';
import { setCRMData, setFMSData, setOKRData } from './userDashboardSlice';
import { set_toast } from './toastSlice';

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  userOnboardingData: {},
  loading: false,
  error: null,
  userPoints: 0,
  searchedCompanyName: '',
  companyDirectors: [],
  companyReraDetails: {},
};

const userOnboardingSlice = createSliceWithThunks({
  name: 'userOnboarding',
  initialState,
  reducers: (create) => ({
    clearUserOnboardingData: (state, action) => {
      state.userOnboardingData = {};
    },
    addUserPoints: (state, action) => {
      state.userPoints = parseInt(state.userPoints || 0) + parseInt(action?.payload || 0);
    },
    setPreferencesUserOnboarding: (state, action) => {
      state.userOnboardingData.preferenceDetails = action.payload;
    },
    fetchUserOnboardingData: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await fetchUserOnboardingDataAPI(userID);
          if (data?.code !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.userOnboardingData = {
            ...state.userOnboardingData,
            ...action.payload, // Merging new data with existing data
          };
        },
      },
    ),
    postOnboardingData: create.asyncThunk(
      async (pageData, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await pOData(pageData, userID);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.userOnboardingData = action.payload?.data?.response?.data;
        },
      },
    ),

    postOnboardingDataForLinkedIn: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await pODataLinkedIn(payload, userID);
          if (data instanceof Error && data.response.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data?.response?.status,
            });
          }
          // if (data?.status !== 201) {

          // }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          thunkAPI.dispatch(set_toast({ show: true, type: 'warning', content: message }));
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.userOnboardingData = action.payload?.data?.response?.data;
        },
      },
    ),
    // -------------Register CRM--------------
    registerCrm: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await registerCrmUser(payload, userID);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          thunkAPI.dispatch(setCRMData(data?.data?.response?.data));
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    // -------------Register FMS--------------
    registerFms: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await registerFmsUser(payload, userID);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          thunkAPI.dispatch(setFMSData(data?.data?.response?.data));
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const redirectionUrl = action?.payload?.data?.response?.data?.url;
          // Redirect the user to the extracted URL
          window.location.href = redirectionUrl;
        },
      },
    ),
    // -------------Register OKR--------------
    registerOkr: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await registerOkrUser(payload, userID);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          thunkAPI.dispatch(setOKRData(data?.data?.response?.data));
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const redirectionUrl = action?.payload?.data?.response?.data?.url;
          // Redirect the user to the extracted URL
          window.location.href = redirectionUrl;
        },
      },
    ),
    // -------------Create company------------
    userOnboardingCreateCompany: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await createCompany(userID, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.message, {
            statusCode: data?.response?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------associate company------------
    userOnboardingAssociateCompany: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await associateCompany(userID, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get all company detail------------
    userOnboardingGetAllCompanyDetails: create.asyncThunk(async (_, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getAllCompanyDetails(userID);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get all company detail by search------------
    userOnboardingGetAllCompanyDetailsBySearch: create.asyncThunk(async (companyName, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getAllCompanyDetailsBySearch(companyName, userID);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get all industries detail------------
    userOnboardingGetAllIndusteriesDetails: create.asyncThunk(async (_, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getAllIndustriesDetails(userID);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get all industries detail------------
    userOnboardingGetIndustryDetails: create.asyncThunk(async (industryId, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getIndustryDetails(userID, industryId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get company detail------------
    userOnboardingGetCompany: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
          const data = await getCompanyDetail(companyId, userID);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    // -------------get company detail------------
    userOnboardingGetCompanyDirectors: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
          const data = await getCompanyDirectorDetail(companyId, userID);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.companyDirectors = action.payload.data?.response?.data?.[0]?.data;
          state.loading = false;
        },
      },
    ),
    // -------------get company detail------------
    userOnboardingGetCompanyFollowStats: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
          const data = await getCompanyFollowDetails(
            {
              ownerId: companyId,
              type: 'company_post',
            },
            userID,
          );
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    // -------------Add offering detail------------
    updateProfileClassification: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await updateProfileClassificationAPI(userID, payload);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get  offering detail------------
    userOnboardingGetOfferings: create.asyncThunk(async (type, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getOfferingData(userID, type);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------Add perferences detail------------
    userOnboardingAddPreferences: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await addUserPreferences(userID, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get  perferences detail------------
    userOnboardingGetPreferences: create.asyncThunk(async (_, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getUserPreferences(userID);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------get  perferences list------------
    userOnboardingGetPreferencesList: create.asyncThunk(async (param, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await getUserPreferenceList(userID, param);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),

    fetchUserPoints: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userID = thunkAPI.getState().user.user.id;
          const data = await fetchPoints(userID);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.userPoints = action.payload?.data?.response?.data?.points;
        },
      },
    ),
    selectPlanPost: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userID = thunkAPI.getState().user.user.id;
        const data = await postSelectPlanData(payload, userID);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    setSearchedCompanyName: (state, action) => {
      state.searchedCompanyName = action.payload;
    },
    // -------------Add directors detalis----------
    userOnboardingAddCompanyDirector: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user.user.id;
        const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
        const data = await addCompanyDirector(companyId, userId, payload);

        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something went wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------------Update directors detalis----------
    userOnboardingUpdateCompanyDirector: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user.user.id;
        const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
        const directorId = payload.directorId;
        const data = await updateCompanyDirector(companyId, userId, directorId, payload);

        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        thunkAPI.dispatch(userOnboardingGetCompanyDirectors());
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something went wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),

    updateCompanyRERADetails: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user.user.id;
        const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
        const data = await updateCompanyRERA(companyId, userId, payload);

        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something went wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    fetchCompanyReraDetails: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user.user.id;
          const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
          const data = await fetchCompanyRera(userId, companyId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.companyReraDetails = action.payload?.data?.response?.data;
        },
      },
    ),
  }),
});

export const {
  fetchUserOnboardingData,
  addUserPoints,
  clearUserOnboardingData,
  postOnboardingData,
  fetchUserPoints,
  userOnboardingGetAllCompanyDetails,
  userOnboardingGetAllCompanyDetailsBySearch,
  userOnboardingGetAllIndusteriesDetails,
  userOnboardingCreateCompany,
  userOnboardingAddPreferences,
  userOnboardingGetPreferencesList,
  userOnboardingGetCompany,
  userOnboardingGetOfferings,
  updateProfileClassification,
  userOnboardingGetPreferences,
  userOnboardingAssociateCompany,
  userOnboardingGetCompanyFollowStats,
  userOnboardingGetIndustryDetails,
  selectPlanPost,
  registerCrm,
  registerFms,
  registerOkr,
  postOnboardingDataForLinkedIn,
  setPreferencesUserOnboarding,
  setSearchedCompanyName,
  userOnboardingGetCompanyDirectors,
  userOnboardingAddCompanyDirector,
  userOnboardingUpdateCompanyDirector,
  updateCompanyRERADetails,
  fetchCompanyReraDetails,
} = userOnboardingSlice.actions;
export default userOnboardingSlice.reducer;
