import React from 'react';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';
import { placeHolderImageUrl } from '../../../../../../constants/globalConstants';

const NewsCardImageComponent = ({ postDetails }) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  return (
    <div className="news_card_post_images">
      {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
        <ImageCarousel
          className="newscard-carousel-image"
          imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []}
        />
      ) : (
        <img src={placeHolderImageUrl} alt="Default Post" className="carousel-image" />
      )}
    </div>
  );
};

export default NewsCardImageComponent;
