import { Card, Col, Modal, Space, Button, Avatar, Tooltip, Divider } from 'antd';
import ProspectImage from '../../components/prospectImage/ProspectImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import PhotoGallery from '../../components/photoGallery/PhotoGallery';
import Reviews from '../../components/reviews/Reviews';
import DataGrid from '../../components/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailedPropertyInfo,
  getOccupantsFromProperty,
  getVacantUnitsFromProperty,
  addBreadcrumb,
  setNavigateFrom,
  unlockVacantUnitFields,
  truncateBreadcrumbs,
  getPieAnalyticsDataFromProperty,
  getBarAnalyticsDataFromProperty,
} from '../../features/searchSlice';
import { setInsufficientPoints, setTransactionModal } from '../../features/savedSlice';
import { isEmpty } from 'lodash';
import { FormatOccupantsData } from '../../../utils/formatSearchData/Occupants';
import { FormatVacantUnitsData } from '../../../utils/formatSearchData/VacantUnit';
import AmenitiesIconEnum from '../../../utils/referenceData/search/amenitiesIconEnum';
import { getPropertyReviews } from '../../features/contactBookSlice';
import properties from '../../../assets/images/WALL.svg';
import search_organization from '../../../assets/images/search_organization.svg';

import './CommercialPropertyDetailsCard.scss';
import Address from '../../components/address/Address';
import { UserOutlined } from '@ant-design/icons';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';
import { setSelectedNav } from '../../features/universalSidenavSlice';
import OccupantsCard from '../../components/occupantsCard/OccupantsCard';
import BarGraph from '../OccupantDetailsCard/BarGraph';
import PieChart from '../OccupantDetailsCard/PieChart';

const CommercialPropertyDetailsCard = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detailedPropertyData, occupantsData, vacantUnitsData, breadcrumbs, pieAnalyticsData, barAnalyticsData } =
    useSelector((store) => store.search);
  const { transactionModal, insufficientPoints } = useSelector((store) => store.saved);
  const { propertyReviews } = useSelector((store) => store.contactBook);
  const { selectedNav } = useSelector((store) => store.leadGenSideNav);

  const [propertyDetails, setPropertyDetails] = useState({});
  const [occupantsDetails, setOccupantsDetails] = useState([]);
  const [vacantUnitDetails, setVacantUnitDetails] = useState([]);
  const [reviwesDetails, setReviewsDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('occupants');
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const toggleDetailsContainer = () => {
    setIsDetailsVisible(!isDetailsVisible); // Toggle visibility
  };

  const occupantsListColumns = [
    {
      title: 'Floor No.',
      dataIndex: 'floorNo',
      key: 'floorno',
    },
    {
      title: 'Unit No.',
      dataIndex: 'unitNo',
      key: 'unitno',
    },
    {
      title: 'Occupant',
      dataIndex: 'occupantName',
      key: 'occupantname',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.isPerson ? (
              <UserOutlined />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}

            <Text style={{ fontSize: '12px', maxWidth: '120px' }} ellipsis={{ tooltip: text }}>
              {text}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text.id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  const vacantUnitListColumns = [
    {
      title: '',
      dataIndex: 'vacantProfileImage',
      key: 'vacantProfileImage',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.vacantProfileImage ? (
              <Avatar src={record?.vacantProfileImage} />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Chargable Area',
      dataIndex: 'chargableArea',
      key: 'chargableArea',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.chargableArea).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.rate).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: (text, record) => {
        return (
          <span className="unit-property-doc-type">
            {record?.transactionType === 'Rent'
              ? 'For Rent'
              : record?.transactionType === 'Sell'
              ? 'For Sell'
              : 'Awaited'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'isResourceLocked',
      key: 'isResourceLocked',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}</div>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text._id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  const onRowClick = (id, page) => {
    if (page === 'occupants') {
      navigate(`/leadGen/occupantDetails/${id}`);
    } else if (page === 'vacantUnits') {
      const vacantUnitData = vacantUnitDetails?.filter((item) => item?.id === id);
      if (vacantUnitData?.[0]?.isResourceLocked == false) {
        navigate(`/leadGen/unitDetails/${id}`);
        return;
      }
      const transactPayload = {
        body: {
          payload: [
            {
              resourceType: 'commercial',
              resourceSubType: 'vacantUnit',
              resourceId: id,
              unlockedFields: ['address', 'poc'],
            },
          ],
        },
      };
      dispatch(unlockVacantUnitFields(transactPayload))
        .then((res) => {
          if (res?.error?.message === 'Rejected') {
            dispatch(setTransactionModal(true));
            dispatch(setInsufficientPoints(100));
            return;
          }
          navigate(`/leadGen/unitDetails/${id}`);
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }
    dispatch(setNavigateFrom('PropertyDetails'));
  };

  useEffect(() => {
    dispatch(getDetailedPropertyInfo(id));
    dispatch(getOccupantsFromProperty(id));
    dispatch(getPropertyReviews(id));
    dispatch(getVacantUnitsFromProperty(id));
    dispatch(getPieAnalyticsDataFromProperty(id));
    dispatch(getBarAnalyticsDataFromProperty(id));
    const crumb = breadcrumbs?.findIndex((el) => el?.path?.includes('occupantDetails'));
    dispatch(truncateBreadcrumbs(breadcrumbs?.[crumb]?.name));
    if (detailedPropertyData?.buildingName) {
      dispatch(
        addBreadcrumb({ name: `${detailedPropertyData?.buildingName}`, path: `/leadGen/propertyDetails/${id}` }),
      );
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      const activePage = queryParams.get('list');
      setActiveTab(activePage);
    }
  }, [id]);

  const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0;

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return (totalRating / reviews.length).toFixed(1);
  };

  useEffect(() => {
    if (isEmpty(detailedPropertyData)) return;
    setPropertyDetails(detailedPropertyData);
  }, [detailedPropertyData]);

  useEffect(() => {
    if (isEmpty(occupantsData)) return;
    const data = FormatOccupantsData(occupantsData);
    setOccupantsDetails(data);
  }, [occupantsData]);

  useEffect(() => {
    if (isEmpty(vacantUnitsData)) return;
    const data = FormatVacantUnitsData(vacantUnitsData);
    setVacantUnitDetails(data);
  }, []);

  useEffect(() => {
    if (isEmpty(propertyReviews)) return;
    setReviewsDetails(propertyReviews);
  }, [propertyReviews]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="personalDtlsSection">
      <Card style={{ flex: 1.3 }} className="personalDtlsSection-card">
        <Space direction="vertical">
          <Title level={4} strong className="card__title">
            <span
              onClick={() => {
                if (selectedNav !== 'ContactBook') {
                  dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                  navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                  dispatch(setSelectedNav('LeadGen'));
                  return;
                }
                navigate('/leadGen/contactbook');
              }}
              className="card__title-pointer"
            >
              {'< '}
              <span style={{ fontSize: '16px', verticalAlign: 'middle', fontWeight: '600' }}>
                {propertyDetails?.buildingName}
              </span>
            </span>
          </Title>
          <div className="prospectImage font10">
            <ProspectImage imageUrl={propertyDetails?.featuredImage || properties} />
            <Text className="font10" style={{ color: '#313131' }}>
              {propertyDetails?.addressInfo?.locality}, {propertyDetails?.addressInfo?.city}
            </Text>
          </div>
          <Text style={{ fontWeight: '900' }}>Building Mgmt. Contact Details</Text>
          <Text style={{ fontSize: '12px' }}>Name : {propertyDetails?.ownerName}</Text>
          <Text style={{ fontSize: '12px' }}>Mobile No : {propertyDetails?.phone}</Text>
          <Text style={{ fontSize: '12px' }}>Email : {propertyDetails?.email}</Text>
          <Text style={{ fontSize: '12px' }}>Developer Name : {propertyDetails?.developer}</Text>
          <br />
          <Address addressInfo={propertyDetails?.addressInfo} className="font900 font13" />
          <br />
          <Text style={{ fontWeight: '900' }}>About</Text>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Text style={{ fontSize: '12px' }}>{propertyDetails?.about}</Text>
          </div>
        </Space>
      </Card>
      <Card style={{ flex: 1.5, height: isDetailsVisible ? '330px' : '100%' }} className="personalDtlsSection-card">
        <Text className="font18 font600">Key Information</Text>
        <Space direction="vertical" className="w-100">
          <Space
            direction="vertical"
            className="w-100 p-10 mt-10"
            style={{ border: '2px solid #ffa31d', borderRadius: '10px', fontWeight: '900' }}
          >
            <div
              style={{
                position: 'absolute',
                backgroundColor: '#ffffff',
                padding: '10px',
                left: '87.7%',
                top: isDetailsVisible ? '36%' : '18%',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  border: '2px solid ffa31d',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {propertyDetails?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}
              </div>
            </div>
            <Text className="font12 bold">Building Type : {propertyDetails?.buildingType}</Text>
            <Text className="font12 bold">Building Status : {propertyDetails?.buildingStatus}</Text>
            <Text className="font12 bold">
              Average sale rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate
                ? `Rs. ${new Intl.NumberFormat().format(
                    Number(propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate).toFixed(2),
                  )}/-`
                : 'Awaited'}
            </Text>
            <Text className="font12 bold">
              Average rent rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate
                ? `Rs. ${new Intl.NumberFormat().format(
                    Number(propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate).toFixed(2),
                  )}/-`
                : 'Awaited'}
            </Text>
            <Text className="font12 bold">
              Average CAM chargeable :{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable)?.toFixed(2)} Rs/sqft/month`
                : 'Awaited'}
            </Text>
          </Space>
          <Text className="font12 p-10">
            <span className="bold">Total Chargeable Area : </span>
            {propertyDetails?.totalChargeableArea}
          </Text>
          <Text className="font12 p-10">
            <span className="bold"> Average Floor Plate :</span>{' '}
            {propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet
              ? `${propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet} sqft`
              : 'Awaited'}
          </Text>
          <Text className="font12 p-10">
            <span className="bold">Efficiency : </span> {propertyDetails?.efficiency || 'N/A'}
          </Text>
          <Text className="font12 p-10">
            <span className="bold">Grade of building : </span> {propertyDetails?.gradeOfBuilding || 'Awaited'}
          </Text>
          <Text className="font12 p-10">
            <span className="bold">Building Classification:</span> {propertyDetails?.buildingClassification}
          </Text>
          <Text className="font12 p-10">
            <span className="bold">Building Structure:</span> {propertyDetails?.buildingStructure}
          </Text>
          <Text className="font18 font600">Amenities</Text>
          {propertyDetails?.amenities && (
            <div className="amenitiesDtlsSection__div">
              {propertyDetails?.amenities?.map((item) => {
                return (
                  <Col key={item} span={6} className="amenitiesDtlsSection__div__col">
                    {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) ? (
                      <Tooltip title={'Default'}>
                        <img src={AmenitiesIconEnum?.['DEFAULT']} className="amenitiesDtlsSection__div__col__img" />
                      </Tooltip>
                    ) : (
                      <Tooltip title={item}>
                        <img
                          src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                          className="amenitiesDtlsSection__div__col__img"
                        />
                      </Tooltip>
                    )}
                  </Col>
                );
              })}
            </div>
          )}
          {propertyDetails?.areaFacilities && propertyDetails?.areaFacilities?.length > 0 && (
            <div className="propertyListContainer__div__card__row1__col1__div1">
              {propertyDetails?.areaFacilities?.map((item) => {
                if (item?.facility && item?.metadata?.location)
                  return (
                    <span key={item.facility} className="propertyListContainer__div__card__row1__col1__div1__span">
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">{item?.facility}</p>
                      :&nbsp;{' '}
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">
                        {item?.metadata?.location}
                      </p>
                    </span>
                  );
                return '';
              })}
            </div>
          )}
          <PhotoGallery photos={propertyDetails?.propertyImages} />
          <Reviews averageRating={calculateAverageRating(reviwesDetails)} reviewsList={reviwesDetails} />
        </Space>
      </Card>
      <div>
        <Card style={{ flex: 1.8, height: isDetailsVisible ? '330px' : '100%', width: '640px' }}>
          <OccupantsCard
            occupantsDetails={occupantsDetails}
            vacantUnitDetails={vacantUnitDetails}
            occupantsListColumns={occupantsListColumns}
            vacantUnitListColumns={vacantUnitListColumns}
            isDetailsVisible={isDetailsVisible}
            onRowClick={onRowClick}
          />
          <div className="view-details" style={{ marginLeft: '77%' }}>
            <Button type="link" onClick={toggleDetailsContainer}>
              {isDetailsVisible ? 'View Less' : 'View Analytics'}
            </Button>
          </div>
        </Card>

        <div className={isDetailsVisible ? 'details-container' : ''}>
          {isDetailsVisible && (
            <div style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'row' }}>
              <BarGraph data={barAnalyticsData} />
              <Divider
                type="vertical"
                style={{
                  height: '150px',
                  width: '5px',
                  color: '#e1dddd',
                  position: 'absolute',
                  left: '42%',
                  top: '17%',
                }}
              />
              <PieChart item={pieAnalyticsData} />
            </div>
          )}
        </div>
      </div>
      <Modal
        centered
        open={transactionModal}
        onCancel={() => dispatch(setTransactionModal(false))}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={
          <Button key="ok" type="primary" onClick={() => navigate('/user/store')}>
            Make Payment
          </Button>
        }
        className="savedModal"
      >
        <Space direction="vertical" align="center">
          <Card
            style={{
              width: 250,
              marginTop: '20px',
              background: 'transparent linear-gradient(123deg, #0081FC 0%, #66B3FD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 0px 20px #00000029',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical">
              <Title style={{ color: '#FFFFFF' }} level={4}>
                Oops insufficient points!
              </Title>
              <Text style={{ color: '#FFFFFF' }}>You need additional {insufficientPoints} points to unlock</Text>
            </Space>
          </Card>
        </Space>
      </Modal>
    </section>
  );
};

export default CommercialPropertyDetailsCard;
