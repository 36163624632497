import './personalDetails.scss';
import { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Space } from 'antd';
import UserImage from '../../../components/userImage/UserImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import verifiedIcon from '../../../../assets/images/verified.png';
import Dragger from 'antd/es/upload/Dragger';
import { CheckCircleOutlined, InboxOutlined } from '@ant-design/icons';
import LinkedinIcon from '../../../../assets/images/linkedIn.png';
// import TwitterIcon from '../../../../assets/images/twitter.png';
import TwitterIcon from '../../../../assets/images/TwitterNew.png';
import { setIsFormEditable } from '../../../features/myAccountSlice';
import { useDispatch } from 'react-redux';

const PersonalDetails = ({
  isFormEditable,
  loading,
  user,
  profileCompletion,
  handleOpenVerifyOtpModal,
  userImgUrl,
  uploadUserRERACertificate,
  personalDetails,
  setReraIdpayload,
}) => {
  const [personalEmail, setPersonalEmail] = useState(user?.personalDetails?.personalEmail ?? '');
  const [, setIsValidEmail] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isreraIdValue, setReraIdValue] = useState();
  const [isValidReraId, setIsValidReraId] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setPersonalEmail(e?.target?.value);
    setIsValidEmail(/^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/.test(personalEmail));
  };
  const handleUploadClick = (e) => {
    setIsModalVisible(true);
  };

  const handleUploadedClick = (e) => {
    setIsImageModalVisible(true);
  };

  const handleChangeReraId = (e) => {
    setReraIdValue(e);
    const formattedValue = e.toUpperCase();
    personalDetails.setFieldValue('rera', formattedValue);
    setReraIdpayload(personalDetails.getFieldValue('rera'));
  };

  useEffect(() => {
    // form.setFieldValue('rera', isreraIdValue);
  }, [isreraIdValue, form]);
  const handleModalCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };
  const handleModalVisibleCancel = () => {
    setIsImageModalVisible(false);
  };

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const handleSuccessModalClose = () => {
    setFileList([]);
    setIsSuccessModalVisible(false);
  };
  const beforeUpload = async (file) => {
    const isAllowedType = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(file.type);
    const isAllowedSize = file.size / 1024 / 1024 < 5; // Size in MB

    if (!isAllowedType) {
      message('You can only upload JPEG, JPG, PNG, or PDF files!');
      return false;
      // return Upload.LIST_IGNORE; // Prevent file from uploading
    }
    if (!isAllowedSize) {
      message.error('File must be smaller than 5MB!');
      return false;
      // return Upload.LIST_IGNORE; // Prevent file from uploading
    }

    try {
      await uploadUserRERACertificate(file);
      // await uploadUserRERACertificate(file, isreraIdValue);

      setIsModalVisible(false);
      setIsSuccessModalVisible(true);

      setReraIdpayload(personalDetails.getFieldValue('rera'));
    } catch (error) {
      console.error('Upload failed', error);
    }
    return false;
  };
  useEffect(() => {
    if (isSuccessModalVisible) {
      const timer = setTimeout(() => {
        handleSuccessModalClose();
      }, 1000); // Close the modal after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [isSuccessModalVisible, handleSuccessModalClose]);
  return (
    <>
      <div className={isModalVisible || isSuccessModalVisible ? 'blurred' : ''}>
        <div className="personalDtlsSection__personalDetails">
          <Title className="personalDetails-title">Personal Details</Title>
          <Form.Item className="editDetails-div">
            {isFormEditable ? (
              <Button className="editSaveBtn" htmlType="submit" ghost type="primary" loading={loading}>
                Save
              </Button>
            ) : null}
          </Form.Item>
          {!isFormEditable ? (
            <Button
              className="editSaveBtn"
              onClick={() => dispatch(setIsFormEditable(true))}
              ghost
              type="primary"
              loading={loading}
            >
              Edit Profile
            </Button>
          ) : null}
        </div>
        <Space direction="vertical" className="personalDetails-space">
          <Text className="personalDetails-message">Complete your entire profile details and gain 50 points</Text>
          <div className="item-center">
            <UserImage
              userImage={userImgUrl}
              isFormEditable={isFormEditable}
              isVerified={true}
              completionPercentage={profileCompletion?.overallCompletion}
              user={user}
            />
          </div>{' '}
        </Space>
        <Form.Item
          label="Name"
          name="name"
          className="formItemLabel"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          {isFormEditable ? (
            <Input
              suffix={<img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />}
              disabled={true}
              className="input-box"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('name') || '-'}
                  <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Mobile no"
          name="phone"
          className="formItemLabel"
          rules={[{ required: true, message: 'Please input your mobile!' }]}
        >
          {isFormEditable ? (
            <Input
              suffix={
                user?.verificationStatus?.phone ? (
                  <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                ) : (
                  <Button clasName="anchortobutton" onClick={() => handleOpenVerifyOtpModal(user?.phone, 'phone')}>
                    Verify
                  </Button>
                )
              }
              disabled={true}
              className="input-box"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('phone') || '-'}
                  {user?.verificationStatus?.phone && (
                    <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                  )}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Personal Email"
          name="personalEmail"
          shouldUpdate
          initialValue={personalEmail}
          className="formItemLabel companyDetails-label"
          rules={[
            {
              required: false,
              message: 'Please input valid Email.',
              pattern: /^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              onChange={handleChange}
              name="personalEmail"
              disabled={user?.personalDetails?.personalEmail}
              suffix={
                user?.verificationStatus?.personalEmail && user?.personalDetails?.personalEmail ? (
                  <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                ) : (
                  <Button
                    className="anchor-button"
                    disabled={user?.verificationStatus?.personalEmail}
                    onClick={() =>
                      user?.personalDetails?.personalEmail
                        ? handleOpenVerifyOtpModal(user?.personalDetails?.personalEmail, 'personalEmail')
                        : null
                    }
                  >
                    Verify
                  </Button>
                )
              }
              placeholder="Please enter Personal Email Id"
              className="company-placeholder input-box"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <div className="userPersonalDtls__formValues" style={{ display: 'flex', alignItems: 'center' }}>
                  <Text strong>
                    {getFieldValue('personalEmail') || '-'}
                    {user?.verificationStatus?.personalEmail ? (
                      <img
                        src={verifiedIcon}
                        alt="verified"
                        width="17px"
                        height="17px"
                        style={{ position: 'absolute', right: '11px' }}
                      />
                    ) : null}
                  </Text>
                </div>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Aadhar No"
          name="aadharNumber"
          className="formItemLabel"
          rules={[
            {
              message: 'Please input valid Aadhar no.',
              pattern: /^[0-9]{12}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              initialValue={user?.personalDetails?.aadharNumber}
              placeholder="Please enter Aadhar Number"
              type="number"
              className="input-details aadhar-input"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('aadharNumber') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Personal RERA registration id"
          name="rera"
          className="formItemLabel"
          rules={[
            {
              required: false,
              message: 'Please input valid RERA Id.',
              // pattern: /^[A-Z]{2}\/RERA\/REG\/\d{3}\/\d{4}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              placeholder="Please fill in, if you have one"
              className="input-details"
              onInput={(e) => (e.target.value = e?.target?.value?.toUpperCase())}
              onChange={(e) => handleChangeReraId(e?.target?.value)}
              defaultValue={user?.personalDetails?.personalRERARefistration?.registrationId}
              suffix={
                <Button className="anchor-button" onClick={handleUploadClick}>
                  Upload
                </Button>
              }
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <>
                  <div className="rera-upload-container">
                    <Text strong className="userPersonalDtls__formValues">
                      {getFieldValue('rera') || '-'}
                    </Text>
                    {user?.personalDetails?.personalRERARefistration?.registrationFileUrl && (
                      <>
                        <img
                          src={verifiedIcon}
                          alt="verified"
                          width="17px"
                          height="17px"
                          className="verified-icon"
                          style={{ marginLeft: '80px' }}
                        />
                        <Button type="link" className="anchor-button" onClick={handleUploadedClick}>
                          Uploaded
                        </Button>
                      </>
                    )}
                  </div>

                  {/* Modal for showing the uploaded image */}
                  <Modal
                    title="Personal RERA Registration Certificate"
                    visible={isImageModalVisible}
                    onCancel={handleModalVisibleCancel}
                    footer={null}
                    centered
                  >
                    <div style={{ textAlign: 'center' }}>
                      {user?.personalDetails?.personalRERARefistration?.registrationFileUrl ? (
                        <>
                          {/* Check if the uploaded file is a PDF */}
                          {user?.personalDetails?.personalRERARefistration?.registrationFileUrl?.endsWith('.pdf') ? (
                            <div style={{ marginTop: '20px' }}>
                              <Button
                                type="primary"
                                alt="PDF File "
                                onClick={() =>
                                  window.open(user?.personalDetails?.personalRERARefistration?.registrationFileUrl)
                                }
                                // onClick={() => {
                                //   const link = document.createElement('a');
                                //   link.href = user?.personalDetails?.personalRERARefistration?.registrationFileUrl;
                                //   link.download = 'registrationFile'; // Optional: You can set a custom file name here
                                //   document.body.appendChild(link);
                                //   link.click();
                                //   document.body.removeChild(link);
                                // }}
                              >
                                Download File
                              </Button>
                            </div>
                          ) : (
                            <img
                              src={user?.personalDetails?.personalRERARefistration?.registrationFileUrl}
                              alt="Uploaded"
                              style={{ maxWidth: '100%', maxHeight: '100%' }}
                            />
                          )}
                        </>
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                  </Modal>

                  {/* <Modal
                    title="Personal RERA Registration Certificate"
                    visible={isImageModalVisible}
                    onCancel={handleModalVisibleCancel}
                    footer={null}
                    centered
                  >
                    <div style={{ textAlign: 'center' }}>
                      {user?.personalDetails?.personalRERARefistration?.registrationFileUrl ? (
                        <img
                          src={user?.personalDetails?.personalRERARefistration?.registrationFileUrl}
                          alt="Uploaded"
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                  </Modal> */}
                </>
              )}
            </Form.Item>
          )}
          {/* Modal for Drag and Drop Upload */}
          <Modal visible={isModalVisible} onCancel={handleModalCancel} footer={null} centered>
            <Dragger
              name="file"
              multiple={false}
              beforeUpload={beforeUpload}
              fileList={fileList}
              onRemove={() => setFileList([])}
              onChange={({ fileList: newFileList }) => setFileList(newFileList)}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Drag and Drop</p>
              <p className="ant-upload-hint">your file here or upload from your gallery</p>
              <p className="ant-upload-hint" style={{ color: '#0080FC' }}>
                Only JPEG, JPG, PNG, and PDF files with a max size of 5MB
              </p>
            </Dragger>
          </Modal>
          {/* Success Modal for showing the upload success message */}
          <Modal visible={isSuccessModalVisible} onCancel={handleSuccessModalClose} footer={null} centered>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
              <h2 style={{ marginTop: '20px' }}>Uploaded Successfully!</h2>
            </div>
          </Modal>
        </Form.Item>
        <Form.Item
          label="PAN"
          name="panNumber"
          className="formItemLabel"
          rules={[
            {
              required: false,
              message: 'Please input valid PAN no.',
              pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              placeholder="Please enter Pan Number"
              className="input-details"
              initialValue={user?.personalDetails?.aadharNumber}
              onInput={(e) => (e.target.value = e?.target?.value?.toUpperCase())}
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('panNumber') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Social Profiles" className="formItemLabel">
          {isFormEditable ? (
            <>
              {/* LinkedIn URL Input */}
              <Form.Item
                name="linkedInUrl"
                placeholder="Please enter LinkedIn URL"
                label="LinkedIn URL"
                className="formItemLabel"
                rules={[
                  {
                    message: 'Please input a valid LinkedIn URL!',
                    pattern: /^https:\/\/(www\.)?linkedin\.com\/.*$/,
                  },
                ]}
              >
                <Input placeholder="Please enter LinkedIn URL" className="input-details" />
              </Form.Item>

              {/* Twitter URL Input */}
              <Form.Item
                name="twitterUrl"
                placeholder="Please enter Twitter URL"
                className="formItemLabel"
                label="Twitter URL"
                rules={[
                  {
                    message: 'Please input a valid Twitter URL!',
                    pattern: /^https:\/\/(www\.)?twitter\.com\/.*$/,
                  },
                ]}
              >
                <Input placeholder="Please enter Twitter URL" className="input-details" />
              </Form.Item>
            </>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {/* LinkedIn Icon */}
                  {getFieldValue('linkedInUrl') ? (
                    <a href={getFieldValue('linkedInUrl')} target="_blank" rel="noopener noreferrer">
                      <img src={LinkedinIcon} alt="LinkedIn Profile" width="30px" height="30px" />
                    </a>
                  ) : (
                    '-'
                  )}

                  {/* Twitter Icon */}
                  {getFieldValue('twitterUrl') ? (
                    <a href={getFieldValue('twitterUrl')} target="_blank" rel="noopener noreferrer">
                      <img
                        src={TwitterIcon}
                        alt="Twitter Profile"
                        width="30px"
                        height="30px"
                        style={{ border: '1px solid #DBDBDB', borderRadius: '5px' }}
                      />
                    </a>
                  ) : (
                    '-'
                  )}
                </div>
              )}
            </Form.Item>
          )}
        </Form.Item>
      </div>
    </>
  );
};

export default PersonalDetails;
