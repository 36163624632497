import React from 'react';
import './yourGroup.scss';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GroupCard from '../GroupCard/GroupCard';
const YourGroups = ({ groups, onCreateGroup }) => {
  return (
    <div className="yourGroupContainer">
      <div className="d-flex jc-between">
        <div className="font24 fontDark-2">Your Groups</div>
        <Button onClick={onCreateGroup} type="primary">
          <PlusOutlined /> Create New
        </Button>
      </div>
      <div className="d-flex g-10 mt-20 flex-wrap">
        {groups?.length > 0 ? groups?.map((group) => <GroupCard data={group} />) : <span>No Group found</span>}
      </div>
    </div>
  );
};

export default YourGroups;
