import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import CommonButton from '../../../common/CommonButton';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';

import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import './jobPost.scss';
import { useNavigate } from 'react-router';
import { placeHolderImageUrl } from '../../../../../../constants/globalConstants';
import RepostIcon from '../../../../../../assets/SocialApp/RepostIcon';
import ReactShowMoreText from 'react-show-more-text';

const JobCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails,
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  const navigate = useNavigate();

  return (
    <div className="job_post_content ">
      <div className="job_post_images">
        {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
          <ImageCarousel className="carousel-image" imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []} />
        ) : (
          <img src={placeHolderImageUrl} alt="Default Post" className="carousel-image" />
        )}
      </div>
      <div className="d-flex d-column jc-between w-70 word-break-all">
        {isViewPost && postDetails?.isReposted && (
          <div className="d-flex g-5 a-center mb-10">
            <div className="repost-icon">
              <RepostIcon className="repost-icon" />
            </div>
            <div>You Reposted</div>
          </div>
        )}
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Company :</span> {postDetails?.postDetails?.company}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Job Location :</span> {postDetails?.postDetails?.location}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Job Title :</span> {postDetails?.postDetails?.title}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Workplace type :</span> {postDetails?.postDetails?.workplaceType?.[0]}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Job Type :</span> {postDetails?.postDetails?.jobType?.[0]}
          </span>

          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Industry/Domain :</span> {postDetails?.postDetails?.industry}
          </span>
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex font500 fontExtraLight g-5">
            <span className="fontt600 fontLight"> Skills :</span>{' '}
            {postDetails?.postDetails?.skills?.map((elem, index) => (
              <span style={{ flex: 'none' }} key={index} className=" font14 font500 fontExtraLight g-15">
                {elem}
              </span>
            ))}
          </span>
          <span className="font14 postBody fontExtraLight d-flex g-5">
            <span className="fontt600 fontLight">
              Description :
              {isViewPost ? (
                <span
                  className="font14 postBody fontExtraLight"
                  dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
                ></span>
              ) : (
                <ReactShowMoreText
                  /* Default options */
                  lines={4}
                  more={<span className="fontBlue cursor-pointer mt-10 mb-10">See more</span>}
                  className="content-css"
                  anchorClass="show-more-less-clickable"
                  onClick={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  <div
                    className={`font14 postBody fontDark ${!isViewPost && 'truncatedText'}`}
                    dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
                  ></div>
                </ReactShowMoreText>
              )}
              {/* <span
                className="cursor-pointer mt-10 mb-10"
                style={{ fontWeight: '600', color: '#0081FC' }}
                onClick={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
              >
                See more
              </span> */}
            </span>
          </span>

          {postDetails?.postDetails?.CTA && (
            <CommonButton style={{ width: '90px' }} target="_blank" href={postDetails?.postDetails?.CTA?.link}>
              {postDetails?.postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
          setLoginPopup={setLoginPopup}
        />
      </div>
    </div>
  );
};

export default JobCardPost;
