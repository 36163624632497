import { LoadingOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialEvents, setSearchVisibleSocialApp } from '../../../features/socialAppSlice';
import { fetchAccouncementsData, fetchRecentActivities } from '../../../features/userDashboardSlice';
import NoPost from '../../myAccount/Posts/NoPost';
import ShowEventCard from './ShowEventCard';
import './showEventCard.scss';

const ShowAllEvents = () => {
  // // States
  const limit = 10;

  const [offset, setOffset] = useState(0);
  const [allEvents, setAllEvents] = useState([]);
  const dispatch = useDispatch();
  // fetching on mounting
  useEffect(() => {
    setOffset(0);
    dispatch(setSearchVisibleSocialApp(true));
    dispatch(fetchRecentActivities());
    dispatch(fetchAccouncementsData());
    dispatch(
      getSocialEvents({
        offset: offset,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllEvents(res?.payload?.data?.response?.data);
      }
    });
    return () => dispatch(setSearchVisibleSocialApp(false));
  }, []);

  // handleing infinite scroll
  const handleScroll = () => {
    setOffset((prev) => prev + limit);
    dispatch(
      getSocialEvents({
        offset: offset + limit,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllEvents((prev) => {
          return {
            ...prev,
            eventPosts: [...(prev?.eventPosts || []), ...(res?.payload?.data?.response?.data?.eventPosts || [])],
            totalCount: res?.payload?.data?.response?.data?.totalCount,
          };
        });
      }
    });
  };
  return (
    <div id="scrolldivEvents" className="eventsContainer">
      <div className="title">All Events</div>

      {allEvents?.eventPosts?.length > 0 ? (
        <InfiniteScroll
          dataLength={allEvents?.eventPosts?.length}
          next={handleScroll}
          hasMore={(allEvents?.eventPosts?.length || 0) < (allEvents?.totalCount || 0)}
          endMessage={<div className="text-center font18 p-10"></div>}
          loader={
            <span>
              <Spin className="w-100 d-flex jc-center" indicator={<LoadingOutlined />} />
            </span>
          }
          scrollableTarget="scrolldivEvents"
        >
          {/* <div className="d-flex d-column g-10"> */}
          {allEvents?.eventPosts?.length > 0 &&
            allEvents?.eventPosts?.map((event) => {
              return <ShowEventCard event={event} />;
            })}
          {/* </div> */}
        </InfiniteScroll>
      ) : (
        <NoPost />
      )}
    </div>
  );
};

export default ShowAllEvents;
