import { Divider } from 'antd';
import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSocialEvent } from '../../../features/socialAppSlice';
import CommonButton from '../common/CommonButton';
import PostHeader from '../posts/postHeader/PostHeader';
import './showEventCard.scss';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';

const ShowEventCard = ({ event }) => {
  const [events, setEvent] = useState(event);
  const sanitizer = DOMPurify.sanitize;

  const dispatch = useDispatch();
  const defaultImage =
    'https://social-dev-posts-publick.s3.ap-south-1.amazonaws.com/1724606286500-artists-eyes-N3lZic-azGs-unsplash%20%281%29.jpg';

  const interestCall = (isInterested) => {
    dispatch(updateSocialEvent({ eventId: events?._id, deleteData: isInterested }))?.then((res) => {
      const response = res?.payload?.data?.response?.data;
      if (response) {
        setEvent((prev) => ({ ...prev, isInterested: !prev?.isInterested }));
      }
    });
  };
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };
  return (
    <div className="eventCard mt-10">
      <div
        style={{
          background: `
      linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
        events?.imageUrls?.[0]?.url || placeHolderImageUrl
      })
`,
        }}
        className="eventCardImage"
      >
        <span className="eventTitle">{events?.title}</span>
        <span className="eventType">Event Type - {events?.postDetails?.type}</span>
        <span className="eventLink">
          Online link -
          <a href={events?.postDetails?.link} target="_blank">
            <span className="link">{truncate(events?.postDetails?.link, { length: 40 })}</span>
          </a>
        </span>
      </div>

      <div className="d-flex d-column jc-between w-100">
        <PostHeader
          showKebabMenu={false}
          handleFollow={() => {}}
          handleUnFollow={() => {}}
          postDetails={events}
          showFollowButton={false}
        />
        <div className="d-flex g-5 d-column flex-2">
          <Text className="font18 fontDark-3 font700 eventMainTitle">{events?.title}</Text>
          <div className="publisherContainer">
            <div className="font14 postBody fontExtraLight">{events?.postDetails?.organiser}</div>
            <Divider type="vertical" className="divider" />
            <div className="font14 postBody fontExtraLight">{formatDate(events?.postDetails?.dateTime?.date)}</div>
            {/* <Divider type="vertical" className="divider" />
            <div className="font14 postBody fontExtraLight">{events?.postDetails?.organiser}</div> */}
          </div>
          <div
            className={`font18 fontDark-3 font500`}
            dangerouslySetInnerHTML={{ __html: sanitizer(events?.postDetails?.body) }}
          ></div>
        </div>

        <CommonButton
          style={{
            width: '100%',
            height: 44,
            background: events?.isInterested ? '#0081FC' : '#F0F0F0',
            color: events?.isInterested ? '#F9F9F9' : '#151515',
          }}
          onClick={() => {
            interestCall(events?.isInterested);
          }}
          className="fontDark-3"
        >
          Interested
        </CommonButton>
      </div>
    </div>
  );
};

export default ShowEventCard;
