import Text from 'antd/es/typography/Text';
import React, { useState } from 'react';
import CommonButton from '../../../common/CommonButton';

import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Form, message } from 'antd';
import { useDispatch } from 'react-redux';
import { votePollCard } from '../../../../../features/socialAppSlice';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import { AVATAR_IMAGES } from './pollCard.utils';
import './pollCardPost.scss';
import PollComponent from './PollComponent';
import PollProgress from './PollProgress';
import ViewReportsModal from './ViewReportsModal';
import { useSearchParams } from 'react-router-dom';

const PollCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails,
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  // Initialize the form instance
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [params, setSearchParams] = useSearchParams();
  const sharedPost = params?.get('sharedPost');
  const [postDetailsData, setPostDetailsData] = useState(postDetails);
  const [loader, setLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isVoted, setIsVoted] = useState(postDetails?.postDetails?.isVoted);
  const [progressData, setProgressData] = useState(postDetails?.postDetails);

  // Handle form submission
  const handleAddVote = () => {
    const votePayload = {
      postId: postDetails._id,
      optionId: selectedValue,
    };
    setLoader(true);
    dispatch(votePollCard(votePayload))?.then((res) => {
      setLoader(false);
      message.success('Voted successfully...');
      setProgressData(res?.payload?.data?.response?.data?.options);
      setPostDetailsData((prev) => ({ ...prev, postDetails: res?.payload?.data?.response?.data }));
      setIsVoted(true);
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      setSelectedValue(value);
      form.setFieldsValue({ poll: value });
    } else {
      setSelectedValue(null);
      form.setFieldsValue({ poll: null });
    }
  };

  return (
    <div className="job_post_content ">
      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-6 flex-2">
          <Form form={form} onFinish={handleAddVote} layout="vertical" initialValues={{ poll: null }}>
            <Text className="font16 fontLight mb-20">{postDetails?.title}</Text>
            {!isVoted ? (
              <PollComponent
                options={postDetailsData?.postDetails?.options}
                selectedValue={selectedValue}
                handleCheckboxChange={handleCheckboxChange}
              />
            ) : (
              <div className="p-20">
                <PollProgress responses={progressData} />
              </div>
            )}

            <div className="d-flex g-5 jc-between a-center">
              <div className="d-flex g-5 a-center avatarGroupContainer">
                <div className="d-flex g-5 ">
                  {[...new Array(postDetailsData?.postDetails?.totalVotes || 0)]?.map((elem, index) => (
                    <Avatar key={nanoid()} src={AVATAR_IMAGES?.[index]} />
                  ))}
                </div>
                <span className="font14 fontLight-2">Total Votes: {postDetailsData?.postDetails?.totalVotes || 0}</span>
              </div>

              <div className="d-flex g-5">
                <CommonButton
                  onClick={() => {
                    setShowVideoModal(true);
                  }}
                  ghost
                >
                  View Report
                </CommonButton>
                <CommonButton
                  htmlType="submit"
                  disabled={!selectedValue || isVoted}
                  loader={loader}
                  onClick={() => {
                    if (sharedPost) {
                      setLoginPopup(true);
                      return;
                    }
                    form.submit();
                  }}
                >
                  Vote
                </CommonButton>
                <ViewReportsModal
                  showVideoModal={showVideoModal}
                  setShowVideoModal={setShowVideoModal}
                  postDetails={postDetailsData}
                />
              </div>
            </div>
          </Form>
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
          isPollCard={true}
          setLoginPopup={setLoginPopup}
        />
      </div>
    </div>
  );
};

export default PollCardPost;
