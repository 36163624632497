import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import React from 'react';
import './pollCardPost.scss';
import PollProgress from './PollProgress';

const ViewReportsModal = ({ showVideoModal, setShowVideoModal, postDetails }) => {
  return (
    <Modal
      onCancel={() => {
        setShowVideoModal(false);
      }}
      className="viewReportsModal"
      wrapper={'audio'}
      maskClosable
      closeIcon={<CloseCircleFilled className="font20 fontLight" />}
      width={700}
      footer={null}
      open={showVideoModal}
      centered
    >
      <div className="d-flex g-10 d-column p-30">
        <Text className="font16 fontLight-2 font600">Poll</Text>
        <Text className="font14 font600">Question</Text>
        <Text className="font12 fontDark">{postDetails?.title}</Text>
        <div className="d-flex jc-between">
          <Text className="font12 fontDark font600">Responses: </Text>
          <Text className="font12 fontDark font600">Total Votes: {postDetails?.postDetails?.totalVotes || 0}</Text>
        </div>
        <PollProgress viewPost={true} responses={postDetails?.postDetails?.options} showAvatar={true} />
      </div>
    </Modal>
  );
};

export default ViewReportsModal;
