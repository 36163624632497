// React imports
import React, { useEffect, useState } from 'react';

// icons imports
import logo from '../../../../assets/logo.png';
import RaiseIcon from '../../../home/images/RaiseIcon';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  LoadingOutlined,
  RightCircleFilled,
  SendOutlined,
} from '@ant-design/icons';

// css imports
import './raiseConcern.scss';
import { useDispatch, useSelector } from 'react-redux';
import { raiseConcern } from '../../../../universal/features/userSlice';
import { useLocation } from 'react-router';
import { Select, Button } from 'antd';
import categoryOptions from '../../../../assets/default-data/concernCategoryOptions.json';

const { Option } = Select;
const defaultOption = 'Select Category';

const RaiseConcern = ({ resourceDetails, onClose }) => {
  //   hooks
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userOnboardingData } = useSelector((store) => store.userOnboarding);
  // states
  const [showConcernPopOver, setShowConcernPopover] = useState(false);
  const [concernText, setConcernText] = useState('');
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    setOption(categoryOptions);
  }, []);

  // open/close raise concern popover
  const toggleShowConcernPopover = () => {
    setShowConcernPopover((prev) => !prev);
    setConcernText('');
    setSuccess(false);
    setLoader(false);
    setSelectedOption(defaultOption);
  };

  //   on concern text change
  const onConcernTextChange = (e) => {
    setConcernText(e.target.value);
    setSuccess(false);
  };

  const onOptionChange = (value) => {
    setSelectedOption(value);
  };

  const onSubmitTimer = () => {
    setTimeout(() => {
      if (onClose) onClose();
    }, 3000);
  };

  //   on raise concern click
  const raiseConcernClick = () => {
    if (concernText?.length == 0 || selectedOption === defaultOption) return;
    setLoader(true);
    const payload = {
      category: selectedOption,
      description: concernText,
      path: pathname,
      location: userOnboardingData?.geoLocation?.location,
    };

    if (onClose) {
      payload.resourceType = resourceDetails.resourceType;
      payload.resourceSubType = resourceDetails.resourceSubType;
      payload.resourceName = resourceDetails.resourceName;
      payload.resourceId = resourceDetails.resourceId;
    }
    dispatch(raiseConcern(payload)).then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response?.code == '201') {
        setConcernText('');
        setSuccess(true);
        onSubmitTimer();
      }
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    toggleShowConcernPopover();
  };

  const shouldShowPopover = onClose || showConcernPopOver;
  const containerClass = onClose ? 'raise_concern_container modal' : 'raise_concern_container';
  const popoverClass = onClose ? 'pop_over modal_pop_over' : 'pop_over';

  return (
    <div className={`${containerClass}  ${showConcernPopOver && 'show_popover'}`}>
      {shouldShowPopover && (
        <div className={popoverClass}>
          <div className="pop_over_header">
            <span className="pop_over_text">Raise a concern</span>
            <CloseCircleFilled onClick={handleClose} />
          </div>
          <div className="pop_over_content">
            {success ? (
              <span className="pop_over_text_success">
                <div className="success_box">
                  <CheckCircleFilled className="success_icon" />
                  <span>Concern raised successfully</span>
                </div>
              </span>
            ) : (
              <>
                <Select
                  placeholder={defaultOption}
                  value={selectedOption}
                  onChange={onOptionChange}
                  style={{ width: '100%' }}
                  className="custom-select"
                >
                  {option.map((item) => (
                    <Option style={{ fontSize: '11px' }} key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
                <span className="pop_over_text">Your Concern</span>
                <div className="input_container">
                  <textarea
                    rows={3}
                    placeholder="Write your concern here"
                    value={concernText}
                    onChange={onConcernTextChange}
                  />
                </div>

                <Button
                  loading={loader}
                  disabled={selectedOption === defaultOption && concernText.trim()}
                  onClick={raiseConcernClick}
                  className="submit_btn"
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {!onClose && (
        <span onClick={toggleShowConcernPopover} className="message_icon_outer_circle">
          <RaiseIcon strokeColor="#ffffff" height="30px" width="30px" />
        </span>
      )}
    </div>
  );
};

export default RaiseConcern;
