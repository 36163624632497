import { client, clientV2 } from '../../utils/apiClient';
import { asyncHandler } from '../../utils/asyncHandler';

//----------------------User Authentication APIs------------------------------------------//
const signInInit = async (param) => {
  const [data, err] = await asyncHandler(clientV2.post(`/auth/sign-in/init`, param.body, { authorization: false }));
  if (err) return err;
  return data;
};

const registerInit = async (param) => {
  const [data, err] = await asyncHandler(clientV2.post(`/auth/sign-up/init`, param.body, { authorization: false }));
  if (err) return err;
  return data;
};

const sendOtp = async (param) => {
  const [data, err] = await asyncHandler(clientV2.post(`/auth/otp/send`, param.body));
  if (err) return err;
  return data;
};

const verifyOtpAPI = async (param) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/auth/otp/verify`, param.body, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};

const signIn = async (param) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/auth/sign-in`, param.body, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};
const login = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/auth/login`, payload, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};

const register = async (param) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/auth/register`, param.body, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};
const forgotPasswordSendOtp = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/user/forgot-password/send-otp`, payload, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};
const forgotPasswordVerifyOtp = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/user/forgot-password/verify-otp`, payload, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};
const forgotPasswordUpdatePassword = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/user/forgot-password/update-password`, payload, {
      authorization: false,
    }),
  );
  if (err) return err;
  return data;
};

export {
  signInInit,
  login,
  registerInit,
  sendOtp,
  verifyOtpAPI,
  signIn,
  register,
  forgotPasswordUpdatePassword,
  forgotPasswordSendOtp,
  forgotPasswordVerifyOtp,
};
