import React, { useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { socialAppFollowUser, socialAppUnFollowUser } from '../../../../features/socialAppSlice';
import NoPost from '../../../myAccount/Posts/NoPost';
import './AllPosts.scss';
import GenericPost from './genericPost/GenericPost';
import NewsCardPost from './newsCardPost/NewsCardPost';
import ReportCardPost from './reportCardPost/ReportCardPost';
import EventCardPost from './eventCardPost/EventCardPost';
import LeadCardPost from './leadCardPost/LeadCardPost';
import AdCardPost from './adCardPost/AdCardPost';
import JobCardPost from './jobCardPost/JobCardPost';
import PollCardPost from './pollCardPost/PollCardPost';

const AllPosts = ({ setPostDetails, setAllposts, handleScroll, allPosts }) => {
  // hooks
  const dispatch = useDispatch();
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: NewsCardPost,
    ad_card: AdCardPost,
    event_card: EventCardPost,
    lead_card: LeadCardPost,
    job_card: JobCardPost,
    poll_card: PollCardPost,
  };
  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllposts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?.ownerId === userId) {
              elem = { ...elem, isFollowed: true };
            }
            return elem;
          }),
        }));
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllposts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?.ownerId === userId) {
              elem = { ...elem, isFollowed: false };
            }
            return elem;
          }),
        }));
      }
    });
  };

  return (
    <div id="scrolldiv" style={{height:'70vh'}} className="postContainer">
      {allPosts?.posts?.length > 0 ? (
        <InfiniteScroll
          dataLength={allPosts?.posts?.length}
          next={handleScroll}
          hasMore={(allPosts?.posts?.length || 0) < (allPosts?.totalRecords || 0)}
          endMessage={<div className="text-center font18 p-10">No more posts</div>}
          loader={
            <span>
              <Spin className="w-100 d-flex jc-center" indicator={<LoadingOutlined />} />
            </span>
          }
          scrollableTarget="scrolldiv"
        >
          {allPosts?.posts?.map((post) => {
            const CurrentPost = postTypes?.[post?.type];
            return (
              <div key={post._id} className="allPostContainer">
                {CurrentPost && (
                  <CurrentPost
                    setAllposts={setAllposts}
                    handleFollow={handleFollow}
                    handleUnFollow={handleUnFollow}
                    postDetails={post}
                    setPostDetails={setPostDetails}
                  />
                )}
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <NoPost />
      )}
    </div>
  );
};

export default AllPosts;
