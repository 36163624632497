import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSavedPosts, updateActivity } from '../../../features/socialAppSlice';
import MyPosts from './MyPosts';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NoPost from './NoPost';


const SavedPosts = ({ activeAccountTab, activeTab }) => {
    // hooks
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user?.user?.id);

    // States
    const limit = 10;
    const [offset, setOffset] = useState(0);
    const [allPosts, setAllposts] = useState([]);
  
    useEffect(() => {
      setOffset(0);
      if (activeTab == 'SAVED_POSTS' && activeAccountTab == 'personal_details') {
        dispatch(
            getUserSavedPosts({
            offset: offset,
            limit: limit,
          }),
        )?.then((res) => {
          if (res?.payload?.data?.response?.data) {
            setAllposts(res?.payload?.data?.response?.data?.posts);
          }
        });
      }
    }, [activeTab, activeAccountTab]);
    const handleScroll = () => {
      setOffset((prev) => prev + limit);
      dispatch(
        getUserSavedPosts({
          offset: offset + limit,
          limit: limit,
        }),
      )?.then((res) => {
        if (res?.payload?.data?.response?.data) {
          setAllposts((prev) => {
            return {
              ...prev,
              posts: [...(prev?.posts || []), ...(res?.payload?.data?.response?.data?.posts?.posts || [])],
              totalRecords: res?.payload?.data?.response?.data?.posts?.totalRecords,
            };
          });
        }
      });
    };

    const handleUnSave = (postId) => {
      dispatch(updateActivity({ userId: userId, postId: postId, action: 'unsaves' }))?.then((res) => {
        if (res?.payload) {
          setAllposts((prev) => {
            const updatedPosts = prev.posts.filter((post) => post._id !== postId);
            return {
              ...prev,
              posts: updatedPosts,
              totalRecords: prev.totalRecords - 1,
            };
          });
        }
      });
    };
  
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <InfiniteScroll
          style={{ padding: '0px 10px' }}
          dataLength={allPosts?.posts?.length || 0}
          next={handleScroll}
          hasMore={(allPosts?.posts?.length || 0) < (allPosts?.totalRecords || 0)}
          loader={
            <span>
              <Spin className="w-100 d-flex jc-center" indicator={<LoadingOutlined />} />
            </span>
          }
          scrollableTarget="scrollableDiv"
        >
          {allPosts?.posts?.length > 0 ? (
            allPosts?.posts?.map((elem, index) => <MyPosts setAllPosts={setAllposts} onDelete={handleUnSave} key={index} data={elem} />)
          ) : (
            <NoPost />
          )}
        </InfiniteScroll>
      </div>
    );
  };

export default SavedPosts