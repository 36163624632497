import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'antd';
import genericPostImage from '../../../../assets/images/genericPostImage.png';
import './PostType.scss';
import { createPostTypes, createPostTypes2 } from '../../socialApp/posts/addPost/posts.utils';

const PostType = ({ disabled, onCardClick, selectedCard, setShowForm }) => {
  const [selectedPost, setSelectedPost] = useState();
  const handleOnClick = (card, key) => {
    onCardClick(card);
    setShowForm(key);
  };
  // handleOnClick(e, card?.key)
  return (
    <div className="pl-10">
      <h2 className="font18 fontLight mt-20">Select a Post Type:</h2>
      <h3 className="font14 fontExtraLight">Please select the respective post tile</h3>
      <div className="cards-component mt-20">
        <div style={{ gap: 50 }} className="d-flex flex-wrap jc-between">
          {createPostTypes2.map((card, index) => {
            const Icon = card?.icon;
            return (
              <div key={card?.key} className={`addPostIcons cursor-pointer ${card?.disabled ? 'disabled' : ''}`}>
                <div
                  className="d-flex d-column a-center"
                  onClick={(e) => setSelectedPost({ e, key: card?.key })}
                  key={index}
                >
                  <div
                    style={selectedPost?.key == card?.key ? { background: '#0080fc' } : {}}
                    className="svgContainerMyAccount"
                  >
                    {' '}
                    {Icon && <Icon color={selectedPost?.key == card?.key ? 'white' : undefined} />}
                  </div>
                  <span className="fontLight mt-10">{card?.label}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex jc-end mt-10">
          <Button
            style={{ width: 100 }}
            className="font14 mt-20"
            onClick={() => handleOnClick(selectedPost?.e, selectedPost?.key)}
            disabled={!selectedPost}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PostType;
