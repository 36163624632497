import { SettingOutlined } from '@ant-design/icons';
import { Dropdown, Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNotificationConfig, updateNotificationConfg } from '../../../features/userSlice';
import { capitalize } from 'lodash';

const NotificationConfig = ({ type }) => {
  const [config, setConfig] = useState({});
  const dispatch = useDispatch();
  const onOpenChange = (open) => {
    if (open) {
      dispatch(
        getNotificationConfig({
          resourceType: type,
          commercial: true,
        }),
      )?.then((res) => {
        const data = res?.payload?.data?.response?.data?.notificationConfig;
        if (data) {
          setConfig(data);
        }
      });
    }
  };

  const onToggleValue = (key, value) => {
    const payload = {
      ...config,
      medium: { ...config.medium, [key]: value ? false : true },
    };
    dispatch(updateNotificationConfg(payload))?.then((res) => {
      const data = res?.payload?.data?.response?.data;
      if (data) {
        setConfig((prev) => ({
          ...prev,
          medium: { ...prev?.medium, [key]: value ? false : true },
        }));
      }
    });
  };

  const DropdownContent = (
    <div className="ps-dropdown-content">
      <div>
        <h3>Notification</h3>
      </div>
      {Object.entries(config?.medium || {})?.map(([key, value]) => (
        <div className="ps-dropdown-content__item">
          <span>{capitalize(key)}</span>
          <Switch onChange={() => onToggleValue(key, value)} checked={value} />
        </div>
      ))}
    </div>
  );

  return (
    <Dropdown onOpenChange={onOpenChange} overlay={DropdownContent} trigger={['click']} placement="bottomRight">
      <SettingOutlined style={{ marginRight: '8px', cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default NotificationConfig;
