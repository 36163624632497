import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { isEmpty, get } from 'lodash';
import base64 from 'base-64';
import OauthPopup from 'react-oauth-popup';

import {
  initLogin,
  sendforgotPasswordOtp,
  setCurrentStep,
  updateForgotPassword,
  userLogIn,
  verifyforgotPasswordOtp,
  verifyLoginOtp,
} from '../../features/loginSlice';
import { fetchUserProfileCompletion } from '../../features/myAccountSlice';
import { setAccessToken, setUserData, setUserDataV2 } from '../../features/userSlice';
import LoginForm from './LoginForm';
import Otp from '../otp/Otp';
import { parseJson, isEmail, isMobileNumber } from '../../../utils/helpers';
import { configKeys } from '../../../utils/config';
import { setCRMData, setFMSData, setOKRData } from '../../features/userDashboardSlice';
import { useSearchParams } from 'react-router-dom';
import EnterPassword from '../enterPassword/EnterPassword';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import ForgotPasswordOtp from '../forgotPassword/otp/Otp';
import UpdatePassword from '../forgotPassword/updatePassword/UpdatePassword';
import { message } from 'antd';

const Login = () => {
  const [tempUser, setTempUser] = useState({});
  const [params, setSearchParams] = useSearchParams();
  const shareableLink = params?.get('shareable');
  const { currentStep, loading, otpMedium, userId } = useSelector((store) => store.login);
  const { accessToken } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [value, setValue] = useState('');
  const [forgotPassowordPhone, setForgotPassowordPhone] = useState('');
  const [sendOtpLoader, setSendOtpLoader] = useState(false);
  const [verifyOtpLoader, setVerifyOtpLoader] = useState(false);
  const [changePasswordLoader, setChangePasswordLoader] = useState(false);

  const onCode = (code) => {
    const parsedCode = parseJson(base64.decode(code));
    if (parsedCode) {
      dispatch(setAccessToken(get(parsedCode, 'data.accessToken', '')));
      dispatch(setUserData(get(parsedCode, 'data.user', {})));
      dispatch(setCRMData(get(parsedCode, 'data.user.crmDetails', {})));
      dispatch(setOKRData(get(parsedCode, 'data.user.okrDetails', {})));
      dispatch(setFMSData(get(parsedCode, 'data.user.fmsDetails', {})));
      return;
    }

    return;
  };

  const onClose = () => console.log('closed!');

  useEffect(() => {
    if (!isEmpty(accessToken)) {
      if (shareableLink) {
        navigateTo(decodeURIComponent(shareableLink));
        return;
      }
      dispatch(fetchUserProfileCompletion());
      navigateTo('/user/offerings');
    }
  }, [accessToken, navigateTo]);

  useEffect(() => {
    dispatch(setCurrentStep('LOGIN_FORM'));
  }, [dispatch]);

  const handleSubmit = (value) => {
    if (isEmail(value) || isMobileNumber(value)) setTempUser({ [isEmail(value) ? 'email' : 'phone']: value });
    dispatch(
      initLogin({
        body: {
          type: isEmail(value) ? 'email' : 'phone',
          data: value,
        },
      }),
    );
  };
  const handlePasswordSubmit = (password) => {
    if (isEmail(value) || isMobileNumber(value)) setTempUser({ [isEmail(value) ? 'email' : 'phone']: value });
    dispatch(
      userLogIn({
        emailOrMobile: value,
        password: password?.password,
      }),
    )?.then((res) => {
      const user = res?.payload?.data?.response.data.user;
      const token = res?.payload?.data?.response.data.access_token;
      if (res?.payload?.data?.response.data) {
        dispatch(setUserData(user));
        dispatch(setAccessToken(token));
        message.success('User logged-in successfully!');
        navigateTo('/user/offerings');
      } else {
        message.error(res?.payload?.message);
      }
    });
  };
  const handleForgotPassword = (value) => {
    dispatch(
      sendforgotPasswordOtp({
        phone: Number(value?.mobileNumber) || '',
      }),
    )?.then((res) => {
      setSendOtpLoader(false);
      if (res?.payload?.data) {
        dispatch(setCurrentStep('FORGOT_PASSWORD_OTP_VERIFY'));
      } else {
        message.error(res?.payload?.message);
      }
    });
  };
  const handleVerifyOtpForgot = (otp) => {
    const otps = otp?.join('');
    setVerifyOtpLoader(true);
    dispatch(
      verifyforgotPasswordOtp({
        phone: Number(forgotPassowordPhone),
        otp: parseInt(otps),
      }),
    )?.then((res) => {
      setVerifyOtpLoader(false);
      console.log('res', res);
      if (res?.payload?.data) {
        dispatch(setCurrentStep('FORGOT_PASSWORD_NEW_PASSWORD'));
      } else {
        message.error(res?.payload?.message);
      }
    });
  };
  const handleSubmitUpdatePass = (value) => {
    dispatch(
      updateForgotPassword({
        phone: Number(forgotPassowordPhone) || '',
        password: value?.password,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response) {
        dispatch(setCurrentStep('LOGIN_FORM'));
        message.success('Password reset successfully');
      } else {
        message.error(res?.payload?.message);
      }
    });
  };

  const handleVerifyOtp = (otp) => {
    const payload = otp.join('');
    dispatch(
      verifyLoginOtp({
        otp: payload,
      }),
    );
  };

  const handleCloseEmailVerification = () => {}; // This function is a empty function just to

  const STEPS_MAP = Object.freeze({
    LOGIN_FORM: (
      <LoginForm
        handleSubmit={handleSubmit}
        loading={loading}
        onClose={onClose}
        onCode={onCode}
        value={value}
        setValue={setValue}
        OauthPopup={OauthPopup}
        url={`${configKeys.API_URL}/auth/linkedin`}
      />
    ),
    ENTER_PASSWORD: (
      <EnterPassword
        handleSubmit={handlePasswordSubmit}
        loading={loading}
        onClose={onClose}
        onCode={onCode}
        OauthPopup={OauthPopup}
        url={`${configKeys.API_URL}/auth/linkedin`}
      />
    ),
    FORGOT_PASSWORD: (
      <ForgotPassword
        handleSubmit={handleForgotPassword}
        loading={sendOtpLoader}
        forgotPassowordPhone={forgotPassowordPhone}
        setForgotPassowordPhone={setForgotPassowordPhone}
      />
    ),
    FORGOT_PASSWORD_OTP_VERIFY: (
      <ForgotPasswordOtp
        handleVerifyOtp={handleVerifyOtpForgot}
        loading={verifyOtpLoader}
        phone={forgotPassowordPhone}
      />
    ),
    FORGOT_PASSWORD_NEW_PASSWORD: (
      <UpdatePassword loading={changePasswordLoader} handleSubmit={handleSubmitUpdatePass} />
    ),

    OTP_FORM: (
      <Otp
        handleCloseEmailVerification={handleCloseEmailVerification}
        handleVerifyOtp={handleVerifyOtp}
        loading={loading}
        mediumType={otpMedium}
        user={tempUser}
        backDispatch={() => setCurrentStep('LOGIN_FORM')}
        hashedUserId={userId}
      />
    ),
  });

  return <div>{STEPS_MAP[currentStep]}</div>;
};

export default Login;
