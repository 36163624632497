import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export const SocialShareHOC = () => {
  const location = useLocation();
  const { pathname, search } = location;
  // Search params
  const { accessToken } = useSelector((store) => store.user);
  return !isEmpty(accessToken) ? (
    <Navigate to={`/user${pathname + search}`} />
  ) : (
    <Navigate to={`/share${pathname + search}?sharedPost=true`} />
  );
};
