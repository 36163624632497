import { Space } from 'antd';
import Text from 'antd/es/typography/Text';
import './PhotoGallery.scss';
const PhotoGallery = ({ photos }) => {
  return (
    <>
      <Space direction="vertical" classNames="photogallery-container">
        <Text style={{ fontWeight: '900' }}>Photo Gallery</Text>
        <div className="photogallery-container__imgbox">
          {photos?.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="photogallery"
              className="photogallery-container__imgbox-img"
              onError={(e) =>
                (e.target.src = 'https://s3.ap-south-1.amazonaws.com/trythatai.dev/image_2024_03_10T18_24_42_397Z.png')
              }
            />
          ))}
        </div>
      </Space>
    </>
  );
};

export default PhotoGallery;
