import React from 'react';
import { Button, Typography, Divider } from 'antd';
import './BuildingStatsComponent.scss'; // Create custom styles for specific needs
import FloorComponent from '../floorComponent/FloorComponent';

const { Title, Link } = Typography;

const BuildingStatsComponent = ({ floors }) => {
  return (
    <div className="building-stats">
      <div>
        <Title level={4}>Stats of building</Title>
      </div>
      <div className="tab-header">
        <span className="font14" style={{ color: '#0081FC', marginRight: '20px' }}>
          Occupied
        </span>
        <span className="font14" style={{ color: '#767676' }}>
          Vacant
        </span>
      </div>
      <div className="floors">
        {floors.map((floor) => (
          <FloorComponent key={floor.number} floorNumber={floor.number} rooms={floor.rooms} />
        ))}
      </div>
      <Divider />
      <div className="extra-info">
        <div className="floor">
          <div className="floor-number">
            <Button type="primary" shape="circle">
              G
            </Button>
          </div>
          <div className="rooms">
            <Button className="room staticFloors" shape="round" size="large">
              <span style={{ color: '#151515', textAlign: 'center' }}>Ground Floor</span>
            </Button>
          </div>
        </div>
        <div className="floor">
          <div className="floor-number">
            <Button type="primary" shape="circle">
              B
            </Button>
          </div>
          <div className="rooms">
            <Button className="room staticFloors" shape="round" size="large">
              <span style={{ color: '#151515', textAlign: 'center' }}>Basement</span>
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="analytics-link">
        <Link href="#">View Analytics</Link>
      </div> */}
    </div>
  );
};

export default BuildingStatsComponent;
