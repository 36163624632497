import React, { Component } from 'react';
import './toggleSwitch.scss';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
const ToggleSwitch = ({ checked, disabled, onClick, imageUrl }) => {
  return (
    <div className={`toggle-switch ${disabled && 'disabled'}`}>
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name="toggleSwitch"
        id="toggleSwitch"
        checked={checked}
        disabled={disabled}
        onClick={onClick}
      />
      <label className="toggle-switch-label" htmlFor="toggleSwitch">
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch">
          <Avatar style={{ width: '100%', height: '100%' }} icon={<UserOutlined />} src={imageUrl} />
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
