import { CloseCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import { isEmpty } from 'lodash';

import React, { useEffect, useState } from 'react';
const { CheckableTag } = Tag;

const SelectUploadedImages = ({ onChange, setImgUrl = () => {}, setImageNames = () => {}, imageUrl = [] }) => {
  const uploadedImages = [
    {
      url: 'https://fastly.picsum.photos/id/9/5000/3269.jpg?hmac=cZKbaLeduq7rNB8X-bigYO8bvPIWtT-mh8GRXtU3vPc',
      id: 1,
    },
    {
      url: 'https://fastly.picsum.photos/id/17/2500/1667.jpg?hmac=HD-JrnNUZjFiP2UZQvWcKrgLoC_pc_ouUSWv8kHsJJY',
      id: 2,
    },
    {
      url: 'https://fastly.picsum.photos/id/58/1280/853.jpg?hmac=YO3QnOm9TpyM5DqsJjoM4CHg8oIq4cMWLpd9ALoP908',
      id: 3,
    },
    {
      url: 'https://fastly.picsum.photos/id/56/2880/1920.jpg?hmac=BIplhYgNZ9bsjPXYhD0xx6M1yPgmg4HtthKkCeJp6Fk',
      id: 4,
    },
    {
      url: 'https://fastly.picsum.photos/id/89/4608/2592.jpg?hmac=G9E4z5RMJgMUjgTzeR4CFlORjvogsGtqFQozIRqugBk',
      id: 5,
    },
  ];

  const [selectedImages, setSelectedImages] = useState([]);
  const [alltags, setAllTags] = useState(uploadedImages);

  const handleChange = (tag, checked) => {
    setImageNames([]);
    const nextSelectedImages = checked ? [...(selectedImages || []), tag] : selectedImages.filter((t) => t !== tag);
    setSelectedImages(nextSelectedImages);
    setImgUrl(nextSelectedImages?.map((elem) => elem?.url));
    if (typeof onChange === 'function') {
      // <-- BE VERY CAREFULL WITH THIS OPERATION
      onChange(nextSelectedImages);
    }
  };

  // useEffect(() => {
  //   if (isEmpty(imageUrl)) onChange([]);
  // }, [imageUrl]);

  return (
    <div className="d-flex d-column g-5 jc-center ">
      <div className="d-flex jc-between">
        {alltags?.map((tag) => (
          <CheckableTag
            className="preUploadedImages"
            key={tag}
            checked={selectedImages?.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            <img style={{ width: 40, height: 40, borderRadius: 8 }} src={tag?.url} />
            {selectedImages?.includes(tag) && <CloseCircleFilled className="unSelectImage" />}
          </CheckableTag>
        ))}
      </div>
      <span className="fontExtraLight font10">
        Note: Upload JPEG or PNG images up to 5MB, or select from the image library.
      </span>
    </div>
  );
};

export default SelectUploadedImages;
