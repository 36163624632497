import React, { useEffect, useState } from 'react';
import GenericPostForm from '../../socialApp/posts/addPostForms/GenericPostForm/GenericPostForm';
import NewsPostForm from '../../socialApp/posts/addPostForms/NewsPostForm/NewsPostForm';
import ReportPostForm from '../../socialApp/posts/addPostForms/ReportsPostForm/ReportsPostForm';
import PostType from './PostType';
import AdvertisementPostForm from '../../socialApp/posts/addPostForms/AdvertisementPostForm/AdvertisementPostForm';
import EventCardForm from '../../socialApp/posts/addPostForms/EventCardForm/EventCardForm';
import RequirementCardForm from '../../socialApp/posts/addPostForms/RequirementCardForm/RequirementCardForm';
import JobPostForm from '../../socialApp/posts/addPostForms/JobPostForm/JobPostForm';
import PollCardForm from '../../socialApp/posts/addPostForms/PollCardForm/PollCardForm';

const CreatePost = ({ hidePostType = false, setActiveTab, isCompany, postRel }) => {
  const [disabled, setDisabled] = useState(true);
  const [card, setSelectedCard] = useState({});
  const [showForm, setShowForm] = useState(false);
  const onCardClick = (newCard) => {
    if (card?.key === newCard?.key) {
      setDisabled(!disabled);
    } else {
      setDisabled(false);
    }
    setSelectedCard(newCard);
  };

  useEffect(() => {
    if (!showForm) {
      setSelectedCard({});
      setDisabled(true);
    }
  }, [showForm, hidePostType]);

  const formMaps = {
    GENERIC_POST: GenericPostForm,
    REPORT_CARD: ReportPostForm,
    NEWS_CARD: NewsPostForm,
    Ad_CARD: AdvertisementPostForm,
    EVENT_CARD: EventCardForm,
    LEAD_CARD: RequirementCardForm,
    JOB_CARD: JobPostForm,
    POLL_CARD: PollCardForm,
  };
  const FormComponent = formMaps?.[showForm];

  return (
    <>
      {showForm || hidePostType ? (
        <FormComponent
          isPostFromMyAccount={true}
          postRel={postRel}
          setActiveTab={setActiveTab}
          isCompany={isCompany}
          description={card?.description}
          setShowForm={setShowForm}
        />
      ) : (
        <PostType onCardClick={onCardClick} disabled={disabled} setShowForm={setShowForm} selectedCard={card} />
      )}
    </>
  );
};

export default CreatePost;
