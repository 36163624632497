import { DeleteOutlined, EditOutlined, UserOutlined, WarningFilled } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import './postHeaderKebabMenu.scss';

const PostHeaderKeababMenu = ({ onAccountDetailsCLick, onReportAccountClick }) => {
  const [visible, setVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();

    setVisible(false);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setVisible((prevVisible) => !prevVisible);
  };

  const handleMouseEnter = (key) => {
    setHoveredItem(key);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const menu = (
    <Menu onClick={handleMenuClick} className="custom-menu">
      <Menu.Item
        key="1"
        onClick={onAccountDetailsCLick}
        onMouseEnter={() => handleMouseEnter('1')}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: hoveredItem === '1' ? '#f2f9ff' : 'transparent',
        }}
      >
        <div className="hover-change">
          <span>
            <UserOutlined /> Account Details
          </span>
        </div>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={onReportAccountClick}
        onMouseEnter={() => handleMouseEnter('2')}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: hoveredItem === '2' ? '#f2f9ff' : 'transparent',
        }}
      >
        <div className="hover-change">
          <span className="fontError">
            <WarningFilled /> Report
          </span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={visible}
      onClick={handleDropdownClick}
      onVisibleChange={setVisible}
      overlayClassName="custom-menu"
    >
      <div className="kebab-menu-icon" onClick={handleDropdownClick}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </Dropdown>
  );
};

export default PostHeaderKeababMenu;
