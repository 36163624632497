import { FileOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Spin, Typography, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';
import SelectUploadedImages from '../SelectUploadedImages';
import ReactQuillComponent from '../common/ReactQuillComponent';
import { bytesToMB } from '../addPostForm.utils';
// import './genericPostForm.scss';
const AdvertisementPostForm = ({
  setShowForm = () => {},
  setAllposts,
  closePopup = () => {},
  setActiveTab = () => {},
  isCompany,
  postRel = 'user_post',
  isPostFromMyAccount = false,
  groupId,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  // form derails
  const [formDetails] = Form.useForm();

  // states

  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const userId = useSelector((state) => state.user?.user?.id);
  const userv2 = useSelector((state) => state.user?.userV2);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const companyName = useSelector((state) => state.user?.user?.professionalDetails?.companyName);

  // handle image upload
  const handleUpload = async (file) => {
    if (bytesToMB(file?.size) <= 5) {
      const formData = new FormData();
      formData.append('files', file);
      setImageloader(true);
      dispatch(uploadPostImage(formData))?.then((res) => {
        setImageloader(false);
        message.success(`Image uploaded successfully...`);
        if (res?.payload?.data?.response?.data) {
          setImgNames((prev) => [...(prev || []), file?.name]);
          setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        }
      });
    } else {
      message.error('File size should not exceed 5 MB');
    }
  };

  // handle submit
  const handleFormSubmit = (values) => {
    const createPostPayload = {
      title: values?.post_title || '',
      type: 'ad_card',
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      postDetails: {
        body: values?.post_description || '',
        city: values?.city || '',
        locality: values?.locality || '',
        designation: values?.designation || '',
        CTA: {
          name: values?.cta || '',
          link: values?.link || '',
        },
      },
      isGroupPost: groupId || undefined,
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        closePopup();
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        message.success('Post created successfully...');
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'ad_card',
            title: values?.post_title || '',
            body: values?.post_caption || '',
            ownerId: userId,
            ownerType: postRel,
            likes: 0,
            comments: 0,
            isOwner: true,
            imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
            postDetails: {
              body: values?.post_description || '',
              city: values?.city || '',
              locality: values?.locality || '',
              designation: values?.designation || '',
              CTA: {
                name: values?.cta || '',
                link: values?.imageURL || '',
              },
            },
            ownerDetails: {
              _id: userId,
              name: postRel === 'company_post' ? companyName : userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.professionalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));

          // Clear any existing timeout to avoid removing the wrong item
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          //clear post after 3 seconds from all posts
          // const id = setTimeout(() => {
          //   setAllposts((prevItems) => ({
          //     ...prevItems,
          //     posts: prevItems?.posts?.slice(1) || [],
          //     totalRecords: prevItems?.totalRecords - 1,
          //   }));
          // }, 5000);

          // setTimeoutId(id);
        }
      } else {
        message.error('Some error occurred...');
      }
    });
  };

  //reset from
  const resetForm = () => {
    formDetails.resetFields();
    setImgNames([]);
    setImgUrls([]);
  };

  // Cleanup timeout if the component unmounts before timeout completes
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  //JSX
  return (
    <div className="genericpostform">
      {true && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark font600">Advt.</Text>
          </div>
          {!isPostFromMyAccount && (
            <div>
              <CommonButton ghost size="large" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          )}
        </div>
      )}
      <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
        <div className="mt-10">
          <div className={`d-flex ${isPostFromMyAccount && 'd-column'} `}>
            <div className={`imageContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="imageURL"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Please upload a file!',
                  },
                ]}
              >
                <div className="d-flex g-20" style={{ marginBottom: '20px' }}>
                  <Upload
                    listType="picture-card"
                    className="uploadImage"
                    multiple
                    accept=".png,.jpg,.jpeg,.avif"
                    customRequest={({ file }) => handleUpload(file)}
                    showUploadList={false}
                  >
                    {imgLoader ? (
                      <Spin indicator={<LoadingOutlined />} />
                    ) : (
                      <>
                        <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Image</Text>
                      </>
                    )}
                  </Upload>
                </div>
                {imgNames?.length > 0 && (
                  <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
                    {imgNames?.map((elem, index) => (
                      <span className="upoadedFileContainer" key={index}>
                        <FileOutlined /> {elem}
                      </span>
                    ))}
                  </div>
                )}
              </Form.Item>
              <Form.Item required label="" className="dropdown-form-item">
                <Form.Item name="imageURL">
                  <SelectUploadedImages imageUrl={imgUrls} setImgUrl={setImgUrls} setImageNames={setImgNames} />
                </Form.Item>
              </Form.Item>
            </div>
            <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="post_title"
                required
                label="AD Title"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Post Title!',
                  },
                ]}
              >
                <Input placeholder="Enter Title" min={0} />
              </Form.Item>
              <Form.Item
                name="post_description"
                required
                label="Description"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Description!',
                  },
                ]}
              >
                {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                <ReactQuillComponent placeholder="Write description here under 256 characters" />
                {/* <TextArea placeholder="Write Description here" style={{ width: '470px', height: 80 }} min={0} /> */}
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="designation"
                    label="Designation"
                    className="buyerrow-form__space--text"
                    style={{ marginLeft: '17px' }}
                  >
                    <Input placeholder="Designation" style={{ width: '90%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="city" label="City" className="buyerrow-form__space--text">
                    <Input style={{ width: ' 95%' }} min={0} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="locality" label="Locality" className="dropdown-form-item">
                <Input min={0} />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="cta"
                    // required
                    label="CTA"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const link = getFieldValue('link');
                          if (link && !value) {
                            return Promise.reject('Enter CTA');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    className="buyerrow-form__space--text"
                    style={{ marginLeft: '17px' }}
                  >
                    <Input placeholder="Ex. Contact Us" style={{ width: '90%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="link"
                    label="Link"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const buttonCTA = getFieldValue('cta');
                          if (buttonCTA && !value) {
                            return Promise.reject('Enter Website Link');
                          }
                          return Promise.resolve();
                        },
                      }),
                      {
                        message: 'Please enter valid url.',
                        pattern:
                          '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                      },
                    ]}
                    className="buyerrow-form__space--text"
                  >
                    <Input placeholder="http://" style={{ width: ' 95%' }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {isPostFromMyAccount && (
          <div className="d-flex a-center jc-end g-10">
            <div>
              <CommonButton ghost size="large" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default AdvertisementPostForm;
