import DOMPurify from 'dompurify';
import React from 'react';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import './leadCardPost.scss';
import RepostIcon from '../../../../../../assets/SocialApp/RepostIcon';

const LeadCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  const sanitizer = DOMPurify.sanitize;

  return (
    <div className="lead_card_post_content ">
      <div className="d-flex d-column jc-between w-100 word-break-all">
        {isViewPost && postDetails?.isReposted && (
          <div className="d-flex g-5 a-center mb-10">
            <div className="repost-icon">
              <RepostIcon className="repost-icon" />
            </div>
            <div>You Reposted</div>
          </div>
        )}
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <div
            className={`font14 postBody fontExtraLight ${!isViewPost && 'truncatedText'}`}
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>

          {/* <span>Designation: Sales Associate</span> */}
          <div>Engagement Type: {postDetails?.postDetails?.engagementType}</div>
          <div>City: {postDetails?.postDetails?.location}</div>
          <div>Is Open to Broker: {postDetails?.postDetails?.isOpenToBroker ? 'Yes' : 'No'}</div>
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
          setLoginPopup={setLoginPopup}
        />
      </div>
    </div>
  );
};

export default LeadCardPost;
