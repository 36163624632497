export default function UnlockedIcon({ fillColor, strokeColor, selectedPage }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.481" height="20" viewBox="0 0 18.481 20">
      <g id="Group_27313" data-name="Group 27313" transform="translate(-0.134 -0.5)">
        <path
          id="Union_3"
          data-name="Union 3"
          d="M2444.055-351a1.423,1.423,0,0,1-1.42-1.421v-9.94a1.422,1.422,0,0,1,1.42-1.42h8.5v-2.938a4.285,4.285,0,0,1,4.279-4.28,4.284,4.284,0,0,1,4.28,4.28v4.247h-1.42v-4.247a2.863,2.863,0,0,0-2.86-2.86,2.863,2.863,0,0,0-2.859,2.86v2.938h1.439a1.421,1.421,0,0,1,1.42,1.42v9.94a1.422,1.422,0,0,1-1.42,1.421Zm0-1.421h11.361v-9.94h-11.361Zm3.551-4.971a2.13,2.13,0,0,1,2.13-2.129,2.13,2.13,0,0,1,2.13,2.129,2.13,2.13,0,0,1-2.13,2.131A2.13,2.13,0,0,1,2447.606-357.392Z"
          transform="translate(-2442.5 371.5)"
          fill="#ffa31d"
        />
      </g>
    </svg>
  );
}
