import { Divider } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCompanyToggleOnPostTrue } from '../../../../features/socialAppSlice';
import ToggleSwitch from '../../common/toggleSwitch/ToggleSwitch';
import { createPostTypes2 } from './posts.utils';

const AddPost = ({ isCompanyToggleOnPostTrue, showPostForm, setShowPostForm, imageUrl = '' }) => {
  const dispatch = useDispatch();
  const handleCardClick = (key) => {
    setShowPostForm(key);
  };

  const userRole = useSelector((store) => store?.user?.userV2?.companyDetails?.type);
  const user = useSelector((store) => store?.user);
  const companyName = useSelector((state) => state.user?.user?.professionalDetails?.companyName);

  // Checking is user is admin or not
  const isUserAdmin = () => {
    return userRole?.toLowerCase() === 'admin';
  };

  return (
    <div className="socialAppContainer__postContainer--addPost addPost">
      <div className="d-flex jc-between p-10">
        <div className="font14 font700 p-5">Create your post</div>

        <ToggleSwitch
          disabled={!isUserAdmin()}
          imageUrl={isCompanyToggleOnPostTrue ? null : user?.personalDetails?.imageUrl}
          checked={isCompanyToggleOnPostTrue}
          onClick={() => dispatch(setIsCompanyToggleOnPostTrue())}
        />
      </div>
      <Divider className="addPostdivider" />
      <div className="d-flex g-20 a-start pt-20 pb-10">
        {createPostTypes2?.map((card, index) => {
          const Icon = card?.icon;
          return (
            <div key={card?.key} className={`addPostIcons  cursor-pointer ${card?.disabled ? 'disabled' : ''}`}>
              <div className="d-flex d-column a-start" g-5 onClick={() => handleCardClick(card?.key)} key={index}>
                <div className="svgContainer"> {Icon && <Icon />}</div>
                <div className="svgContainerTwo"> {Icon && <Icon color={'#0081FC'} />}</div>
                <span className="cardLabel">{card?.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddPost;
