export default function SaveForLaterIcon({ strokeColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 22">
      <path
        id="Path_18546"
        data-name="Path 18546"
        d="M10,5.925A1.927,1.927,0,0,1,11.925,4h11.55A1.927,1.927,0,0,1,25.4,5.925V25.176a.826.826,0,0,1-1.342.644L17.7,20.732l-6.358,5.088A.826.826,0,0,1,10,25.176Zm1.925-.275a.272.272,0,0,0-.275.275V23.46l5.533-4.428a.827.827,0,0,1,1.034,0l5.533,4.428V5.925a.272.272,0,0,0-.275-.275Z"
        transform="translate(-10 -4)"
        fill="white"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
      />
    </svg>
  );
}
