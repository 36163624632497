import React from 'react';
import CustomSkeleton from '../../../../../../universal/components/skeleton/CustomSkeleton';
import '../companyDetails.scss';
import { nanoid } from '@reduxjs/toolkit';

const SelectedCompanyDetailsSkeleton = () => {
  return (
    <div className="companyCard border-light-2">
      <div className=" mt-10 flex-1">
        <div className="d-flex mt-10 g-5 a-center ">
          <CustomSkeleton width={50} height={50} />
          <div className="flex-1">
            <CustomSkeleton width={'50%'} height={30} />
            <div className="d-flex g-5 mt-10">
              {[...new Array(5)]?.map(() => (
                <CustomSkeleton key={nanoid()} height={20} width={20} />
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex g-5 mt-10">
          <CustomSkeleton height={100} width={'100%'} />
          <CustomSkeleton height={100} width={'100%'} />
        </div>
      </div>
      <div className="bottomRow  d-flex jc-center">
        <CustomSkeleton height={20} width={'100%'} />
      </div>
    </div>
  );
};

export default SelectedCompanyDetailsSkeleton;
