import React from 'react';

const AudioIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.323" height="31.233" viewBox="0 0 27.323 31.233">
      <path
        id="Icon_fa-solid-podcast"
        data-name="Icon fa-solid-podcast"
        d="M19.48,22.688a10.734,10.734,0,1,0-11.637,0c.073,1.055.244,2.318.439,3.482l.012.061a13.662,13.662,0,1,1,10.734,0l.012-.067C19.23,24.993,19.407,23.737,19.48,22.688Zm-.14-2.324a3.939,3.939,0,0,0-.427-.988,4.153,4.153,0,0,0-1.336-1.366,5.855,5.855,0,1,0-7.831,0A4.153,4.153,0,0,0,8.41,19.376a3.939,3.939,0,0,0-.427.988,8.782,8.782,0,1,1,11.356,0Zm-5.678-1.336c2.007,0,3.9.525,3.9,2.671a44.639,44.639,0,0,1-1.256,8.105c-.311,1.159-1.494,1.427-2.647,1.427s-2.33-.268-2.647-1.427a43.723,43.723,0,0,1-1.256-8.1c0-2.141,1.9-2.671,3.9-2.671Zm0-8.782a3.415,3.415,0,1,1-3.415,3.415,3.415,3.415,0,0,1,3.415-3.415Z"
        fill={color}
      />
    </svg>
  );
};

export default AudioIcon;
