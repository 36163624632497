import { Button } from 'antd';

const Room = ({ number, occupied }) => {
  return (
    <Button className={`room ${occupied ? 'occupied' : 'vacant'}`} shape="round" size="large">
      {number}
    </Button>
  );
};

export default Room;
