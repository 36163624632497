export default function SocialIcon({ strokeColor }) {
  return (
    <svg
      id="Group_23363"
      data-name="Group 23363"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 20 22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_8673"
            data-name="Rectangle 8673"
            width="19.96"
            height="20"
            fill="none"
            // stroke={strokeColor}
            
          />
        </clipPath>
      </defs>
      <g id="Group_22726" data-name="Group 22726" transform="translate(0 0)">
        <g id="Group_22725" data-name="Group 22725" clip-path="url(#clip-path)">
          <path
            id="Path_15556"
            data-name="Path 15556"
            d="M44.492,1.615A5.174,5.174,0,0,0,40.813,0h0c-.059,0-.123,0-.185,0a5.227,5.227,0,0,0-.643,10.392,4.675,4.675,0,0,0,.777.064,5.334,5.334,0,0,0,5.222-4.948l.008-.158a5.173,5.173,0,0,0-1.5-3.738M43.3,8.59a3.56,3.56,0,0,1-2.482.916,4.213,4.213,0,0,1-2.6-.881l-.244-.19.241-.187a4.134,4.134,0,0,1,2.56-.889,4.048,4.048,0,0,1,2.512.868L43.5,8.4ZM40.757,5.216a1.2,1.2,0,0,1-1.194-1.149,1.165,1.165,0,0,1,.329-.843,1.2,1.2,0,0,1,.865-.361h.024a1.177,1.177,0,0,1-.024,2.354M44.4,7.4l-.112.177-2.822-1.3.252-.246a3.176,3.176,0,0,0,1.165-2.142,1.888,1.888,0,0,0-.648-1.376A2.108,2.108,0,0,0,40.762,1.9a2.148,2.148,0,0,0-1.62.75,1.964,1.964,0,0,0-.519,1.462A2.85,2.85,0,0,0,39.8,6l.3.244-2.86,1.315-.115-.155a4.153,4.153,0,0,1,.688-5.289A4.273,4.273,0,0,1,40.751.951a4.318,4.318,0,0,1,2.734.985A4.216,4.216,0,0,1,44.4,7.4"
            transform="translate(-26.032 0)"
            fill="none"
            // stroke={strokeColor}
          
          />
          <path
            id="Path_15557"
            data-name="Path 15557"
            d="M44.492,1.615A5.174,5.174,0,0,0,40.813,0h0c-.059,0-.123,0-.185,0a5.227,5.227,0,0,0-.643,10.392,4.675,4.675,0,0,0,.777.064,5.334,5.334,0,0,0,5.222-4.948l.008-.158a5.173,5.173,0,0,0-1.5-3.738M43.3,8.59a3.56,3.56,0,0,1-2.482.916,4.213,4.213,0,0,1-2.6-.881l-.244-.19.241-.187a4.134,4.134,0,0,1,2.56-.889,4.048,4.048,0,0,1,2.512.868L43.5,8.4ZM40.757,5.216a1.2,1.2,0,0,1-1.194-1.149,1.165,1.165,0,0,1,.329-.843,1.2,1.2,0,0,1,.865-.361h.024a1.177,1.177,0,0,1-.024,2.354M44.4,7.4l-.112.177-2.822-1.3.252-.246a3.176,3.176,0,0,0,1.165-2.142,1.888,1.888,0,0,0-.648-1.376A2.108,2.108,0,0,0,40.762,1.9a2.148,2.148,0,0,0-1.62.75,1.964,1.964,0,0,0-.519,1.462A2.85,2.85,0,0,0,39.8,6l.3.244-2.86,1.315-.115-.155a4.153,4.153,0,0,1,.688-5.289A4.273,4.273,0,0,1,40.751.951a4.318,4.318,0,0,1,2.734.985A4.216,4.216,0,0,1,44.4,7.4"
            transform="translate(-26.032 0)"
            fill="none"
            // stroke={strokeColor}
            
          />
          <path
            id="Path_15558"
            data-name="Path 15558"
            d="M44.492,1.615A5.174,5.174,0,0,0,40.813,0h0c-.059,0-.123,0-.185,0a5.227,5.227,0,0,0-.643,10.392,4.675,4.675,0,0,0,.777.064,5.334,5.334,0,0,0,5.222-4.948l.008-.158a5.173,5.173,0,0,0-1.5-3.738M43.3,8.59a3.56,3.56,0,0,1-2.482.916,4.213,4.213,0,0,1-2.6-.881l-.244-.19.241-.187a4.134,4.134,0,0,1,2.56-.889,4.048,4.048,0,0,1,2.512.868L43.5,8.4ZM40.757,5.216a1.2,1.2,0,0,1-1.194-1.149,1.165,1.165,0,0,1,.329-.843,1.2,1.2,0,0,1,.865-.361h.024a1.177,1.177,0,0,1-.024,2.354M44.4,7.4l-.112.177-2.822-1.3.252-.246a3.176,3.176,0,0,0,1.165-2.142,1.888,1.888,0,0,0-.648-1.376A2.108,2.108,0,0,0,40.762,1.9a2.148,2.148,0,0,0-1.62.75,1.964,1.964,0,0,0-.519,1.462A2.85,2.85,0,0,0,39.8,6l.3.244-2.86,1.315-.115-.155a4.153,4.153,0,0,1,.688-5.289A4.273,4.273,0,0,1,40.751.951a4.318,4.318,0,0,1,2.734.985A4.216,4.216,0,0,1,44.4,7.4"
            transform="translate(-26.032 0)"
            fill="none"
          // stroke={strokeColor}
        
          />
          <path
            id="Path_15559"
            data-name="Path 15559"
            d="M44.492,1.615A5.174,5.174,0,0,0,40.813,0h0c-.059,0-.123,0-.185,0a5.227,5.227,0,0,0-.643,10.392,4.675,4.675,0,0,0,.777.064,5.334,5.334,0,0,0,5.222-4.948l.008-.158a5.173,5.173,0,0,0-1.5-3.738M43.3,8.59a3.56,3.56,0,0,1-2.482.916,4.213,4.213,0,0,1-2.6-.881l-.244-.19.241-.187a4.134,4.134,0,0,1,2.56-.889,4.048,4.048,0,0,1,2.512.868L43.5,8.4ZM40.757,5.216a1.2,1.2,0,0,1-1.194-1.149,1.165,1.165,0,0,1,.329-.843,1.2,1.2,0,0,1,.865-.361h.024a1.177,1.177,0,0,1-.024,2.354M44.4,7.4l-.112.177-2.822-1.3.252-.246a3.176,3.176,0,0,0,1.165-2.142,1.888,1.888,0,0,0-.648-1.376A2.108,2.108,0,0,0,40.762,1.9a2.148,2.148,0,0,0-1.62.75,1.964,1.964,0,0,0-.519,1.462A2.85,2.85,0,0,0,39.8,6l.3.244-2.86,1.315-.115-.155a4.153,4.153,0,0,1,.688-5.289A4.273,4.273,0,0,1,40.751.951a4.318,4.318,0,0,1,2.734.985A4.216,4.216,0,0,1,44.4,7.4"
            transform="translate(-26.032 0)"
           fill="none"
            stroke={strokeColor}
          />
          <path
            id="Path_15560"
            data-name="Path 15560"
            d="M11.1,38.109c-.557-.568-1.114-1.117-1.69-1.69-.241-.238-.4-.345-.53-.345s-.284.1-.519.335l-.886.886-.852.854q-.418.418-.838.835a1.305,1.305,0,0,1-.886.434.88.88,0,0,1-.643-.289c-.4-.426-.335-.964.177-1.481Q5,37.09,5.556,36.532c.511-.511,1.026-1.026,1.534-1.542.351-.356.351-.53,0-.889-.49-.506-1.05-1.068-1.765-1.767a.756.756,0,0,0-.471-.273.737.737,0,0,0-.461.268l-.557.552c-.819.811-1.749,1.727-2.616,2.627A4.26,4.26,0,0,0,1.2,41.459a5.732,5.732,0,0,0,3.422,2.016h.546a5.033,5.033,0,0,0,2.79-1.288q.856-.872,1.722-1.73.707-.707,1.411-1.417c.37-.372.37-.562.013-.932M4.881,33.281l1.3,1.25L4.86,35.82,3.529,34.553Zm3.079,7.53A3.7,3.7,0,0,1,5.066,42.49a3.356,3.356,0,0,1-.608-.056,4.326,4.326,0,0,1-3.35-3.039c-.637-1.888.592-3.176,1.572-3.982l.171-.142,1.3,1.339-.187.166a2,2,0,0,0-.886,1.85,2.572,2.572,0,0,0,.632,1.253,1.511,1.511,0,0,0,1.1.514,3.039,3.039,0,0,0,1.9-.961l.185-.166,1.184,1.387Zm.918-.876-1.285-1.4,1.384-1.17,1.146,1.229Z"
            transform="translate(0 -23.475)"
            fill="none"
          stroke={strokeColor}
          
          />
          <path
            id="Path_15561"
            data-name="Path 15561"
            d="M11.1,38.109c-.557-.568-1.114-1.117-1.69-1.69-.241-.238-.4-.345-.53-.345s-.284.1-.519.335l-.886.886-.852.854q-.418.418-.838.835a1.305,1.305,0,0,1-.886.434.88.88,0,0,1-.643-.289c-.4-.426-.335-.964.177-1.481Q5,37.09,5.556,36.532c.511-.511,1.026-1.026,1.534-1.542.351-.356.351-.53,0-.889-.49-.506-1.05-1.068-1.765-1.767a.756.756,0,0,0-.471-.273.737.737,0,0,0-.461.268l-.557.552c-.819.811-1.749,1.727-2.616,2.627A4.26,4.26,0,0,0,1.2,41.459a5.732,5.732,0,0,0,3.422,2.016h.546a5.033,5.033,0,0,0,2.79-1.288q.856-.872,1.722-1.73.707-.707,1.411-1.417c.37-.372.37-.562.013-.932M4.881,33.281l1.3,1.25L4.86,35.82,3.529,34.553Zm3.079,7.53A3.7,3.7,0,0,1,5.066,42.49a3.356,3.356,0,0,1-.608-.056,4.326,4.326,0,0,1-3.35-3.039c-.637-1.888.592-3.176,1.572-3.982l.171-.142,1.3,1.339-.187.166a2,2,0,0,0-.886,1.85,2.572,2.572,0,0,0,.632,1.253,1.511,1.511,0,0,0,1.1.514,3.039,3.039,0,0,0,1.9-.961l.185-.166,1.184,1.387Zm.918-.876-1.285-1.4,1.384-1.17,1.146,1.229Z"
            transform="translate(0 -23.475)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15562"
            data-name="Path 15562"
            d="M11.1,38.109c-.557-.568-1.114-1.117-1.69-1.69-.241-.238-.4-.345-.53-.345s-.284.1-.519.335l-.886.886-.852.854q-.418.418-.838.835a1.305,1.305,0,0,1-.886.434.88.88,0,0,1-.643-.289c-.4-.426-.335-.964.177-1.481Q5,37.09,5.556,36.532c.511-.511,1.026-1.026,1.534-1.542.351-.356.351-.53,0-.889-.49-.506-1.05-1.068-1.765-1.767a.756.756,0,0,0-.471-.273.737.737,0,0,0-.461.268l-.557.552c-.819.811-1.749,1.727-2.616,2.627A4.26,4.26,0,0,0,1.2,41.459a5.732,5.732,0,0,0,3.422,2.016h.546a5.033,5.033,0,0,0,2.79-1.288q.856-.872,1.722-1.73.707-.707,1.411-1.417c.37-.372.37-.562.013-.932M4.881,33.281l1.3,1.25L4.86,35.82,3.529,34.553Zm3.079,7.53A3.7,3.7,0,0,1,5.066,42.49a3.356,3.356,0,0,1-.608-.056,4.326,4.326,0,0,1-3.35-3.039c-.637-1.888.592-3.176,1.572-3.982l.171-.142,1.3,1.339-.187.166a2,2,0,0,0-.886,1.85,2.572,2.572,0,0,0,.632,1.253,1.511,1.511,0,0,0,1.1.514,3.039,3.039,0,0,0,1.9-.961l.185-.166,1.184,1.387Zm.918-.876-1.285-1.4,1.384-1.17,1.146,1.229Z"
            transform="translate(0 -23.475)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15563"
            data-name="Path 15563"
            d="M11.1,38.109c-.557-.568-1.114-1.117-1.69-1.69-.241-.238-.4-.345-.53-.345s-.284.1-.519.335l-.886.886-.852.854q-.418.418-.838.835a1.305,1.305,0,0,1-.886.434.88.88,0,0,1-.643-.289c-.4-.426-.335-.964.177-1.481Q5,37.09,5.556,36.532c.511-.511,1.026-1.026,1.534-1.542.351-.356.351-.53,0-.889-.49-.506-1.05-1.068-1.765-1.767a.756.756,0,0,0-.471-.273.737.737,0,0,0-.461.268l-.557.552c-.819.811-1.749,1.727-2.616,2.627A4.26,4.26,0,0,0,1.2,41.459a5.732,5.732,0,0,0,3.422,2.016h.546a5.033,5.033,0,0,0,2.79-1.288q.856-.872,1.722-1.73.707-.707,1.411-1.417c.37-.372.37-.562.013-.932M4.881,33.281l1.3,1.25L4.86,35.82,3.529,34.553Zm3.079,7.53A3.7,3.7,0,0,1,5.066,42.49a3.356,3.356,0,0,1-.608-.056,4.326,4.326,0,0,1-3.35-3.039c-.637-1.888.592-3.176,1.572-3.982l.171-.142,1.3,1.339-.187.166a2,2,0,0,0-.886,1.85,2.572,2.572,0,0,0,.632,1.253,1.511,1.511,0,0,0,1.1.514,3.039,3.039,0,0,0,1.9-.961l.185-.166,1.184,1.387Zm.918-.876-1.285-1.4,1.384-1.17,1.146,1.229Z"
            transform="translate(0 -23.475)"
            fill="none"
            stroke={strokeColor}
          />
          <path
            id="Path_15564"
            data-name="Path 15564"
            d="M25.146,23.124a.2.2,0,0,1-.013.083.982.982,0,0,1-.238.319c-.209.222-.423.439-.635.653l-.6.616a1.4,1.4,0,0,1-.4.335.363.363,0,0,1-.145.032.474.474,0,0,1-.308-.142.51.51,0,0,1-.169-.273c-.032-.209.169-.436.37-.635l1.05-1.036a1.813,1.813,0,0,1,.332-.276.514.514,0,0,1,.246-.07.43.43,0,0,1,.228.067c.177.11.281.228.289.327"
            transform="translate(-16.569 -16.644)"
            fill="none"
            stroke={strokeColor}
           
          />
          <path
            id="Path_15565"
            data-name="Path 15565"
            d="M25.146,23.124a.2.2,0,0,1-.013.083.982.982,0,0,1-.238.319c-.209.222-.423.439-.635.653l-.6.616a1.4,1.4,0,0,1-.4.335.363.363,0,0,1-.145.032.474.474,0,0,1-.308-.142.51.51,0,0,1-.169-.273c-.032-.209.169-.436.37-.635l1.05-1.036a1.813,1.813,0,0,1,.332-.276.514.514,0,0,1,.246-.07.43.43,0,0,1,.228.067c.177.11.281.228.289.327"
            transform="translate(-16.569 -16.644)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15566"
            data-name="Path 15566"
            d="M25.146,23.124a.2.2,0,0,1-.013.083.982.982,0,0,1-.238.319c-.209.222-.423.439-.635.653l-.6.616a1.4,1.4,0,0,1-.4.335.363.363,0,0,1-.145.032.474.474,0,0,1-.308-.142.51.51,0,0,1-.169-.273c-.032-.209.169-.436.37-.635l1.05-1.036a1.813,1.813,0,0,1,.332-.276.514.514,0,0,1,.246-.07.43.43,0,0,1,.228.067c.177.11.281.228.289.327"
            transform="translate(-16.569 -16.644)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15567"
            data-name="Path 15567"
            d="M25.146,23.124a.2.2,0,0,1-.013.083.982.982,0,0,1-.238.319c-.209.222-.423.439-.635.653l-.6.616a1.4,1.4,0,0,1-.4.335.363.363,0,0,1-.145.032.474.474,0,0,1-.308-.142.51.51,0,0,1-.169-.273c-.032-.209.169-.436.37-.635l1.05-1.036a1.813,1.813,0,0,1,.332-.276.514.514,0,0,1,.246-.07.43.43,0,0,1,.228.067c.177.11.281.228.289.327"
            transform="translate(-16.569 -16.644)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15568"
            data-name="Path 15568"
            d="M45.475,43.066a.71.71,0,0,1-.147.445,3.264,3.264,0,0,1-.493.506c-.075.062-.147.131-.222.2s-.147.145-.22.217a3.23,3.23,0,0,1-.573.5.661.661,0,0,1-.329.091c-.185,0-.289-.078-.313-.228a1.2,1.2,0,0,1,.3-.846,4.935,4.935,0,0,1,.458-.463c.1-.091.2-.185.287-.278.021-.019.046-.043.07-.067l.043-.048c.206-.22.439-.466.675-.466h.011a.324.324,0,0,1,.051,0,.526.526,0,0,1,.4.434"
            transform="translate(-31.61 -31.215)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15569"
            data-name="Path 15569"
            d="M45.475,43.066a.71.71,0,0,1-.147.445,3.264,3.264,0,0,1-.493.506c-.075.062-.147.131-.222.2s-.147.145-.22.217a3.23,3.23,0,0,1-.573.5.661.661,0,0,1-.329.091c-.185,0-.289-.078-.313-.228a1.2,1.2,0,0,1,.3-.846,4.935,4.935,0,0,1,.458-.463c.1-.091.2-.185.287-.278.021-.019.046-.043.07-.067l.043-.048c.206-.22.439-.466.675-.466h.011a.324.324,0,0,1,.051,0,.526.526,0,0,1,.4.434"
            transform="translate(-31.61 -31.215)"
            fill="none"
            stroke={strokeColor}
            
          />
          <path
            id="Path_15570"
            data-name="Path 15570"
            d="M45.475,43.066a.71.71,0,0,1-.147.445,3.264,3.264,0,0,1-.493.506c-.075.062-.147.131-.222.2s-.147.145-.22.217a3.23,3.23,0,0,1-.573.5.661.661,0,0,1-.329.091c-.185,0-.289-.078-.313-.228a1.2,1.2,0,0,1,.3-.846,4.935,4.935,0,0,1,.458-.463c.1-.091.2-.185.287-.278.021-.019.046-.043.07-.067l.043-.048c.206-.22.439-.466.675-.466h.011a.324.324,0,0,1,.051,0,.526.526,0,0,1,.4.434"
            transform="translate(-31.61 -31.215)"
           fill="none"
            stroke={strokeColor}
          />
          <path
            id="Path_15571"
            data-name="Path 15571"
            d="M45.475,43.066a.71.71,0,0,1-.147.445,3.264,3.264,0,0,1-.493.506c-.075.062-.147.131-.222.2s-.147.145-.22.217a3.23,3.23,0,0,1-.573.5.661.661,0,0,1-.329.091c-.185,0-.289-.078-.313-.228a1.2,1.2,0,0,1,.3-.846,4.935,4.935,0,0,1,.458-.463c.1-.091.2-.185.287-.278.021-.019.046-.043.07-.067l.043-.048c.206-.22.439-.466.675-.466h.011a.324.324,0,0,1,.051,0,.526.526,0,0,1,.4.434"
            transform="translate(-31.61 -31.215)"
           fill="none"
            stroke={strokeColor}
          />
          <path
            id="Path_15572"
            data-name="Path 15572"
            d="M29.143,31.819a1.6,1.6,0,0,1-1,.977h-.016a.439.439,0,0,1-.447-.474A1.02,1.02,0,0,1,28,31.782a1.336,1.336,0,0,1,.68-.442.472.472,0,0,1,.337.142.462.462,0,0,1,.126.337"
            transform="translate(-20.269 -22.948)"
           fill="none"
            stroke={strokeColor}
          />
          <path
            id="Path_15573"
            data-name="Path 15573"
            d="M29.143,31.819a1.6,1.6,0,0,1-1,.977h-.016a.439.439,0,0,1-.447-.474A1.02,1.02,0,0,1,28,31.782a1.336,1.336,0,0,1,.68-.442.472.472,0,0,1,.337.142.462.462,0,0,1,.126.337"
            transform="translate(-20.269 -22.948)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15574"
            data-name="Path 15574"
            d="M29.143,31.819a1.6,1.6,0,0,1-1,.977h-.016a.439.439,0,0,1-.447-.474A1.02,1.02,0,0,1,28,31.782a1.336,1.336,0,0,1,.68-.442.472.472,0,0,1,.337.142.462.462,0,0,1,.126.337"
            transform="translate(-20.269 -22.948)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15575"
            data-name="Path 15575"
            d="M29.143,31.819a1.6,1.6,0,0,1-1,.977h-.016a.439.439,0,0,1-.447-.474A1.02,1.02,0,0,1,28,31.782a1.336,1.336,0,0,1,.68-.442.472.472,0,0,1,.337.142.462.462,0,0,1,.126.337"
            transform="translate(-20.269 -22.948)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15576"
            data-name="Path 15576"
            d="M39.18,41.82a1.115,1.115,0,0,1-.353.6,1.227,1.227,0,0,1-.627.418h-.013a.44.44,0,0,1-.324-.139.471.471,0,0,1-.142-.335,1.676,1.676,0,0,1,.967-.988l.035,0a.465.465,0,0,1,.458.45"
            transform="translate(-27.62 -30.292)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15577"
            data-name="Path 15577"
            d="M39.18,41.82a1.115,1.115,0,0,1-.353.6,1.227,1.227,0,0,1-.627.418h-.013a.44.44,0,0,1-.324-.139.471.471,0,0,1-.142-.335,1.676,1.676,0,0,1,.967-.988l.035,0a.465.465,0,0,1,.458.45"
            transform="translate(-27.62 -30.292)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15578"
            data-name="Path 15578"
            d="M39.18,41.82a1.115,1.115,0,0,1-.353.6,1.227,1.227,0,0,1-.627.418h-.013a.44.44,0,0,1-.324-.139.471.471,0,0,1-.142-.335,1.676,1.676,0,0,1,.967-.988l.035,0a.465.465,0,0,1,.458.45"
            transform="translate(-27.62 -30.292)"
           fill="none"
            // stroke={strokeColor}
          />
          <path
            id="Path_15579"
            data-name="Path 15579"
            d="M39.18,41.82a1.115,1.115,0,0,1-.353.6,1.227,1.227,0,0,1-.627.418h-.013a.44.44,0,0,1-.324-.139.471.471,0,0,1-.142-.335,1.676,1.676,0,0,1,.967-.988l.035,0a.465.465,0,0,1,.458.45"
            transform="translate(-27.62 -30.292)"
           fill="none"
            stroke={strokeColor}
          />
        </g>
      </g>
    </svg>
  );
}
