import React from 'react';
import { isEmpty } from 'lodash';
import ConnectDetails from './ConnectDetails';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import animationDataEmp from '../../../../../assets/images/EmployeeLottieImage.json'
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import './employeeDetails.scss';
import { Button } from 'antd';

const EmployeesDetails = ({ loader, connectsData, onDeleteCard, onSaveCard }) => {
  return (
    <div className="connect_container  d-flex d-column">
      <div className="font14 font900 fontLight-3 mb-10">Employee of Selected Company</div>
      <div style={{ overflow: 'auto' }} className={`employeeDetails ${loader && 'border-light-2'}`}>
        {isEmpty(connectsData) && !loader ? (
          <div className="employe-imgdiv">
            <LottieAnimation height={150} width={150} animationData={animationData} loop={true} />
            <span className="bold employe-imgdiv__text">
              We are finding right<br></br> <span className="bold text-middlecard">CONNECTS</span>
              <br></br>
              For you, Be with us !
            </span>
          </div>
        ) : (
          <>
          {connectsData?.map((data) => (
            <ConnectDetails key={data._id} loader={loader} connectsData={data} onDeleteCard={onDeleteCard} onSaveCard={onSaveCard} />
          ))}
          <div className='employeelottiediv'>
            <div className='employeelottiediv-text'>
              <h4  className='employeelottiediv-text__heading bold'>Do You Know Somebody
                <br/>
                from this Organization?
              </h4>
              <p  className='employeelottiediv-text__pera'>Add connect and earn points</p> 
              <Button className="topnav-search__button">Add Connect</Button>
            </div>
            <div >
            <LottieAnimation height={200} width={100} animationData={animationDataEmp} loop={true} />
          </div>
          </div>
        </>
        )}
      </div>
    </div>
  );
};

export default EmployeesDetails;
