import React from 'react';

const EventIcon = ({ color = '#3a3a3a' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34.802 32.625">
      <g id="Group_27717" data-name="Group 27717" transform="translate(-1250.599 -34.4)">
        <g id="social_media-11" data-name="social media-11" transform="translate(1248.369 30.28)">
          <path
            id="Path_18511"
            data-name="Path 18511"
            d="M32.693,17.25h2.022a1.255,1.255,0,0,1,1.256,1.256V37.7H5.91v-19.2A1.255,1.255,0,0,1,7.166,17.25H9.187"
            transform="translate(-1.306 -5.313)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <path
            id="Path_18512"
            data-name="Path 18512"
            d="M33,56.515H6.263A3.434,3.434,0,0,1,2.83,53.083V52.76h33.6v.323A3.434,3.434,0,0,1,33,56.515Z"
            transform="translate(0 -20.37)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <rect
            id="Rectangle_10690"
            data-name="Rectangle 10690"
            width="23.517"
            height="27.67"
            transform="translate(7.876 4.72)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <rect
            id="Rectangle_10691"
            data-name="Rectangle 10691"
            width="7.476"
            height="7.476"
            transform="translate(10.122 6.938)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <rect
            id="Rectangle_10692"
            data-name="Rectangle 10692"
            width="7.476"
            height="7.476"
            transform="translate(21.543 20.646)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2096"
            data-name="Line 2096"
            x2="9.17"
            transform="translate(19.85 6.938)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2097"
            data-name="Line 2097"
            x2="9.17"
            transform="translate(19.85 9.38)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2098"
            data-name="Line 2098"
            x2="9.17"
            transform="translate(19.85 11.937)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2099"
            data-name="Line 2099"
            x2="9.17"
            transform="translate(19.85 14.414)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2100"
            data-name="Line 2100"
            x2="9.17"
            transform="translate(10.122 20.646)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2101"
            data-name="Line 2101"
            x2="9.17"
            transform="translate(10.122 23.088)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2102"
            data-name="Line 2102"
            x2="9.17"
            transform="translate(10.122 25.651)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2103"
            data-name="Line 2103"
            x2="9.17"
            transform="translate(10.122 28.122)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
          <line
            id="Line_2104"
            data-name="Line 2104"
            x2="18.898"
            transform="translate(10.122 17.53)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
          />
        </g>
      </g>
    </svg>
  );
};

export default EventIcon;
