import { CameraOutlined, SpotifyOutlined, YoutubeOutlined } from '@ant-design/icons';
import ImageIcon from '../../../../../../assets/SocialApp/ImageIcon';
import VideoIcon from '../../../../../../assets/SocialApp/VideoIcon';
import AudioIcon from '../../../../../../assets/SocialApp/AudioIcon';

export const uploadTypes = [
  {
    key: 'IMAGE',
    icon: ImageIcon,
  },
  {
    key: 'VIDEO',
    icon: VideoIcon,
  },
  {
    key: 'AUDIO',
    icon: AudioIcon,
  },
];

export const uploadTypesKeyMap = {
  IMAGE: {
    formats: '.png,.jpeg,.jpg',
    type: 'image',
    label: 'Upload Image',
  },
  VIDEO: {
    formats: '.mp3,.mp4,.3gp',
    type: 'video',
    label: 'Upload Video Image',
  },
  AUDIO: {
    label: 'Upload Audio Image',
    formats: '.mp3,.mp4,.3gp',
    type: 'audio',
  },
};
