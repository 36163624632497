import { Col, Row } from 'antd';
import React from 'react';
import CustomSkeleton from '../../../../../universal/components/skeleton/CustomSkeleton';
import './propertyDetails.scss';
import { nanoid } from '@reduxjs/toolkit';
const SelectedPropertyDetailsSkeleton = ({ className = '' }) => {
  return (
    <div className={`propertyCard border-light-2 ${className}`}>
      <Row className="inside_row">
        <Col span={7} className="propertyListContainer__div__card__row__col__row__col">
          <CustomSkeleton height={'100%'} className="propertyListContainer__div__card__row__col__row__col__img" />
        </Col>
        <Col span={16} className="d-flex d-column g-5 jc-center">
          <CustomSkeleton height={30} width={'50%'} />
          <CustomSkeleton height={30} width={'100%'} />
          <div className="d-flex g-5">
            {[...new Array(5)]?.map(() => (
              <CustomSkeleton key={nanoid()} height={20} width={20} />
            ))}
          </div>
          <CustomSkeleton height={20} width={'100%'} />
          <CustomSkeleton height={20} width={'100%'} />
          <div className="d-flex g-5">
            {[...new Array(5)]?.map(() => (
              <CustomSkeleton key={nanoid()} height={20} width={30} />
            ))}
          </div>
          <CustomSkeleton height={30} width={'100%'} />
        </Col>
      </Row>
    </div>
  );
};

export default SelectedPropertyDetailsSkeleton;
