import React from 'react';

const EventPostIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg id="g1858" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
      <g id="g1864" transform="translate(0.704 10.29)">
        <g id="path1866">
          <path
            id="Path_17637"
            data-name="Path 17637"
            d="M28.072,88.916a1.777,1.777,0,0,1-1.122-.4c-2.648-2.129-11.506-9.084-11.6-9.154a.556.556,0,0,1-.212-.37.551.551,0,0,1,.546-.624.552.552,0,0,1,.34.118c.078.061,8.932,7.011,11.613,9.166a.689.689,0,0,0,.433.158.68.68,0,0,0,.433-.158c2.519-2.027,10.663-8.42,11.584-9.143l.029-.023a.552.552,0,0,1,.68.87l-.014.011c-.366.288-8.961,7.032-11.588,9.146a1.8,1.8,0,0,1-1.122.4Z"
            transform="translate(-15.137 -78.37)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1868" transform="translate(16.517 17.504)">
        <g id="path1870">
          <path
            id="Path_17638"
            data-name="Path 17638"
            d="M125.1,136.482a.548.548,0,0,1-.362-.138l-9.158-8.164a.543.543,0,1,1,.723-.811l9.158,8.166a.544.544,0,0,1-.362.949Z"
            transform="translate(-115.395 -127.23)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1872" transform="translate(0.213 17.505)">
        <g id="path1874" transform="translate(0)">
          <path
            id="Path_17639"
            data-name="Path 17639"
            d="M16.878,136.4a.542.542,0,0,1-.406-.182.543.543,0,0,1,.045-.768l9.159-8.14a.545.545,0,0,1,.361-.137.538.538,0,0,1,.406.183.541.541,0,0,1-.045.766l-9.159,8.14A.542.542,0,0,1,16.878,136.4Z"
            transform="translate(-16.335 -127.17)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1876" transform="translate(2.38 2.667)">
        <g id="path1878" transform="translate(0 0)">
          <path
            id="Path_17640"
            data-name="Path 17640"
            d="M51.594,35.959a.577.577,0,0,1-.574-.577V28.807a1.2,1.2,0,0,0-1.2-1.2H32.277a1.2,1.2,0,0,0-1.2,1.2v6.575a.574.574,0,1,1-1.149,0V28.807a2.355,2.355,0,0,1,2.347-2.357H49.822a2.355,2.355,0,0,1,2.347,2.357v6.575A.577.577,0,0,1,51.594,35.959Z"
            transform="translate(-29.93 -26.45)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1880" transform="translate(9.235)">
        <g id="path1882" transform="translate(0)">
          <path
            id="Path_17641"
            data-name="Path 17641"
            d="M81.169,13.541a.569.569,0,0,1-.367-.132L77.95,11.046a.712.712,0,0,0-.923,0L74.189,13.41a.568.568,0,0,1-.368.132.577.577,0,0,1-.368-1.02l2.839-2.362a1.854,1.854,0,0,1,2.394,0l2.85,2.362a.577.577,0,0,1,.206.391.568.568,0,0,1-.131.421.575.575,0,0,1-.443.209Z"
            transform="translate(-73.244 -9.72)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1884" transform="translate(0 8.41)">
        <g id="path1886" transform="translate(0 0)">
          <path
            id="Path_17642"
            data-name="Path 17642"
            d="M16.364,81.07A1.332,1.332,0,0,1,15,79.777l.021-14.461a.54.54,0,0,1,.008-.087,2.152,2.152,0,0,1,.831-1.319l1.72-1.309a.607.607,0,0,1,.367-.121.6.6,0,0,1,.442.189.5.5,0,0,1-.075.74l-1.72,1.309a1.129,1.129,0,0,0-.424.646l-.021,14.413a.232.232,0,0,0,.216.24H40.636a.234.234,0,0,0,.216-.241l-.021-14.41a1.137,1.137,0,0,0-.426-.649l-1.717-1.3a.515.515,0,0,1-.2-.356.49.49,0,0,1,.13-.384.609.609,0,0,1,.807-.07l1.718,1.3a2.15,2.15,0,0,1,.832,1.322.508.508,0,0,1,.008.086L42,79.773a1.331,1.331,0,0,1-1.364,1.293H16.364Z"
            transform="translate(-15 -62.48)"
            fill={color}
          />
        </g>
      </g>
      <g id="g1888" transform="translate(7.901 5.246)">
        <g id="path1890" transform="translate(0)">
          <path
            id="Path_17643"
            data-name="Path 17643"
            d="M73.375,56.944a.874.874,0,0,1-.516-.169l-2.394-1.739-2.394,1.741a.878.878,0,0,1-1.352-.982l.915-2.815L65.24,51.241a.879.879,0,0,1,.516-1.589h2.96l.915-2.815a.878.878,0,0,1,1.67,0l.915,2.815h2.96a.879.879,0,0,1,.516,1.589L73.3,52.982l.915,2.815a.877.877,0,0,1-.835,1.149Zm-2.911-3.126a.873.873,0,0,1,.516.167l1.873,1.361-.716-2.2a.875.875,0,0,1,.319-.982L74.33,50.8H72.014a.875.875,0,0,1-.835-.607l-.716-2.2-.716,2.2a.876.876,0,0,1-.835.607H66.6l1.873,1.361a.875.875,0,0,1,.319.982l-.716,2.2,1.873-1.361a.873.873,0,0,1,.516-.167Z"
            transform="translate(-64.877 -46.23)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default EventPostIcon;
