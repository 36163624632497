import React from 'react';
import { Avatar, Divider, Tooltip } from 'antd';
import bookmark from '../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../assets/images/bookmark_selected.svg';
import './ResidentialPropertyDetailsPage.scss';
import dayjs from 'dayjs';

const SelectedPropertyUnitCard = ({ unitData, onDeleteCard, onSaveCard }) => {
  // const floorSuffix = (ele) => {
  //   const suffix = 'TH';
  //   if (ele === '1') suffix = 'ST';
  //   if (ele === '2') suffix = 'ND';
  //   if (ele === '3') suffix = 'RD';
  //   if (ele === 'GF') suffix = '';
  //   return ele + suffix;
  // };
  return (
    <div className="residential-container position-relative">
      <div className="companyCard">
        <div className=" g-5 mt-10 flex-1">
          <div className="d-flex jc-between a-center mt-5  ">
            <div className="d-flex a-center g-5 ammountDiv">
              <Avatar className="companyAvatar" src={unitData?.assetsInfo?.[0]?.featuredImage} />
              <div>
                <h5 className="residential-heading text-underline companyContainer__div__card__row__col__row__col1__h5 cursor-pointer">
                  Flat No. {unitData?.address?.unitNo}
                </h5>
                <p className="font12 fontExtraLight">{unitData?.address?.buildingName ?? 'Awaited'}</p>
              </div>
            </div>

            <div className="d-flex">
              <div className={`residential-amountdiv${unitData?.documentType ? '' : '__noData'}`}>
                <p className="font12 fontExtraLight">Amount</p>
                <h2 className="amountdiv__amount">
                  {unitData?.moreTransactionInfo?.[0]?.saleValue
                    ? `${unitData?.moreTransactionInfo?.[0]?.saleValue} Rs`
                    : 'Awaited'}
                </h2>
              </div>
              <span className={`sale-button${unitData?.documentType ? '' : '__noData'}`}>
                {unitData?.documentType ? unitData?.documentType : 'Awaited'}
              </span>
              {!unitData?.isSaved ? (
                <img
                  src={bookmark}
                  alt="bookmark"
                  style={{
                    cursor: 'pointer',
                    height: '25px',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    onSaveCard(unitData?._id, unitData?.address?.unitNo, 'unit', [], 'residential');
                  }}
                />
              ) : (
                <img
                  src={bookmark_selected}
                  alt="bookmark"
                  style={{
                    cursor: 'pointer',
                    height: '25px',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    onDeleteCard(unitData?._id, unitData?.address?.unitNo, 'unit', [], 'residential');
                  }}
                />
              )}
            </div>
          </div>
          <Divider className="residential-divider" />
          <div className="bottomdiv">
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Floor No.</p>
              <h4 className="font16">{unitData?.address?.floorNo}</h4>
            </div>
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Registration Date:</p>
              <h4 className="font16">
                {unitData?.registrationDate ? `${dayjs(unitData?.registrationDate).format('DD MMM. YYYY')}` : 'Awaited'}
              </h4>
            </div>
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Carpet Area:</p>
              <h4 className="font16">
                {unitData?.moreTransactionInfo?.[0]?.carpetArea
                  ? `${unitData?.moreTransactionInfo?.[0]?.carpetArea}Sq.ft.`
                  : `Awaited`}
              </h4>
            </div>
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Sale Rate</p>
              <h4 className="font16">
                {unitData?.moreTransactionInfo?.[0]?.saleRate
                  ? `${Number(unitData?.moreTransactionInfo?.[0]?.saleRate).toFixed(2)} Rs./Sq.ft.`
                  : 'Awaited'}
              </h4>
            </div>
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Seller</p>
              <Tooltip
                title={
                  unitData?.sellerInfoIndividual?.[0]?.personName
                    ? `${unitData?.sellerInfoIndividual?.[0]?.personName}`
                    : `Awaited`
                }
              >
                <h4 className="residential-ellipsis">
                  {unitData?.sellerInfoIndividual?.[0]?.personName
                    ? `${unitData?.sellerInfoIndividual?.[0]?.personName}`
                    : `Awaited`}
                </h4>
              </Tooltip>
            </div>
            <div className="bottomdiv-box">
              <p className="font12 fontExtraLight">Buyer</p>
              <Tooltip
                title={
                  unitData?.buyerInfoIndividual?.[0]?.personName
                    ? `${unitData?.buyerInfoIndividual?.[0]?.personName}`
                    : `Awaited`
                }
              >
                <h4 className="residential-ellipsis">
                  {unitData?.buyerInfoIndividual?.[0]?.personName
                    ? `${unitData?.buyerInfoIndividual?.[0]?.personName}`
                    : `Awaited`}
                </h4>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <span className="connectorLine">
        {[...new Array(200)]?.map(() => (
          <>&#x2022;</>
        ))}
      </span>
    </div>
  );
};

export default SelectedPropertyUnitCard;
