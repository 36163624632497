import React from 'react';

const VideoIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg
      id="Icon_material-twotone-video-library"
      data-name="Icon material-twotone-video-library"
      xmlns="http://www.w3.org/2000/svg"
      width="26.022"
      height="26.022"
      viewBox="0 0 26.022 26.022"
    >
      <path
        id="Path_17267"
        data-name="Path 17267"
        d="M12,21.613H27.613V6H12ZM17.2,7.952l7.807,5.855L17.2,19.662Z"
        transform="translate(-4.193 -3.398)"
        fill="none"
        opacity="0.3"
      />
      <path
        id="Path_17268"
        data-name="Path 17268"
        d="M5.6,8.2H3V26.42a2.61,2.61,0,0,0,2.6,2.6H23.818v-2.6H5.6ZM26.42,3H10.807A2.61,2.61,0,0,0,8.2,5.6V21.215a2.61,2.61,0,0,0,2.6,2.6H26.42a2.61,2.61,0,0,0,2.6-2.6V5.6A2.61,2.61,0,0,0,26.42,3Zm0,18.215H10.807V5.6H26.42ZM16.011,7.554v11.71l7.807-5.855Z"
        transform="translate(-3 -3)"
        fill={color}
      />
    </svg>
  );
};

export default VideoIcon;
