// imports
import { ignore } from 'antd/es/theme/useToken';
import React, { useState } from 'react';

// library imports
import ReactQuill from 'react-quill';

const ReactQuillComponent = ({ onChange, value, style = {}, placeholder = '' }) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ];

  // states
  const [values, setValues] = useState();

  //   Render
  return (
    <ReactQuill
      style={style}
      theme="snow"
      modules={{
        toolbar: toolbarOptions,
      }}
      placeholder={placeholder}
      value={values}
      onChange={(e, b, v, a) => {
        if (a.getLength() === 1) {
          onChange('');
          setValues('');
        } else {
          onChange(e);
          setValues(e);
        }
      }}
    />
  );
};

export default ReactQuillComponent;
