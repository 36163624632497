import React, { useState, Fragment } from 'react';
import { Card, Switch, Typography, Button } from 'antd';
import DataGrid from '../../components/dataGrid/DataGrid';
import BuildingStatsComponent from '../buildingStatsComponent/BuildingStatsComponent'; // Import the BuildingStatsComponent

import './OccupantsCard.scss';

const { Text } = Typography;

const OccupantsCard = ({
  occupantsDetails,
  vacantUnitDetails,
  occupantsListColumns,
  vacantUnitListColumns,
  onRowClick,
  isDetailsVisible
}) => {
  const [activeTab, setActiveTab] = useState('occupants');
  const [view, setView] = useState('occupants'); // Manage the switch state

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleViewChange = (checked) => {
    setView(checked ? 'buildingStats' : 'occupants');
  };
 
  
  const floorsData = [
    {
      number: 6,
      rooms: [
        { number: 601, occupied: true },
        { number: 602, occupied: true },
        { number: 603, occupied: true },
        { number: 604, occupied: true },
        { number: 605, occupied: true },
        { number: 606, occupied: true },
        { number: 607, occupied: true },
        { number: 608, occupied: true },
        { number: 609, occupied: true },
        { number: 610, occupied: true },
        { number: 611, occupied: true },
        { number: 612, occupied: true },
        { number: 613, occupied: false },
        // Add more rooms
      ],
    },
    {
      number: 5,
      rooms: [
        { number: 501, occupied: true },
        { number: 502, occupied: false },
        // Add more rooms
      ],
    },
    {
      number: 4,
      rooms: [
        { number: 401, occupied: true },
        { number: 402, occupied: true },
        { number: 403, occupied: false },
        { number: 404, occupied: false },
        { number: 405, occupied: false },
        { number: 406, occupied: true },
        { number: 407, occupied: true },
        { number: 408, occupied: true },
        // Add more rooms
      ],
    },
    {
      number: 3,
      rooms: [
        { number: 301, occupied: false },
        { number: 302, occupied: false },
        { number: 303, occupied: false },
        // Add more rooms
      ],
    },
    {
      number: 2,
      rooms: [
        { number: 201, occupied: true },
        { number: 202, occupied: true },
        // Add more rooms
      ],
    },
    {
      number: 1,
      rooms: [
        { number: 101, occupied: true },
        { number: 102, occupied: false },
        { number: 103, occupied: true },
        { number: 104, occupied: true },
        { number: 105, occupied: false },
        // Add more rooms
      ],
    },
    // Add more floors
  ];

  return (
    <Card style={{
      flex: 2,
      // height: isDetailsVisible ? '255px' : '100%', 
      border:'none',
    }}>
      <div className="card-header" style={{ width: 'max-content', float: 'right' }}>
        <Switch
          checkedChildren="Building Stats"
          unCheckedChildren="Occupants"
          checked={view === 'buildingStats'}
          onChange={handleViewChange}
          className="large-switch"
        />
      </div>
      {view === 'occupants' ? (
        <Fragment>
          <div className="tab-headings">
            <Text
              className="listHeading"
              style={{
                color: activeTab === 'occupants' ? '#313131' : '#b7b7b7',
              }}
              onClick={() => handleTabChange('occupants')}
            >
              List of Occupants
            </Text>
            <Text
              className="listHeading"
              style={{
                color: activeTab === 'vacantUnits' ? '#313131' : '#b7b7b7',
              }}
              onClick={() => handleTabChange('vacantUnits')}
            >
              List of Vacant Unit
            </Text>
          </div>
          <div className="data-grid-container">
            {activeTab === 'occupants' ? (
              <DataGrid
                columns={occupantsListColumns}
                data={occupantsDetails}
                onRowClick={(id) => onRowClick(id, 'occupants')}
              />
            ) : (
              <DataGrid
                columns={vacantUnitListColumns}
                data={vacantUnitDetails}
                onRowClick={(id) => onRowClick(id, 'vacantUnits')}
              />
            )}
          </div>

      
          

        </Fragment>
      ) : (
        <BuildingStatsComponent floors={floorsData}  />
      )}
    </Card>
  );
};

export default OccupantsCard;
