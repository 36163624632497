import { clientV2, docClientV2 } from '../../utils/apiClient';
import { asyncHandler } from '../../utils/asyncHandler';

// ----------get dashboard status details---------
const getDashboardAllPosts = async (userId, payload) => {
  const { limit, offset } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts/dashboard?limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
// ----------get all events details---------
const getSocialAllEvents = async (userId, payload) => {
  const { limit, offset } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts/events?limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
// ----------get all events details---------
const updateSocialEventsAPI = async (userId, eventId, remove) => {
  const [data, err] = await asyncHandler(
    remove
      ? clientV2.delete(`/user/${userId}/posts/events/${eventId}`, {
          authorization: true,
        })
      : clientV2.patch(`/user/${userId}/posts/events/${eventId}`, {
          authorization: true,
        }),
  );
  if (err) return err;
  return data;
};
// ----------get cart status details---------
const getUserAllPosts = async (userId, payload) => {
  const { limit, offset } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts?ownerId=${userId}&type=user_post&limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
// ----------get cart status details---------
const getProfilePosts = async (userId, payload) => {
  const { limit, offset, ownerId, userType } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts?ownerId=${ownerId}&type=${userType}&limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};

const getUserSavedPosts = async (userId, payload) => {
  const { limit, offset } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts/saved?ownerId=${userId}&type=user_post&limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
// ----------get cart status details---------
const getCompanyAllPosts = async (userId, companyId, payload) => {
  const { limit, offset } = payload;
  const [data, err] = await asyncHandler(
    // &limit=${limit}&offset=${offset}
    clientV2.get(`/user/${userId}/posts?ownerId=${companyId}&type=company_post&limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
// ----------get cart status details---------
const createPost = async (userId, payload) => {
  const [data] = await asyncHandler(
    clientV2.post(`/user/${userId}/posts`, payload, {
      authorization: true,
    }),
  );
  // if (err) return err;
  return data;
};

const followUser = async (param, userId) => {
  try {
    const response = await clientV2.post(`/user/${userId}/follow`, param);
    return response;
  } catch (err) {
    console.error(err);
  }
};
const unfollowUser = async (param, userId) => {
  try {
    const response = await clientV2.post(`/user/${userId}/follow/unfollow`, param);
    return response;
  } catch (err) {
    console.error(err);
  }
};
const uploadPostImage = async (param, userId) => {
  try {
    const response = await docClientV2.post(`/user/${userId}/posts/upload-images`, param);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const updateActivity = async (userId, postId, action, text = '') => {
  try {
    const response = await clientV2.post(`/user/${userId}/posts/activity/${postId}`, { action, text });
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getComments = async (userId, postId) => {
  try {
    const response = await clientV2.get(`/user/${userId}/posts/comments/${postId}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};
const createCommentAPI = async (userId, payload) => {
  try {
    const response = await clientV2.post(`/user/${userId}/comments`, payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const updateComment = async (userId, commentId, text = '') => {
  try {
    const response = await clientV2.patch(`/user/${userId}/comments/${commentId}`, { text });
    return response;
  } catch (err) {
    console.error(err);
  }
};

const deleteComment = async (userId, commentId, postId) => {
  try {
    const response = await clientV2.delete(`/user/${userId}/comments/${commentId}?postId=${postId}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getPost = async (userId, postId, ownerId) => {
  try {
    const response = await clientV2.get(`/user/${userId}/posts/${postId}`, {
      params: { id: userId, ownerId: ownerId, type: 'user_post' },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
const getSharedPost = async (postId) => {
  try {
    const response = await clientV2.get(`/user/${1234}/posts/shared/${postId}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const deletePost = async (userId, postId) => {
  try {
    const response = await clientV2.delete(`/user/${userId}/posts/${postId}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

//vote for poll card
const voteToPollCard = async (userId, payload) => {
  const [data] = await asyncHandler(
    clientV2.post(`/user/${userId}/vote`, payload, {
      authorization: true,
    }),
  );
  // if (err) return err;
  return data;
};

//repost the card
const repostCard = async (userId, payload) => {
  const [data] = await asyncHandler(
    clientV2.post(`/user/${userId}/repost`, payload, {
      authorization: true,
    }),
  );
  // if (err) return err;
  return data;
};
//undo reposted card
const undoRepostCard = async (userId, payload) => {
  const [data] = await asyncHandler(
    clientV2.post(`/user/${userId}/repost/undo`, payload, {
      authorization: true,
    }),
  );
  // if (err) return err;
  return data;
};

const getSocialLeadsAPI = async (leadType, engagementType, offset) => {
  const [data] = await asyncHandler(
    clientV2.get(
      `/recommended-leads?offset=${offset}&limit=10${engagementType ? `&engagement-type=${engagementType}` : ''}${
        leadType ? `&lead-type=${leadType}` : ''
      }`,
      {
        authorization: true,
      },
    ),
  );
  // if (err) return err;
  return data;
};
//get post details
const getPostDetailsByIdService = async (userId, type, postId, ownerId) => {
  const [data] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts/${postId}?type=${type}&ownerId=${ownerId}`, {
      authorization: true,
    }),
  );
  // if (err) return err;
  return data;
};
//get post details
const unlockLeadAPI = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/recommended-leads/transact`, payload, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
//get post details
const createSocialGroups = async (payload) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/social/groups`, payload, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
//get post details
const getSocialGroupMembers = async (userId, searchBy, type = 'user') => {
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/search?name=${searchBy || ''}&type=${type}&limit=50&offset=0`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const getSocialAllGroupsAPI = async () => {
  const [data, err] = await asyncHandler(
    clientV2.get(`/social/groups`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const getSocialGroupDetailAPI = async (groupId) => {
  const [data, err] = await asyncHandler(
    clientV2.get(`/social/groups/${groupId}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const getGroupAllPostsAPI = async (userId, payload) => {
  const { limit, offset, groupId } = payload;
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/posts/groups/${groupId}?limit=${limit}&offset=${offset}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const joinSocialGroupAPI = async (groupId) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/social/groups/${groupId}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const removeFromSocialGroupAPI = async (groupId, userId) => {
  const [data, err] = await asyncHandler(
    clientV2.delete(`/social/groups/${groupId}/user/${userId}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const reportSocialAccount = async (postId, userId) => {
  const [data, err] = await asyncHandler(
    clientV2.post(`/user/${userId}/posts/report/${postId}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const getProfileDetailsByIDAPI = async (userId, type, id) => {
  const [data, err] = await asyncHandler(
    clientV2.get(`/user/${userId}/search?type=${type}&id=${id} `, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
const leaveSocialGroupAPI = async (groupId) => {
  const [data, err] = await asyncHandler(
    clientV2.delete(`/social/groups/${groupId}`, {
      authorization: true,
    }),
  );
  if (err) return err;
  return data;
};
export {
  getUserAllPosts,
  getUserSavedPosts,
  followUser,
  unfollowUser,
  getDashboardAllPosts,
  getCompanyAllPosts,
  createPost,
  uploadPostImage,
  getComments,
  updateActivity,
  getPost,
  deletePost,
  updateComment,
  deleteComment,
  voteToPollCard,
  getPostDetailsByIdService,
  createCommentAPI,
  getSocialAllEvents,
  updateSocialEventsAPI,
  getSocialLeadsAPI,
  unlockLeadAPI,
  getSharedPost,
  createSocialGroups,
  getSocialGroupMembers,
  getSocialAllGroupsAPI,
  getGroupAllPostsAPI,
  getSocialGroupDetailAPI,
  joinSocialGroupAPI,
  leaveSocialGroupAPI,
  removeFromSocialGroupAPI,
  repostCard,
  undoRepostCard,
  getProfileDetailsByIDAPI,
  getProfilePosts,
  reportSocialAccount,
};
