export const FormatProperty = (data) => {
  return {
    _id: data?._id,
    buildingName: data?.buildingName ?? 'Awaited',
    ownerName: data?.representativeInfo?.[0]?.personName ?? 'Awaited',
    phone: data?.representativeInfo?.[0]?.contactNumber ?? 'Awaited',
    email: data?.representativeInfo?.[0]?.emailId ?? 'Awaited',
    about: data?.propertyDescription ?? 'Awaited',
    gradeOfBuilding: data?.gradeOfBuilding ?? 'Awaited',
    amenities: data?.amenities,
    areaFacilities: data?.areaFacilities,
    address: data?.location?.mapLocation,
    featuredImage: data?.assetsInfo?.[0]?.featuredImage,
    name: data?.name ?? 'Awaited',
    averageRating: data?.averageRating,
    buildingType: data?.buildingType ?? 'Awaited',
    buildingStatus: data?.buildingStatus ?? 'Awaited',
    sanctionedBuiltupArea: data?.sanctionedBuiltupArea ?? 'Awaited',
    moreBuildingInfo: data?.moreBuildingInfo,
    efficiency: data?.efficiency ?? 'Awaited',
    buildingStructure: data?.buildingStructure ?? 'Awaited',
    buildingClassification: data?.buildingClassification ?? 'Awaited',
    developer: data?.developer ?? 'Awaited',
    developerEmail: data?.developerEmail ?? 'Awaited',
    developerContact: data?.developerContact ?? 'Awaited',
    societyInfo: data?.societyInfo ?? 'Awaited',
    addressInfo: data?.addressInfo?.[0],
    totalChargeableArea: data?.totalChargeableArea + ' Sqft' ?? 'Awaited',
    propertyImages: data?.assetsInfo?.[0]?.galleryFiles ?? [],
    vacantUnitCount: data?.vacantUnitCount,
    isSaved: data?.isSaved,
    isSubscribed: data?.isSubscribed,
  };
};
