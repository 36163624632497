import React from 'react';
import Text from 'antd/es/typography/Text';
import CommonButton from '../../../common/CommonButton';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';
import DOMPurify from 'dompurify';

import './genericPost.scss';
import PostHeader from '../../postHeader/PostHeader';
import PostFooter from '../../postFooter/PostFooter';
import TruncateText from '../common/imageCarousel/TruncateText';
import { useNavigate } from 'react-router';
import { placeHolderImageUrl } from '../../../../../../constants/globalConstants';
import RepostIcon from '../../../../../../assets/SocialApp/RepostIcon';
import ReactShowMoreText from 'react-show-more-text';

const GenericPost = ({
  isViewPost,
  postDetails,
  setPostDetails,
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  const navigate = useNavigate();

  return (
    <div className="generic_post_content ">
      {postDetails.imageUrls?.length > 0 && (
        <div className="generic_post_images w-40">
          {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
            <ImageCarousel
              className="carousel-image"
              imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []}
            />
          ) : (
            <img src={placeHolderImageUrl} alt="Default Post" className="carousel-image" />
          )}
        </div>
      )}
      <div
        className={`d-flex d-column jc-between ${postDetails.imageUrls?.length > 0 ? 'w-70' : 'w-100'} word-break-all `}
      >
        {isViewPost && postDetails?.isReposted && (
          <div className="d-flex g-5 a-center mb-10">
            <div className="repost-icon">
              <RepostIcon className="repost-icon" />
            </div>
            <div>You Reposted</div>
          </div>
        )}
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>

          {/* {postDetails?.postDetails?.body && (
            <TruncateText
              length={isViewPost ? 20000 : 200}
              data={postDetails?.postDetails?.body}
              onViewMore={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
            />
          )} */}

          {isViewPost ? (
            <div
              className={`font14 postBody fontDark `}
              dangerouslySetInnerHTML={{ __html: postDetails?.postDetails?.body }}
            ></div>
          ) : (
            <ReactShowMoreText
              /* Default options */
              lines={4}
              more={<span className="fontBlue cursor-pointer mt-10 mb-10">See more</span>}
              className="content-css"
              anchorClass="show-more-less-clickable"
              onClick={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
              expanded={false}
              // width={'100%'}

              truncatedEndingComponent={'... '}
            >
              <div
                className={`font14 postBody fontDark ${!isViewPost && 'truncatedText'}`}
                dangerouslySetInnerHTML={{ __html: postDetails?.postDetails?.body }}
              ></div>
            </ReactShowMoreText>
          )}
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex font500 fontExtraLight g-5">
            {postDetails?.postDetails?.tags?.map((elem, index) => (
              <span style={{ flex: 'none' }} key={index} className=" font14 font500 fontExtraLight g-15">
                #{elem}
              </span>
            ))}
          </span>

          {postDetails?.postDetails?.CTA && (
            <CommonButton style={{ width: 'max-content' }} target="_blank" href={postDetails?.postDetails?.CTA?.link}>
              {postDetails?.postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default GenericPost;
