import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import './barGraph.scss';
// // import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);

const BarGraph = ({ data }) => {
  const chartData = {
    labels: Object.keys(data?.[0]),
    datasets: [
      {
        label: '',
        backgroundColor: 'rgb(0, 128, 252)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 0,
        barThickness: 24,
        data: Object.values(data?.[0]),
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
        labels: data.labels,
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          // Ensuring y-axis ticks are integers
          stepSize: 5,
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="barGraphContainer">
      <div className="barGraphContainer-div">
        <h4 className="barGraphContainer-div__text">Building Analytics</h4>
      </div>
      <Bar className="barGraphContainer-graph" data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarGraph;
