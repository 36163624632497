import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './propertyDetailPage.scss';

import { useParams } from 'react-router-dom';
import {
  addBreadcrumb,
  deleteResources,
  saveResources,
  setDetailedOrganizationData,
  showSearchBar,
  viewMoreProperty,
} from '../../features/searchSlice';
import Wishlist from '../searchPage/components/wishlist/Wishlist';
import CompanyDetails from './components/CompanyDetails/CompanyDetails';
import EmployeesDetails from './components/EmployeesDetails/EmployeesDetails';
import PropertyDetails from './components/ProperyDetails/PropertyDetails';
import Search_assets from '../../../assets/images/search-assets.svg';

const PropertyDetailsPage = () => {
  // hooks
  const dispatch = useDispatch();

  // params
  const { propertyId } = useParams();

  // states
  const {
    propertyDetailsLoader,
    companyDetailsLoader,
    connectsData,
    propertyData,
    detailedPropertyData,
    organizationsData,
    wishlist,
    searchQuery,
    connectLoader,
    selectedOrganizationId,
  } = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(showSearchBar(true));
    if (propertyId) {
      dispatch(viewMoreProperty(propertyId));
      dispatch(addBreadcrumb({ name: `Connected Cards`, path: `/leadGen/connectedDetails/${propertyId}` }));
      dispatch(setDetailedOrganizationData([]));
    }
  }, []);

  const onDeleteCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      deleteResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  const onSaveCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      saveResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  return (
    <>
      <div  className="container">
        <div className='propertydetail-container'>
          <div style={{ width: '100%', borderRadius: '8px', position: "relative", zIndex: 1 }}>
            <div className="d-flex w-100 g-20">
              <PropertyDetails
                searchQuery={searchQuery}
                loader={propertyDetailsLoader || companyDetailsLoader}
                propertyData={propertyData}
                selectedPropertyData={detailedPropertyData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
              />
              <CompanyDetails
                loader={propertyDetailsLoader || companyDetailsLoader}
                organizationsData={organizationsData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
                selectedOrganizationId={selectedOrganizationId}
              />
              <EmployeesDetails
                loader={propertyDetailsLoader || companyDetailsLoader || connectLoader}
                connectsData={connectsData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'contents' }}>
            <img
              src={Search_assets}
              alt="Search"
              style={{
                width: '70%',
                position: 'absolute',
                objectFit: 'cover',
                objectPosition: 'center',
                top: '63%',
                opacity: 0.8,
              }}
            />
          </div>
        </div>

        <div style={{ background: 'white', width: '16%', borderRadius: '8px' ,height:'87vh'}}>
          <Wishlist wishlist={wishlist} />
        </div>
      </div>
    </>
  );
};

export default PropertyDetailsPage;
