import { Flex, Form, Input, Row, Typography, Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../../../components/socialApp/common/CommonButton';
import { selectPlanPost } from '../../../features/userOnboardingSlice';
import './selectPlanForm.scss';

const SelectPlanForm = ({ closePopup = () => {} }) => {
  // hooks
  const { Text } = Typography;
  const { TextArea } = Input;
  const dispatch = useDispatch();

  // form derails
  const [formDetails] = Form.useForm();
  
  // states
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((store) => store.user);
  const { userOnboardingData } = useSelector((store) => store.userOnboarding);

  
  // handle submit
  const handleFormSubmit = (e) => {
    const createPostPayload = {
      firstName: e?.firstName,
      lastName: e?.lastName,
      email: e?.email,
      phone: e?.phone,
      company: e?.company,
      message: e?.message,
    };
    setLoader(true);
    dispatch(selectPlanPost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data) {
        message.success('Request submitted successfully...');
        setTimeout(() => {
          closePopup();
          window.location.replace('/user/socialApp');
        }, 1000); // Redirect after 1 second
      } else {
        message.error('Some error occured...');
      }
    });
  };
  

  useEffect(() => {
    if (user) {
    formDetails.setFieldsValue({
      firstName: user?.name?.split(' ')?.[0],
      lastName: user?.name?.split(' ')?.[1],
      email: user?.email,
      phone: user?.phone,
      company: userOnboardingData?.professionalDetails?.companyName || '',
    });
  }
  }, [formDetails, user]);

  // JSX
  return (
    <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
      <Text className="selectcard-text font22 fontDark">Select Plan</Text>
      <div className='selectplan-container'>
        <Row className="d-flex">
          <Col span={12}>
            <Form.Item required label="First Name" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item
                  name="firstName"
                  className="buyerrow-form__space--text"
                  rules={[
                    {
                      required: true,
                      message: 'Add First Name!',
                    },
                  ]}
                >
                  <Input className='input-field' placeholder="Add First Name" min={0} />
                </Form.Item>
              </Flex>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required label="Last Name" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item
                  name="lastName"
                  className="buyerrow-form__space--text"
                  rules={[
                    {
                      required: true,
                      message: 'Add Last Name!',
                    },
                  ]}
                >
                  <Input className='input-field' placeholder="Add Last Name" min={0} />
                </Form.Item>
              </Flex>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item required label="Work E-mail" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="email"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Email!',
                },
              ]}
            >
              <Input className='input-field__email' placeholder="Add Email" min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item required label="Mobile No." className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="phone"
              className="buyerrow-form__space--text"
              rules={[
                {
                  message: 'Please input valid mobile no.',
                  pattern: /^[0-9]{10}$/,
                },
                {
                  required: true,
                  message: 'Add Mobile No.!',
                },
              ]}
            >
              <Input className='input-field__email' placeholder="Add Mobile Number" min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item required label="Company Name" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="company"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Company Name!',
                }, 
                {
                  pattern: /^[a-zA-Z0-9\s]*$/,
                  message: 'Company Name can only include letters, numbers, and spaces!',
                },
              ]}
            >
              <Input className='input-field__email' placeholder="Add Company Name" min={0}  />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item required label="Message" className="dropdown-form-item">
          <Flex gap="middle" horizontal>
            <Form.Item
              name="message"
              className="buyerrow-form__space--text"
              rules={[
                {
                  required: true,
                  message: 'Add Message!',
                },
              ]}
            >
              <TextArea className='input-field__email' placeholder="Write Message here" style={{ height: 80 }} min={0} />
            </Form.Item>
          </Flex>
        </Form.Item>
      </div>
        <Row className="d-flex jc-end formButtons">
          <div className="button-div d-flex jc-end g-20 ">
            <CommonButton loader={loader} type="primary" htmlType="submit">
              Submit
            </CommonButton>
          </div>
        </Row>
    </Form>
  );
};

export default SelectPlanForm;