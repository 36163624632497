import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedRoute = () => {
  const location = useLocation();
  const { pathname, search } = location;
  // Search params
  const shareableLink = search?.includes('shareable');
  const { accessToken } = useSelector((store) => store.user);
  return !isEmpty(accessToken) ? (
    <Outlet context={shareableLink ? `${pathname}${search}` : null} />
  ) : (
    <Navigate to={`/${shareableLink ? `?shareable=${encodeURIComponent(pathname + search)}` : ''}`} />
  );
};
