import React, { useEffect } from 'react';
import Property from './PropertySubscriptions.jsx';
import Company from './CompanySubscriptions.jsx';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedNav } from '../../../../leadgen/features/universalSidenavSlice.js';
const Subscriptions = () => {
  const dispatch = useDispatch();
  
  return (
    <>
      <section className="subscriptionsSection">
        <div className="subscription-card__left">
          <Property />
        </div>
        <div className="subscription-card__right">
          <Company />
        </div>
      </section>
    </>
  );
};

export default Subscriptions;
