import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import { upperFirst } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import InputEmoji from 'react-input-emoji';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useOutsideHandler from '../../../../../utils/useOutsideClickHandeler/useOutsideClickHandeler';
import {
  createComments,
  deleteComment,
  getComments,
  socialAppFollowUser,
  socialAppUnFollowUser,
  updateComment,
} from '../../../../features/socialAppSlice';
import AdCardPost from '../allPosts/adCardPost/AdCardPost';
import EventCardPost from '../allPosts/eventCardPost/EventCardPost';
import GenericPost from '../allPosts/genericPost/GenericPost';
import JobCardPost from '../allPosts/jobCardPost/JobCardPost';
import LeadCardPost from '../allPosts/leadCardPost/LeadCardPost';
import NewsCardPost from '../allPosts/newsCardPost/NewsCardPost';
import PollCardPost from '../allPosts/pollCardPost/PollCardPost';
import ReportCardPost from '../allPosts/reportCardPost/ReportCardPost';
import './CommentComponent.scss';
import CommentMenu from './CommentMenu/CommentMenu';
import dayjs from 'dayjs';

const CommentComponent = ({
  isDashboardPost,
  isUserPost,
  setAllPosts = () => {},
  setPostData = () => {},
  userId,
  postId,
  postDetails,
  setPostDetails = () => {},
  isIndexablePage = false,
}) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.socialAppSlice.socialAppComments);
  const loading = useSelector((state) => state.socialApp?.socialApploader);
  const user = useSelector((state) => state.user?.user);
  const error = useSelector((state) => state.socialApp?.error);
  const [newComment, setNewComment] = useState('');
  const [replyCommentId, setReplyCommentId] = useState('');
  const [editCommentText, setEditCommentText] = useState('');
  const [editingComment, setEditingComment] = useState(null);
  const [editingChildComment, setEditingChildComment] = useState(null);
  const [editChildCommentText, setEditChildCommentText] = useState('');
  const [replyChildCommentText, setReplyChildCommentText] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    if (userId && postId) {
      dispatch(getComments({ userId, postId }));
    }
  }, [userId, postId]);

  const handleAddComment = async (e, parentId = null, newComment) => {
    const sanitizer = DOMPurify.sanitize;
    if (newComment.trim() !== '') {
      dispatch(
        createComments({ userId, payload: { postId, parentCommentId: parentId, text: sanitizer(newComment) } }),
      )?.then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 + 1,
            }));
            setPostDetails((prev) => ({
              ...prev,
              comments: prev?.comments || 0 + 1,
            }));
          }
        }
      });
      setEditingChildComment('');
      setReplyChildCommentText('');
      setEditChildCommentText('');
      setEditingComment('');
      setEditCommentText('');
      setReplyCommentId('');
      setNewComment('');
    }
  };

  const onAvatarClick = () => {};

  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: true,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isFollowed: true,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: true };
            }
            return elem;
          }),
        }));
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: false,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isFollowed: false,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: false };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    const sanitizer = DOMPurify.sanitize;
    setEditCommentText(sanitizer(comment?.text));
  };

  const handleReplyChildComment = (comment) => {
    // setReplyChildCommentText(comment?.text?.toString());
    setReplyChildCommentText(comment);
  };

  const handleUpdateComment = async (e, editCommentText, editingComment) => {
    if (editCommentText?.trim() !== '') {
      dispatch(updateComment({ userId, commentId: editingComment._id, text: editCommentText })).then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          setEditingComment(null);
          setEditingChildComment(null);
          setEditCommentText('');
          setEditChildCommentText('');
        }
      });
    }
  };
  const handleUpdateChildComment = async () => {
    if (editChildCommentText?.trim() !== '') {
      dispatch(updateComment({ userId, commentId: editingChildComment._id, text: editCommentText })).then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          setEditingComment(null);
          setEditCommentText('');
        }
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setEditCommentText('');
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment({ userId, commentId, postId })).then((res) => {
      if (res?.payload) {
        {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 - 1,
            }));
            setPostDetails((prev) => ({
              ...prev,
              comments: prev?.comments || 0 - 1,
            }));
          }
        }
      }
    });
  };

  const sortedComments = comments?.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: NewsCardPost,
    ad_card: AdCardPost,
    event_card: EventCardPost,
    lead_card: LeadCardPost,
    job_card: JobCardPost,
    poll_card: PollCardPost,
  };

  const PostComponent = postTypes?.[postDetails?.type];

  const replyRef = useRef();
  const editRef = useRef();
  const editingRef = useRef();

  useOutsideHandler(replyRef, true, () => {
    setReplyCommentId(null);
    setReplyChildCommentText('');
  });
  useOutsideHandler(editRef, true, () => {
    setEditingChildComment(null);
    setEditingComment(null);
    setEditCommentText(null);
    setEditChildCommentText('');
  });
  useOutsideHandler(editingRef, true, () => {
    setEditingComment(null);
    setEditCommentText(null);
  });

  const getDate = (createdAt) => {
    const date1 = dayjs(createdAt);
    const date2 = dayjs();

    // Calculate the difference in milliseconds
    const diffInMs = date2.diff(date1);

    // Convert the difference to a dayjs duration
    // const diffDuration = dayjs.duration(diffInMs);
    const totalMilliseconds = diffInMs;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    if (totalDays > 0) {
      return `${totalDays}d`;
    } else if (totalHours > 0) {
      return `${totalHours}h`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes}m`;
    } else if (totalSeconds > 0) {
      return `${totalSeconds}s`;
    }
  };
  return (
    <div className="comment-modal-content">
      {!isIndexablePage && (
        <div className="d-flex a-center mb-20">
          <LeftOutlined
            style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
            onClick={() => navigate(-2)}
          />
        </div>
      )}
      <div style={{ background: 'white', borderRadius: 10 }} className="modal-post p-20 pb-0 mb-0">
        <div className="post-left">
          <div>
            <PostComponent
              setPostDetails={setPostDetails}
              isViewPost={true}
              setAllposts={setAllPosts}
              setPostData={setPostData}
              handleFollow={handleFollow}
              handleUnFollow={handleUnFollow}
              postDetails={postDetails}
              isUserPost={isUserPost}
              options={postDetails?.postDetails?.options}
            />
          </div>
        </div>
        {postDetails?.type !== 'poll_card' && (
          <>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'end', marginLeft: '20px' }} className="post-right">
              <div className=" newComment d-flex g-10 jc-between a-center w-100">
                <Avatar
                  className="avatar"
                  onClick={onAvatarClick}
                  src={user?.personalDetails?.imageUrl}
                  icon={<UserOutlined />}
                  style={{ alignContent: 'center' }}
                />
                <div className="comment-input-container position-relative">
                  <InputEmoji
                    value={newComment}
                    shouldReturn
                    onChange={(e) => {
                      setNewComment(e);
                    }}
                    onEnter={(e) => handleAddComment(e, null, e)}
                    placeholder="Write your comment"
                  />
                </div>
              </div>
              <div className={`comments-list-2 mt-10`}>
                {loading && <p>Loading comments...</p>}
                {error && <p>{error}</p>}
                {sortedComments.length > 0 ? (
                  sortedComments.map((comment, index) => (
                    <div key={index} className="d-flex g-10">
                      <Avatar
                        className="avatar"
                        onClick={onAvatarClick}
                        src={comment?.userDetails?.personalDetails?.imageUrl}
                        icon={<UserOutlined />}
                        style={{ alignContent: 'center' }}
                      />
                      <div className="d-flex d-column w-100">
                        <div key={comment._id} className="comment-item">
                          <div className="d-flex jc-between">
                            <div className="d-flex d-column mb-10">
                              <span className="font14 font600 fontLight">{upperFirst(comment?.userDetails?.name)}</span>
                              <div className="d-flex g-5 a-center">
                                <span className="font12 fontExtraLight ">
                                  {upperFirst(comment?.userDetails?.professionalDetails?.designation)}
                                </span>
                                {comment?.userDetails?.professionalDetails?.companyName && (
                                  <span className="font12 fontExtraLight ">
                                    @
                                    <Tooltip title={comment?.userDetails?.professionalDetails?.companyName}>
                                      {comment?.userDetails?.professionalDetails?.companyName}
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex a-center">
                              <span className="font10 fontLight-2">{getDate(comment?.createdAt)}</span>
                              {comment.userId === userId && (
                                <CommentMenu
                                  onEditComment={() => handleEditComment(comment)}
                                  onDeleteComment={() => handleDeleteComment(comment?._id)}
                                />
                              )}
                            </div>
                          </div>

                          {editingComment && editingComment._id === comment._id ? (
                            <div ref={editingRef} className="comment-edit-container position-relative">
                              <InputEmoji
                                shouldReturn
                                keepOpened
                                value={editCommentText}
                                onChange={setEditCommentText}
                                placeholder="Edit your comment"
                                onEnter={(e) => handleUpdateComment(e, editCommentText, editingComment)}
                                // onBlur={handleCancelEdit}
                              />
                            </div>
                          ) : (
                            <div className="comment-text font14">
                              <span dangerouslySetInnerHTML={{ __html: comment?.text }}></span>
                            </div>
                          )}
                        </div>
                        <span className="fontLight-6 mt-10 font12 pl-5 cursor-pointer">
                          <span
                            onClick={() => {
                              setReplyCommentId(comment._id);
                            }}
                          >
                            Reply
                          </span>{' '}
                          <span className="fontLight-4"> &#x25cf; {comment?.commentDetails?.length} Replies</span>
                        </span>

                        {replyCommentId == comment?._id && (
                          <div ref={replyRef} className="comment-edit-container d-flex g-5 mt-10 mb-10 pl-30">
                            <Avatar
                              className="avatar"
                              onClick={onAvatarClick}
                              src={user?.personalDetails?.imageUrl}
                              icon={<UserOutlined />}
                              style={{ alignContent: 'center' }}
                            />
                            <InputEmoji
                              shouldReturn
                              keepOpened
                              value={replyChildCommentText}
                              onChange={handleReplyChildComment}
                              onEnter={(e) => handleAddComment(e, comment?._id, replyChildCommentText)}
                              placeholder="Add reply"
                            />
                          </div>
                        )}
                        <div className="d-flex d-column g-10 pl-30 mt-10">
                          {comment?.commentDetails?.length > 0 &&
                            comment?.commentDetails?.map((childComments) => (
                              <div key={childComments._id} className="comment-item">
                                <div className="d-flex jc-between ">
                                  <div className="d-flex d-column mb-10 replyContainers">
                                    <span className="font14 font600 fontLight">
                                      {upperFirst(childComments?.userDetails?.name)}
                                    </span>
                                    <div className="d-flex g-5 a-center">
                                      <span className="font12 fontExtraLight ">
                                        {upperFirst(childComments.userDetails?.professionalDetails?.designation)}
                                      </span>
                                      {childComments?.userDetails?.professionalDetails?.companyName && (
                                        <span className="font12 fontExtraLight ">
                                          @
                                          <Tooltip title={childComments?.userDetails?.professionalDetails?.companyName}>
                                            {childComments?.userDetails?.professionalDetails?.companyName}
                                          </Tooltip>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex a-center">
                                    <span className="font10 fontLight-2">{getDate(childComments?.createdAt)}</span>
                                    {childComments.userId === userId && (
                                      <CommentMenu
                                        onEditComment={() => {
                                          setEditingChildComment(childComments);
                                          setEditChildCommentText(childComments?.text?.toString());
                                        }}
                                        onDeleteComment={() => handleDeleteComment(childComments?._id)}
                                      />
                                    )}
                                  </div>
                                </div>
                                {editingChildComment && editingChildComment?._id === childComments?._id ? (
                                  <div ref={editRef} className="comment-edit-container position-relative">
                                    <InputEmoji
                                      shouldReturn
                                      keepOpened
                                      value={editChildCommentText}
                                      onChange={setEditChildCommentText}
                                      onEnter={(e) => handleUpdateComment(e, editChildCommentText, editingChildComment)}
                                      placeholder="Edit your comment"
                                      // onBlur={() => setEditingChildComment(null)}
                                    />
                                  </div>
                                ) : (
                                  <div className="comment-text font14">
                                    <span dangerouslySetInnerHTML={{ __html: childComments?.text }}></span>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ marginLeft: '48px' }}>No comments yet</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default CommentComponent;
