import { LeftOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Divider } from 'antd';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CommonButton from '../../common/CommonButton';
import './addGroup.scss';
import { createPostTypes2 } from './posts.utils';
import { joinSocialGroup, leaveSocialGroup, removeFromSocialGroup } from '../../../../features/socialAppSlice';

const AddGroupPost = ({ groupDetail, setfetch = () => {}, toggleShowPurpose, showPurpose, setShowPostForm }) => {
  const dispatch = useDispatch();

  const removeFromGroup = (id) => {
    dispatch(
      removeFromSocialGroup({
        groupId: groupDetail?._id,
        userId: id,
      }),
    )?.then((res) => {
      if (res) {
        setfetch(true);
      }
    });
  };
  const handleCardClick = (key) => {
    setShowPostForm(key);
  };
  const user = useSelector((store) => store?.user);
  // Checking is user is admin or not
  const isUserMember = () => {
    return groupDetail?.memberDetails?.find((elem) => elem?.id == user?.user?.id);
  };
  const isUserAdmin = () => {
    return [...(groupDetail?.adminDetails || [])]?.find((elem) => elem?.id == user?.user?.id);
  };
  const navigate = useNavigate();

  return (
    <div className="cursor-pointer socialAppContainer__postContainer--addPost addPost" style={{ padding: 0 }}>
      <div
        className="d-flex jc-between groupDetails pt-10"
        style={{
          background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${groupDetail?.imageUrl})`,
        }}
      >
        <div className="d-flex g-10 a-start">
          <LeftOutlined
            onClick={() => {
              showPurpose ? toggleShowPurpose() : navigate(-2);
            }}
            className="mt-10 cursor-pointer"
            style={{ color: '#c7c7c7', fontSize: 32 }}
          />
          <div onClick={toggleShowPurpose} className="d-flex d-column g-3 cursor-pointer">
            <span className="fontWhite font30 font700">{upperFirst(groupDetail?.name)}</span>
            <div className="d-flex g-10 a-center">
              <span className="fontOrange font16">{groupDetail?.memberCount}</span>
              <span className="fontWhite font14">members</span>
            </div>
            <div className="font12 fontLight-2" style={{ color: '#c7c7c7' }}>
              Created on : {dayjs(groupDetail?.createdOn)?.format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className="d-flex d-column jc-between a-end">
          <div className="d-flex  pl-40">
            {groupDetail?.memberDetails?.map((elem, index) => (
              <Avatar
                style={{ border: '2px solid white', width: 40, height: 40, marginLeft: index !== 0 && -5 }}
                key={nanoid()}
                src={elem?.imageUrl}
                icon={<UserOutlined />}
              />
            ))}
          </div>
          {isUserMember() && !isUserAdmin() && (
            <div
              onClick={() => {
                dispatch(leaveSocialGroup(groupDetail?._id))?.then((res) => {
                  if (res) {
                    setfetch(true);
                  }
                });
              }}
              className="font12"
              style={{ color: '#c7c7c7' }}
            >
              <LogoutOutlined /> Leave group
            </div>
          )}
          {!isUserMember() && !isUserAdmin() && (
            <div
              onClick={() => {
                dispatch(joinSocialGroup(groupDetail?._id))?.then((res) => {
                  if (res) {
                    setfetch(true);
                  }
                });
              }}
              className="font12"
              style={{ color: '#c7c7c7' }}
            >
              Join group
            </div>
          )}
        </div>
      </div>
      {!showPurpose && (isUserMember() || isUserAdmin()) && (
        <>
          <div className="d-flex jc-between p-10">
            <div className="font14 font700 p-5">Create post</div>
          </div>
          <Divider className="addPostdivider" />

          <div className="d-flex g-20 a-start pt-20 pb-10 pl-10 ">
            {createPostTypes2?.map((card, index) => {
              const Icon = card?.icon;
              return (
                <div key={card?.key} className={`addPostIcons  cursor-pointer ${card?.disabled ? 'disabled' : ''}`}>
                  <div className="d-flex d-column a-start" g-5 onClick={() => handleCardClick(card?.key)} key={index}>
                    <div className="svgContainer"> {Icon && <Icon />}</div>
                    <div className="svgContainerTwo"> {Icon && <Icon color={'#0081FC'} />}</div>
                    <span className="cardLabel">{card?.label}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {showPurpose && (
        <div style={{ height: 800, overflow: 'scroll' }} className="d-flex d-column p-20  g-20">
          <div className="d-flex d-column g-10 ">
            <span className="fontDark font16 font700">Purpose</span>
            <span className="font14 fontLight" dangerouslySetInnerHTML={{ __html: groupDetail?.purpose }}></span>
          </div>
          <div className="d-flex d-column g-10 ">
            <span className="fontDark font16 font700">Group Rules</span>
            <span
              className="groupRules font14 fontLight"
              dangerouslySetInnerHTML={{ __html: groupDetail?.rules }}
            ></span>
          </div>
          <div className="d-flex d-column g-10">
            <span className="fontDark font16 font700">Group Admin</span>
            <span className="d-flex g-20 font14 fontLight-2">
              {groupDetail?.adminDetails?.map((elem) => (
                <span className="d-flex g-20 a-center">
                  <Avatar src={elem?.imageUrl || ''} icon={<UserOutlined />} />
                  <span>{elem?.name}</span>
                </span>
              ))}
            </span>
          </div>
          <div className="d-flex d-column g-10">
            <span className="fontDark font16 font700">Group Members</span>
            <span className="d-flex g-20  flex-wrap font14 fontLight-2">
              {groupDetail?.memberDetails?.map((elem) => (
                <span className="d-flex g-20 a-center jc-between" style={{ width: '48%' }}>
                  <div className="d-flex g-10 a-center">
                    <Avatar src={elem?.imageUrl || ''} icon={<UserOutlined />} />
                    <span>{elem?.name}</span>
                  </div>
                  {isUserAdmin() && (
                    <CommonButton ghost onClick={() => removeFromGroup(elem?.id)}>
                      Remove
                    </CommonButton>
                  )}
                </span>
              ))}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddGroupPost;
