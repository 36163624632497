import React from 'react';

const LeadPostIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg
      id="Lead_Card"
      data-name="Lead Card"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="Group_25055" data-name="Group 25055">
        <path
          id="Path_17632"
          data-name="Path 17632"
          d="M96.629,33.526A.607.607,0,0,1,96,32.944V25.582a.631.631,0,0,1,1.257,0v7.363A.607.607,0,0,1,96.629,33.526Z"
          transform="translate(-83.129 -22.46)"
          fill={color}
        />
        <path
          id="Path_17633"
          data-name="Path 17633"
          d="M91.656,11.955A1.557,1.557,0,1,1,93,11.177,1.562,1.562,0,0,1,91.656,11.955Zm0-2.186a.629.629,0,1,0,.544.314.631.631,0,0,0-.544-.314Z"
          transform="translate(-78.156 -8.84)"
          fill={color}
        />
        <g id="Group_25054" data-name="Group 25054" transform="translate(0 0.707)">
          <path
            id="Path_17634"
            data-name="Path 17634"
            d="M27.251,23.7a.629.629,0,0,1-.629-.629V21.462h-4.4a1.182,1.182,0,0,1-1.18-1.18v-.85H19.985a1.363,1.363,0,0,1-.077.151,1.558,1.558,0,1,1,0-1.558,1.207,1.207,0,0,1,.075.149h1.053V16.328A1.557,1.557,0,1,1,23,15.676a1.54,1.54,0,0,1-.7.637V20.2h1.811V18.378a1.559,1.559,0,1,1,1.257,0V20.2H26.7a1.182,1.182,0,0,1,1.18,1.18v1.683A.629.629,0,0,1,27.251,23.7ZM18.56,18.188a.616.616,0,0,0-.533.31.612.612,0,0,0,.533.92.615.615,0,0,0,0-1.229Zm6.174-1.831a.6.6,0,0,0-.086,1.191.431.431,0,0,0,.085.006.423.423,0,0,0,.077,0,.613.613,0,0,0,.443-.3.6.6,0,0,0-.522-.9Zm-3.086-2a.546.546,0,0,0-.472.27.557.557,0,0,0,0,.545.544.544,0,0,0,.47.267.541.541,0,1,0,0-1.081Z"
            transform="translate(-17.002 -13.34)"
            fill={color}
          />
          <path
            id="Path_17635"
            data-name="Path 17635"
            d="M114.429,23.7a.629.629,0,0,1-.629-.629V21.385a1.182,1.182,0,0,1,1.18-1.18h1.336V18.378a1.559,1.559,0,1,1,1.257,0V20.2h1.811V16.312a1.556,1.556,0,1,1,1.995-.637,1.573,1.573,0,0,1-.737.652v1.845H121.7c.022-.052.049-.1.075-.151a1.557,1.557,0,1,1,0,1.558,1.692,1.692,0,0,1-.077-.151h-1.052v.85a1.182,1.182,0,0,1-1.18,1.18h-4.4v1.606a.629.629,0,0,1-.629.629Zm8.691-5.509a.615.615,0,0,0,0,1.229.615.615,0,1,0,0-1.229Zm-6.174-1.808a.576.576,0,0,0-.083,1.146.545.545,0,0,0,.082.006.389.389,0,0,0,.074,0,.578.578,0,0,0,.424-.863.579.579,0,0,0-.5-.283Zm3.086-2.042a.56.56,0,0,0,0,1.119.564.564,0,0,0,.487-.28.574.574,0,0,0,0-.563.563.563,0,0,0-.486-.275Z"
            transform="translate(-97.677 -13.34)"
            fill={color}
          />
        </g>
      </g>
      <path
        id="Path_17636"
        data-name="Path 17636"
        d="M39.488,98.754a1.264,1.264,0,0,1-.677-.195,1.164,1.164,0,0,1-.551-.986V93.156L31.871,88.09A2.786,2.786,0,0,1,30.8,85.905V84.794a1.644,1.644,0,0,1,.277-3.274h1.746a.629.629,0,1,1,0,1.257H31.076a.391.391,0,1,0,0,.781H50.759a.391.391,0,1,0,0-.781H34.545a.629.629,0,1,1,0-1.257H50.759a1.644,1.644,0,0,1,.277,3.274v1.111a2.786,2.786,0,0,1-1.072,2.185l-2.894,2.295a.673.673,0,0,1-.923-.085.613.613,0,0,1,.088-.885l2.894-2.3a1.542,1.542,0,0,0,.6-1.215V84.815H32.109V85.9a1.549,1.549,0,0,0,.6,1.215l6.626,5.256a.616.616,0,0,1,.237.484v4.6l2.7-1.114V93.488H40.746a.629.629,0,1,1,0-1.257h1.94l2.24-1.776a.675.675,0,0,1,.92.083.616.616,0,0,1-.085.887l-2.184,1.732v3.238a1.181,1.181,0,0,1-.744,1.085l-2.86,1.179A1.265,1.265,0,0,1,39.488,98.754Z"
        transform="translate(-27.418 -71.754)"
        fill={color}
      />
    </svg>
  );
};

export default LeadPostIcon;
