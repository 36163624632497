import { Flex, Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Outlet } from 'react-router';
// import SideNav from './components/sideNav/SideNav';
import SideNav from '../universal/components/sideNav/SideNav';
import TopNav from './components/topNav/TopNav';
// import TopNav from '../universal/components/topNav/TopNav'; 
import RaiseConcern from '../universal/components/raiseConcern/RaiseConcern';
import Breadcrumb from '../../leadgen/pages/searchPage/components/breadcrumbs/BreadCrumbs';
const LeadGenLayout = ({ loading }) => {
  return (
    <Flex gap="middle" wrap="wrap">
      <Layout>
        <SideNav />
        <Layout>
          <TopNav />
          <Breadcrumb /> {/* Breadcrumb component */}
          <Content>{loading ? <Spin spinning={loading} size="large" fullscreen /> : <Outlet />}</Content>
        </Layout>
        <RaiseConcern />
      </Layout>
    </Flex>
  );
};

export default LeadGenLayout;
