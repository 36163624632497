import genericCard from '../../../../../assets/images/generic-card.png';
import newsCard from '../../../../../assets/images/news-card.png';
import reportCard from '../../../../../assets/images/report-card.png';
import jobCard from '../../../../../assets/images/job-card.png';
import leadCard from '../../../../../assets/images/lead-card.png';
import eventCard from '../../../../../assets/images/event-card.png';
import pollCard from '../../../../../assets/images/poll-card.png';
import advCard from '../../../../../assets/images/adv-card.png';
import GenericPostIcon from '../../../../../assets/SocialApp/GenericPostIcon';
import NewsPostIcon from '../../../../../assets/SocialApp/NewsPostIcon copy';
import ReportPostIcon from '../../../../../assets/SocialApp/ReportPostIcon';
import AdvPostIcon from '../../../../../assets/SocialApp/AdvPostIcon';
import LeadPostIcon from '../../../../../assets/SocialApp/LeadPostIcon';
import PollPostIcon from '../../../../../assets/SocialApp/PollPostIcon';
import EventPostIcon from '../../../../../assets/SocialApp/EventPostIcon';
import JobPostIcon from '../../../../../assets/SocialApp/JobPostIcon';

export const createPostTypes = [
  { label: 'Generic Post', key: 'GENERIC_POST', icon: genericCard, enable: true },
  { label: 'News', key: 'NEWS_CARD', icon: newsCard, enable: true },
  { label: 'Reports', key: 'REPORT_CARD', icon: reportCard, enable: true },
  { label: 'Advt.', key: 'Ad_CARD', icon: advCard, enable: true },
  { label: 'List../Req..', key: 'LEAD_CARD', icon: leadCard, enable: true },
  { label: 'Hiring', key: 'JOB_CARD', icon: jobCard, enable: true },
  { label: 'Create a Poll', key: 'POLL_CARD', icon: pollCard, enable: true },
  { label: 'Share an Event', key: 'EVENT_CARD', icon: eventCard, enable: true },
];
export const createPostTypes2 = [
  { label: 'Generic Post', key: 'GENERIC_POST', icon: GenericPostIcon, enable: true },
  { label: 'News', key: 'NEWS_CARD', icon: NewsPostIcon, enable: true },
  { label: 'Reports', key: 'REPORT_CARD', icon: ReportPostIcon, enable: true },
  { label: 'Advt.', key: 'Ad_CARD', icon: AdvPostIcon, enable: true },
  { label: 'List../Req..', key: 'LEAD_CARD', icon: LeadPostIcon, enable: true },
  { label: 'Hiring', key: 'JOB_CARD', icon: JobPostIcon, enable: false },
  { label: 'Create a Poll', key: 'POLL_CARD', icon: PollPostIcon, enable: true },
  { label: 'Share an Event', key: 'EVENT_CARD', icon: EventPostIcon, enable: true },
];
