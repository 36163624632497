import { DeleteOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

import Text from 'antd/es/typography/Text';
import { truncate } from 'lodash';
import { useNavigate } from 'react-router';
import './postTypes.scss';
import { placeHolderImageUrl } from '../../../../../constants/globalConstants';
import RepostIcon from '../../../../../assets/SocialApp/RepostIcon';

const ReportCardPost = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
  handleDeleteRepost,
}) => {
  const navigate = useNavigate();
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  return (
    <div className="profile-container">
      {data?.isReposted && (
        <div className="d-flex g-5 a-center">
          <div className="repost-icon">
            <RepostIcon className="repost-icon" />
          </div>
          <div>You Reposted</div>
        </div>
      )}
      <div className="profile-layout" gutter={16}>
        {data.imageUrls?.[0]?.url && (
          <div
            style={{
              background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
              data.imageUrls?.[0]?.url || placeHolderImageUrl
            })
`,
            }}
            className="report-profile-image"
          >
            <span className="postImageTitle">{data?.title}</span>
            {data?.postDetails?.pdfUrl && (
              <a target="_blank" href={data?.postDetails?.pdfUrl} download="report-card" className="postImageFooter">
                <DownloadOutlined style={{ fontSize: 16 }} />
                Download full Report
              </a>
            )}
          </div>
        )}
        {/* </div> */}
        <div className="content-col" style={{ width: '100%' }}>
          <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
            <span className="d-flex g-15 a-center ">
              <Avatar src={data?.ownerDetails?.personalDetails?.imageUrl || ''} icon={<UserOutlined />} />
              {isCompany ? (
                <div className="d-flex d-column">
                  <span className="font14 fontExtraLight ">{data?.ownerDetails?.name}</span>
                  {data?.ownerDetails?.address && (
                    <span className="font14 fontExtraLight ">
                      {data?.ownerDetails?.address?.city},{data?.ownerDetails?.address?.state}
                    </span>
                  )}
                </div>
              ) : (
                <div className="d-flex d-column">
                  <Text style={{ fontWeight: '600', color: '#535353' }}>{data?.ownerDetails?.name}</Text>
                  <div className="d-flex">
                    {data?.ownerDetails?.professionalDetails?.designation && (
                      <Text
                        style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                      >{`${data?.ownerDetails?.professionalDetails?.designation}`}</Text>
                    )}
                    {data?.ownerDetails?.professionalDetails?.companyName && (
                      <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                        @{data?.ownerDetails?.professionalDetails?.companyName}
                      </Text>
                    )}
                  </div>
                </div>
              )}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }}>
              <DeleteOutlined onClick={data?.isReposted ? handleDeleteRepost : handleDeletPost} />
            </div>
          </div>
          <Text id="post-title" style={{ fontWeight: '600', color: '#535353' }}>
            {truncate(data?.title, { length: 40 })}
          </Text>
          <div
            id="post-synopsys"
            // className="font14 postBody fontExtraLight"
            style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}
            dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.synopsys, { length: 142 })) }}
          ></div>
          <div
            id="post-body"
            // className="font14 postBody fontExtraLight"
            style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}
            dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.body, { length: 142 })) }}
          ></div>
          <Text
            className="cursor-pointer"
            style={{ fontWeight: '600', color: '#0081FC' }}
            onClick={() => navigate(`/user/socialApp/postDetails/${data?._id}/${data?.ownerType}`)}
          >
            See more
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ReportCardPost;
