import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { truncate } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import CommentComponent from '../../../socialApp/posts/postFooter/CommentComponent';
import NewsCardAudioComponentMyAccount from './AudioVideoComponents/NewsCardAudioComponentMyAccount';
import NewsCardVideoComponentMyAccount from './AudioVideoComponents/NewsCardVideoComponentMyAccount';
import NewsCardImageComponentMyAccount from './AudioVideoComponents/NewsCardImageComponentMyAccount';
import RepostIcon from '../../../../../assets/SocialApp/RepostIcon';

const MyAccountNewsCardPost = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
  handleDeleteRepost,
}) => {
  const AudioVideoComponetMap = {
    audio: NewsCardAudioComponentMyAccount,
    video: NewsCardVideoComponentMyAccount,
    image: NewsCardImageComponentMyAccount,
  };
  const navigate = useNavigate();
  const AudioVideoComponet = AudioVideoComponetMap?.[data?.postDetails?.subType?.toLowerCase()];

  return (
    <div className="profile-container">
      {data?.isReposted && (
        <div className="d-flex g-5 a-center">
          <div className="repost-icon">
            <RepostIcon className="repost-icon" />
          </div>
          <div>You Reposted</div>
        </div>
      )}
      <div className="profile-layout" gutter={16}>
        <div className="imgcontainer position-relative">
          {AudioVideoComponet && <AudioVideoComponet postDetails={data} isFromMyAccount={true} />}
        </div>
        <div className="content-col" style={{ width: '100%' }}>
          <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
            <span className="d-flex g-15 a-center ">
              <Avatar src={data?.ownerDetails?.personalDetails?.imageUrl || ''} icon={<UserOutlined />} />
              {isCompany ? (
                <div className="d-flex d-column">
                  <span className="font14 fontExtraLight ">{data?.ownerDetails?.name}</span>
                  {data?.ownerDetails?.address && (
                    <span className="font14 fontExtraLight ">
                      {data?.ownerDetails?.address?.city},{data?.ownerDetails?.address?.state}
                    </span>
                  )}
                </div>
              ) : (
                <div className="d-flex d-column">
                  <Text style={{ fontWeight: '600', color: '#535353' }}>{data?.ownerDetails?.name}</Text>
                  <div className="d-flex">
                    {data?.ownerDetails?.professionalDetails?.designation && (
                      <Text
                        style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                      >{`${data?.ownerDetails?.professionalDetails?.designation}`}</Text>
                    )}
                    {data?.ownerDetails?.professionalDetails?.companyName && (
                      <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                        @{data?.ownerDetails?.professionalDetails?.companyName}
                      </Text>
                    )}
                  </div>
                </div>
              )}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }}>
              <DeleteOutlined onClick={data?.isReposted ? handleDeleteRepost : handleDeletPost} />
            </div>
          </div>
          <Text id="post-title" style={{ fontWeight: '600', color: '#535353' }}>
            {truncate(data?.title, { length: 40 })}
          </Text>

          <div
            id="post-body"
            style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}
            dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.body, { length: 142 })) }}
          ></div>
          <Text
            className="cursor-pointer"
            style={{ fontWeight: '600', color: '#0081FC' }}
            onClick={() => navigate(`/user/socialApp/postDetails/${data?._id}/${data?.ownerType}`)}
          >
            See more
          </Text>
        </div>
        {postData && (
          <Modal
            styles={{
              mask: {
                backdropFilter: 'blur(6px)',
              },
            }}
            centered
            title={null}
            open={isVisiable}
            onCancel={handleCancel}
            footer={null}
            width={820}
          >
            <CommentComponent
              isUserPost={true}
              setAllPosts={setAllPosts}
              setPostData={setPostData}
              postDetails={postData}
              postId={postData?._id}
              userId={user?._id}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MyAccountNewsCardPost;
