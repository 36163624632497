import React from 'react';

const RepostIcon = ({ color = '#aaa9a9' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.735" height="22.394" viewBox="0 0 19.735 22.394">
      <path
        id="Repost"
        d="M37.176,39.9a.553.553,0,0,1-.394-.163l-2.66-2.66a.557.557,0,0,1,0-.789l2.662-2.662a.558.558,0,1,1,.791.787l-1.71,1.709h7.965a2.105,2.105,0,0,0,2.1-2.1V23.387a2.105,2.105,0,0,0-2.1-2.1.558.558,0,0,1,0-1.116,3.22,3.22,0,0,1,3.217,3.217v10.64a3.22,3.22,0,0,1-3.217,3.217H35.864l1.71,1.707a.551.551,0,0,1,.163.395.558.558,0,0,1-.558.559Zm-6.649-2.66a3.22,3.22,0,0,1-3.217-3.217V23.387a3.22,3.22,0,0,1,3.217-3.217h7.965l-1.71-1.707a.559.559,0,0,1,.4-.953.559.559,0,0,1,.395.163l2.662,2.662a.559.559,0,0,1,0,.789l-2.66,2.66a.558.558,0,1,1-.79-.789l1.709-1.707H30.528a2.105,2.105,0,0,0-2.1,2.1V34.03a2.105,2.105,0,0,0,2.1,2.1.558.558,0,1,1,0,1.116Z"
        transform="translate(-27.31 -17.51)"
        fill={color}
      />
    </svg>
  );
};

export default RepostIcon;
