// imports
import { Button, Form, Space, Steps, Typography, message } from 'antd';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import LottieAnimation from '../../components/lottieAnimation/LottieAnimation';
import Offerings from '../../components/userOnboarding/Offerings/Offerings';
import Preference from '../../components/userOnboarding/preferences/Preference';
import PersonalDetails from '../../components/userOnboarding/professionalDetails/PersonalDetails';
import SelectPlan from '../../components/userOnboarding/selectPlan/SelectPlan';

import Title from 'antd/es/typography/Title';
import { isEmpty } from 'lodash';
import animationData from '../../../assets/images/userOnboardingLottie.json';
import {
  fetchUserOnboardingData,
  postOnboardingData,
  postOnboardingDataForLinkedIn,
  registerCrm,
  userOnboardingGetAllCompanyDetails,
  userOnboardingGetAllCompanyDetailsBySearch,
  userOnboardingGetPreferences,
  setPreferencesUserOnboarding,
  setSearchedCompanyName,
  fetchUserPoints,
  userOnboardingGetAllIndusteriesDetails,
} from '../../features/userOnboardingSlice';
import { STEPS_MAP, getCrmPayload, getPayloadTemplateV1, getPayloadTemplateV2 } from './userboarding.utils';
import './useronboarding.scss';
import { set_toast } from '../../features/toastSlice';
import { isObjectId } from '../../../utils/helpers';

const UserOnboarding = () => {
  // hooks
  const dispatch = useDispatch();
  const [userOfferings] = Form.useForm();
  const { Text } = Typography;

  // States
  const { userOnboardingData, userPoints, searchedCompanyName } = useSelector((store) => store.userOnboarding);
  const [current, setCurrent] = useState(0);
  const [loader, setLoader] = useState(false);
  const [companyCIN, setCompanyCIN] = useState(null);
  const [selectedIAmAState, setSelectedIAmAState] = useState('');
  const [_userPreferenceData, set_userPreferenceData] = useState({});
  const [createCompany, setCreateCompany] = useState(false);
  const [backStepToInitialStep, setBackStepToInitialStep] = useState(undefined);
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [allIndustriesList, setAllIndustriesList] = useState([]);
  const [dataupdationIds, setDataupdationIds] = useState({});
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const { currentLocation } = useSelector((store) => store.userDashboard);
  const [run, setRun] = useState(true);
  const joyrideRef = useRef();
  const tourSteps = [
    {
      content: <h2 style={{ marginTop: '-15px' }}>Let's begin our journey!</h2>,
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div>
          <p>User should select options from Company Name and Select Industry.</p>
          <p>User can fill Designation Years of experience and select Key skills.</p>
          <p>
            after select mandatory options you can click on Next button or user can go back screen with help of select
            Back button.{' '}
          </p>
        </div>
      ),
      styles: {
        options: {
          width: 300,
        },
      },
      title: 'Professional Details',
      target:
        'body #root .h-100 .ant-layout .ant-layout .ant-layout-content .useronboarding .useronboarding-leftdiv .ant-steps [data-testid="step-1"]',
      placement: 'bottom',
    },
    {
      content: (
        <div>
          <p>User can select his type.</p>
          <p>after that user can click on Next button or Back button. </p>
        </div>
      ),
      styles: {
        options: {
          width: 300,
        },
      },
      target:
        'body #root .h-100 .ant-layout .ant-layout .ant-layout-content .useronboarding .useronboarding-leftdiv .ant-steps [data-testid="step-2"]',
      title: 'Profile',
      placement: 'bottom',
    },
    {
      content: (
        <div>
          <p>User can add tag for Services that prasad sells, BI target Audience and BI would like to buy.</p>
          <p>after that user can click on Next button or Back button. </p>
        </div>
      ),
      placement: 'bottom',
      target:
        'body #root .h-100 .ant-layout .ant-layout .ant-layout-content .useronboarding .useronboarding-leftdiv .ant-steps [data-testid="step-3"]',
      title: 'Preferences',
      styles: {
        options: {
          width: 300,
        },
      },
    },
    {
      content: (
        <div>
          <p>
            User can select plan either Pay as you go or Enterprise Plan, for Enterprise Plan select have to click on
            Let's connect button and fill the form details.
          </p>
          <p>after that user can click on Next button or Back button. </p>
        </div>
      ),
      placement: 'bottom',
      target:
        'body #root .h-100 .ant-layout .ant-layout .ant-layout-content .useronboarding .useronboarding-leftdiv .ant-steps [data-testid="step-4"]',
      title: 'Select Plan',
      styles: {
        options: {
          width: 320,
        },
      },
    },
  ];

  useEffect(() => {
    // Common operations
    handleGetAllCompanyList();

    // Initial Mount: Fetch user onboarding data
    if (backStepToInitialStep === undefined) {
      dispatch(fetchUserOnboardingData());
    }
    // When backStepToInitialStep changes
    if (searchedCompanyName) {
      handleGetAllCompanyListBySearch(searchedCompanyName);
    }
    if (backStepToInitialStep) {
      setSelectedCompanyId(allCompanyList?.[0]?.value);
    }

    // Dependencies: Initially run and also when backStepToInitialStep changes
  }, [backStepToInitialStep, searchedCompanyName]);

  // Updating states on updation
  useEffect(() => {
    if (isEmpty(userOnboardingData)) return;
    if (!loader && !isEmpty(userOnboardingData?.profileClassification?.profileClassification)) {
      setSelectedIAmAState(userOnboardingData?.profileClassification?.profileClassification || '');
    }

    if (!isEmpty(userOnboardingData?.preferences)) {
      set_userPreferenceData(userOnboardingData?.preferences);
    }
    setCompanyCIN(
      allCompanyList?.find((elem) => elem?.cin === userOnboardingData?.professionalDetails?.companyCIN)?.cin ||
        companyCIN,
    );
    setSelectedIndustry(
      allIndustriesList?.filter((item) => item?.value === userOnboardingData?.professionalDetails?.industry)?.[0]
        ?.label,
    );
    setSelectedCompanyId(userOnboardingData?.companyDetails?.companyId);

    let industryName = null;
    if (
      isObjectId(
        allCompanyList?.find((elem) => elem?.value === userOnboardingData?.companyDetails?.companyId)?.industry,
      )
    ) {
      let industryId = allCompanyList?.find(
        (elem) => elem?.value === userOnboardingData?.companyDetails?.companyId,
      )?.industry;
      industryName = allIndustriesList?.filter((elem) => elem?.value === industryId)?.[0]?.label;
    } else {
      industryName = allCompanyList?.find(
        (elem) => elem?.value === userOnboardingData?.companyDetails?.companyId,
      )?.industry;
    }
    // --prefiilling data----
    userOfferings.setFieldsValue({
      companyRepresentativeName: userOnboardingData?.name,
      companyRepresentativeMobile: userOnboardingData?.phone,
      companyRepresentativeEmail: userOnboardingData?.email,
      companyName: allCompanyList?.find((elem) => elem?.label === userOnboardingData?.professionalDetails?.companyName)
        ?.value,
      industry: industryName ? industryName : '--',
      designation: userOnboardingData?.professionalDetails?.designation,
      yearsOfExperience: userOnboardingData?.professionalDetails?.experience,
      keySkills: userOnboardingData?.professionalDetails?.keySkills,
      iAmI: !isEmpty(userOnboardingData?.profileClassification?.profileClassification)
        ? userOnboardingData?.profileClassification?.profileClassification
        : '',

      //Broker data
      broker_open_to_broker: !isEmpty(userOnboardingData?.profileClassification)
        ? userOnboardingData?.profileClassification?.openToBroker
          ? 'Yes'
          : 'No'
        : '',

      //Seller data
      seller_open_to_broker: !isEmpty(userOnboardingData?.profileClassification)
        ? userOnboardingData?.profileClassification?.openToBroker
          ? 'Yes'
          : 'No'
        : '',

      //Buyer data
      buyer_open_to_broker: !isEmpty(userOnboardingData?.profileClassification)
        ? userOnboardingData?.profileClassification?.openToBroker
          ? 'Yes'
          : 'No'
        : '',

      //Co Working data
      coworking_openToBrokers: !isEmpty(userOnboardingData?.profileClassification)
        ? userOnboardingData?.profileClassification?.openToBroker
          ? 'Yes'
          : 'No'
        : '',
      profileDescription: userOnboardingData?.profileDescription?.text,
      //Preference
      preference_userSells: userOnboardingData?.preferenceDetails?.userSells,
      preference_userTargetAudience: userOnboardingData?.preferenceDetails?.userTargetAudience,
      preference_userWouldBuy: userOnboardingData?.preferenceDetails?.userWouldBuy,
    });

    const userPreferenceDataObj = {
      userSells: userOnboardingData?.preferenceDetails?.userSells,
      userTargetAudience: userOnboardingData?.preferenceDetails?.userTargetAudience,
      userWouldBuy: userOnboardingData?.preferenceDetails?.userWouldBuy,
    };
    set_userPreferenceData(userPreferenceDataObj);
  }, [
    allCompanyList,
    allIndustriesList,
    companyCIN,
    currentLocation?.locationDetails,
    loader,
    userOfferings,
    userOnboardingData,
  ]);

  const handleGetAllCompanyList = () => {
    dispatch(userOnboardingGetAllCompanyDetails())?.then((res) => {
      if (res?.payload?.data?.response?.data?.[0]?.data) {
        const items = res?.payload?.data?.response?.data?.[0]?.data?.map((elem) => ({
          value: elem?._id,
          label: elem?.companyName,
          cin: elem?.cin,
          industry: elem?.industry,
        }));
        setAllCompanyList(items);
        dispatch(userOnboardingGetAllIndusteriesDetails())?.then((res) => {
          const items = res?.payload?.data?.response?.data?.map((elem) => ({
            value: elem?.id,
            label: elem?.name,
          }));
          if (res?.payload?.data?.response?.data) {
            setAllIndustriesList(items);
            dispatch(fetchUserOnboardingData());
          }
        });
      }
    });
  };

  const handleSelectCompany = (companyId) => {
    if (!searchedCompanyName) {
      dispatch(setSearchedCompanyName(''));
    }
    setSelectedCompanyId(companyId);
    let industryId = allCompanyList?.find((elem) => elem?.value === companyId)?.industry;
    if (isObjectId(industryId)) {
      userOfferings.setFieldsValue({
        companyName: allCompanyList?.find((elem) => elem?.value === companyId)?.value,
        industry: allIndustriesList?.find((elem) => elem?.value === industryId)?.label
          ? allIndustriesList?.find((elem) => elem?.value === industryId)?.label
          : '--',
      });
    } else {
      userOfferings.setFieldsValue({
        companyName: allCompanyList?.find((elem) => elem?.value === companyId)?.value,
        industry: industryId ? industryId : '--',
      });
    }
  };

  const handleGetAllCompanyListBySearch = (companyName) => {
    dispatch(userOnboardingGetAllCompanyDetailsBySearch(companyName))?.then((res) => {
      if (res?.payload?.data?.response?.data?.[0]?.data) {
        const items = res?.payload?.data?.response?.data?.[0]?.data?.map((elem) => ({
          value: elem?._id,
          label: elem?.companyName,
          cin: elem?.cin,
          industry: elem?.industry,
        }));
        setAllCompanyList(items);
      }
    });
  };

  const handleSelectOffering = (selectedOffering) => {
    setSelectedIAmAState(selectedOffering);
  };

  // handle submit
  const handleDataSubmit = (e) => {
    // it will call api based on current active tab i.e company details , professional details etc
    setLoader(true);
    const payload = getPayloadTemplateV1({
      e,
      current,
      allCompanyList,
      allIndustriesList,
      userOnboardingData,
      createCompany,
      companyCIN,
    });
    let industry = null;
    if (STEPS_MAP?.[current] === 'professionalDetails') {
      industry =
        allIndustriesList?.filter((item) =>
          createCompany ? item?.value === e?.industryName : item?.label === e?.industry,
        )?.[0]?.label ?? '--';
    } else {
      industry =
        allIndustriesList?.filter((item) => item?.value === userOnboardingData?.professionalDetails?.industry)?.[0]
          ?.label ?? '--';
    }
    if (
      STEPS_MAP?.[current] === 'professionalDetails' &&
      ((!userOnboardingData?.phone && e?.companyRepresentativeMobile) ||
        (!userOnboardingData?.email && e?.companyRepresentativeEmail))
    ) {
      if (!userOnboardingData?.phone && e?.companyRepresentativeMobile) {
        dispatch(
          postOnboardingDataForLinkedIn({
            body: { type: 'phone', data: e?.companyRepresentativeMobile },
          }),
        ).then((res) => {
          if (res?.payload?.status !== 201) {
            setLoader(false);
            return;
          }
          handlePostOnBoardingData(e, payload, industry);
        });
      }

      if (!userOnboardingData?.email && e?.companyRepresentativeEmail) {
        dispatch(
          postOnboardingDataForLinkedIn({
            body: { type: 'email', data: e?.companyRepresentativeEmail },
          }),
        ).then((res) => {
          if (res?.payload?.status !== 201) {
            setLoader(false);
            return;
          }
          handlePostOnBoardingData(e, payload, industry);
        });
      }
    } else if (
      STEPS_MAP?.[current] === 'preferences' ||
      (STEPS_MAP?.[current] === 'offerings' &&
        (!Object.keys(userOnboardingData).includes('profileClassification') ||
          Object.keys(userOnboardingData?.profileClassification)?.length === 0) &&
        industry === 'Real Estate')
    ) {
      dispatch(
        getPayloadTemplateV2({
          e,
          current,
          selectedIAmI: e?.iAmI,
          createCompany,
          companyCIN,
          selectedCompanyId,
          industry,
        }),
      )?.then((res) => {
        if (res?.payload?.data?.response) {
          setLoader(false);
          setCreateCompany(false);
          dispatch(fetchUserOnboardingData());
          if (STEPS_MAP?.[current] === 'offerings') {
            setDataupdationIds((prev) => ({
              [e?.iAmI]: res?.payload?.data?.response?.data?.id,
            }));
            dispatch(registerCrm(getCrmPayload(userOnboardingData, e?.iAmI, companyCIN)));
          } else if (STEPS_MAP?.[current] === 'preferences') {
            dispatch(userOnboardingGetPreferences()).then((res) => {
              const userPreferenceData = res?.payload?.data?.response?.data?.[0];
              const preferenceDetails = {
                userSells: userPreferenceData?.userSells,
                userTargetAudience: userPreferenceData?.userTargetAudience,
                userWouldBuy: userPreferenceData?.userWouldBuy,
              };
              dispatch(setPreferencesUserOnboarding(preferenceDetails));
            });
          }
          setBackStepToInitialStep(false);
          if (createCompany) return;
          setCurrent((prev) => {
            return prev + 1;
          });
        } else {
          message.error(res?.payload?.message);
        }
      });
    } else if (
      STEPS_MAP?.[current] === 'offerings' &&
      industry === 'Real Estate' &&
      Object.keys(userOnboardingData).includes('profileClassification') &&
      Object.keys(userOnboardingData?.profileClassification)?.length > 0
    ) {
      setLoader(false);
      setCreateCompany(false);
      dispatch(fetchUserOnboardingData());
      dispatch(userOnboardingGetPreferences()).then((res) => {
        const userPreferenceData = res?.payload?.data?.response?.data?.[0];
        const preferenceDetails = {
          userSells: userPreferenceData?.userSells,
          userTargetAudience: userPreferenceData?.userTargetAudience,
          userWouldBuy: userPreferenceData?.userWouldBuy,
        };
        dispatch(setPreferencesUserOnboarding(preferenceDetails));
      });
      setBackStepToInitialStep(false);
      if (createCompany) return;
      setCurrent((prev) => {
        return prev + 1;
      });
    } else if (STEPS_MAP?.[current] === 'offerings' && industry !== 'Real Estate') {
      handlePostOnBoardingData(e, payload, industry);
    } else if (STEPS_MAP?.[current] !== 'offerings') {
      handlePostOnBoardingData(e, payload, industry);
    } else {
      setLoader(false);
      setCreateCompany(false);
      dispatch(fetchUserOnboardingData());
      setBackStepToInitialStep(false);
      if (createCompany) return;
      setCurrent((prev) => {
        return prev + 1;
      });
    }
  };

  const handlePostOnBoardingData = (e, payload, industry) => {
    dispatch(postOnboardingData(payload))?.then((res) => {
      if (res?.payload?.data?.response?.code === 201) {
        dispatch(userOnboardingGetPreferences()).then((res) => {
          const userPreferenceData = res?.payload?.data?.response?.data?.[0];
          const preferenceDetails = {
            userSells: userPreferenceData?.userSells,
            userTargetAudience: userPreferenceData?.userTargetAudience,
            userWouldBuy: userPreferenceData?.userWouldBuy,
          };
          dispatch(setPreferencesUserOnboarding(preferenceDetails));
        });
        // V2 API CALL
        setLoader(false);
        if (STEPS_MAP?.[current] === 'professionalDetails' || STEPS_MAP?.[current] === 'preferences') {
          dispatch(
            getPayloadTemplateV2({
              e,
              current,
              selectedIAmI: e?.iAmI,
              createCompany,
              companyCIN,
              selectedCompanyId,
              industry,
            }),
          )?.then((res) => {
            if (STEPS_MAP?.[current] === 'professionalDetails' && createCompany === true) {
              setSelectedCompanyId(res?.payload?.data?.response?.data?.[0]?.company?._id);
              handleGetAllCompanyList();
            }
            if (res?.payload?.data?.response) {
              if (typeof res?.payload?.data?.response?.data?.[0] === 'string') {
                dispatch(
                  set_toast({
                    show: true,
                    type: 'warning',
                    content: res?.payload?.data?.response?.data?.[0],
                  }),
                );
                setLoader(false);
                return;
              }
              setCreateCompany(false);
              dispatch(fetchUserOnboardingData());
              setBackStepToInitialStep(false);
              if (createCompany && current === 0) {
                setBackStepToInitialStep(true);
                return;
              }
              setCurrent((prev) => {
                return prev + 1;
              });
            } else {
              message.error(res?.payload?.message);
            }
          });
        } else {
          setCreateCompany(false);
          dispatch(fetchUserOnboardingData());
          setBackStepToInitialStep(false);
          setCurrent((prev) => {
            return prev + 1;
          });
        }
      }
    });
  };

  // ------all steps--------
  const steps = [
    {
      title: 'Professional Details',
      content: (
        <PersonalDetails
          setCompanyCIN={setCompanyCIN}
          allCompanyList={allCompanyList}
          allIndustriesList={allIndustriesList}
          setCreateCompany={(val) => {
            setCreateCompany(val);
            userOfferings.setFieldsValue({
              addCompanyName: null,
              industryName: null,
              CIN: null,
              websiteLink: null,
              address: null,
              locality: null,
              city: null,
              state: null,
              pinCode: null,
            });
          }}
          createCompany={createCompany}
          handleSelectCompany={handleSelectCompany}
          keySkillsL={userOnboardingData?.professionalDetails?.keySkills}
          fetchCompanies={(companyName) => {
            dispatch(setSearchedCompanyName(companyName));
            handleGetAllCompanyListBySearch(companyName);
          }}
        />
      ),
      dataTestid: 'step-1',
    },
    {
      title: 'Profile',
      content: (
        <Offerings
          _IAmA={selectedIAmAState}
          selectedIndustry={
            allIndustriesList?.filter((item) => item?.value === userOnboardingData?.professionalDetails?.industry)?.[0]
              ?.label
          }
          handleSelectOffering={handleSelectOffering}
        />
      ),
      dataTestid: 'step-2',
    },
    {
      title: 'Preferences',
      content: (
        <Preference
          _userPreferenceData={_userPreferenceData}
          offerings={selectedIAmAState}
          setPreferenceUserSells={(val) =>
            userOfferings?.setFieldsValue({
              preference_userSells: val,
            })
          }
        />
      ),
      dataTestid: 'step-3',
    },
    {
      title: 'Select Plan',
      content: <SelectPlan />,
      dataTestid: 'step-4',
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    className: 'step-class',
    'data-testid': item.dataTestid,
  }));
  const contentStyle = {
    marginTop: 16,
    minHeight: '370px',
  };

  useEffect(() => {
    dispatch(fetchUserPoints());
  }, [current]);

  return (
    <>
      <section className="useronboarding">
        <div
          className="useronboarding-leftdiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: current === 4 ? '100%' : '70%',
            background: 'white',
            borderRadius: '8px',
            padding: '20px',
            overflow: 'scroll',
          }}
        >
          <Text className="useronboarding-leftdiv__welcome">
            Welcome, <Text className="useronboarding-leftdiv__welcome--user">{userOnboardingData?.name}</Text>
          </Text>
          {current < 3 ? (
            <Text className="useronboarding-leftdiv__desc">
              Fill this 4 step form and get your business toolkit ready to scale up!
            </Text>
          ) : (
            <Fragment>
              <Text className="useronboarding-leftdiv__Highlightdesc ">
                <i>Thank you for your interest in Trythat.ai !!</i>
              </Text>
              <Text className="useronboarding-leftdiv__desc">
                As our valued user you have earned free <span>{userPoints} points.</span> Please continue to experience
                our trial using these points.
              </Text>
              {selectedIndustry !== 'Real Estate' ? (
                <Text className="useronboarding-leftdiv__desc">
                  We are currently focused on the Real Estate Industry. You will be notified when we launch features
                  related to <span>{selectedIndustry ?? '--'}</span>
                </Text>
              ) : null}
            </Fragment>
          )}
          <Steps
            className="useronboarding-leftdiv__steps"
            labelPlacement="vertical"
            size="small"
            current={current}
            items={items}
          />
          <Form
            name="userOfferings"
            form={userOfferings}
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={handleDataSubmit}
            autoComplete="off"
          >
            <div style={contentStyle}>{steps[current].content}</div>
            <div className="useronboarding-leftdiv__btnbox">
              {current < 3 ? (
                createCompany && current === 0 ? (
                  <Button
                    htmlType="submit"
                    loading={loader}
                    className="useronboarding-leftdiv__btnbox--btn"
                    type="primary"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled={current === 3}
                    htmlType="submit"
                    loading={loader}
                    className="useronboarding-leftdiv__btnbox--btn"
                    type="primary"
                  >
                    Next
                  </Button>
                )
              ) : null}

              {/* -------- If User create company he/she can reset----------  */}
              {createCompany && current === 0 ? (
                <Button
                  onClick={() => {
                    setCreateCompany(false);
                  }}
                  className="useronboarding-leftdiv__btnbox--btn"
                  type="primary"
                  ghost
                >
                  Reset
                </Button>
              ) : (
                <Button
                  disabled={current === 0}
                  onClick={() => {
                    if (current === 1) setBackStepToInitialStep(true);
                    else setBackStepToInitialStep(false);
                    setCurrent((prev) => {
                      return prev - 1;
                    });
                  }}
                  className="useronboarding-leftdiv__btnbox--btn"
                  type="primary"
                  ghost
                >
                  Back
                </Button>
              )}
            </div>
          </Form>
        </div>
        {current !== 4 && (
          <div className="useronboarding-rightdiv">
            <img
              src={require('../../../assets/images/LottieBackground.png')}
              className="useronboarding-rightdiv__img"
              alt=""
            />
            <Space direction="vertical" className="useronboarding-rightdiv__space">
              <Title level={3} className="useronboarding-rightdiv__space--great">
                Great going {userOnboardingData?.name}!
              </Title>
              <div className="useronboarding-rightdiv__space--pointbox">
                <div className="pointbox-box">
                  <img
                    src={require('../../../assets/images/pointsBackground.png')}
                    className="pointbox-box__img"
                    alt=""
                  />
                  <div className="pointbox-box__point">
                    <Title className="pointbox-box__point--userpoint">{userPoints}</Title>
                  </div>
                </div>
              </div>
              <Title level={5} className="useronboarding-rightdiv__space--pointearn">
                POINTS EARNED!
              </Title>
              <div className="useronboarding-rightdiv__space--descdiv">
                <Text className="descdiv-desc">
                  Keep up the spirit and by the end of this survey you will have 500 points in your wallet.
                </Text>
              </div>
              <LottieAnimation height={220} width={220} animationData={animationData} loop={true} />
            </Space>
          </div>
        )}
      </section>
    </>
  );
};
export default UserOnboarding;
