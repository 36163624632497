import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import CommonButton from '../common/CommonButton';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppFollowUser, socialAppUnFollowUser } from '../../../features/socialAppSlice';
const ViewProfileHeader = ({ userDetails, userId, setAllPosts = () => {}, isFollowed }) => {
  const { user } = useSelector((store) => store?.user);
  // hooks
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  // on back button click
  const onBackButtonClick = () => {
    navigateTo(-2);
  };

  // onUnFollowUser
  const onUnFollowUser = () => {
    return dispatch(
      socialAppFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            elem = { ...elem, isFollowed: false };
            return elem;
          }),
        }));
      }
    });
  };
  // onUnFollowUser
  const onFollowUser = () => {
    return dispatch(
      socialAppUnFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            elem = { ...elem, isFollowed: true };
            return elem;
          }),
        }));
      }
    });
  };
  return (
    <div className="d-flex jc-between ">
      <span className="d-flex g-10 a-center viewProfileHeader">
        <LeftOutlined style={{ fontSize: 32, color: '#0080fc' }} onClick={onBackButtonClick} />
        <Avatar size={104} src={''} icon={<UserOutlined />} />
        <div className="d-flex d-column ml-20">
          <span className="font30 font700 fontLight ">{userDetails?.name}</span>
          <span className="font18 fontExtraLight  ">
            {userDetails?.professionalDetails?.designation}
            {userDetails?.professionalDetails?.companyName && ` @${userDetails?.professionalDetails?.companyName}`}
          </span>
        </div>
      </span>
      <span className="d-flex g-15 a-center">
        {userId != user?._id && (
          <CommonButton
            onClick={() => {
              isFollowed ? onUnFollowUser() : onFollowUser();
            }}
            style={{ height: 'unset', fontSize: 18, padding: '0px 20px' }}
            ghost
          >
            {isFollowed ? 'Unfollow' : 'Follow'}
          </CommonButton>
        )}
      </span>
    </div>
  );
};

export default ViewProfileHeader;
