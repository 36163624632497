import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import './kebabMenu.scss';
import ShareIcon from '../../../layout/home/images/ShareIcon';
import Subscribe from '../../../layout/home/images/Subscribe';
import RaiseIcon from '../../../layout/home/images/RaiseIcon';
import UnSubscribeIcon from '../../../layout/home/images/UnSubscribeIcon';

const KebabMenu = ({ onShare, onRaiseConcern, onSubscribed, onUnSubscribed, resourceId }) => {
  const [isSubscribed, setIsSubscribed] = useState();
  const [visible, setVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    setIsSubscribed(resourceId?.isSubscribed == null ? false : true);
  }, []);
  const handleSubscribe = () => {
    if (isSubscribed) {
      onUnSubscribed();
    } else {
      onSubscribed();
    }
    setIsSubscribed((prevState) => !prevState);
  };

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    if (e.key === '2') {
      handleSubscribe();
    } else {
      setVisible(false);
    }
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setVisible((prevVisible) => !prevVisible);
  };

  const handleMouseEnter = (key) => {
    setHoveredItem(key);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const menu = (
    <Menu onClick={handleMenuClick} className="custom-menu">
      <Menu.Item
        key="1"
        onClick={() => onShare()}
        onMouseEnter={() => handleMouseEnter('1')}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: hoveredItem === '1' ? '#f2f9ff' : 'transparent',
        }}
      >
        <div className="hover-change">
          <ShareIcon strokeColor={hoveredItem === '1' ? '#0081FC' : '#535353'} />
          <span>Share</span>
        </div>
      </Menu.Item>

      {resourceId?.buildingType !== 'residential' && (
        <Menu.Item
          key="2"
          onMouseEnter={() => handleMouseEnter('2')}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundColor: isSubscribed ? 'transparent' : hoveredItem === '2' ? '#f2f9ff' : 'transparent',
          }}
        >
          <div
            className="hover-change"
            style={{
              color: isSubscribed ? '#fca500' : hoveredItem === '2' ? '#0081FC' : '#535353',
            }}
          >
            {isSubscribed ? (
              <UnSubscribeIcon strokeColor="#fca500" />
            ) : (
              <Subscribe strokeColor={hoveredItem === '2' ? '#0081FC' : '#535353'} />
            )}
            <span>{isSubscribed ? 'Unsubscribe' : 'Subscribe'}</span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item
        key="3"
        onClick={onRaiseConcern}
        onMouseEnter={() => handleMouseEnter('3')}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: hoveredItem === '3' ? '#f2f9ff' : 'transparent',
        }}
      >
        <div className="hover-change">
          <RaiseIcon className="raise-icon" strokeColor={hoveredItem === '3' ? '#0081FC' : '#535353'} />
          <span>Raise a concern</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={visible}
      onClick={handleDropdownClick}
      onVisibleChange={setVisible}
      overlayClassName="custom-menu"
    >
      <div className="kebab-menu-icon" onClick={handleDropdownClick}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </Dropdown>
  );
};

export default KebabMenu;
