import { Header } from 'antd/es/layout/layout';
import logo from '../../../../assets/logo.svg';
// styles
import './topNav.scss';
import CommonButton from '../../../../universal/components/socialApp/common/CommonButton';

const TopNavWithoutInteraction = ({ onLogin }) => {
  return (
    <Header className="header">
      <div className="header__items">
        <div className="header__logo">
          <img src={logo} alt="logo" width="150px" className="header__logo-img" />
        </div>
        {onLogin && (
          <span className="mr-20">
            <CommonButton onClick={onLogin}>Login</CommonButton>
          </span>
        )}
      </div>
    </Header>
  );
};

export default TopNavWithoutInteraction;
