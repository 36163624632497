import React, { useState } from 'react';
import { Form, Checkbox, Button } from 'antd';

const PollComponent = ({ options, selectedValue, handleCheckboxChange = () => {} }) => {
  return (
    <Form.Item name="poll" rules={[{ required: true, message: 'Please select an option!' }]}>
      <div className="d-flex d-column g-10 p-5">
        {options?.map((option) => (
          <Checkbox
            key={option.id}
            checked={selectedValue === option.id}
            onChange={(e) => handleCheckboxChange(e, option.id)}
          >
            {option.title}
          </Checkbox>
        ))}
      </div>
    </Form.Item>
  );
};

export default PollComponent;
