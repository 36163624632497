import React from 'react';

const CommentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 17.21 19.003">
      <g id="Comment" transform="translate(-17.34 -8)">
        <path
          id="Path_17680"
          data-name="Path 17680"
          d="M107.7,117.228a.376.376,0,1,1,0-.752H114.1a.311.311,0,0,0,.31-.311v-3.83a.376.376,0,1,1,.752,0v3.83a1.063,1.063,0,0,1-1.061,1.063H107.7Z"
          transform="translate(-80.613 -93.138)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17681"
          data-name="Path 17681"
          d="M109.488,118.812a.168.168,0,1,1,0-.335h6.406a.519.519,0,0,0,.518-.519v-3.83a.168.168,0,1,1,.335,0v3.83a.855.855,0,0,1-.854.854h-6.406Z"
          transform="translate(-82.405 -94.93)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17682"
          data-name="Path 17682"
          d="M51.947,84.016a.368.368,0,0,1,0-.736h9.346a.368.368,0,0,1,0,.736Z"
          transform="translate(-30.676 -67.444)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17683"
          data-name="Path 17683"
          d="M51.947,102.927a.369.369,0,0,1,0-.737h9.346a.369.369,0,0,1,0,.737Z"
          transform="translate(-30.676 -84.385)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17684"
          data-name="Path 17684"
          d="M51.947,121.866a.368.368,0,0,1,0-.736h4.933a.368.368,0,0,1,0,.736Z"
          transform="translate(-30.676 -101.353)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17685"
          data-name="Path 17685"
          d="M25.569,11.841a.376.376,0,1,1,0-.752H26.6V8.752h-8.51v2.339h5.876a.376.376,0,1,1,0,.752H17.939a.6.6,0,0,1-.6-.6V8.6a.6.6,0,0,1,.6-.6h8.817a.6.6,0,0,1,.6.6v2.645a.6.6,0,0,1-.6.6H25.57Z"
          fill="#aaa9a9"
        />
        <path
          id="Path_17686"
          data-name="Path 17686"
          d="M32.676,23.592a.376.376,0,0,1,0-.752h5.863a.376.376,0,1,1,0,.752Z"
          transform="translate(-13.403 -13.295)"
          fill="#aaa9a9"
        />
        <path
          id="Path_17687"
          data-name="Path 17687"
          d="M22.448,63.986a.51.51,0,0,1-.2-.04.654.654,0,0,1-.366-.676V60.984H20.04A1.041,1.041,0,0,1,19,59.943V50.321a1.042,1.042,0,0,1,1.04-1.041H34.963a.9.9,0,0,1,.9.9v4.483a.369.369,0,0,1-.737,0V50.271a.256.256,0,0,0-.255-.256H20.04a.3.3,0,0,0-.3.3v9.623a.3.3,0,0,0,.3.3h2.207a.369.369,0,0,1,.369.369v2.439l2.7-2.7a.369.369,0,0,1,.26-.108h1.45a.368.368,0,1,1,0,.736h-1.3l-2.741,2.744a.769.769,0,0,1-.534.257Z"
          transform="translate(-1.487 -36.983)"
          fill="#aaa9a9"
        />
      </g>
    </svg>
  );
};

export default CommentIcon;
