import { Button } from 'antd';
import Room from '../roomComponent/RoomComponent';
import './FloorComponent.scss';

const FloorComponent = ({ floorNumber, rooms }) => {
  return (
    <div className="floor">
      <div className="floor-number">
        <Button type="primary" shape="circle">
          {floorNumber}
        </Button>
      </div>
      <div className="rooms">
        {rooms.map((room) => (
          <Room key={room.number} number={room.number} occupied={room.occupied} />
        ))}
      </div>
    </div>
  );
};

export default FloorComponent;
