import { nanoid } from '@reduxjs/toolkit';
import { Card, Col, Divider, List, Rate, Row, Tooltip } from 'antd';
import { isEmpty, truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import coreBadge from '../../../../../assets/images/Icon core-badge.svg';
import locationSVG from '../../../../../assets/images/address-location.svg';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import { getSearchResults, setDetailedResidentialPropertyData } from '../../../../features/searchSlice';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import Ready from '../../../../../assets/images/ready.svg';
import UnderConstruction from '../../../../../assets/images/UnderConstruction.svg';
import '../../../searchPage/searchPage.scss';
import './residentialProperty.scss';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
import SelectedPropertyDetailsSkeleton from '../../../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';

const ResidentialProperty = ({ propertyData, onSaveCard, onDeleteCard }) => {
  const { searchQuery, pagination, hasMoreData, searchResultLoader } = useSelector((store) => store.search);
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const dispatch = useDispatch();

  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();

  // Function to show RaiseConcern
  const handleRaiseConcernClick = (propertyItem) => {
    setSelectedProperty(propertyItem);
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    setSelectedProperty({});
  };

  useEffect(() => {
    dispatch(setDetailedResidentialPropertyData({}));
  }, []);
  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const navigateToPropertyDetail = (e, id, page) => {
    e.stopPropagation();
    navigateTo(`/leadGen/propertyDetails/${id}?list=${page}`, { replace: true });
  };
  return (
    <div >
      {!isEmpty(propertyData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for Residential property as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery?.body?.query}</i> and similar properties
          </div>
        </div>
      )}
      {/* ----------search card skeleton------- */}

      {isEmpty(propertyData) && searchResultLoader && (
        <div className="d-flex  flex-wrap g-10 jc-between">
          {[...new Array(20)]?.map(() => (
            <SelectedPropertyDetailsSkeleton className="searchCardSkeleton" key={nanoid()} />
          ))}
        </div>
      )}
      {/* ----------search card skeleton------- */}

      {!isEmpty(propertyData) ? (
        <div
          id="columnOneDiv"
          style={{ height: '100vh', overflow: 'auto', paddingBottom: '100px' }}
          className={propertyData?.length < 3 ? 'columnOneDivNoWrap' : 'columnOneDiv'}
        >
          <InfiniteScroll
            dataLength={propertyData?.length}
            next={() => {
              loadMoreData();
            }}
            hasMore={hasMoreData}
            endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
          >
            <List
              dataSource={propertyData}
              renderItem={(propertyItem) => (
                <List.Item key={nanoid()} style={{ borderBlockEnd: 'none', width: '100%', padding: '3px' }}>
                  <div key={propertyItem?._id} className="propertyListContainer__div">
                    <Card
                      className="propertyListContainer__div__card cursor-pointer"
                      onClick={() => {
                        navigateTo(`/leadGen/connectedResidentialDetails/${propertyItem?._id}`);
                        dispatch(setSelectedNav('LeadGen'));
                      }}
                    >
                      <Row className="inside_row">
                        <div style={{ position: 'absolute', zIndex: 3, marginLeft: '20px', marginTop: '-8px' }}>
                          {propertyItem?.buildingStatus === 'Ready' && <img src={Ready} alt="Ready" />}
                          {propertyItem?.buildingStatus === 'Under Construction' && (
                            <img src={UnderConstruction} alt="Under Construction" />
                          )}
                        </div>
                        <Col
                          span={7}
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          className="propertyListContainer__div__card__row__col__row__col"
                        >
                          {propertyItem?.assetsInfo?.[0]?.featuredImage ? (
                            <img
                              src={propertyItem?.assetsInfo?.[0]?.featuredImage}
                              alt="Property Img"
                              className="propertyListContainer__div__card__row__col__row__col__img"
                            />
                          ) : (
                            <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
                          )}
                        </Col>
                        <Col span={16} className="d-flex d-column jc-between">
                          <div>
                            <div className="d-flex jc-between a-center mt-10 ">
                              <h5
                                style={{ color: '#0081FC' }}
                                className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                              // onClick={() => {
                              //   navigateTo(`/leadgen/propertyDetails/${propertyItem?._id}`);
                              // }}
                              >
                                {propertyItem?.buildingName}
                              </h5>
                              <div className="d-flex">
                                <div>
                                  {!propertyItem?.isSaved && (
                                    <img
                                      src={bookmark}
                                      alt="bookmark"
                                      style={{
                                        // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                        cursor: 'pointer',
                                        height: '25px',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onSaveCard(
                                          propertyItem?._id,
                                          propertyItem?.buildingName,
                                          'property',
                                          ['representativeInfo'],
                                          'residential',
                                        );
                                      }}
                                    />
                                  )}
                                  {propertyItem?.isSaved && (
                                    <img
                                      src={bookmark_selected}
                                      alt="bookmark_selected"
                                      style={{
                                        // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                        cursor: 'pointer',
                                        height: '25px',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteCard(
                                          propertyItem?._id,
                                          propertyItem?.buildingName,
                                          'property',
                                          ['representativeInfo'],
                                          'residential',
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <KebabMenu
                                  onShare={() => {
                                    setShowShareModal(
                                      `${window.location.host}/leadgen/connectedResidentialDetails/${propertyItem?._id}?shareable=true`,
                                    );
                                  }}
                                  onRaiseConcern={() => handleRaiseConcernClick(propertyItem)}
                                  onSubscribed={() => handleSubscribedClick(propertyItem, 'ResidentialProperty')}
                                  onUnSubscribed={() => handleUnSubscribedClick(propertyItem, 'ResidentialProperty')}
                                  resourceId={propertyItem}
                                />
                              </div>
                            </div>
                            <div
                              className="propertyListContainer__div__card__row__col__row__col1__para"
                              style={{ color: '#313131' }}
                            >
                              {`${propertyItem?.addressInfo?.[0]?.locality}, ${propertyItem?.addressInfo?.[0]?.city}`}
                            </div>
                            <Rate
                              allowHalf
                              disabled
                              defaultValue={
                                !isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0
                              }
                            />
                            <div className="d-flex  d-column g-5 mt-10">
                              <div className="d-flex g-5">
                                <img
                                  src={locationSVG}
                                  style={{ marginTop: 3 }}
                                  alt="location"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                />
                                <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <Tooltip
                                    title={populateAddressInfo(propertyItem?.addressInfo?.[0])}
                                    overlayStyle={{ zIndex: 1000000000 }}
                                  >
                                    {truncate(populateAddressInfo(propertyItem?.addressInfo?.[0]), { length: 70 })}
                                  </Tooltip>                                </span>
                              </div>
                              <div className="d-flex g-5 a-center">
                                <img
                                  src={coreBadge}
                                  alt="coreBadge"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                  style={{ marginLeft: '-2px' }}
                                />
                                <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <b>Grade of building :</b> {propertyItem?.gradeOfBuilding || 'Awaited'}
                                </p>
                              </div>
                              <div className="d-flex g-5 a-center" style={{ alignItems: 'flex-start', marginTop: '2px' }}>
                                <img
                                  src={aminities}
                                  alt="aminities"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                  style={{ marginLeft: '-2px' }}
                                />
                                <div className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <p className="bold">Amenities:</p>
                                  <div
                                    style={{ display: 'inline-flex', justifyContent: 'space-between', marginTop: '10px' }}
                                  >
                                    {propertyItem?.amenities && (
                                      <div
                                        className="propertyListContainer__div__card__row1__col1__amenities"
                                        style={{
                                          maxHeight: '200px',
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          width:
                                            propertyItem?.amenities && propertyItem?.amenities?.length < 4
                                              ? '100%'
                                              : '165px',
                                        }}
                                      >
                                        {propertyItem?.amenities &&
                                          propertyItem?.amenities?.length > 0 &&
                                          propertyItem?.amenities?.slice(0, 4)?.map((item, index) => {
                                            return (
                                              <Col
                                                span={
                                                  propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? 6 : 4
                                                }
                                                className="propertyListContainer__div__card__row1__col1__div__col"
                                                style={{ flex: index === 0 ? 'initial' : 'auto' }}
                                              >
                                                {isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                                  <Tooltip title={'Default'}>
                                                    <img

                                                      src={AmenitiesIconEnum?.['DEFAULT']}
                                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                                    />
                                                  </Tooltip>
                                                )}
                                                {!isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                                  <Tooltip title={item}>
                                                    <img

                                                      src={AmenitiesIconEnum?.[item.toLowerCase()]}
                                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                                    />
                                                  </Tooltip>
                                                )}
                                              </Col>
                                            );
                                          })}
                                      </div>
                                    )}
                                    {propertyItem?.amenities?.length > 4 ? (
                                      <p
                                        className="propertyListContainer__div__card__row1__col1__div__col__para"
                                        style={{ verticalAlign: 'top', marginTop: '5px', fontWeight: 'bolder' }}
                                      >
                                        {`${propertyItem?.amenities?.length - 4} More...`}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>
                          <div className="bottomRow d-flex jc-between">
                            <span
                              onClick={(e) => {
                                if (propertyItem?.vacantUnitCount) {
                                  navigateToPropertyDetail(e, propertyItem?._id, 'vacantUnits');
                                  dispatch(setSelectedNav('LeadGen'));
                                }
                                else e.stopPropagation();
                              }}
                              className={`residential-hover propertyListContainer__div__card__row1__col1__row__col1__para1  ${propertyItem?.vacantUnitCount ? 'cursor-pointer' : 'cursor-default'
                                }`}
                            >
                              {`${propertyItem?.vacantUnitCount ?? 0} Unit Available`}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      ) : (
        <div style={{ marginTop: '15rem', marginLeft: '38rem' }}>----No Result Found----</div>
      )}
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'residential',
            resourceSubType: 'Property',
            resourceName: selectedProperty?.buildingName,
            resourceId: selectedProperty?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </div>
  );
};

export default ResidentialProperty;
