import React from 'react';
import './customSkeleton.scss';
const CustomSkeleton = ({ width, height, margin, className = '' }) => {
  return (
    <>
      <div className={`cust-skeleton ${className}`} style={{ height, width }} aria-live="polite" aria-busy="true">
        <div style={{ width, height, margin }} className="custom-loading-skeleton"></div>
      </div>
    </>
  );
};

export default CustomSkeleton;
