import React from 'react';
import SelectedCompanyDetails from './SelectedCompanyDetails';
import SelectedCompanyDetailsSkeleton from './skeletons/SelectedCompanyDetailsSkeleton';
import SimilarCompanies from './SimilarCompanies';

const CompanyDetails = ({ organizationsData, loader, onSaveCard, onDeleteCard, selectedOrganizationId }) => {
  return (
    <>
      {loader ? (
        <div className="company_container">
          <div className="font14 fontLight-3 mb-10">Company in Selected Property</div>
          <SelectedCompanyDetailsSkeleton />
          <SimilarCompanies loader={loader} companyData={organizationsData?.filter((elem, index) => index !== 0)} />
        </div>
      ) : (
        <div className="company_container">
          <div className="font14 font900 fontLight-3 mb-10">Company in Selected Property</div>
          <SelectedCompanyDetails
            companyData={
              organizationsData?.filter((elem, index) =>
                selectedOrganizationId ? elem?._id == selectedOrganizationId : index === 0,
              )[0]
            }
            onSaveCard={onSaveCard}
            onDeleteCard={onDeleteCard}
          />
          <SimilarCompanies
            loader={loader}
            companyData={organizationsData?.filter((elem, index) =>
              selectedOrganizationId ? elem?._id != selectedOrganizationId : index !== 0,
            )}
          />
        </div>
      )}
    </>
  );
};

export default CompanyDetails;
