import React from 'react';
import { useState, useEffect } from 'react';
import { Layout, Row, Col, Space, Typography, Form, Select, Tag, Input, Button } from 'antd';
import { keySkills } from '../../../../utils/referenceData/personalDetailsOfferings';
import './aboutcompany.scss';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch } from 'react-redux';
import DirectorDetails from './DirectorDetails';

const { Content } = Layout;
const { Text } = Typography;

const AboutCompany = ({ companyData, companyDirectors, isFormEditable }) => {
  const dispatch = useDispatch();
  const [isDirectorsFormEditable, setIsDirectorsFormEditable] = useState(false);

  const options = keySkills.map((el) => {
    return {
      label: el,
      value: el,
    };
  });
  return (
    <Content className="content">
      <Row>
        <Col span={24} className="section">
          <Form.Item className="bold" label="About" name="about">
            {isFormEditable ? (
              <TextArea className="aboutCompany-textarea" />
            ) : (
              <Form.Item shouldUpdate>{({ getFieldValue }) => <Text strong>{getFieldValue('about')}</Text>}</Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={24} className="section">
          <Form.Item
            label="Key Offerings"
            className="companyDetails-label bold"
            rules={[
              {
                required: true,
                message: 'Please select Key Offerings.',
              },
            ]}
          >
            {isFormEditable ? (
              <>
                <Form.Item name="keySkills">
                  <Select
                    className="keySkills"
                    mode="multiple"
                    style={{
                      width: '100%',
                      height: '40px',
                      marginTop: '8px',
                    }}
                    placeholder="Hardware and Computers"
                    options={options}
                  />
                </Form.Item>
                <Form.Item shouldUpdate className="userPersonalDtls__formValues">
                  {({ getFieldValue }) => (
                    <Text strong className="userPersonalDtls__formValues" style={{ maxWidth: '400px' }}>
                      <div style={{ maxHeight: '80px', overflow: 'scroll' }}>
                        {Array.isArray(getFieldValue('keySkills')) && getFieldValue('keySkills')?.length > 0
                          ? getFieldValue('keySkills')?.map((el, index) => (
                              <Tag
                                key={index}
                                style={{
                                  backgroundColor: '#3F52A3',
                                  fontWeight: '100px',
                                  border: 'none',
                                  color: '#fff',
                                  borderRadius: '100px',
                                  paddingLeft: '20px',
                                  paddingRight: '20px',
                                }}
                              >
                                {el}
                              </Tag>
                            ))
                          : getFieldValue('keySkills')
                              ?.split(',')
                              ?.map((el, index) => (
                                <Tag
                                  key={index}
                                  style={{
                                    backgroundColor: '#3F52A3',
                                    fontWeight: '100px',
                                    border: 'none',
                                    color: '#fff',
                                    borderRadius: '100px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                  }}
                                >
                                  {el}
                                </Tag>
                              ))}
                      </div>
                    </Text>
                  )}
                </Form.Item>
              </>
            ) : (
              <Form.Item shouldUpdate className="userPersonalDtls__formValues">
                {({ getFieldValue }) => (
                  <Text strong className="userPersonalDtls__formValues" style={{ maxWidth: '400px' }}>
                    <div style={{ maxHeight: '80px', overflow: 'scroll' }}>
                      {Array.isArray(getFieldValue('keySkills'))
                        ? getFieldValue('keySkills')?.map((el, index) => (
                            <Tag
                              key={index}
                              color="#3F52A3"
                              style={{
                                backgroundColor: '#3F52A3',
                                fontWeight: '100px',
                                border: 'none',
                                color: '#fff',
                                borderRadius: '100px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                              }}
                            >
                              {el}
                            </Tag>
                          ))
                        : getFieldValue('keySkills')
                            ?.split(',')
                            ?.map((el, index) => (
                              <Tag
                                key={index}
                                color="#3F52A3"
                                style={{
                                  backgroundColor: '#3F52A3',
                                  fontWeight: '100px',
                                  border: 'none',
                                  color: '#fff',
                                  borderRadius: '100px',
                                  paddingLeft: '20px',
                                  paddingRight: '20px',
                                }}
                              >
                                {el}
                              </Tag>
                            ))}
                    </div>
                  </Text>
                )}
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={24} className="section">
          <div className="director">
            <span>
              <strong>Directors</strong>
            </span>
            <div>
              <span>
                {isDirectorsFormEditable ? (
                  <span type="primary" style={{ marginBottom: '10px', color: '#0081FC', fontWeight: '900' }}>
                    Add Director
                  </span>
                ) : // <strong>Founded Year</strong>
                null}
              </span>
            </div>
          </div>

          {companyDirectors?.map((director, index) => (
            <Space
              key={index}
              style={{
                backgroundColor: '#e6f4ff',
                borderRadius: '10px',
                width: '100%',
                marginTop: '20px',
              }}
              direction="vertical"
            >
              <DirectorDetails directorDetails={director} key={index} />
            </Space>
          ))}
        </Col>
      </Row>
    </Content>
  );
};

export default AboutCompany;
