// Css Imports
import { Card, Collapse, Divider, Empty, Tooltip } from 'antd';
import dayjs from 'dayjs';
import genIcon from '../../../../assets/LeadGen.png';
import '../socialApp.scss';
// React,React-Redux,Router Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, truncate } from 'lodash';
import { useParams } from 'react-router';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';
import AnnouncementsCardDashboard from '../../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import { useWindowSize } from '../../../../utils/useWindowSize/useWindowSize';
import JoinEventAccordion from '../../../components/socialApp/allEvents/JoinEventAccordion';
import LeadCards from '../../../components/socialApp/leadCard/LeadCard';
import AdvertisementPostForm from '../../../components/socialApp/posts/addPostForms/AdvertisementPostForm/AdvertisementPostForm';
import EventCardForm from '../../../components/socialApp/posts/addPostForms/EventCardForm/EventCardForm';
import GenericPostForm from '../../../components/socialApp/posts/addPostForms/GenericPostForm/GenericPostForm';
import JobPostForm from '../../../components/socialApp/posts/addPostForms/JobPostForm/JobPostForm';
import NewsPostForm from '../../../components/socialApp/posts/addPostForms/NewsPostForm/NewsPostForm';
import PollCardForm from '../../../components/socialApp/posts/addPostForms/PollCardForm/PollCardForm';
import ReportPostForm from '../../../components/socialApp/posts/addPostForms/ReportsPostForm/ReportsPostForm';
import RequirementCardForm from '../../../components/socialApp/posts/addPostForms/RequirementCardForm/RequirementCardForm';
import GroupPosts from '../../../components/socialApp/socialGroups/GroupDetail/GroupPosts/GroupPosts';
import AddGroupPost from '../../../components/socialApp/socialGroups/addGroupPost/AddGroupPost';
import { fetchUserProfileDataV2 } from '../../../features/myAccountSlice';
import {
  getSocialAllGroups,
  getSocialEvents,
  getSocialGroupDetail,
  getSocialGroupPosts,
  setIsCompanyToggleFalse,
  setSearchVisibleSocialApp,
} from '../../../features/socialAppSlice';
import { fetchAccouncementsData, fetchRecentActivities } from '../../../features/userDashboardSlice';
import GroupAccordian from '../../../components/socialApp/socialGroups/groupAccordian/GroupAccordian';
// Slices and reducer imports

// Component Imports

const SocialGroupDetails = () => {
  const { groupId } = useParams();
  const { recentActivities } = useSelector((store) => store.userDashboard);
  const { announcements } = useSelector((store) => store.userDashboard);
  const { isCompanyToggleOnPostTrue } = useSelector((store) => store.socialAppSlice);

  const { isAbove1920 } = useWindowSize();
  // Hooks
  const dispatch = useDispatch();
  const { Panel } = Collapse;

  // // States
  const limit = 10;

  const [showPostForm, setShowPostForm] = useState(null);
  const [offset, setOffset] = useState(0);
  const [allPosts, setAllposts] = useState([]);
  const [postDetails, setPostDetails] = useState(null);
  const [showCreatePostPopup, setShowCreatePopup] = useState(false);
  const [fetch, setfetch] = useState(false);
  const [showPurpose, setShowPurpose] = useState(false);
  const [groupDetail, setGroupDetails] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const { user } = useSelector((store) => store.user);

  const [events, setAllEvents] = useState();

  useEffect(() => {
    dispatch(getSocialGroupDetail(groupId))?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setGroupDetails(res?.payload?.data?.response?.data);
      }
    });
  }, [fetch]);

  // fetching on mounting
  useEffect(() => {
    dispatch(fetchUserProfileDataV2());
    setOffset(0);
    dispatch(setSearchVisibleSocialApp(true));
    dispatch(setIsCompanyToggleFalse(false));
    dispatch(fetchRecentActivities());
    dispatch(fetchAccouncementsData());
    dispatch(getSocialAllGroups())?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllGroups(res?.payload?.data?.response?.data);
      }
    });
    dispatch(
      getSocialGroupPosts({
        offset: offset,
        limit: limit,
        groupId: groupId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts(res?.payload?.data?.response?.data);
      }
    });

    dispatch(
      getSocialEvents({
        offset: 0,
        limit: 2,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllEvents(res?.payload?.data?.response?.data);
      }
    });
    return () => dispatch(setSearchVisibleSocialApp(false));
  }, []);

  // handleing infinite scroll
  const handleScroll = () => {
    setOffset((prev) => prev + limit);
    dispatch(
      getSocialGroupPosts({
        offset: offset + limit,
        limit: limit,
        groupId: groupId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts((prev) => {
          return {
            ...prev,
            posts: [...(prev?.posts || []), ...(res?.payload?.data?.response?.data?.posts || [])],
            totalRecords: res?.payload?.data?.response?.data?.totalRecords,
          };
        });
      }
    });
  };

  const formMaps = {
    GENERIC_POST: GenericPostForm,
    REPORT_CARD: ReportPostForm,
    NEWS_CARD: NewsPostForm,
    Ad_CARD: AdvertisementPostForm,
    EVENT_CARD: EventCardForm,
    LEAD_CARD: RequirementCardForm,
    JOB_CARD: JobPostForm,
    POLL_CARD: PollCardForm,
  };

  const FormComponent = formMaps?.[showPostForm];

  return (
    <div className="socialAppContainer">
      {!showPurpose && (
        <div className="socialAppContainer__postContainer">
          {/* -----Add post------ */}
          {!showPostForm && (
            <AddGroupPost
              groupDetail={groupDetail}
              setfetch={setfetch}
              showPostForm={showPostForm}
              toggleShowPurpose={() => setShowPurpose(true)}
              setShowPostForm={setShowPostForm}
              imageUrl={user?.personalDetails?.imageUrl}
              setShowCreatePopup={setShowCreatePopup}
            />
          )}

          {showPostForm ? (
            <FormComponent groupId={groupId} setAllposts={setAllposts} setShowForm={setShowPostForm} />
          ) : (
            <GroupPosts
              setPostDetails={setPostDetails}
              setAllposts={setAllposts}
              handleScroll={handleScroll}
              allPosts={allPosts}
            />
          )}
          {/* -----ALl post------ */}
        </div>
      )}
      {showPurpose && (
        <div className="socialAppContainer__postContainer">
          {/* -----Add post------ */}
          <AddGroupPost
            groupDetail={groupDetail}
            showPurpose={showPurpose}
            toggleShowPurpose={() => setShowPurpose(false)}
            showPostForm={showPostForm}
            setShowPostForm={setShowPostForm}
            imageUrl={user?.personalDetails?.imageUrl}
            setShowCreatePopup={setShowCreatePopup}
          />
          {/* -----ALl post------ */}

          <div className="socialAppContainer__postContainer--post"></div>
        </div>
      )}

      <div style={{ flex: 1, overflow: 'scroll' }}>
        <LeadCards />
        <Card className="mt-10 mb-10 d-flex d-column h-100  tryThatAddCard" style={{ height: 400 }}>
          <div className="d-flex a-center jc-center h-100">
            {' '}
            <img src={placeHolderImageUrl} />
          </div>
        </Card>
        {!isAbove1920 && (
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion
                onClick={() => {
                  setAllEvents(true);
                }}
              />
            </Panel>
          </Collapse>
        )}
      </div>
      {isAbove1920 && (
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion events={events} />
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default SocialGroupDetails;
