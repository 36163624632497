import { nanoid } from '@reduxjs/toolkit';
import { Col, Form, Input, Row, Select, Space, Tag, Typography, Spin } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { alphabetsRegex, numberRegex } from '../../../../utils/Constants';
import { keySkills } from '../../../../utils/referenceData/personalDetailsOfferings';
import yrsOfExperienceOption from '../../../../assets/default-data/yrsOfExperienceOptions.json';

import './PersonalDetails.scss';

const PersonalDetails = ({
  keySkillsL,
  createCompany,
  setCompanyCIN,
  allCompanyList,
  allIndustriesList,
  setCreateCompany,
  fetchCompanies,
  handleSelectCompany,
}) => {
  const { user } = useSelector((store) => store.user);
  const [keySkillsList, setKeySkillsList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState("'Select Years of Experience'");
  const [searchValue, setSearchValue] = useState('');
  const { Text } = Typography;
  const { Option } = Select;
  const options = keySkills.map((el) => {
    return {
      label: el,
      value: el,
    };
  });

  const selectProps = {
    value: keySkillsList,
  };

  useEffect(() => {
    setOption(yrsOfExperienceOption);
  }, []);

  useEffect(() => {
    setKeySkillsList(keySkillsL);
  }, [keySkillsL]);

  // Debounced function to handle search input
  const debouncedFetchCompanies = useCallback(
    debounce((value) => {
      if (!value) return;
      fetchCompanies(value); // Fetch companies from the server based on the search input
    }, 300), // Adjust debounce delay (300ms) as needed
    [fetchCompanies],
  );

  // Handle search input change
  const handleSearch = (value) => {
    if (!value) {
      setSearchValue('');
      debouncedFetchCompanies(''); // Clear results if no input
      return;
    }
    setSearchValue(value);
    debouncedFetchCompanies(value); // Trigger debounced search
  };

  // Handle value change
  const handleChange = (value) => {
    // Handle the selected value
    handleSelectCompany(value);
  };
  return (
    <>
      {false ? (
        <div className="companydetails">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="companydetails-container">
            {/*  If company is not already present */}
            {createCompany ? (
              <div>
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="addCompanyName"
                        label="Company Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Company's Name!",
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="addCompanyName" placeholder="Enter Company Name" />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="industryName"
                        label="Select Industry"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your Industry!',
                          },
                        ]}
                      >
                        <Select
                          className="row-formitem__select"
                          options={allIndustriesList || []}
                          placeholder="Select Industry"
                          optionFilterProp="label"
                          autoClearSearchValue={false}
                          dropdownRender={(menu) => <div>{menu}</div>}
                          onChange={(val) => {
                            let industryName = allIndustriesList.filter((item) => item?.value === val);
                            setSelectedIndustry(industryName?.[0]?.label);
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                {selectedIndustry === 'Other' && (
                  <Row gutter={24} className="companydetails-container__row">
                    <Col span={12}>
                      <Row>
                        <Form.Item
                          className="companydetails-container__row-formitem"
                          name="otherIndustry"
                          label="Other Industry"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter other industry!',
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => setCompanyCIN(e.target.value)}
                            className="row-formitem__input"
                            name="otherIndustry"
                            placeholder="Enter your industry"
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                )}
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="CIN"
                        label="CIN/LLPIN/Shopact"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter CIN/LLPIN/Shopact!',
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => setCompanyCIN(e.target.value)}
                          className="row-formitem__input"
                          name="cin"
                          placeholder="Type here"
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="websiteLink"
                        label="Website Link"
                        rules={[
                          {
                            pattern:
                              /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                            message: 'Please enter valid website link',
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="websiteLink" placeholder="Type here" />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="address"
                        label="Add Address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Company's Address!",
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="address" placeholder="Address" />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="locality"
                        label="Locality"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter an locality!',
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="locality" placeholder="Type here" />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter city!',
                          },
                          {
                            pattern: alphabetsRegex,
                            message: 'Enter valid city',
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="city" placeholder="Type here" />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter an state!',
                          },
                          {
                            pattern: alphabetsRegex,
                            message: 'Enter valid state',
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="state" placeholder="Type here" />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Form.Item
                      className="companydetails-container__row-formitem"
                      name="pinCode"
                      label="Pincode"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter pincode!',
                        },
                        {
                          pattern: numberRegex,
                          message: 'Enter valid pincode',
                        },
                      ]}
                    >
                      <Input className="row-formitem__input" name="pinCode" placeholder="Type here" />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                </Row>
              </div>
            ) : (
              // If company is already present
              <>
                <Row gutter={24} className="companydetails-container__row">
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="companyName"
                        label="Company Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please Select your Company !',
                          },
                        ]}
                      >
                        <Select
                          className="row-formitem__select"
                          options={allCompanyList || []}
                          placeholder="Select/Search Company"
                          optionFilterProp="label"
                          showSearch
                          onSearch={handleSearch} // Trigger server-side search
                          onChange={handleChange}
                          autoClearSearchValue={false}
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <div
                                className="createCompanyButton"
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => setCreateCompany(true)}
                              >
                                <PlusCircleFilled type="plus" /> Create Company
                              </div>
                            </div>
                          )}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        className="companydetails-container__row-formitem"
                        name="industry"
                        label="Select Industry"
                        rules={[
                          {
                            required: true,
                            message: 'Please select an appropraite Industry!',
                          },
                        ]}
                      >
                        <Input className="row-formitem__input" name="industry" placeholder="Industry" disabled={true} />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        style={{ width: '100%' }}
                        name="companyRepresentativeName"
                        label="Company Representative Name"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Company Representative Name',
                          },
                        ]}
                      >
                        <Input
                          name="companyRepresentativeName"
                          disabled={true}
                          style={{ height: '40px', marginTop: '8px', color: '#132056' }}
                          placeholder="Enter Company Representative Name"
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        style={{ width: '100%' }}
                        name="companyRepresentativeMobile"
                        label="Company Representative Mobile No."
                        rules={[
                          {
                            message: 'Please input valid mobile no.',
                            pattern: /^[0-9]{10}$/,
                          },
                          {
                            required: true,
                            message: 'Enter Company Representative Mobile No.',
                          },
                        ]}
                      >
                        <Input
                          name="companyRepresentativeMobile"
                          disabled={!!user?.phone}
                          style={{ height: '40px', marginTop: '8px', color: '#132056' }}
                          placeholder="Company Representative Mobile No."
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Row>
                      <Form.Item
                        style={{ width: '100%' }}
                        name="companyRepresentativeEmail"
                        label="Company Representative E-mail Id"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Company Representative E-mail Id',
                          },
                        ]}
                      >
                        <Input
                          name="companyRepresentativeEmail"
                          disabled={!!user?.email}
                          style={{ height: '40px', marginTop: '8px', color: '#132056', fontWeight: '500' }}
                          placeholder="Enter Company Representative E-mail Id"
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item style={{ width: '100%' }} name="designation" label="Designation">
                        <Input style={{ height: '40px', marginTop: '8px' }} placeholder="Enter Designation" />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Row>
                      <Form.Item style={{ width: '100%' }} name="yearsOfExperience" label="Years of Experience">
                        <Select
                          placeholder={'Select Years of Experience'}
                          style={{ width: '100%' }}
                          className="custom-select-yrs-experience"
                        >
                          {option.map((item) => (
                            <Option style={{ fontSize: '11px' }} key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Space
                        className="keySkillsSpace"
                        style={{
                          width: '100%',
                        }}
                      >
                        <Form.Item name="keySkills" label="Key Skills">
                          <Select
                            className="keySkills"
                            mode="multiple"
                            // maxCount={3}
                            style={{
                              width: '100%',
                              height: '40px',
                              marginTop: '8px',
                            }}
                            {...selectProps}
                            placeholder="Select Skills"
                            options={options}
                            onChange={setKeySkillsList}
                          />
                        </Form.Item>
                      </Space>
                      <div style={{ maxHeight: '100px', overflow: 'scroll' }}>
                        {keySkillsList?.map((el) => {
                          return (
                            <Tag
                              key={nanoid()}
                              color="#3F52A3"
                              style={{
                                borderRadius: '100px',
                                marginBottom: '15px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                              }}
                            >
                              <Text style={{ color: 'white', fontSize: '12px' }}>{el}</Text>
                            </Tag>
                          );
                        })}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PersonalDetails;
