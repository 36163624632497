import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import './pieChart.scss';

const PieChart = ({ item }) => {
  const labelOccupied = item?.[0]?.percentage?.occupied_unit_percentage
    ? `${item?.[0]?.count?.occupied_unit_count} units (${Number(
        item?.[0]?.percentage?.occupied_unit_percentage,
      )?.toFixed(2)}%)`
    : `${item?.[0]?.count?.occupied_unit_count} units`;
  const labelVacant = item?.[0]?.percentage?.vacant_unit_percentage
    ? `${item?.[0]?.count?.vacant_unit_count} units (${Number(item?.[0]?.percentage?.vacant_unit_percentage)?.toFixed(
        2,
      )}%)`
    : `${item?.[0]?.count?.vacant_unit_count} units`;
  const labelConfirmed = item?.[0]?.percentage?.to_be_confirmed_unit_percenatge
    ? `${item?.[0]?.count?.te_be_confirmed_unit_count} units (${Number(
        item?.[0]?.percentage?.to_be_confirmed_unit_percenatge,
      )?.toFixed(2)}%)`
    : `${item?.[0]?.count?.te_be_confirmed_unit_count} units`;
  const chartData = {
    labels: [`Vacant . ${labelVacant}`, `Occupied . ${labelOccupied}`, `To be confirmed . ${labelConfirmed}`],
    datasets: [
      {
        data: [
          item?.[0]?.count?.vacant_unit_count,
          item?.[0]?.count?.occupied_unit_count,
          item?.[0]?.count?.te_be_confirmed_unit_count,
        ],
        hoverOffset: 0,
        cutout: 50,
        backgroundColor: ['rgb(0, 128, 252)', '#FFA31D', '#D3D3D3'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
        callbacks: {
          label: function (tooltipItem) {
            // Return only the label part without the value
            return '';
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="pie-chart">
        <Doughnut data={chartData} options={options} />
      </div>

      <div className="pie-container">
        <h2>{item?.[0]?.totalSquareFeet}</h2>
        <h3>Total Sq.Ft</h3>
      </div>
      <div className="legend-container">
        <div className="legend-item legend-item--orange"></div>
        <h5>
          Occupied . {item?.[0]?.count?.occupied_unit_count} units{' '}
          {item?.[0]?.percentage?.occupied_unit_percentage
            ? `(${Number(item?.[0]?.percentage?.occupied_unit_percentage)?.toFixed(2)}%)`
            : ''}
        </h5>
        <div className="legend-item"></div>
        <h5>
          To be confirmed . {item?.[0]?.count?.te_be_confirmed_unit_count} units{' '}
          {item?.[0]?.percentage?.to_be_confirmed_unit_percenatge
            ? `(${Number(item?.[0]?.percentage?.to_be_confirmed_unit_percenatge)?.toFixed(2)}%)`
            : ''}
        </h5>
        <div className="legend-item legend-item--blue"></div>
        <h5>
          Vacant . {item?.[0]?.count?.vacant_unit_count} units{' '}
          {item?.[0]?.percentage?.vacant_unit_percentage
            ? `(${Number(item?.[0]?.percentage?.vacant_unit_percentage)?.toFixed(2)}%)`
            : ''}
        </h5>
      </div>
    </div>
  );
};

export default PieChart;
