import { CloseCircleFilled } from '@ant-design/icons';
import { Space, Tag, Tooltip } from 'antd';

import React, { useEffect, useState } from 'react';
const { CheckableTag } = Tag;

const LocationTags = ({ onChange, value = [], tagsData = [] }) => {
  const [alltags, setAllTags] = useState();
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...alltags, tag] : alltags.filter((t) => t?.label !== tag);
    setAllTags(nextSelectedTags);
    if (typeof onChange === 'function') {
      // <-- BE VERY CAREFULL WITH THIS OPERATION
      onChange(nextSelectedTags);
    }
  };
  useEffect(() => {
    if (value?.length > 0) {
      setAllTags(value);
    }
  }, [value]);

  return (
    <Space wrap>
      {alltags?.map((tag) => (
        <CheckableTag
          className="languageTag"
          key={tag?.label}
          style={{
            background: alltags?.find((elem) => elem?.type == tag?.type) ? '#003fab' : '#DEF0FF',
            color: alltags?.find((elem) => elem?.type == tag?.type) ? 'white' : '#548AD3',
          }}
          checked
          onChange={(checked) => handleChange(tag?.label, checked)}
        >
          <Tooltip title={tag?.label}>
            <span className="ellipsis">{tag?.label}</span>
          </Tooltip>
          <CloseCircleFilled className="closed-icon" />
        </CheckableTag>
      ))}
    </Space>
  );
};

export default LocationTags;
