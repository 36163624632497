export const FormatVacantUnitsData = (data) => {
  const vacantUnitsData = [];
  for (let index = 0; index < data?.length; index++) {
    let element = {};
    element = {
      vacantProfileImage: data?.[index]?.assetsInfo?.featuredImage,
      chargableArea: `${data?.[index]?.moreTransactionInfo?.chargableArea} Sq.Ft.`,
      rate: `${
        data?.[index]?.documentType === 'Rent'
          ? data?.[index]?.moreTransactionInfo?.rentRate
          : data?.[index]?.moreTransactionInfo?.saleRate
      } Sq.Ft.`,
      transactionType: data?.[index]?.documentType,
      isResourceLocked: data?.[index]?.isResourceLocked,
      id: data?.[index]?._id,
    };
    vacantUnitsData.push(element);
  }
  return vacantUnitsData;
};
