import { Space, Tag } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
const { CheckableTag } = Tag;
const tagsData = ['Yes', 'No'];

const OpenToBrokerForm = ({ value = [], onChange }) => {
  const [selectedTags, setSelectedTags] = useState(value);
  // Handle tag changes
  const handleChange = useCallback(
    (tag, checked) => {
      const nextSelectedTags = checked ? [tag] : [];
      if (
        nextSelectedTags?.length !== selectedTags?.length ||
        (nextSelectedTags?.length && !nextSelectedTags.includes(selectedTags?.[0]))
      ) {
        setSelectedTags(nextSelectedTags);
        if (typeof onChange === 'function') {
          onChange(nextSelectedTags);
        }
      }
    },
    [selectedTags, onChange],
  );

  // Synchronize selectedTags with value prop
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(selectedTags)) {
      setSelectedTags(value);
    }
  }, [value, selectedTags]);
  return (
    <>
      <Space wrap>
        {tagsData.map((tag) => (
          <CheckableTag
            style={{
              background: selectedTags?.includes(tag) ? '#003fab' : '#DEF0FF',
              borderRadius: '100px',
              color: '#548AD3',
              paddingLeft: '30px',
              paddingRight: '30px',
              cursor: 'pointer',
              color: selectedTags?.includes(tag) ? 'white' : '#548AD3',
            }}
            key={tag}
            checked={selectedTags?.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Space>
    </>
  );
};

export default OpenToBrokerForm;
