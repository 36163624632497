import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectedPropertyDetail from '../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetails';
import SelectedPropertyDetailsSkeleton from '../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import SimilarProperties from '../propertyDetailsPage/components/ProperyDetails/SimilarProperties';
import {
  addBreadcrumb,
  deleteResources,
  saveResources,
  showSearchBar,
  viewMoreResidentialProperty,
} from '../../features/searchSlice';
import SelectedUnits from './SelectedUnits';
import './ResidentialPropertyDetailsPage.scss';
import Search_assets from '../../../assets/images/search-assets.svg';
import Wishlist from '../searchPage/components/wishlist/Wishlist';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';

const ResidentialPropertyDetailsPage = () => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const loader = false;
  const { detailedResidentialPropertyData, wishlist, searchQuery, residentialPropertyData, unitsData } = useSelector(
    (state) => state.search,
  );
  const selectedPropertyData = detailedResidentialPropertyData;

  const [selectedUnit, setSelectedUnit] = useState({});

  useEffect(() => {
    dispatch(showSearchBar(true));
    if (propertyId) {
      dispatch(viewMoreResidentialProperty(propertyId));
    }
  }, []);
  useEffect(() => {
    dispatch(addBreadcrumb({ name: `Connected Cards`, path: `/leadGen/connectedResidentialDetails/${propertyId}` }));
  }, [propertyId]);

  useEffect(() => {
    if (isEmpty(unitsData)) return;
    setSelectedUnit(unitsData?.filter((el, index) => index === 0)?.[0]);
  }, [unitsData]);

  const handleUnitRowClick = (id) => {
    if (!id) return;
    setSelectedUnit(unitsData?.filter((el, index) => el?._id === id)?.[0]);
  };

  const onDeleteCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      deleteResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  const onSaveCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      saveResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  return (
    <>
      {loader ? (
        <div className="property_container">
          <div className="font14 font900 fontLight-3 mb-10">Property Selected</div>
          <SelectedPropertyDetailsSkeleton />
          <SimilarProperties
            loader={loader}
            propertyData={residentialPropertyData.filter((item) => item?._id !== selectedPropertyData?._id)}
          />
        </div>
      ) : (
        <div className="container">
          <div className='residentialdetail-container'>
            <div className="property_container " style={{ position: 'relative', zIndex: 2 }}>
              <div className="font14 font900 fontLight-3 mb-10">Property Selected</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SelectedPropertyDetail
                  propertyItem={
                    Array.isArray(detailedResidentialPropertyData)
                      ? detailedResidentialPropertyData?.[0]
                      : detailedResidentialPropertyData
                  }
                  onSaveCard={onSaveCard}
                  onDeleteCard={onDeleteCard}
                  residentialData={true}
                />
                <div className="similarproperty-div">
                  <SimilarProperties
                    searchQuery={searchQuery}
                    onCardClick={(id) => dispatch(viewMoreResidentialProperty(id))}
                    propertyData={residentialPropertyData.filter((item) => {
                      if (Array.isArray(selectedPropertyData)) {
                        return item?._id !== selectedPropertyData?.[0]?._id;
                      }
                      return item?._id !== selectedPropertyData?._id;
                    })}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
              <div className="font14 font900 fontLight-3 mb-10">Unit in Selected Property</div>
              <div style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                <SelectedUnits
                  searchQuery={searchQuery}
                  selectedPropertyData={selectedPropertyData}
                  onSaveCard={onSaveCard}
                  onDeleteCard={onDeleteCard}
                  unitData={selectedUnit}
                  similarUnits={unitsData?.filter((el, index) => el?._id !== selectedUnit?._id)}
                  handleUnitRowClick={handleUnitRowClick}
                />
              </div>
            </div>
          </div>
          <div style={{ background: 'white', width: '16%', borderRadius: '8px', height: '88vh' }}>
            <Wishlist wishlist={wishlist} />
          </div>
          <div style={{ width: '100%', display: 'contents' }}>
            <img
              src={Search_assets}
              alt="Search"
              style={{
                width: '73%',
                position: 'absolute',
                objectFit: 'cover',
                objectPosition: 'center',
                top: '55%',
                opacity: 0.8,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ResidentialPropertyDetailsPage;
