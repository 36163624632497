// Css Imports
import { Card, Collapse, Divider, Empty, Tooltip } from 'antd';
import dayjs from 'dayjs';
import genIcon from '../../../../assets/LeadGen.png';
import '../socialApp.scss';
// React,React-Redux,Router Imports
import { isEmpty, truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';
import AnnouncementsCardDashboard from '../../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import { useWindowSize } from '../../../../utils/useWindowSize/useWindowSize';
import JoinEventAccordion from '../../../components/socialApp/allEvents/JoinEventAccordion';
import LeadCards from '../../../components/socialApp/leadCard/LeadCard';
import GroupAccordian from '../../../components/socialApp/socialGroups/groupAccordian/GroupAccordian';
import ViewProfileContent from '../../../components/socialApp/viewProfile/ViewProfileContent';
import ViewProfileHeader from '../../../components/socialApp/viewProfile/ViewProfileHeader';
import { fetchUserProfileDataV2 } from '../../../features/myAccountSlice';
import {
  getProfileAllPosts,
  getProfileDetailsByID,
  getSocialAllGroups,
  getSocialEvents,
  setSearchVisibleSocialApp,
} from '../../../features/socialAppSlice';
import { fetchAccouncementsData, fetchRecentActivities } from '../../../features/userDashboardSlice';
import './viewUserProfile.scss';
// Slices and reducer imports

// Component Imports

const ViewUserProfile = () => {
  const { userId } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const userType = params?.get('userType');
  const { recentActivities } = useSelector((store) => store.userDashboard);
  const { announcements } = useSelector((store) => store.userDashboard);
  const navigate = useNavigate();
  const { isAbove1920 } = useWindowSize();
  // Hooks
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  // States
  const limit = 9;
  const [offset, setOffset] = useState(0);
  const [allPosts, setAllposts] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    dispatch(getSocialAllGroups())?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllGroups(res?.payload?.data?.response?.data);
      }
    });
    dispatch(
      getProfileDetailsByID({
        type: userType == 'company_post' ? 'company' : 'user',
        id: userId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setProfileData(res?.payload?.data?.response?.data);
      }
    });
    setOffset(0);
    dispatch(
      getProfileAllPosts({
        offset: offset,
        limit: limit,
        ownerId: userId,
        userType: userType,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts(res?.payload?.data?.response?.data?.posts);
      }
    });
  }, []);
  const handleScroll = () => {
    setOffset((prev) => prev + limit);
    dispatch(
      getProfileAllPosts({
        offset: offset + limit,
        limit: limit,
        ownerId: userId,
        userType: userType,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts((prev) => {
          return {
            ...prev,
            posts: [...(prev?.posts || []), ...(res?.payload?.data?.response?.data?.posts?.posts || [])],
            totalRecords: res?.payload?.data?.response?.data?.posts?.totalRecords,
          };
        });
      }
    });
  };

  // // States

  const [events, setAllEvents] = useState();

  // fetching on mounting
  useEffect(() => {
    dispatch(fetchUserProfileDataV2());
    dispatch(setSearchVisibleSocialApp(true));
    dispatch(fetchRecentActivities());
    dispatch(fetchAccouncementsData());

    dispatch(
      getSocialEvents({
        offset: 0,
        limit: 2,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllEvents(res?.payload?.data?.response?.data);
      }
    });
    return () => dispatch(setSearchVisibleSocialApp(false));
  }, []);
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';

  return (
    <div className="socialAppContainer">
      <div className="socialAppContainer__postContainer">
        <div className="viewProfileContainer">
          <div className="viewProfileContainer__profileContainer">
            <ViewProfileHeader
              isFollowed={allPosts?.posts?.[0]?.isFollowed}
              userId={userId}
              userDetails={allPosts?.posts?.[0]?.ownerDetails}
              setAllPosts={setAllposts}
            />
            <Divider style={{ margin: 0, background: '#949494' }} />
            <ViewProfileContent allPosts={allPosts} handleScroll={handleScroll} />
          </div>
        </div>
      </div>

      <div style={{ flex: 1, overflow: 'scroll' }}>
        <LeadCards />
        <Card className="mt-10 mb-10 d-flex d-column h-100  tryThatAddCard" style={{ height: 400 }}>
          <div className="d-flex a-center jc-center h-100">
            {' '}
            <img src={placeHolderImageUrl} />
          </div>
        </Card>
        {!isAbove1920 && (
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion
                onClick={() => {
                  setAllEvents(true);
                }}
              />
            </Panel>
          </Collapse>
        )}
      </div>
      {isAbove1920 && (
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion events={events} />
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default ViewUserProfile;
