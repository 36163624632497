import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import verifiedIcon from '../../../assets/images/verified.png';
import './ProspectImage.scss';
const ProspectImage = ({
  imageUrl,
  size = 130,
  imgClassName = 'prospectimage-container__circle',
  verifiedIconClassName = 'prospectimage-container__imgbox-img',
}) => {
  return (
    <div className="prospectimage-container">
      <Avatar
        size={size}
        src={imageUrl}
        icon={<UserOutlined />}
        className={imgClassName}
        style={{ border: '1px solid black' }}
      />
      <div className="prospectimage-container__imgbox">
        {/* <img src={verifiedIcon} alt="verified" className={verifiedIconClassName} /> */}
      </div>
    </div>
  );
};

export default ProspectImage;
