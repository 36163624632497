import { Avatar, Rate, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import './companyDetails.scss';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { truncate } from 'lodash';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
import { useDispatch, useSelector } from 'react-redux';

const SelectedCompanyDetails = ({ companyData, onDeleteCard, onSaveCard }) => {
  // hooks
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);

  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();

  const { raiseModal } = useSelector((store) => store.myAccount);
  const [zIndexer, setZindexer] = useState(false);
  useEffect(() => {
    setZindexer(raiseModal);
  }, [raiseModal]);

  // Function to show RaiseConcern
  const handleRaiseConcernClick = () => {
    setShowRaiseConcern(true);
  };
  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
  };
 

  return (
    <>
      {' '}
      <div key={companyData?._id} className="position-relative" style={{ zIndex: '0' }}>
        <div className="companyCard">
          <div className=" g-5 mt-10 flex-1">
            <div className="d-flex jc-between a-center mt-5 ">
              <div className="d-flex a-center g-5">
                <Avatar
                  className="companyAvatar"
                  src={companyData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage || 'https://place-hold.it/700'}
                />
                <div>
                  <h5
                    style={{ color: '#0081FC' }}
                    className="text-underline companyContainer__div__card__row__col__row__col1__h5 cursor-pointer"
                  >
                    {companyData?.companyName}
                  </h5>
                  <Rate
                    allowHalf
                    disabled
                    defaultValue={!isNaN(Number(companyData?.averageRating)) ? Number(companyData?.averageRating) : 4}
                  />
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: '-25px' }}>
                {!companyData?.isSaved && (
                  <img
                    src={bookmark}
                    alt="bookmark"
                    style={{
                      visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                    onClick={() => {
                      onSaveCard(
                        companyData?._id,
                        companyData?.companyName,
                        'organization',
                        ['directorInfo', 'propertyEngagement'],
                        'commercial',
                      );
                    }}
                  />
                )}
                {companyData?.isSaved && (
                  <img
                    src={bookmark_selected}
                    alt="bookmark_selected"
                    style={{
                      visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                    onClick={() => {
                      onDeleteCard(
                        companyData?._id,
                        companyData?.companyName,
                        'organization',
                        ['directorInfo', 'propertyEngagement'],
                        'commercial',
                      );
                    }}
                  />
                )}
                <span>
                  <KebabMenu
                    onShare={() =>
                      setShowShareModal(
                        `${window.location.host}/leadGen/organizationDetails/${companyData?._id}?list=occupants&shareable=true`,
                      )
                    }
                    onRaiseConcern={handleRaiseConcernClick}
                    onSubscribed={() => handleSubscribedClick(companyData, 'CompanyDetails')}
                    onUnSubscribed={() => handleUnSubscribedClick(companyData, 'CompanyDetails')}
                    resourceId={companyData}
                  />

                  {showShareModal && (
                    <ShareModal
                      shareUrl={showShareModal}
                      showShareModal={showShareModal}
                      setShowShareModal={setShowShareModal}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex w-50 d-column font10 " style={{ marginLeft: '5px' }}>
                <div className="bold fontLight-2 mt-15">Registered Address :</div>
                <div className="mt-15">{populateAddressInfo(companyData?.companyAddresses[0])}</div>
              </div>
              <div
                className="mt-20"
                style={{
                  backgroundColor: '#707070',
                  width: '1px' /* Adjust the width as needed */,
                  height: '80px',
                  marginRight: '15px',
                }}
              ></div>
              <div className="d-flex w-50 d-column font10 fontLight-2 mt-10 ">
                <div className="fontLight-2  p-5">
                  <span className="bold"> Floor No : </span> {companyData?.propertyEngagement?.floorNo || ' Awaited'}
                </div>
                <div className=" fontLight-2  p-5">
                  <span className="bold"> Unit No : </span>
                  <Tooltip title={companyData?.propertyEngagement?.unitNo}>
                    {truncate(companyData?.propertyEngagement?.unitNo, { length: 30 }) || ' Awaited'}
                  </Tooltip>
                </div>
                <div className=" fontLight-2  p-5">
                  <span className="bold">Industry : </span>
                  {companyData?.industryType || ' Awaited'}
                </div>
              </div>
            </div>
          </div>
          <div className="bottomRow  d-flex jc-center">
            <span
              className="property-hover font10 cursor-pointer bold"
              onClick={() => navigateTo(`/leadGen/organizationDetails/${companyData?._id}`)}
            >
              View More Details
            </span>
          </div>
        </div>
        <span className="connectorLine" style={{ width: zIndexer ? '25%' : '130%' }}>
          {[...new Array(200)]?.map(() => (
            <>&#x2022;</>
          ))}
        </span>
      </div>
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'Organization',
            resourceName: companyData?.companyName,
            resourceId: companyData?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </>
  );
};

export default SelectedCompanyDetails;
