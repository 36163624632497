import React from 'react';

const ImageIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.041" height="27.041" viewBox="0 0 27.041 27.041">
      <g id="Icon_feather-image" data-name="Icon feather-image" transform="translate(1.5 1.5)">
        <path
          id="Path_17277"
          data-name="Path 17277"
          d="M7.1,4.5H25.318a2.6,2.6,0,0,1,2.6,2.6V25.318a2.6,2.6,0,0,1-2.6,2.6H7.1a2.6,2.6,0,0,1-2.6-2.6V7.1A2.6,2.6,0,0,1,7.1,4.5Z"
          transform="translate(-4.5 -4.5)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_17278"
          data-name="Path 17278"
          d="M14.4,12.452A1.952,1.952,0,1,1,12.452,10.5,1.952,1.952,0,0,1,14.4,12.452Z"
          transform="translate(-5.296 -5.296)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_17279"
          data-name="Path 17279"
          d="M28.318,21.506,21.812,15,7.5,29.312"
          transform="translate(-4.898 -5.892)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};

export default ImageIcon;
