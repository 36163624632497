import React from 'react';

const SocialUsersIcon = ({ color = '#3A3A3A' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.555" height="34.625" viewBox="0 0 37.956 30.766">
      <g id="Group_27716" data-name="Group 27716" transform="translate(-1092.175 -41.279)">
        <g id="Group_27714" data-name="Group 27714" transform="translate(1092.775 42.04)">
          <g id="_79-users" data-name="79-users" transform="translate(0)">
            <circle
              id="Ellipse_1808"
              data-name="Ellipse 1808"
              cx="5.782"
              cy="5.782"
              r="5.782"
              transform="translate(13.027 5.193)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Path_18535"
              data-name="Path 18535"
              d="M26.153,29.576A8.576,8.576,0,1,0,9,29.576Z"
              transform="translate(0.802 -0.171)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Path_18536"
              data-name="Path 18536"
              d="M24.9,16.252A6.126,6.126,0,1,0,20,6.45"
              transform="translate(3.279 -4)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Path_18537"
              data-name="Path 18537"
              d="M25.225,25.576h7.351A8.576,8.576,0,0,0,24,17"
              transform="translate(4.179 -1.072)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Path_18538"
              data-name="Path 18538"
              d="M14.027,6.45a6.126,6.126,0,1,0-4.9,9.8"
              transform="translate(-0.55 -4)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
            <path
              id="Path_18539"
              data-name="Path 18539"
              d="M9.576,17A8.576,8.576,0,0,0,1,25.576H8.351"
              transform="translate(-1 -1.072)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SocialUsersIcon;
