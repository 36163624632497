import React from 'react';

const ShareIcon = ({className ,strokeColor}) => {
  return (
    <svg
      id="Share"
      xmlns="http://www.w3.org/2000/svg"
      width="15.061"
      height="15.588"
      viewBox="0 0 20.061 17.588"
      style={{ marginRight: '5px' }}
      className={`share-icon ${className}`}
      
    >
      <path
        id="Path_18056"
        data-name="Path 18056"
        d="M19.685,45.068a.886.886,0,0,1-.132-.011.8.8,0,0,1-.641-.666l-1.32-9.91-7.414-5.6a.779.779,0,0,1,.469-1.4c15.612.248,18.985.318,19.225.491l.058.072-.018.091c-.077.185-1.389,2.45-9.555,16.547a.785.785,0,0,1-.673.387Zm-.349-.769a.357.357,0,0,0,.358.312h0a.354.354,0,0,0,.309-.18c1.7-2.941,7.389-12.757,9.043-15.627l.3-.519-.6-.019c-2.682-.089-11.265-.246-18.1-.353h-.083a.326.326,0,0,0-.316.228.329.329,0,0,0,.116.373L18,34.248,19.337,44.3Z"
        transform="translate(-9.868 -27.48)"
        fill="#535353"
        stroke={strokeColor}
      />
      <path
        id="Path_18484"
        data-name="Path 18484"
        d="M0,0,13.3,0V.525L.031.5Z"
        transform="translate(7.675 6.812) rotate(-27.84)"
        fill="#535353"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default ShareIcon;
