import {
  doSubscribed,
  doUnSubscribed,
  doSubscribedResedential,
  doUnSubscribedResedential,
} from '../../leadgen/features/searchSlice';
import { useDispatch } from 'react-redux';

export const useSubscriptionHandlers = () => {
  const dispatch = useDispatch();

  const handleSubscribedClick = (propertyItem, propertyType) => {
    let payload = '';
    if (propertyType === 'commercialProperty') {
      payload = {
        type: 'property',
        name: propertyItem?.buildingName ?? 'Awaited',
        city: propertyItem?.addressInfo?.[0]?.city ?? 'Awaited',
        locality: propertyItem?.addressInfo?.[0]?.locality ?? 'Awaited',
        resourceId: propertyItem?._id,
        featuredImages: propertyItem?.assetsInfo?.[0]?.featuredImage
          ? [propertyItem?.assetsInfo?.[0]?.featuredImage]
          : [],
      };
      return dispatch(doSubscribed(payload)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'ResidentialProperty') {
      payload = {
        type: 'property',
        name: propertyItem?.buildingName ?? 'Awaited',
        city: propertyItem?.addressInfo?.[0]?.city ?? 'Awaited',
        locality: propertyItem?.addressInfo?.[0]?.locality ?? 'Awaited',
        resourceId: propertyItem?._id,
        featuredImages: propertyItem?.assetsInfo?.[0]?.featuredImage
          ? [propertyItem?.assetsInfo?.[0]?.featuredImage]
          : [],
      };
      return dispatch(doSubscribedResedential(payload)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'ResidentialPropertyDetails') {
      payload = {
        type: 'property',
        name: propertyItem?.buildingName ?? 'Awaited',
        city: propertyItem?.addressInfo?.city ?? 'Awaited',
        locality: propertyItem?.addressInfo?.locality ?? 'Awaited',
        resourceId: propertyItem?._id,
        featuredImages: propertyItem?.featuredImage ? [propertyItem?.featuredImage] : [],
      };

      return dispatch(doSubscribedResedential(payload)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'Company') {
      payload = {
        type: 'company',
        name: propertyItem?.companyName ?? 'Awaited',
        city: propertyItem?.companyAddresses[0]?.city ?? 'Awaited',
        locality: propertyItem?.companyAddresses[0]?.locality ?? 'Awaited',
        resourceId: propertyItem?._id,
        featuredImages: propertyItem?.otherCompanyInfo?.assetsInfo[0]?.featuredImage
          ? [propertyItem?.otherCompanyInfo?.assetsInfo[0]?.featuredImage]
          : [],
      };

      return dispatch(doSubscribed(payload)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'CompanyDetails') {
      payload = {
        type: 'company',
        name: propertyItem?.companyName ?? 'Awaited',
        city: propertyItem?.companyAddresses[0]?.city ?? 'Awaited',
        locality: propertyItem?.companyAddresses[0]?.locality ?? 'Awaited',
        resourceId: propertyItem?._id,
        featuredImages:
          propertyItem?.otherCompanyInfo?.assetsInfo?.length > 0 &&
          propertyItem.otherCompanyInfo.assetsInfo[0]?.featuredImage
            ? [propertyItem.otherCompanyInfo.assetsInfo[0].featuredImage]
            : [],
      };

      return dispatch(doSubscribed(payload)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    }
  };

  const handleUnSubscribedClick = (propertySubItem, propertyType) => {
    let unSubscribedId;
    let propertyId = propertySubItem?._id;
    let propertyDetails = {};
    if (propertyType === 'property_type_from_list') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.key,
        propertyId: propertySubItem?.resourceId,
        propertyType: propertyType,
      };
      return dispatch(doUnSubscribed(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    }
    if (propertyType === 'company_type_from_list') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.key,
        propertyId: propertySubItem?.resourceId,
        propertyType: propertyType,
      };
      return dispatch(doUnSubscribed(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    }

    if (propertyType === 'commercialProperty') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.isSubscribed,
        propertyId: propertySubItem?._id,
        propertyType: propertyType,
      };
      return dispatch(doUnSubscribed(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'property') {
      unSubscribedId = propertySubItem?.isSubscribed;
    } else if (propertyType === 'Company') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.isSubscribed,
        propertyId: propertySubItem?._id,
        propertyType: propertyType,
      };
      return dispatch(doUnSubscribed(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
    } else if (propertyType === 'ResidentialPropertyDetails') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.isSubscribed,
        propertyId: propertySubItem?._id,
        propertyType: propertyType,
      };

      return dispatch(doUnSubscribedResedential(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
      // propertyItem.isSaved = false;
    } else if (propertyType === 'CompanyDetails') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.isSubscribed,
        propertyId: propertySubItem?._id,
        propertyType: propertyType,
      };

      return dispatch(doUnSubscribed(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
      // propertyItem.isSaved = false;
    } else if (propertyType === 'ResidentialProperty') {
      propertyDetails = {
        unSubscribedId: propertySubItem?.isSubscribed,
        propertyId: propertySubItem?._id,
        propertyType: propertyType,
      };
      return dispatch(doUnSubscribedResedential(propertyDetails)).then((res) => {
        if (res?.payload?.data?.response?.code === '201') {
        }
      });
      // propertyItem.isSaved = false;
    }
  };

  return {
    handleSubscribedClick,
    handleUnSubscribedClick,
  };
};
