import React, { useRef } from 'react';
import { Avatar, Button, Card, Pagination, Dropdown, Switch, Table, Spin, Tooltip } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import './companySubscriptions.scss';
import { BellOutlined, HomeOutlined, RightOutlined, SettingOutlined } from '@ant-design/icons';
import {
  doSubscribed,
  doUnSubscribed,
  fetchSubscriptionsDetailsCompany,
} from '../../../../universal/features/myAccountSlice';
import { useSubscriptionHandlers } from '../../../../leadgen/services/subscribtionsHandler';
import NotificationConfig from './NotificationConfig';

const CompanySubscriptions = () => {
  const [whatsappEnabled, setWhatsappEnabled] = useState(true);
  const [emailEnabled, setEmailEnabled] = useState(true);
  const { handleUnSubscribedClick } = useSubscriptionHandlers();
  const { subscriptionDetailsCompany, subscriptionDetailsCompanyLoader } = useSelector((store) => store.myAccount);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableContainerRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);

  //company Coloumns
  const companyTablecolumns = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record?.propertyIcon}
            style={{ flexShrink: 0 }} // Prevents shrinking of the avatar
          />
          <Tooltip title={record.propertyName}>
            <span
              style={{
                marginLeft: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '150px', // Set a fixed width or percentage as per your layout
                cursor: 'pointer', // Optional: Indicates interactivity
              }}
            >
              {record.propertyName}
            </span>
          </Tooltip>
        </div>
      ),
    },

    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },

    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: '',
      dataIndex: 'subscribe',
      key: 'subscribe',
      render: (text, record) => (
        <Button
          type="primary"
          icon={<BellOutlined />}
          style={{
            backgroundColor: '#F2F9FF',
            color: '#0081FC',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleUnsubscribe(record, 'company')}
        >
          Unsubscribe
        </Button>
      ),
    },
    {
      title: '',
      key: 'arrow',
      render: () => (
        <Button
          type="link"
          icon={<RightOutlined />}
          style={{ color: '#1890ff', alignItems: 'center', justifyContent: 'center' }}
        />
      ),
    },
  ];

  const fetchSubscriptions = (page, shouldCheckScroll = false) => {
    setLoading(true); // Start loading
    let limit = 8;
    const offset = (page - 1) * limit;

    dispatch(fetchSubscriptionsDetailsCompany({ propertyType: 'company', limit, offset }))
      .then((response) => {
        // Check if the payload contains the subscriptions data
        const newData = response?.payload?.data?.response?.data?.subscriptions || []; // Ensure newData is an array
        const totalDataCount = response?.payload?.data?.response?.data?.totalCount || 0;

        setTotalCount(totalDataCount);
        // Ensure that data is unique by checking if an item already exists
        setData((prevData) => {
          const uniqueData = newData.filter((newItem) => !prevData.some((prevItem) => prevItem._id === newItem._id));
          return [...prevData, ...uniqueData]; // Only append new unique items
        });

        setLoading(false); // Stop loading

        if (shouldCheckScroll) {
          setTimeout(() => {
            const tableBody = document.querySelectorAll('.ant-table-body')[1];
            if (tableBody) {
              const { scrollHeight, clientHeight } = tableBody;
              if (scrollHeight <= clientHeight && newData.length > 0 && data.length < totalDataCount) {
                setCurrentPage((prevPage) => prevPage + 1);
              }
            }
          }, 100);
        }
      })
      .catch((error) => {
        setLoading(false); // Ensure loading stops in case of error
      });
    
  };
  useEffect(() => {
    fetchSubscriptions(currentPage, true);
  }, [currentPage]);

  useEffect(() => {
    const tableBody = document.querySelectorAll('.ant-table-body')[1];

    if (tableBody) {
      tableBody.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data, loading]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    console.log('Scroll Top:', scrollTop);
    if ((scrollTop + clientHeight) / scrollHeight >= 0.8 && !loading && data.length < totalCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };


  const handleUnsubscribe = async (record) => {
    await handleUnSubscribedClick(record, 'company_type_from_list');
    setData([]); // Reset data
    setCurrentPage(1); // Reset page
    fetchSubscriptions(1); // Re-fetch data from the first page
  };

  const DropdownContent = (
    <div className="ps-dropdown-content">
      <div>
        <h3>Notification</h3>
      </div>
      <div className="ps-dropdown-content__item">
        <span>WhatsApp</span>
        <Switch checked={whatsappEnabled} onChange={setWhatsappEnabled} />
      </div>
      <div className="ps-dropdown-content__item">
        <span>Email</span>
        <Switch checked={emailEnabled} onChange={setEmailEnabled} />
      </div>
    </div>
  );

  return (
    <div>
      <Card className="right-card">
        <div className="right-card__position">
          <div className="flex-container">
            <Title level={5} className="points-desc">
              Company
            </Title>
            <NotificationConfig type="company" />
          </div>

          <Table
            id="companyTable"
            scroll={{ y: 'calc(72vh - 50px)' }}
            columns={companyTablecolumns}
            dataSource={data.map((item) => ({
              key: item?.id ? item?.id : item?._id, // unique key
              propertyName: item?.name || 'Unknown Name', // Default to avoid undefined
              propertyIcon: item?.featuredImages[0] || <Avatar icon={<HomeOutlined />} />, // Default icon
              industry: item?.locality || 'Unknown Locality', // Default to avoid undefined
              city: item?.city || 'Unknown City', // Default to avoid undefined
              resourceId: item?.resourceId, // unique key

            }))}
            pagination={false}
            loading={subscriptionDetailsCompanyLoader}
          />
          {loading && (
            <div style={{ textAlign: 'center', padding: '10px' }}>
              <Spin />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CompanySubscriptions;
