import React from 'react';
import './groupCard.scss';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { truncate, upperFirst } from 'lodash';

const GroupCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/user/socialApp/groupDetails/${data?._id}`);
      }}
      className="groupCardContainer cursor-pointer"
      style={{ width: '48%' }}
    >
      <div style={{ background: `url(${data?.imageUrl})` }} className="groupCardImageCaraousel">
        {/* <img  src={data?.imageUrl} /> */}
      </div>
      <div className="d-flex d-column jc-between ml-10  w-70">
        <div className="d-flex d-column jc-between">
          <span className="fontDarkBlue font700 font18">{upperFirst(data?.name)}</span>
          <span
            className="fontLight font12"
            dangerouslySetInnerHTML={{ __html: truncate(data?.purpose, { length: 120 }) }}
          ></span>
          <span>
            {' '}
            <span className="fontOrange font12 mr-5">{data?.memberCount}</span>
            <span className="fontLight-4">Members</span>
          </span>
        </div>
        <div className="d-flex jc-end">
          <span className="fontLight-5 font12 fontLight-4 ">
            Created on : {dayjs(data?.createdAt).format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
