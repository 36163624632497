// Css Imports
import { Card, Collapse, Empty } from 'antd';
import './socialApp.scss';
// React,React-Redux,Router Imports
import React, { useEffect, useState } from 'react';
// Css Imports
import './socialApp.scss';
// React,React-Redux,Router Imports

import { isEmpty } from 'lodash';
import AnnouncementsCardDashboard from '../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import JoinEventAccordion from '../../components/socialApp/allEvents/JoinEventAccordion';
import LeadCards from '../../components/socialApp/leadCard/LeadCard';
// Slices and reducer imports
import { useDispatch, useSelector } from 'react-redux';
import { placeHolderImageUrl } from '../../../constants/globalConstants';
import { useWindowSize } from '../../../utils/useWindowSize/useWindowSize';
import ShowAllEvents from '../../components/socialApp/allEvents/ShowEvents';
import RecentActivity from '../../components/socialApp/recentActivity/RecentActivity';
import GroupAccordian from '../../components/socialApp/socialGroups/groupAccordian/GroupAccordian';
import { getSocialAllGroups, getSocialEvents } from '../../features/socialAppSlice';
// Slices and reducer imports

// Component Imports

const AllEvents = () => {
  const { isAbove1920 } = useWindowSize();
  const { Panel } = Collapse;
  const [allGroups, setAllGroups] = useState([]);

  const { announcements } = useSelector((store) => store.userDashboard);
  const dispatch = useDispatch();
  // fetching on mounting

  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';

  const [events, setAllEvents] = useState();

  useEffect(() => {
    dispatch(getSocialAllGroups())?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllGroups(res?.payload?.data?.response?.data);
      }
    });
    dispatch(
      getSocialEvents({
        offset: 0,
        limit: 2,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllEvents(res?.payload?.data?.response?.data);
      }
    });
  }, []);
  return (
    <div className="socialAppContainer">
      <div className="socialAppContainer__postContainer">
        <ShowAllEvents />
      </div>

      <div style={{ flex: 1, overflow: 'scroll' }}>
        <LeadCards />

        <Card className="mt-10 mb-10 d-flex d-column h-100  tryThatAddCard" style={{ height: 400 }}>
          <div className="d-flex a-center jc-center h-100">
            {' '}
            <img src={placeHolderImageUrl} />
          </div>
        </Card>
        {!isAbove1920 && (
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <RecentActivity />
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion events={events} />
            </Panel>
          </Collapse>
        )}
      </div>
      {isAbove1920 && (
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <RecentActivity />
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion events={events} />
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default AllEvents;
