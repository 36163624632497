import { UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import React from 'react';
import InputEmoji from 'react-input-emoji';
import { useSelector } from 'react-redux';
import AdCardPost from '../../../components/socialApp/posts/allPosts/adCardPost/AdCardPost';
import EventCardPost from '../../../components/socialApp/posts/allPosts/eventCardPost/EventCardPost';
import GenericPost from '../../../components/socialApp/posts/allPosts/genericPost/GenericPost';
import JobCardPost from '../../../components/socialApp/posts/allPosts/jobCardPost/JobCardPost';
import LeadCardPost from '../../../components/socialApp/posts/allPosts/leadCardPost/LeadCardPost';
import NewsCardPost from '../../../components/socialApp/posts/allPosts/newsCardPost/NewsCardPost';
import PollCardPost from '../../../components/socialApp/posts/allPosts/pollCardPost/PollCardPost';
import ReportCardPost from '../../../components/socialApp/posts/allPosts/reportCardPost/ReportCardPost';
import CommentMenu from '../../../components/socialApp/posts/postFooter/CommentMenu/CommentMenu';
import './CommentComponent.scss';

const ShareablePost = ({ userId, postDetails, setLoginPopup }) => {
  const loading = useSelector((state) => state.socialApp?.socialApploader);
  const user = useSelector((state) => state.user?.user);
  const error = useSelector((state) => state.socialApp?.error);

  const openLogin = () => {
    setLoginPopup(true);
  };

  const sortedComments = postDetails?.commentData
    ?.slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: NewsCardPost,
    ad_card: AdCardPost,
    event_card: EventCardPost,
    lead_card: LeadCardPost,
    job_card: JobCardPost,
    poll_card: PollCardPost,
  };
  const PostComponent = postTypes?.[postDetails?.type];

  const getDate = (createdAt) => {
    const date1 = dayjs(createdAt);
    const date2 = dayjs();

    // Calculate the difference in milliseconds
    const diffInMs = date2.diff(date1);

    // Convert the difference to a dayjs duration
    // const diffDuration = dayjs.duration(diffInMs);
    const totalMilliseconds = diffInMs;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    if (totalDays > 0) {
      return `${totalDays}d`;
    } else if (totalHours > 0) {
      return `${totalHours}h`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes}m`;
    } else if (totalSeconds > 0) {
      return `${totalSeconds}s`;
    }
  };
  return (
    <div className="comment-modal-content">
      <div style={{ background: 'white', borderRadius: 10 }} className="modal-post p-20 pb-0 mb-0">
        <div className="post-left">
          <div>
            {PostComponent && (
              <PostComponent
                setPostDetails={() => {}}
                isShareable={true}
                isViewPost={true}
                setAllposts={() => {}}
                setPostData={() => {}}
                handleFollow={() => {}}
                handleUnFollow={() => {}}
                postDetails={postDetails}
                setLoginPopup={setLoginPopup}
                isUserPost={false}
                options={postDetails?.postDetails?.options}
              />
            )}
          </div>
        </div>
        {postDetails?.type !== 'poll_card' && (
          <>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'end', marginLeft: '20px' }} className="post-right">
              <div className=" newComment d-flex g-10 jc-between a-center w-100">
                <Avatar
                  className="avatar"
                  onClick={openLogin}
                  src={user?.personalDetails?.imageUrl}
                  icon={<UserOutlined />}
                  style={{ alignContent: 'center' }}
                />
                <div className="comment-input-container position-relative">
                  <InputEmoji shouldReturn onEnter={openLogin} placeholder="Write your comment" />
                </div>
              </div>
              <div className={`comments-list-2 mt-10`}>
                {loading && <p>Loading comments...</p>}
                {error && <p>{error}</p>}
                {sortedComments?.length > 0 ? (
                  sortedComments.map((comment, index) => (
                    <div key={index} className="d-flex g-10">
                      <Avatar
                        className="avatar"
                        onClick={openLogin}
                        src={comment?.userDetails?.personalDetails?.imageUrl}
                        icon={<UserOutlined />}
                        style={{ alignContent: 'center' }}
                      />
                      <div className="d-flex d-column w-100">
                        <div key={comment._id} className="comment-item">
                          <div className="d-flex jc-between">
                            <div className="d-flex d-column mb-10">
                              <span className="font14 font600 fontLight">{upperFirst(comment?.userDetails?.name)}</span>
                              <div className="d-flex g-5 a-center">
                                <span className="font12 fontExtraLight ">
                                  {upperFirst(comment?.userDetails?.professionalDetails?.designation)}
                                </span>
                                {comment?.userDetails?.professionalDetails?.companyName && (
                                  <span className="font12 fontExtraLight ">
                                    @
                                    <Tooltip title={comment?.userDetails?.professionalDetails?.companyName}>
                                      {comment?.userDetails?.professionalDetails?.companyName}
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex a-center">
                              <span className="font10 fontLight-2">{getDate(comment?.createdAt)}</span>
                              {comment.userId === userId && (
                                <CommentMenu onEditComment={openLogin} onDeleteComment={openLogin} />
                              )}
                            </div>
                          </div>

                          <div className="comment-text font14">
                            <span dangerouslySetInnerHTML={{ __html: comment?.text }}></span>
                          </div>
                        </div>
                        <span className="fontLight-6 mt-10 font12 pl-5 cursor-pointer">
                          <span onClick={openLogin}>Reply</span>{' '}
                          <span className="fontLight-4"> &#x25cf; {comment?.commentDetails?.length} Replies</span>
                        </span>

                        <div className="d-flex d-column g-10 pl-30 mt-10">
                          {comment?.commentDetails?.length > 0 &&
                            comment?.commentDetails?.map((childComments) => (
                              <div key={childComments._id} className="comment-item">
                                <div className="d-flex jc-between ">
                                  <div className="d-flex d-column mb-10 replyContainers">
                                    <span className="font14 font600 fontLight">
                                      {upperFirst(childComments?.userDetails?.name)}
                                    </span>
                                    <div className="d-flex g-5 a-center">
                                      <span className="font12 fontExtraLight ">
                                        {upperFirst(childComments.userDetails?.professionalDetails?.designation)}
                                      </span>
                                      {childComments?.userDetails?.professionalDetails?.companyName && (
                                        <span className="font12 fontExtraLight ">
                                          @
                                          <Tooltip title={childComments?.userDetails?.professionalDetails?.companyName}>
                                            {childComments?.userDetails?.professionalDetails?.companyName}
                                          </Tooltip>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex a-center">
                                    <span className="font10 fontLight-2">{getDate(childComments?.createdAt)}</span>
                                    {childComments.userId === userId && (
                                      <CommentMenu onEditComment={openLogin} onDeleteComment={openLogin} />
                                    )}
                                  </div>
                                </div>

                                <div className="comment-text font14">
                                  <span dangerouslySetInnerHTML={{ __html: childComments?.text }}></span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ marginLeft: '48px' }}>No comments yet</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ShareablePost;
