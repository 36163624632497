import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../../../../layout/default/BackIcon';

//  styles
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../../features/loginSlice';
import './updatePassword.scss';
import logo from '../../../../assets/logo.svg';
const UpdatePassword = ({ handleSubmit, loading }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  return (
    <div className="updatePassword-box">
      <div className="updatePassword-header">
        <BackIcon onClick={() => dispatch(setCurrentStep('FORGOT_PASSWORD'))} className="backicon" />
        <div className="login-box__img">
          <img src={logo} alt="logo" width="150px" />
        </div>
      </div>
      <div>
        <Form
          className="updatePassword-box__form"
          name="basic"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            label="Set Password"
            className="form-item"
            rules={[
              {
                message:
                  'Password must be contain 8-12 character, One Capital Letter, One Small Letter, One Special Character, One number',
                pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,12}$/,
              },
              {
                required: true,
                message: 'Please enter password',
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter Password" className="aadhar-input" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            required
            className="form-item"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const password = getFieldValue('password');
                  if (password && !value) {
                    return Promise.reject('Enter confirm password');
                  } else if (password !== value) {
                    return Promise.reject('Password does not match');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter Confirm Password" className="aadhar-input" />
          </Form.Item>

          <Form.Item className="mt-10">
            <Button
              loading={loading.registerLoading}
              type="primary"
              style={{
                background: '#023FAC',
                fontSize: '12px',
              }}
              block
              htmlType="submit"
            >
              Submit
            </Button>
            <Text className="font14 fontLight d-flex jc-center mt-10">
              Don't have an account ?
              <Link to="/register" className="register-to__login">
                &nbsp;Register
              </Link>
            </Text>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePassword;
