import { DownloadOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import CommonButton from '../../../common/CommonButton';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import './reportCardPost.scss';
import RepostIcon from '../../../../../../assets/SocialApp/RepostIcon';

const ReportCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  return (
    <div className="report_card_post_content ">
      {postDetails.imageUrls?.[0]?.url && (
        <div className="report_card_post_images w-40">
          <div
            style={{
              background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
              postDetails.imageUrls?.[0]?.url || defaultImage
            })
`,
            }}
            className="report-carousel-image"
          >
            <span className="postImageTitle">{postDetails?.title}</span>
            {postDetails?.postDetails?.pdfUrl && (
              <a
                target="_blank"
                href={postDetails?.postDetails?.pdfUrl}
                download="report-card"
                className="postImageFooter"
              >
                <DownloadOutlined style={{ fontSize: 16 }} />
                Download full Report
              </a>
            )}
          </div>
        </div>
      )}
      <div
        className={`d-flex d-column jc-between ${postDetails.imageUrls?.[0]?.url ? 'w-70' : 'w-100'} word-break-all`}
      >
        {isViewPost && postDetails?.isReposted && (
          <div className="d-flex g-5 a-center mb-10">
            <div className="repost-icon">
              <RepostIcon className="repost-icon" />
            </div>
            <div>You Reposted</div>
          </div>
        )}
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <div
            className="font14 postBody fontExtraLight truncatedText"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.synopsys) }}
          ></div>
          <div
            className={`font14 postBody fontExtraLight ${!isViewPost && 'truncatedText'}`}
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex font500 fontExtraLight g-5">
            {postDetails?.tags?.map((elem, index) => (
              <span style={{ flex: 'none' }} key={index} className=" font14 font500 fontExtraLight g-15">
                #{elem}
              </span>
            ))}
          </span>

          {postDetails?.CTA && (
            <CommonButton style={{ width: '90px' }} target="_blank" href={postDetails?.CTA?.link}>
              {postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
          setLoginPopup={setLoginPopup}
        />
      </div>
    </div>
  );
};

export default ReportCardPost;
