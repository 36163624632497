export const leadTypes = [
  {
    label: 'Residential',
  },
  {
    label: 'Commercial',
  },
  {
    label: 'Co-Working',
  },
];

export const engagementType = [
  {
    label: 'Buy',
  },
  {
    label: 'Sale',
  },
  {
    label: 'Rent-IN',
  },
  {
    label: 'Rent-OUT',
  },
];
