// Css Imports
import { Card, Collapse, Empty } from 'antd';
import '../socialApp.scss';
// React,React-Redux,Router Imports
import React, { useEffect, useState } from 'react';
// Css Imports
// React,React-Redux,Router Imports

import { isEmpty } from 'lodash';
import AnnouncementsCardDashboard from '../../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import JoinEventAccordion from '../../../components/socialApp/allEvents/JoinEventAccordion';
import LeadCards from '../../../components/socialApp/leadCard/LeadCard';
// Slices and reducer imports
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useWindowSize } from '../../../../utils/useWindowSize/useWindowSize';
import CommentComponent from '../../../components/socialApp/posts/postFooter/CommentComponent';
import RecentActivity from '../../../components/socialApp/recentActivity/RecentActivity';
import { getPostDetailsById, getSocialAllGroups } from '../../../features/socialAppSlice';
import { useSearchParams } from 'react-router-dom';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';
import GroupAccordian from '../../../components/socialApp/socialGroups/groupAccordian/GroupAccordian';
// Slices and reducer imports

// Component Imports

const ViewPostDetails = () => {
  const { isAbove1920 } = useWindowSize();
  const { Panel } = Collapse;
  const { announcements } = useSelector((store) => store.userDashboard);
  const dispatch = useDispatch();
  const { postId, postType } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const [allGroups, setAllGroups] = useState([]);
  const myPosts = params?.get('myPosts');
  // fetching on mounting
  const { user, userV2 } = useSelector((store) => store.user);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const [postDetails, setPostDetails] = useState();
  useEffect(() => {
    dispatch(getSocialAllGroups())?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllGroups(res?.payload?.data?.response?.data);
      }
    });
    dispatch(
      getPostDetailsById({
        type: postType,
        postId,
        ownerId: postType == 'company_post' ? companyId : user?.id,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setPostDetails(res?.payload?.data?.response?.data?.posts);
      }
    });
  }, []);

  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  return (
    <div className="socialAppContainer">
      <div className="socialAppContainer__postContainer">
        {postDetails && (
          <CommentComponent
            isDashboardPost={true}
            setAllPosts={() => {}}
            userId={user?.id}
            isUserPost={true}
            postId={postDetails?._id}
            postDetails={postDetails}
            setPostDetails={setPostDetails}
          />
        )}
      </div>

      <div style={{ flex: 1, overflow: 'scroll' }}>
        <LeadCards />
        <Card className="mt-10 mb-10 d-flex d-column h-100  tryThatAddCard" style={{ height: 400 }}>
          <div className="d-flex a-center jc-center h-100">
            {' '}
            <img src={placeHolderImageUrl} />
          </div>
        </Card>
        {!isAbove1920 && (
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <RecentActivity />
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion />
            </Panel>
          </Collapse>
        )}
      </div>
      {isAbove1920 && (
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <RecentActivity />
            <Panel header="Group">{allGroups && <GroupAccordian groups={allGroups?.otherGroups?.groups} />}</Panel>

            <Panel header="Event">
              <JoinEventAccordion />
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default ViewPostDetails;
