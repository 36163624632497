import { Avatar } from 'antd';
import React, { useState } from 'react';
import './common.scss';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';

const JoinGroupEventCard = ({ data }) => {
  const defaultImage =
    'https://social-dev-posts-publick.s3.ap-south-1.amazonaws.com/1724606286500-artists-eyes-N3lZic-azGs-unsplash%20%281%29.jpg';
  return (
    <div className="d-flex jc-between ">
      <span className="d-flex g-10 a-center ">
        <Avatar src={data?.imageUrls?.[0]?.url || placeHolderImageUrl} className="avatar" onClick={() => {}} />
        <div className="d-flex d-column">
          <span className="font16 font700 fontdark ">{data?.title}</span>
          <div className="d-flex g-5">{data?.postDetails?.type}</div>
        </div>
      </span>

      <span className="d-flex g-15 a-center">
        {/* <CommonButton loader={loader} onClick={() => {}} ghost>
          Join Now
        </CommonButton> */}
      </span>
    </div>
  );
};

export default JoinGroupEventCard;
