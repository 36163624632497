import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../../../layout/default/BackIcon';

//  styles
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../features/loginSlice';
import './enterPassword.scss';

const EnterPassword = ({ handleSubmit, loading, onCode, onClose, OauthPopup, url }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  return (
    <div className="enterpassword-box">
      <div className="enterpassword-header">
        <BackIcon onClick={() => dispatch(setCurrentStep('LOGIN_FORM'))} className="backicon" />
        <h2 className="header-text">Password</h2>
      </div>
      <div>
        <Form
          className="enterpassword-box__form"
          name="basic"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            label="Password"
            className="form-item"
            rules={[
              {
                required: true,
                message: 'Please enter password',
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter Password" className="aadhar-input" />
          </Form.Item>
          <div
            className="cursor-pointer d-flex jc-end"
            onClick={() => {
              dispatch(setCurrentStep('FORGOT_PASSWORD'));
            }}
          >
            Forgot Password ?
          </div>

          <Form.Item className="mt-10">
            <Button
              loading={loading.registerLoading}
              type="primary"
              style={{
                background: '#023FAC',
                fontSize: '12px',
              }}
              block
              htmlType="submit"
            >
              Submit
            </Button>
            <Text className="font14 fontLight d-flex jc-center mt-10">
              Don't have an account ?
              <Link to="/register" className="register-to__login">
                &nbsp;Register
              </Link>
            </Text>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EnterPassword;
