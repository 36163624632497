import { Avatar } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import CommonButton from '../../common/CommonButton';
import { placeHolderImageUrl } from '../../../../../constants/globalConstants';

const GroupAccordianCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex jc-between ">
      <span className="d-flex g-10 a-center ">
        <Avatar src={data?.imageUrl || placeHolderImageUrl} className="avatar" onClick={() => {}} />
        <div className="d-flex d-column">
          <span className="font16 font700 fontdark ">{data?.name}</span>
          <span>
            <span className="fontOrange font12 mr-5">{data?.memberCount}</span>
            <span className="fontLight-4">Members</span>
          </span>
        </div>
      </span>

      <span className="d-flex g-15 a-center">
        <CommonButton onClick={() => navigate(`/user/socialApp/groupDetails/${data?._id}`)} ghost>
          Join Now
        </CommonButton>
      </span>
    </div>
  );
};

export default GroupAccordianCard;
