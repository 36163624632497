import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LeftCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { InputOTP } from 'antd-input-otp';

import logo from '../../../../assets/logo.svg';
//css imports
import { sendforgotPasswordOtp, setCurrentStep } from '../../../features/loginSlice';
import './otp.scss';

const ForgotPasswordOtp = ({ handleVerifyOtp, loading, phone }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  const [otpValue, setOtpValue] = useState([]);
  const [resendOTPbtnDisabled, setResendOTPbtnDisabled] = useState(true);
  const [countdown, setCountDown] = useState(30);
  const [showCountDown, setShowCountDown] = useState(true);
  // const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    setOtpValue([]);
    setCountDown(30);
    setResendOTPbtnDisabled(true);
    setShowCountDown(true);
  }, []);

  useEffect(() => {
    if (!showCountDown) return;

    let intervalID = setInterval(() => {
      setCountDown((prev) => {
        if (prev < 1) {
          clearInterval(intervalID);
          setResendOTPbtnDisabled(false);
          setShowCountDown(false);
          setCountDown(prev - 1);
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(intervalID);
  }, [showCountDown]);

  const resendOTP = () => {
    setResendOTPbtnDisabled(true);
    setCountDown(30);
    dispatch(
      sendforgotPasswordOtp({
        phone,
      }),
    ).then((e) => {
      setShowCountDown(true);
    });
  };

  useEffect(() => {
    setResendOTPbtnDisabled(true);
    setShowCountDown(true);
  }, []);

  return (
    <div className="otp">
      <div className="otp-header" style={{ gap: '70px', justifyContent: 'unset' }}>
        <LeftCircleOutlined onClick={() => dispatch(setCurrentStep('FORGOT_PASSWORD'))} />
        <img src={logo} alt="logo" width="150px" />
      </div>
      <Text className="otp-text">
        Please enter the 6 digit OTP shared on your registered
        <Text>{` Mobile ${phone?.substring(0, 6)}xxxx`}</Text>
      </Text>
      <InputOTP
        autoSubmit={handleVerifyOtp}
        value={otpValue}
        autoFocus
        onChange={setOtpValue}
        name="termsAgree"
        namelength={6}
        inputType="numeric"
        className="otp-input"
      />
      <Button
        disabled={!otpValue || otpValue?.[0]?.length == 0}
        className="otp-form__button"
        onClick={() => {
          handleVerifyOtp(otpValue);
        }}
        loading={loading}
        block
      >
        Submit
      </Button>
      <div className="otp-form__message">
        <div>
          Didn't get OTP? &nbsp;
          <Text
            disabled={resendOTPbtnDisabled}
            style={{
              textDecoration: 'underline',
              cursor: resendOTPbtnDisabled ? '' : 'pointer',
            }}
            onClick={() => {
              if (resendOTPbtnDisabled) return;
              resendOTP();
            }}
          >
            Resend
          </Text>
        </div>
        {showCountDown && <Text>Resend OTP in ({countdown}s)</Text>}
      </div>
    </div>
  );
};

export default ForgotPasswordOtp;
