import { Button, Form, Input, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../../../layout/default/BackIcon';

//  styles
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../features/loginSlice';
import './forgotPassword.scss';

const ForgotPassword = ({ handleSubmit, loading, setForgotPassowordPhone, forgotPassowordPhone }) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  return (
    <div className="forgotPassword-box">
      <div className="forgotPassword-header">
        <BackIcon onClick={() => dispatch(setCurrentStep('LOGIN_FORM'))} className="backicon" />
        <h2 className="header-text">Forgot Password</h2>
      </div>
      <div>
        <Form
          className="forgotPassword-box__form"
          name="basic"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="mobileNumber"
            label="Registered Mobile Number"
            className="form-item"
            rules={[
              {
                message: 'Please input valid mobile no.',
                pattern: /^[0-9]{10}$/,
              },
              {
                required: true,
                message: 'Please enter Registered Mobile Number',
              },
            ]}
          >
            <Input
              onChange={(e) => setForgotPassowordPhone(e.target.value)}
              placeholder="Enter Registered Mobile Number"
              className="aadhar-input"
            />
          </Form.Item>

          <Form.Item className="mt-10">
            <Button
              disabled={forgotPassowordPhone?.length == 0 || !forgotPassowordPhone}
              loading={loading.registerLoading}
              type="primary"
              style={{
                background: '#023FAC',
                fontSize: '12px',
              }}
              block
              htmlType="submit"
            >
              Send
            </Button>
            <Text className="font14 fontLight d-flex jc-center mt-10">
              Don't have an account ?
              <Link to="/register" className="register-to__login">
                &nbsp;Register
              </Link>
            </Text>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
