import React from 'react';

const UnSubscribeIcon = ({ strokeColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.424"
      height="15.541"
      viewBox="0 0 18.424 20.541"
      style={{ marginRight: '5px' }}
    >
      <g id="Group_27332" data-name="Group 27332" transform="translate(-11.576 -4.116)">
        <g id="Illustration" transform="translate(-6.944 -11.879)">
          <g id="Notification" transform="translate(18.52 16.306)">
            <path
              id="Path_18491"
              data-name="Path 18491"
              d="M47.392,104.122a1.9,1.9,0,0,1-1.417-.633.272.272,0,1,1,.405-.363h0a1.353,1.353,0,0,0,1.914.108,1.144,1.144,0,0,0,.11-.108.271.271,0,1,1,.4.363h0A1.893,1.893,0,0,1,47.392,104.122Z"
              transform="translate(-39.58 -83.892)"
              fill="#ffa31d"
              stroke={strokeColor}
            />
            <path
              id="Path_18492"
              data-name="Path 18492"
              d="M32.792,43.114h-12.9a1.491,1.491,0,0,1-1.373-1.585h0v-.691a1.7,1.7,0,0,1,.4-1.122,4.076,4.076,0,0,0,.955-2.669V32.5c0-4.127,2.893-7.473,6.463-7.473s6.464,3.346,6.464,7.473v4.544a4.091,4.091,0,0,0,.957,2.669,1.7,1.7,0,0,1,.4,1.122v.691A1.489,1.489,0,0,1,32.792,43.114ZM26.334,25.852c-3.173.01-5.741,2.983-5.745,6.651v4.544A4.943,4.943,0,0,1,19.426,40.3a.8.8,0,0,0-.195.542v.691a.721.721,0,0,0,.662.763h12.9a.719.719,0,0,0,.66-.763v-.691a.8.8,0,0,0-.195-.542,4.954,4.954,0,0,1-1.163-3.249V32.5C32.091,28.828,29.513,25.851,26.334,25.852Z"
              transform="translate(-18.52 -24.208)"
              fill="#ffa31d"
              stroke={strokeColor}
            />
            <path
              id="Path_18493"
              data-name="Path 18493"
              d="M51.354,17.437a.347.347,0,0,1-.284-.146.7.7,0,0,0-.97-.155.776.776,0,0,0-.155.155.347.347,0,0,1-.491.082h0a.349.349,0,0,1-.082-.483,1.384,1.384,0,0,1,2.257,0,.349.349,0,0,1-.084.483A.36.36,0,0,1,51.354,17.437Z"
              transform="translate(-42.686 -16.618)"
              fill="#ffa31d"
              stroke={strokeColor}
            />
          </g>
        </g>
        <g
          id="Ellipse_1530"
          data-name="Ellipse 1530"
          transform="translate(21 6)"
          fill="#fff"
          stroke="#ffa31d"
          stroke-width="0.75"
        >
          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
          <circle cx="4.5" cy="4.5" r="4.125" fill="none" />
        </g>
        <path
          id="Path_18494"
          data-name="Path 18494"
          d="M115.935,52.843c-.036,0-.072,0-.108,0a1.105,1.105,0,0,1-.827-.5l-.928-1.433a.487.487,0,0,1,.409-.752.489.489,0,0,1,.41.222l.928,1.433a.133.133,0,0,0,.1.062h.013a.138.138,0,0,0,.1-.041l2.536-2.637a.487.487,0,1,1,.7.675L116.736,52.5a1.1,1.1,0,0,1-.8.341Z"
          transform="translate(-90.993 -40.442)"
          fill="#ffa31d"
        />
      </g>
    </svg>
  );
};

export default UnSubscribeIcon;
