import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import GroupCardCarousel from '../GroupCard/GroupCardCarousel';
import './allOtherGroups.scss';
import { RightOutlined } from '@ant-design/icons';
const AllOtherGroups = ({ groups, setJoinGroup }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      // <button onClick={() => onClick()}>
      <RightOutlined />
      // </button>
    );
  };
  return (
    <div className="allOtherGroups2">
      <div className="font24 fontDark-2">All Other Groups</div>
      {groups?.length > 0 ? (
        <Carousel infinite={true} autoPlay autoPlaySpeed={5000} responsive={responsive}>
          {groups?.map((group) => (
            <GroupCardCarousel setJoinGroup={setJoinGroup} data={group} />
          ))}
        </Carousel>
      ) : (
        <span>No Groups Found</span>
      )}
    </div>
  );
};

export default AllOtherGroups;
