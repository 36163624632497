import React from 'react';
import JoinGroupEventCard from '../common/JoinGroupEventCard';
import CommonButton from '../common/CommonButton';
import { useNavigate } from 'react-router';
import { nanoid } from '@reduxjs/toolkit';

const JoinEventAccordion = ({ events }) => {
  const navigate = useNavigate();

  const onSeeAllClick = () => {
    navigate('/user/socialApp/events');
  };
  return (
    <div className="d-flex d-column g-20 ">
      {events?.eventPosts?.map((elem) => (
        <JoinGroupEventCard key={nanoid()} data={elem} />
      ))}

      <span className="font16  fontExtraLight cursor-pointer" onClick={onSeeAllClick}>
        See All
      </span>
    </div>
  );
};

export default JoinEventAccordion;
