import React from 'react';

const RaiseIcon = ({ className, strokeColor, height = '15px', width = '15px' }) => {
  return (
    <svg
      id="Illustration"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.369 19"
      className={`raise-icon ${className}`}
    >
      <path
        id="Path_18071"
        data-name="Path 18071"
        d="M22.085,35.31H4.307a1.8,1.8,0,0,1-1.556-2.7l8.889-15.4a1.8,1.8,0,0,1,3.112,0L18.438,23.6a.417.417,0,0,1-.722.417L14.03,17.636a.962.962,0,0,0-1.668,0l-8.889,15.4a.964.964,0,0,0,.834,1.444H22.085a.964.964,0,0,0,.832-1.444L19.355,26.86a.414.414,0,0,1,.722-.407l3.564,6.171a1.8,1.8,0,0,1-1.556,2.7Z"
        transform="translate(-2.511 -16.32)"
        fill="#535353"
        stroke={strokeColor}
      />
      <path
        id="Path_18072"
        data-name="Path 18072"
        d="M51.259,61.127a.759.759,0,0,1-.759-.759V58.219a.759.759,0,0,1,1.518,0v2.146a.759.759,0,0,1-.759.762Zm0-3.188a.285.285,0,0,0-.285.285v2.14a.285.285,0,0,0,.571,0V58.219a.285.285,0,0,0-.285-.285Z"
        transform="translate(-40.478 -48.351)"
        fill="#535353"
        stroke={strokeColor}
      />
      <path
        id="Path_18073"
        data-name="Path 18073"
        d="M51.232,86.918a.759.759,0,1,1,.531-.222A.759.759,0,0,1,51.232,86.918Zm0-1.046a.285.285,0,1,0,.285.285A.285.285,0,0,0,51.232,85.872Z"
        transform="translate(-40.453 -70.921)"
        fill="#535353"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default RaiseIcon;
