import React, { useState } from 'react';

import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import './MyPosts.scss';
import GenericPost from './MyPostsTypes/GenericPost';
import ReportCardPost from './MyPostsTypes/ReportCardPost';
import MyAccountNewsCardPost from './MyPostsTypes/MyAccountNewsCardPost';
import AdPost from './MyPostsTypes/AdPost';
import EventPost from './MyPostsTypes/EventPost';
import LeadPost from './MyPostsTypes/LeadPost';
import JobPost from './MyPostsTypes/JobPost';
import PollCard from './MyPostsTypes/PollCard';
import { message } from 'antd';
import { undoRepostedCardPost } from '../../../features/socialAppSlice';

const MyPosts = ({ data, companyData, setAllPosts, isCompany, onDelete, userId, allPosts }) => {
  const dispatch = useDispatch();

  // states
  const [postData, setPostData] = useState(null);
  const [isVisiable, setIsVisiable] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const sanitizer = DOMPurify.sanitize;
  const handleCancel = () => {
    setIsVisiable(false);
  };

  const handleDeletPost = () => {
    onDelete(data._id);
  };

  const handleSeeMore = () => {
    setPostData(data);
    setIsVisiable(true);
  };

  const handleDeleteRepost = () => {
    const undoRepostPayload = {
      postId: data._id,
    };
    console.log('filter', allPosts);
    dispatch(undoRepostedCardPost(undoRepostPayload))?.then((res) => {
      message.success('Repost Deleted successfully...');

      setAllPosts((prev) => ({
        ...prev,
        posts: prev?.posts?.filter((post) => post._id !== data._id),
        totalRecords: prev?.totalRecords - 1,
      }));
    });
  };

  const cardTypeMap = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: MyAccountNewsCardPost,
    ad_card: AdPost,
    event_card: EventPost,
    lead_card: LeadPost,
    job_card: JobPost,
    poll_card: PollCard,
  };

  const PostComponent = cardTypeMap?.[data?.type];
  return (
    PostComponent && (
      <PostComponent
        handleCancel={handleCancel}
        handleSeeMore={handleSeeMore}
        data={data}
        sanitizer={sanitizer}
        handleDeletPost={handleDeletPost}
        postData={postData}
        isCompany={isCompany}
        setAllPosts={setAllPosts}
        setPostData={setPostData}
        user={user}
        isVisiable={isVisiable}
        companyData={companyData}
        handleDeleteRepost={handleDeleteRepost}
      />
    )
    // <div className="profile-layout" gutter={16}>
    //   <div className="imgcontainer position-relative">
    //     <img
    //       src={
    //         data?.imageUrls?.length > 0
    //           ? data?.imageUrls?.[0]?.url
    //           : 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai'
    //       }
    //       alt="Profile"
    //       className="profile-image"
    //     />
    //   </div>
    //   <div className="content-col" style={{ width: '100%' }}>
    //     <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
    //       <span className="d-flex g-15 a-center ">
    //         <Avatar src={user?.personalDetails?.imageUrl} icon={<UserOutlined />} />
    //         {isCompany ? (
    //           <div className="d-flex d-column">
    //             {companyData?.name && (
    //               <span className="font14 fontExtraLight ">{user?.professionalDetails?.companyName}</span>
    //             )}
    //             {companyData?.address && (
    //               <span className="font14 fontExtraLight ">
    //                 {companyData?.address?.city},{companyData?.address?.state}
    //               </span>
    //             )}
    //           </div>
    //         ) : (
    //           <div className="d-flex d-column">
    //             <Text style={{ fontWeight: '600', color: '#535353' }}>{user?.name}</Text>
    //             <div className="d-flex">
    //               {user?.professionalDetails?.designation && (
    //                 <Text
    //                   style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
    //                 >{`${user?.professionalDetails?.designation} @`}</Text>
    //               )}
    //               {user?.professionalDetails?.companyName && (
    //                 <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
    //                   {user?.professionalDetails?.companyName}
    //                 </Text>
    //               )}
    //             </div>
    //           </div>
    //         )}
    //       </span>
    //       <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }}>
    //         <DeleteOutlined onClick={handleDeletPost} />
    //       </div>
    //     </div>
    //     <Text id="post-title" style={{ fontWeight: '600', color: '#535353' }}>
    //       {truncate(data?.title, { length: 40 })}
    //     </Text>
    //     <Text id="post-body" style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}>
    //       {truncate(data?.body, { length: 142 })}
    //     </Text>
    //     <Text className="cursor-pointer" style={{ fontWeight: '600', color: '#0081FC' }} onClick={handleSeeMore}>
    //       See more
    //     </Text>
    //   </div>
    //   {postData && (
    //     <Modal
    //       styles={{
    //         mask: {
    //           backdropFilter: 'blur(6px)',
    //         },
    //       }}
    //       centered
    //       title={null}
    //       open={isVisiable}
    //       onCancel={handleCancel}
    //       footer={null}
    //       width={820}
    //     >
    //       <CommentComponent
    //         isUserPost={true}
    //         setAllPosts={setAllPosts}
    //         setPostData={setPostData}
    //         postDetails={postData}
    //         postId={postData?._id}
    //         userId={user?._id}
    //       />
    //     </Modal>
    //   )}
    // </div>
  );
};

export default MyPosts;
