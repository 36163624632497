import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './breadcrumbs.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addBreadcrumb, clearBreadcrumbs, truncateBreadcrumbs } from '../../../../features/searchSlice';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { breadcrumbs } = useSelector((state) => state.search);
  useEffect(() => {
    // Clear breadcrumbs when navigating to a new "start" route
    if (
      location.pathname.includes('/leadGen/connectedDetails') ||
      location.pathname.includes('/leadGen/propertyDetails') ||
      location.pathname.includes('/leadGen/organizationDetails') ||
      location.pathname.includes('/leadGen/connectedResidentialDetails') ||
      location.pathname.includes('/leadGen/occupantDetails') ||
      location.pathname.includes('/leadGen/unitDetails') ||
      location.pathname.includes('/leadGen/connectDetails')
    ) {
      // dispatch(clearBreadcrumbs());
      dispatch(addBreadcrumb({ name: 'Search', path: '/leadGen/search' }));
      dispatch(setSelectedNav('LeadGen'));
      return;
    }
    dispatch(clearBreadcrumbs());
  }, [location.pathname, dispatch]);

  const handleBreadcrumbClick = (breadcrumb) => {
    dispatch(truncateBreadcrumbs(breadcrumb.name));
    navigate(breadcrumb.path);
  };
  return (
    <nav className="breadcrumb-nav">
      <ul className="breadcrumb">
        {breadcrumbs?.map((breadcrumb, index) => {
          if (breadcrumb?.name) {
            return (
              <li
                key={breadcrumb.path}
                onClick={() => handleBreadcrumbClick(breadcrumb)}
                className={index === breadcrumbs.length - 1 ? 'breadcrumb-item active' : 'breadcrumb-item'}
              >
                {index === breadcrumbs.length - 1 ? (
                  <span>{breadcrumb.name}</span>
                ) : (
                  <Link onClick={() => handleBreadcrumbClick(breadcrumb)}>{breadcrumb.name}</Link>
                )}
                {index < breadcrumbs.length - 1 && <span className="breadcrumb-separator"> &gt; </span>}
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
