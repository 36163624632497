import React, { useState } from 'react';
import CommonButton from '../../common/CommonButton';
import { message, Popover, Tooltip } from 'antd';
import RepostIcon from '../../../../../assets/SocialApp/RepostIcon';
import { useDispatch } from 'react-redux';
import { repostCardPost, undoRepostedCardPost } from '../../../../features/socialAppSlice';
const RepostComponent = ({ reposted, setRepostCount, postId, sharedPost, setLoginPopup }) => {
  const dispatch = useDispatch();
  const [repostMenuOpen, setRepostMenuOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isReposted, setIsReposted] = useState(reposted);
  const handleMenuClick = (action) => {
    if (action === 'repost') {
      handleRepost(postId);
    } else {
      handleUndo(postId);
    }
  };
  const handleOpenMenuChange = (newOpen) => {
    setRepostMenuOpen(newOpen);
  };
  const handleRepost = (postId) => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    const repostPayload = {
      postId,
    };
    setLoader(true);
    dispatch(repostCardPost(repostPayload))?.then((res) => {
      setRepostCount(true);
      message.success('Reposted successfully...');
      setRepostMenuOpen(false);
      setLoader(false);
      setIsReposted(true);
    });
  };
  const handleUndo = (postId) => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    const undoRepostPayload = {
      postId,
    };
    setLoader(true);
    dispatch(undoRepostedCardPost(undoRepostPayload))?.then((res) => {
      setRepostCount(false);
      message.success('Undo Repost successfully...');
      setRepostMenuOpen(false);
      setLoader(false);
      setIsReposted(false);
    });
  };
  return (
    <Popover
      content={
        <CommonButton
          type="text"
          htmlType="submit"
          loader={loader}
          onClick={() => handleMenuClick(isReposted ? 'undo' : 'repost')}
        >
          {isReposted ? 'Undo' : 'Repost'}
        </CommonButton>
      }
      trigger="click"
      open={repostMenuOpen}
      onOpenChange={handleOpenMenuChange}
    >
      <span className="d-flex a-center">
        <Tooltip title="Repost">
          <span>
            <RepostIcon color={isReposted ? '#0081FC' : '#aaa9a9'} />
          </span>
        </Tooltip>
      </span>
    </Popover>
  );
};
export default RepostComponent;
