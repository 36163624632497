import React from 'react';
import './Address.scss';

const FormattedAddress = ({ address, className = '', color = '' }) => {
    if (!address || typeof address !== 'object') return null;
    const { street, locality, block, city, state, pincode } = address;
    const addressString = [street, locality, block, city, state, pincode].filter(Boolean).join(', ');

    const parts = addressString.split(new RegExp(`(${locality}|${city})`, 'i'));

    return (
        <div className="formatted-address">
            {parts.map((part, index) =>
                part.toLowerCase() === locality.toLowerCase() || part.toLowerCase() === city.toLowerCase() ? (
                    <span
                        key={index}
                        className={className}
                        style={{ color: color }}
                    >
                        {part}
                    </span>
                ) : (
                    <span key={index}>{part}</span>
                )
            )}
        </div>
    );
};

export default FormattedAddress;
