import { Card, Spin, Typography } from 'antd';
import { Outlet, useLocation } from 'react-router';
import man from '../../assets/man.png';
import './defaultLayout.scss';

const DefaultLayout = ({ loading }) => {
  const { Title } = Typography;
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/';
  const isRegisterPage = pathname === '/register';
  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <section className="page-container">
          <div className="page-container__left">
            <img src={man} width={'65%'} alt="" />
            <div className="left-item">
              {isLoginPage && (
                <>
                  <Title className="left-item__title">Welcome Back to TryThat.ai</Title>
                  <Title level={3} className="left-item__slogan">
                    Stay informed, stay ahead.
                  </Title>
                  <Title level={4} className="left-item__desc">
                    Thank you for being part of our community. We appreciate your continued trust and support. Stay
                    tuned for exciting new features and updates that will enhance your real estate experience.
                  </Title>
                </>
              )}
              {isRegisterPage && (
                <>
                  <Title className="left-item__title">Join TryThat.ai To Transform Your Real Estate Business</Title>
                  <Title level={4} className="left-item__desc">
                    Access your personalized dashboard, manage your leads, track your inventory, and harness the full
                    power of our AI-driven real estate solutions. Start making smarter business decisions today.
                  </Title>
                </>
              )}
            </div>
          </div>

          <div className="page-container__right">
            <div>
              <Card className="right-card">
                <Outlet />
              </Card>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DefaultLayout;
