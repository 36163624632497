export default function SubscriptionsIcon({ strokeColor }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 22">
    //   <g id="Group_27336" data-name="Group 27336" transform="translate(851.358 3077.985)">
    //     <path
    //       id="Path_84"
    //       data-name="Path 84"
    //       d="M24.3,19.4H4.5a.754.754,0,0,1-.729-.545.741.741,0,0,1,.325-.837,5.479,5.479,0,0,0,1.464-1.8A14.177,14.177,0,0,0,7.051,9.259,7.194,7.194,0,0,1,14.4,2.25a7.194,7.194,0,0,1,7.351,7.009,14.177,14.177,0,0,0,1.491,6.952,5.479,5.479,0,0,0,1.464,1.8.741.741,0,0,1,.325.837A.754.754,0,0,1,24.3,19.4ZM6.259,17.9H22.545c-1.039-1.418-2.292-4.075-2.292-8.638A5.694,5.694,0,0,0,14.4,3.75,5.694,5.694,0,0,0,8.551,9.259C8.551,13.822,7.3,16.478,6.259,17.9Z"
    //       transform="translate(-855.1 -3080.235)"
    //       fill="white"
    // stroke={strokeColor}
    // strokeLinecap="round"
    // strokeLinejoin="round"
    // strokeWidth="1.7"
    //     />
    //     <path
    //       id="Path_85"
    //       data-name="Path 85"
    //       d="M18.345,33.944a4.162,4.162,0,0,1-3.589-2.067.75.75,0,0,1,1.3-.753,2.649,2.649,0,0,0,4.583,0,.75.75,0,0,1,1.3.753A4.162,4.162,0,0,1,18.345,33.944Z"
    //       transform="translate(-859.044 -3089.075)"
    //       fill="white"
    //       stroke={strokeColor}
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //     />
    //     <g id="Group_27339" data-name="Group 27339" transform="translate(-859.399 -3082.879)">
    //       <g
    //         id="Ellipse_1530"
    //         data-name="Ellipse 1530"
    //         transform="translate(21 6)"
    //         fill="white"
    //         stroke={strokeColor}
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="1.5"
    //       >
    //         <circle cx="4.5" cy="4.5" r="4.5" stroke={strokeColor} />
    //         <circle cx="4.5" cy="4.5" r="4.125" fill="white" />
    //       </g>
    //       <path
    //         id="Path_18494"
    //         data-name="Path 18494"
    //         d="M115.935,52.843c-.036,0-.072,0-.108,0a1.105,1.105,0,0,1-.827-.5l-.928-1.433a.487.487,0,0,1,.409-.752.489.489,0,0,1,.41.222l.928,1.433a.133.133,0,0,0,.1.062h.013a.138.138,0,0,0,.1-.041l2.536-2.637a.487.487,0,1,1,.7.675L116.736,52.5a1.1,1.1,0,0,1-.8.341Z"
    //         transform="translate(-90.993 -40.442)"
    //         fill="white"
    //         stroke={strokeColor}
    //         strokeLinecap="round"
    //         strokeLinejoin="inherit"
    //         strokeWidth="1.5"
    //       />
    //     </g>
    //   </g>
    // </svg>

    <svg xmlns="http://www.w3.org/2000/svg" width="21.959" height="22.854" viewBox="0 0 21.959 22.854">
      <g id="Group_27336" data-name="Group 27336" transform="translate(851.358 3077.985)">
        <path
          id="Path_84"
          data-name="Path 84"
          d="M24.3,19.4H4.5a.754.754,0,0,1-.729-.545.741.741,0,0,1,.325-.837,5.479,5.479,0,0,0,1.464-1.8A14.177,14.177,0,0,0,7.051,9.259,7.194,7.194,0,0,1,14.4,2.25a7.194,7.194,0,0,1,7.351,7.009,14.177,14.177,0,0,0,1.491,6.952,5.479,5.479,0,0,0,1.464,1.8.741.741,0,0,1,.325.837A.754.754,0,0,1,24.3,19.4ZM6.259,17.9H22.545c-1.039-1.418-2.292-4.075-2.292-8.638A5.694,5.694,0,0,0,14.4,3.75,5.694,5.694,0,0,0,8.551,9.259C8.551,13.822,7.3,16.478,6.259,17.9Z"
          transform="translate(-855.1 -3080.235)"
          fill="#fff"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
        />
        <path
          id="Path_85"
          data-name="Path 85"
          d="M18.345,33.944a4.162,4.162,0,0,1-3.589-2.067.75.75,0,0,1,1.3-.753,2.649,2.649,0,0,0,4.583,0,.75.75,0,0,1,1.3.753A4.162,4.162,0,0,1,18.345,33.944Z"
          transform="translate(-859.044 -3089.075)"
          fill="#fff"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
        />
        <g id="Group_27339" data-name="Group 27339" transform="translate(-859.399 -3082.879)">
          <g
            id="Ellipse_1530"
            data-name="Ellipse 1530"
            transform="translate(21 6)"
            fill="#fff"
            stroke="#fff"
            stroke-width="0.75"
            // stroke={strokeColor}
          >
            <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
            <circle cx="4.5" cy="4.5" r="4.125" fill="none" />
          </g>
          <path
            id="Path_18494"
            data-name="Path 18494"
            d="M115.935,52.843c-.036,0-.072,0-.108,0a1.105,1.105,0,0,1-.827-.5l-.928-1.433a.487.487,0,0,1,.409-.752.489.489,0,0,1,.41.222l.928,1.433a.133.133,0,0,0,.1.062h.013a.138.138,0,0,0,.1-.041l2.536-2.637a.487.487,0,1,1,.7.675L116.736,52.5a1.1,1.1,0,0,1-.8.341Z"
            transform="translate(-90.993 -40.442)"
            fill="#0081fc"
          // stroke={strokeColor}

          />
        </g>
      </g>
    </svg>
  );
}
