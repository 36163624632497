import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { placeHolderImageUrl } from '../../../../constants/globalConstants';
import NoPost from '../../myAccount/Posts/NoPost';
import './viewProfile.scss';
const ViewProfileContent = ({ allPosts, handleScroll }) => {
  const navigate = useNavigate();
  return (
    <div className="profilePosts" id="">
      {allPosts?.posts?.length > 0 ? (
        <InfiniteScroll
          dataLength={allPosts?.posts?.length}
          next={handleScroll}
          hasMore={(allPosts?.posts?.length || 0) < (allPosts?.totalRecords || 0)}
          endMessage={<div className="text-center font18 p-10">No more posts</div>}
          loader={
            <span>
              <Spin className="w-100 d-flex jc-center" indicator={<LoadingOutlined />} />
            </span>
          }
          scrollableTarget="profilePosts"
        >
          <div className="profileContent" id="profilePosts">
            {allPosts?.posts?.map((data, index) => (
              <div
                onClick={() => navigate(`/user/socialApp/postDetails/${data?._id}/${data?.ownerType}`)}
                key={index}
                className="profileContentCard"
              >
                <img alt="" src={data?.imageUrls?.[0]?.url || placeHolderImageUrl} />
                <div className="cardOverlay">
                  <span className="cardText cursor-pointer ">{data?.title}</span>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <NoPost />
      )}
    </div>
  );
};

export default ViewProfileContent;
