import React from 'react';

const JobPostIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg
      id="Job_Card"
      data-name="Job Card"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <path
        id="Path_17619"
        data-name="Path 17619"
        d="M32.1,40.78a.514.514,0,0,1-.408-.188L28.32,37.007l-.765.816a.552.552,0,0,1-.4.18.529.529,0,0,1-.148-.02l-.016,0-.015-.006a.628.628,0,0,1-.377-.442l-.311-1.417H10.508A2.6,2.6,0,0,1,8,33.437v-17a2.59,2.59,0,0,1,2.508-2.66H26.436a2.59,2.59,0,0,1,2.508,2.66v14.6l3.023.755a.58.58,0,0,1,.411.438.664.664,0,0,1-.151.6l-.763.813,3.378,3.59a.667.667,0,0,1,0,.861L32.507,40.6A.553.553,0,0,1,32.1,40.78Zm0-1.48,1.526-1.628-3.378-3.589a.65.65,0,0,1-.169-.457.59.59,0,0,1,.169-.407l.442-.481-4.211-1.049,1,4.491.428-.457a.518.518,0,0,1,.415-.2.512.512,0,0,1,.408.188L32.1,39.3ZM9.155,33.437A1.411,1.411,0,0,0,10.508,34.9h15.51L25.163,31a.609.609,0,0,1,.154-.57.547.547,0,0,1,.4-.177.537.537,0,0,1,.132.017l1.938.484v-12.1H9.155V33.435Zm18.633-16V16.44A1.4,1.4,0,0,0,26.435,15H10.508A1.4,1.4,0,0,0,9.155,16.44v.994Z"
        transform="translate(-8 -13.78)"
        fill={color}
        fill-rule="evenodd"
      />
      <g id="Group_25052" data-name="Group 25052" transform="translate(2.386 1.705)">
        <g id="Group_25049" data-name="Group 25049" transform="translate(0 0)">
          <path
            id="Path_17620"
            data-name="Path 17620"
            d="M24.291,26.289a.661.661,0,0,1-.661-.661.676.676,0,0,1,.661-.678h1.426a.67.67,0,0,1,.678.678.66.66,0,0,1-.678.661Z"
            transform="translate(-23.63 -24.95)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_17621"
            data-name="Path 17621"
            d="M27.4,27.678H25.976a.359.359,0,0,1-.356-.356.363.363,0,0,1,.356-.372H27.4a.364.364,0,1,1,0,.728Z"
            transform="translate(-25.316 -26.645)"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
        <g id="Group_25050" data-name="Group 25050" transform="translate(2.865 0)">
          <path
            id="Path_17622"
            data-name="Path 17622"
            d="M43.078,26.289a.67.67,0,0,1-.678-.661.68.68,0,0,1,.678-.678h1.409a.67.67,0,0,1,.678.678.66.66,0,0,1-.678.661Z"
            transform="translate(-42.4 -24.95)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_17623"
            data-name="Path 17623"
            d="M46.181,27.678H44.772a.364.364,0,1,1,0-.728h1.409a.364.364,0,1,1,0,.728Z"
            transform="translate(-44.095 -26.645)"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
        <g id="Group_25051" data-name="Group 25051" transform="translate(5.748 0)">
          <path
            id="Path_17624"
            data-name="Path 17624"
            d="M61.951,26.289a.661.661,0,0,1-.661-.661.676.676,0,0,1,.661-.678h1.426a.67.67,0,0,1,.678.678.66.66,0,0,1-.678.661Z"
            transform="translate(-61.29 -24.95)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_17625"
            data-name="Path 17625"
            d="M65.071,27.678H63.646a.359.359,0,0,1-.356-.356.363.363,0,0,1,.356-.372h1.426a.364.364,0,1,1,0,.728Z"
            transform="translate(-62.985 -26.645)"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
      </g>
      <g id="Group_25053" data-name="Group 25053" transform="translate(3.126 6.345)">
        <path
          id="Path_17626"
          data-name="Path 17626"
          d="M92.411,60.262a.6.6,0,0,1-.6-.6V54.591a.6.6,0,0,1,.6-.6h1.264a1.867,1.867,0,0,1,1.563,2.883,1.866,1.866,0,0,1-1.078,3.389Zm1.749-1.186a.678.678,0,1,0,0-1.357H93v1.357Zm-.485-2.541a.681.681,0,0,0,.679-.681.663.663,0,0,0-.679-.661H93v1.342Z"
          transform="translate(-82.143 -53.99)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="Path_17627"
          data-name="Path 17627"
          d="M61.182,60.27a2.036,2.036,0,0,1-2.042-2.026v-2.2A2.044,2.044,0,0,1,61.182,54h.211a2.036,2.036,0,0,1,2.026,2.042v2.2a2.028,2.028,0,0,1-2.026,2.026Zm0-5.069a.832.832,0,0,0-.841.841v2.2a.842.842,0,0,0,.841.841h.211a.852.852,0,0,0,.841-.841v-2.2a.842.842,0,0,0-.841-.841h-.211Z"
          transform="translate(-54.46 -53.998)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="Path_17628"
          data-name="Path 17628"
          d="M30.344,60.355a1.866,1.866,0,0,1-1.864-1.864.6.6,0,1,1,1.2,0,.691.691,0,0,0,.662.662.672.672,0,0,0,.662-.662V54.7a.6.6,0,1,1,1.2,0v3.79a1.866,1.866,0,0,1-1.864,1.864Z"
          transform="translate(-28.48 -54.083)"
          fill={color}
          fill-rule="evenodd"
        />
      </g>
      <path
        id="Path_17629"
        data-name="Path 17629"
        d="M29.6,103.261a.6.6,0,1,1,0-1.2H42.4a.6.6,0,0,1,0,1.2Z"
        transform="translate(-25.795 -88.221)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_17630"
        data-name="Path 17630"
        d="M29.6,118.1a.587.587,0,0,1-.6-.585.6.6,0,0,1,.6-.6H42.4a.587.587,0,0,1,.585.6.576.576,0,0,1-.585.585Z"
        transform="translate(-25.795 -100.743)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_17631"
        data-name="Path 17631"
        d="M29.6,132.853a.6.6,0,1,1,0-1.2H42.4a.6.6,0,0,1,.585.6.593.593,0,0,1-.585.6Z"
        transform="translate(-25.795 -113.172)"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default JobPostIcon;
