import { Avatar, Progress } from 'antd';
import React from 'react';
import AvatarGroup from './AvatarGroup';
import { AVATAR_IMAGES } from './pollCard.utils';
import { isArray } from 'lodash';
import './pollCardPost.scss';
import { nanoid } from '@reduxjs/toolkit';
import { UserOutlined } from '@ant-design/icons';
const PollProgress = ({ responses = [], viewPost, showAvatar = false }) => {
  return (
    <div className="d-flex d-column g-20">
      {((isArray(responses) ? responses : responses?.options) || [])?.map((res) => (
        <div className="d-flex d-column g-5">
          <div className="d-flex jc-between">
            <div className={`${viewPost ? 'w-100' : 'w-80'} position-relative d-flex g-10 a-center`}>
              {/* <span>{res?.votePercentage || 0}%</span> */}
              <span className={`d-flex g-10 a-center  ${viewPost ? 'w-100' : 'w-80'}`}>
                {/* <Progress
                  percentPosition={{ align: 'start', type: 'outer' }}
                  showInfo={true}
                  strokeColor="#0081FC"
                  style={{ borderRadius: '4px' }}
                  percent={res?.votePercentage}
                /> */}
                <span className="fontDark font14" style={{ width: 40 }}>
                  {' '}
                  {res?.votePercentage}%
                </span>
                <div className="progressOuter w-100">
                  <span className="percent_title p-10" style={{ color: res?.votePercentage == 0 ? 'black' : 'white' }}>
                    {' '}
                    {res?.title}
                  </span>
                  <div className="progressInner" style={{ width: `${res?.votePercentage}%` }}></div>
                </div>
                {/* <span style={{ color: !!!res?.votePercentage && '#535353' }} className="progress_title">
                  {res?.title}
                </span> */}
              </span>
            </div>
          </div>
          {showAvatar && (
            <div className="d-flex g-5 pl-40">
              {[...new Array(res?.totalVotes || 0)]?.map((elem, index) => (
                <Avatar style={{ filter: 'blur(6px)' }} key={nanoid()} icon={<UserOutlined />} />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PollProgress;
