export default function LockedIcon({ fillColor, strokeColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.286" height="20" viewBox="0 0 14.286 20">
      <g id="Group_27314" data-name="Group 27314" transform="translate(0.5 0.5)">
        <path
          id="Union_5"
          data-name="Union 5"
          d="M1.429,20A1.43,1.43,0,0,1,0,18.572v-10A1.43,1.43,0,0,1,1.429,7.142H2.857V4.285a4.286,4.286,0,0,1,8.572,0V7.142h1.428a1.43,1.43,0,0,1,1.429,1.429v10A1.43,1.43,0,0,1,12.858,20Zm0-1.428H12.858v-10H1.427ZM4.286,4.285V7.142H10V4.285a2.858,2.858,0,0,0-5.715,0ZM5,13.571a2.143,2.143,0,1,1,2.143,2.143A2.145,2.145,0,0,1,5,13.571Z"
          transform="translate(-0.5 -0.5)"
          fill="#ffa31d"
        />
      </g>
    </svg>
  );
}
