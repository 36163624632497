import { EmailShareButton, LinkedinShareButton } from 'react-share';
import { Modal } from 'antd';
import shareEmail from '../../assets/images/shareEmail.svg';
import copyLink from '../../assets/images/copyLink.svg';
import linkedinShare from '../../assets/images/linkedinShare.png';
import './shareModal.scss';
import { copyToClipboard } from './shareModal.utils';

export function ShareModal({ showShareModal, shareUrl, setShowShareModal }) {
  return (
    <Modal
      styles={{
        mask: {
          backdropFilter: 'blur(6px)',
        },
      }}
      width={400}
      centered
      footer={false}
      open={showShareModal}
      onCancel={() => setShowShareModal(false)}
    >
      <span style={{ color: '#717171' }}>Share</span>
      <div className="Demo__container">
        <div className="Demo__some-network">
          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <img src={linkedinShare} />
          </LinkedinShareButton>
          Linked In
        </div>

        <div className="Demo__some-network">
          <EmailShareButton url={shareUrl} className="Demo__some-network__share-button">
            <img src={shareEmail} />
          </EmailShareButton>
          E-Mail
        </div>
        <div className="Demo__some-network">
          <img src={copyLink} onClick={() => copyToClipboard(shareUrl)} />
          Copy link
        </div>
      </div>
    </Modal>
  );
}
