import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import Text from 'antd/es/typography/Text';
import locationSVG from '../../../assets/images/address-location.svg';
import FormattedAddress from './FormattedAddress';
import './Address.scss';

const Address = ({ addressInfo, className, color }) => {
  return (
    <Fragment>
      <Text className="address-head">Address</Text>
      <Row className="address-container__items">
        <Col span={2}>
          <img src={locationSVG} alt="location" className="items-img" />
        </Col>
        <Col span={20}>
          <FormattedAddress address={addressInfo} className={className} color={color} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Address;
