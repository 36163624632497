import { UserOutlined } from '@ant-design/icons';
import { Avatar, message, Tooltip } from 'antd';
import React, { useState } from 'react';
import CommonButton from '../../common/CommonButton';
import { truncate, upperFirst } from 'lodash';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import PostHeaderKeababMenu from './PostHeaderKeababMenu/PostHeaderKeababMenu';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { reportSocialPost } from '../../../../features/socialAppSlice';
const PostHeader = ({
  postDetails,
  showKebabMenu = true,
  isUserPost,
  handleFollow,
  handleUnFollow,
  showFollowButton = true,
  isCompanyPost = false,
  setLoginPopup,
}) => {
  // hooks
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const sharedPost = params?.get('sharedPost');
  const navigate = useNavigate();
  const { isAbove1320, isAbove1920 } = useWindowSize();
  const onFollowUser = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    setLoader(true);
    handleFollow(postDetails?.ownerId)?.then((res) => {
      setLoader(false);
    });
  };

  const onUnFollowUser = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    setLoader(true);
    handleUnFollow(postDetails?.ownerId)?.then((res) => {
      setLoader(false);
    });
  };

  const onAvatarClick = () => {
    // navigateTo('123');
  };

  function formatDateWithoutDay(isoString) {
    if (isoString === '') {
      return null;
    }
    const date = new Date(isoString);
    const optionsDate = { month: 'short', day: '2-digit', year: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true };

    // Format date and time
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return { formattedDate, formattedTime };
  }

  const formattedDate = formatDateWithoutDay(postDetails?.createdAt || '');

  const onAccountDetailsCLick = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    navigate(`/user/socialApp/userProfile/${postDetails?.ownerId}?userType=${postDetails?.ownerType}`);
  };
  return (
    <div className="d-flex jc-between ">
      <span className="d-flex g-10 a-start ">
        <Avatar
          className="avatar"
          onClick={onAvatarClick}
          src={postDetails?.ownerDetails?.personalDetails?.imageUrl || ''}
          icon={<UserOutlined />}
        />
        <div className="d-flex d-column">
          <span className="font14 font600 fontLight">{upperFirst(postDetails?.ownerDetails?.name)}</span>
          {postDetails?.ownerType == 'company_post' ? (
            <div className="d-flex g-5 a-center">
              {postDetails?.ownerDetails?.address && (
                <span className="font14 fontExtraLight ">
                  {postDetails?.ownerDetails?.address?.city},{postDetails?.ownerDetails?.address?.state}
                </span>
              )}
            </div>
          ) : (
            <div className="d-flex g-5 a-center">
              {postDetails?.ownerDetails?.professionalDetails?.designation && (
                <span className="font12 fontExtraLight ">
                  {upperFirst(postDetails?.ownerDetails?.professionalDetails?.designation)}
                </span>
              )}
              {postDetails?.ownerDetails?.professionalDetails?.companyName && (
                <span className="font12 fontExtraLight ">
                  @
                  <Tooltip title={postDetails?.ownerDetails?.professionalDetails?.companyName}>
                    {truncate(postDetails?.ownerDetails?.professionalDetails?.companyName, {
                      length: isAbove1920 ? 100 : isAbove1320 ? 30 : 10,
                    })}
                  </Tooltip>
                </span>
              )}
            </div>
          )}
          {formattedDate && (
            <span className="d-flex font10 fontExtraLight g-5">
              {formattedDate.formattedDate}
              <li>{formattedDate.formattedTime}</li>
            </span>
          )}
        </div>
      </span>
      <div className="d-flex a-start g-20">
        {postDetails?.ownerId != user?._id && !isUserPost && showFollowButton && (
          <span className="d-flex g-15 a-center">
            <CommonButton
              loader={loader}
              onClick={() => {
                postDetails.isFollowed ? onUnFollowUser() : onFollowUser();
              }}
              ghost
            >
              {postDetails.isFollowed ? 'Unfollow' : 'Follow'}
            </CommonButton>
          </span>
        )}
        {showKebabMenu && postDetails?.ownerType != 'company_post' && (
          <PostHeaderKeababMenu
            onReportAccountClick={() => {
              dispatch(
                reportSocialPost({
                  postId: postDetails?._id,
                }),
              )?.then((res) => {
                if (res) {
                  message.success('Post reported successfully');
                }
              });
            }}
            onAccountDetailsCLick={onAccountDetailsCLick}
          />
        )}
      </div>
    </div>
  );
};

export default PostHeader;
