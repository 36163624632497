import { FilterOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Divider, message, Popover, Radio, Tooltip } from 'antd';
import { capitalize, truncate } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSocialLeads, unlockLead } from '../../../features/socialAppSlice';
import { engagementType as engagementTypes } from '../posts/addPostForms/RequirementCardForm/requirementForm.utils';
import './leadCard.scss';
import useOutsideHandler from '../../../../utils/useOutsideClickHandeler/useOutsideClickHandeler';
import { fetchUserPoints } from '../../../features/userOnboardingSlice';

const LeadCards = () => {
  const dispatch = useDispatch();
  const imageUrls = [1, 2, 3, 4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [leads, setLeads] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [leadType, setLeadType] = useState(0);
  const [engagementType, setEngagementType] = useState(null);
  const [offset, setoffSet] = useState(0);
  const [appliedFilter, setAppliedFilter] = useState(0);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const refLead = useRef();

  // useOutsideHandler(ref, open, () => setOpen(false));
  // useOutsideHandler(refLead, open, () => setOpen(false));

  const handleFetchMore = () => {
    dispatch(getSocialLeads({ leadType: leadType, engagementType: engagementType, offset: offset + 10 }))?.then(
      (res) => {
        const data = res?.payload?.data?.response?.data;
        if (data) {
          setLeads((prev) => [...(prev || []), ...(data?.leads || [])]);
          setTotalCount(data?.totalCount);
        }
      },
    );
    setoffSet((prev) => prev + 10);
  };

  useEffect(() => {
    dispatch(getSocialLeads({ leadType: leadType, engagementType: engagementType, offset: offset }))?.then((res) => {
      const data = res?.payload?.data?.response?.data;
      if (data) {
        setLeads(data?.leads);
        setTotalCount(data?.totalCount);
      }
    });
  }, []);

  const onApplyFilter = () => {
    setOpen(false);
    setCurrentIndex(0);
    setAppliedFilter({
      leadType: leadType,
      engagementType: engagementType,
    });
    dispatch(getSocialLeads({ leadType: leadType, engagementType: engagementType, offset: 0 }))?.then((res) => {
      const data = res?.payload?.data?.response?.data;
      if (data) {
        setLeads(data?.leads?.length > 0 ? data?.leads : []);
        setTotalCount(data?.leads?.length > 0 ? data?.totalCount : 0);
      } else {
        setTotalCount(0);
        setLeads([]);
      }
    });
  };
  const onClear = () => {
    setOpen(false);
    setCurrentIndex(0);

    setAppliedFilter({
      leadType: null,
      engagementType: null,
    });
    dispatch(getSocialLeads({ leadType: null, engagementType: null, offset: 0 }))?.then((res) => {
      const data = res?.payload?.data?.response?.data;
      if (data) {
        setLeads(data?.leads?.length > 0 ? data?.leads : []);
        setTotalCount(data?.leads?.length > 0 ? data?.totalCount : 0);
      } else {
        setTotalCount(0);
        setLeads([]);
      }
    });
  };

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
  }, [imageUrls.length]);

  const goToNext = () => {
    setCurrentIndex((prev) => {
      if (prev == leads?.length - 2 && leads?.length < totalCount) {
        handleFetchMore();
        return prev + 1;
      } else {
        if (prev == totalCount - 1 && leads?.length == totalCount) {
          return 0;
        }
        return prev + 1;
      }
    });
  };

  const goToIndex = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  const Filters = () => {
    return (
      <div style={{ width: 200 }} className="leadCardPopover d-flex d-column g-10 fontLight font16">
        <span>Select your preference for Leads</span>
        <Radio.Group
          value={leadType}
          onChange={(value) => setLeadType(value?.target?.value)}
          className="d-flex d-column g-5 font16"
        >
          <Radio value="residential">Residential</Radio>
          <Radio value="commercial">Commercial</Radio>
        </Radio.Group>
        <Divider />
        <Radio.Group
          value={engagementType}
          onChange={(value) => setEngagementType(value?.target?.value)}
          className="font16"
        >
          <Radio value={null}>All</Radio>
          <div className="d-flex flex-wrap g-5">
            {engagementTypes?.map((elem) => {
              return <Radio value={elem?.label?.toLowerCase()}>{elem?.label}</Radio>;
            })}
          </div>
        </Radio.Group>
        <div className="d-flex jc-between">
          <div
            style={{ textDecoration: 'underline' }}
            onClick={onClear}
            className="fontBlue cursor-pointer  font16 text-right"
          >
            Clear Filter{' '}
          </div>
          <div
            style={{ textDecoration: 'underline' }}
            onClick={onApplyFilter}
            className="fontBlue cursor-pointer  font16 text-right"
          >
            Apply Filter{' '}
          </div>
        </div>
      </div>
    );
  };

  const unlock = (payload, id) => {
    dispatch(unlockLead(payload))?.then((res) => {
      const data = res?.payload?.data?.response?.data;
      if (data) {
        dispatch(fetchUserPoints());

        const updatedLead = leads?.map((elem) => {
          if (elem?._id == id) {
            elem = { ...elem, userDetails: data?.ownerDetails, isUnlocked: true };
          }
          return elem;
        });
        setLeads(updatedLead);
      } else {
        message?.error(capitalize(res?.payload?.message));
      }
    });
  };

  const LeadCard = ({ data }) => {
    return (
      <div style={{}} className="font12 fontWhite d-flex leadCardContainer d-column jc-between g-20">
        <div className="d-flex g-20 jc-between">
          <div className="font16">Looking for</div>
          <div>Unlock for 100 Points</div>
        </div>
        <div className="d-flex g-20 ">
          <div className="tag">{capitalize(data?.postDetails?.engagementType)}</div>
          <div className="tag">{capitalize(data?.postDetails?.leadType)}</div>
        </div>
        {data?.postDetails?.location && (
          <div>
            <div>Location Preference</div>
            <div>{data?.postDetails?.location}</div>
          </div>
        )}
        {data?.postDetails?.body && (
          <div>
            <div>Details</div>

            <Tooltip
              placement="bottomLeft"
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.postDetails?.body,
                  }}
                ></div>
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: truncate(data?.postDetails?.body, { length: 100 }),
                }}
              ></div>
            </Tooltip>
          </div>
        )}
        {((data?.postDetails?.budgetRange?.lowerLimit && data?.postDetails?.budgetRange?.upperLimit) || '') && (
          <div className="d-flex g-20 jc-between">
            <div>
              <div>Budget Range</div>
              <div>
                ₹{data?.postDetails?.budgetRange?.lowerLimit}-₹{data?.postDetails?.budgetRange?.higherLimit}
              </div>
            </div>
            <div className="width-max-content">
              <div>Open to brokers</div>
              <div>{data?.postDetails?.isOpenToBroker ? 'Yes' : 'No'}</div>
            </div>
          </div>
        )}
        <div
          className="position-relative"
          onClick={() =>
            data?.isUnlocked
              ? {}
              : unlock(
                  {
                    postId: data?._id,
                    ownerId: data?.ownerId,
                    ownerType: data?.ownerType,
                  },
                  data?._id,
                )
          }
        >
          <div className={`bottomFooter ${!data?.isUnlocked && 'isLocked'}`}>
            <span>Name: {data?.userDetails?.name}</span>
            <span>Email: {data?.userDetails?.email}</span>
            <span>Phone: {data?.userDetails?.phone}</span>
          </div>
          <span className="lockIcon">
            {data?.isUnlocked ? (
              <UnlockOutlined />
            ) : (
              <LockOutlined
              // onClick={() =>
              //   unlock(
              //     {
              //       postId: data?._id,
              //       ownerId: data?.ownerId,
              //       ownerType: data?.ownerType,
              //     },
              //     data?._id,
              //   )
              // }
              />
            )}
          </span>
        </div>
      </div>
    );
  };
  const NoLeadFound = () => {
    return (
      <div
        style={{ height: 500 }}
        ref={refLead}
        className="font18 fontWhite d-flex leadCardContainer d-column jc-between a-center g-20"
      >
        No Leads Found
      </div>
    );
  };
  return (
    <div className="socialAppContainer__rightContainer">
      <span className="font18 font500 mb-10 d-flex jc-between">
        Leads
        <div ref={ref} className="d-flex g-10">
          <div className="d-flex g-5">
            <div className="filterTag">{capitalize(appliedFilter?.leadType) || 'All'}</div>
            <div className="filterTag">{capitalize(appliedFilter?.engagementType) || 'All'}</div>
          </div>
          <Popover
            open={open}
            onOpenChange={(open) => {
              setOpen(open);
              setLeadType(appliedFilter?.leadType);
              setEngagementType(appliedFilter?.engagementType);
            }}
            placement="bottomRight"
            content={Filters}
            trigger={'click'}
          >
            <FilterOutlined />
          </Popover>
        </div>
      </span>
      <div style={{ flex: 3, padding: 0 }}>
        <div className={`carousel w-100`}>
          <div
            className="carousel-inner"
            style={{ transform: `translateX(-${currentIndex * 100}%)`, height: 'inherit' }}
          >
            {leads?.length > 0 ? (
              leads?.map((lead, index) => (
                <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                  <LeadCard data={lead} />
                </div>
              ))
            ) : (
              <NoLeadFound />
            )}
          </div>
          {/* {leads.length > 1 && (
            <div className="carousel-dots">
              {leads.map((url, index) => (
                <button
                  key={index}
                  className={`dot ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => goToIndex(index)}
                ></button>
              ))}
            </div>
          )} */}
          {leads.length > 1 && currentIndex > 0 && (
            <button className="carousel-control prev" onClick={goToPrevious}>
              &#10094;
            </button>
          )}
          {currentIndex < totalCount - 1 && (
            <button className="carousel-control next" onClick={goToNext}>
              &#10095;
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default LeadCards;
