import React from 'react';

const BackIcon = ({ onClick, className }) => {
  return (
    <svg
      id="Component_30_5"
      data-name="Component 30 – 5"
      xmlns="http://www.w3.org/2000/svg"
      width="25.868"
      height="25.868"
      viewBox="0 0 40.868 40.868"
      onClick={onClick}
      className={className} 
    >
      <path
        id="Path_15427"
        data-name="Path 15427"
        d="M20.434,0A20.434,20.434,0,1,1,0,20.434,20.434,20.434,0,0,1,20.434,0Z"
        fill="#f2f2f2"
        opacity="0.5"
      />
      <path
        id="Icon_ionic-ios-arrow-back"
        data-name="Icon ionic-ios-arrow-back"
        d="M14.692,16.175l7.555-7.549a1.427,1.427,0,0,0-2.021-2.015l-8.56,8.554a1.424,1.424,0,0,0-.042,1.968l8.6,8.614a1.427,1.427,0,1,0,2.021-2.015Z"
        transform="translate(2.475 4.256)"
        fill="#535353"
      />
    </svg>
  );
};

export default BackIcon;
