import { CloseCircleFilled, PlayCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import PlayIcon from '../../../../../../assets/SocialApp/PlayIcon';
const NewsCardVideoComponent = ({ postDetails }) => {
  const videoLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'video')?.url;
  const imageLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'image')?.url;
  const [showVideoModal, setShowVideoModal] = useState(false);
  const playerRef = useRef();

  useEffect(() => {
    const internalPlayer = playerRef?.current ? playerRef.current.getInternalPlayer() : null;
    if (showVideoModal) {
      internalPlayer?.playVideo();
      internalPlayer?.seekTo(0);
    } else {
      internalPlayer?.pauseVideo();
    }
  }, [showVideoModal]);
  return (
    <div>
      <div className="news_card_post_audio_video">
        <img src={imageLink} />
        <span onClick={() => setShowVideoModal(true)} className="playIcon">
          <PlayIcon />
        </span>
      </div>
      <Modal
        className="modalAudioVideo"
        onCancel={() => {
          setShowVideoModal(false);
          playerRef.current.getInternalPlayer().pauseVideo();
        }}
        maskClosable
        width={1000}
        footer={null}
        closeIcon={<CloseCircleFilled className="font20 fontWhite" />}
        open={showVideoModal}
        centered
      >
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width={1000}
          height={700}
          playing={showVideoModal}
          url={videoLink}
        />
      </Modal>
    </div>
  );
};

export default NewsCardVideoComponent;
