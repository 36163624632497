export default function AdPlaceHolder() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118">
        <g id="Icon_akar-image" data-name="Icon akar-image" transform="translate(-1.5 -1.5)">
          <path id="Path_18053" data-name="Path 18053" d="M3,26A23,23,0,0,1,26,3H95a23,23,0,0,1,23,23V95a23,23,0,0,1-23,23H26A23,23,0,0,1,3,95Z" fill="none" stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <path id="Path_18054" data-name="Path 18054" d="M37.75,23.375A14.375,14.375,0,1,1,23.375,9,14.375,14.375,0,0,1,37.75,23.375Z" transform="translate(17 17)" fill="none" stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <path id="Path_18055" data-name="Path 18055" d="M58.024,21.713,9,75.642H78.765A22.235,22.235,0,0,0,101,53.407v-.765c0-2.679-1.006-3.709-2.818-5.693L75.01,21.679a11.5,11.5,0,0,0-16.985.035Z" transform="translate(17 42.358)" fill="none" stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>      
    );
  }
  