import React from 'react';
import { useState, useEffect } from 'react';
import { Layout, Row, Col, Space, Typography, Form, Select, Tag, Input, Button, Flex } from 'antd';
import './aboutcompany.scss';
import { isEmpty } from 'lodash';
import { FormOutlined, MailFilled, PhoneFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  userOnboardingGetCompanyDirectors,
  userOnboardingUpdateCompanyDirector,
} from '../../../features/userOnboardingSlice';

const DirectorDetails = ({ directorDetails }) => {
  const { Text } = Typography;
  const [isFormEditable, setIsFormEditable] = useState(false);
  const dispatch = useDispatch();

  const [directorName, setDirectorName] = useState('');
  const [directorContactNo, setDirectorContactNo] = useState('');
  const [directorEmail, setDirectorEmail] = useState('');

  useEffect(() => {
    if (isEmpty(directorDetails)) return;
    setDirectorName(directorDetails?.directorName);
    setDirectorContactNo(directorDetails?.contactNumber);
    setDirectorEmail(directorDetails?.emailAddress);
  }, [directorDetails]);

  const onFinish = (e) => {
    const payload = {
      directorId: directorDetails?.directorId,
      body: {
        DIN: directorDetails?.din,
        FirstName: directorName?.split(' ')?.[0],
        LastName: directorName?.split(' ')?.[directorName?.split(' ')?.length - 1],
        phone: directorContactNo,
        email: directorEmail,
      },
    };
    dispatch(userOnboardingUpdateCompanyDirector(payload));
    setIsFormEditable(false);
  };

  return (
    <Space
      style={{
        backgroundColor: '#e6f4ff',
        padding: '20px',
        borderRadius: '10px',
        width: '100%',
      }}
      direction="vertical"
    >
      {isFormEditable ? (
        <>
          <Flex vertical gap={16}>
            <div>
              <Typography.Text level={4}>Name</Typography.Text>
              <Input
                name="directoreName"
                placeholder="Enter Name"
                value={directorName}
                onChange={(e) => setDirectorName(e?.target?.value)}
              />
            </div>
            <div>
              <Typography.Text level={4}>Contact Number</Typography.Text>
              <Input
                name="contactNumber"
                placeholder="Enter Contact Number"
                value={directorContactNo}
                onChange={(e) => setDirectorContactNo(e?.target?.value)}
              />
            </div>
            <div>
              <Typography.Text level={4}>Email</Typography.Text>
              <Input
                name="email"
                placeholder="Enter Email"
                value={directorEmail}
                onChange={(e) => setDirectorEmail(e?.target?.value)}
              />
            </div>
            <div>
              <Button type="primary" onClick={onFinish} style={{ marginBottom: '10px' }}>
                Save
              </Button>
            </div>
          </Flex>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text className="text-name bold">{directorDetails?.directorName}</Text>
            <FormOutlined className="text-name" onClick={() => setIsFormEditable(true)} />
          </div>
          <Text className="text" underline>
            <PhoneFilled /> <span className="contact">{directorDetails?.contactNumber}</span>
          </Text>
          <Text className="text" underline>
            <MailFilled /> <span className="contact">{directorDetails?.emailAddress}</span>
          </Text>
        </>
      )}
    </Space>
  );
};

export default DirectorDetails;
