// react imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// library imports
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Card, Col, Pagination, Row, Space, Table } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import './transactionalDetails.scss';
// action imports
import { fetchPaymentDetails, fetchTransactionDetails } from '../../../features/myAccountSlice';
import { paymentStatusMaps } from './TransactionDetailUtil';

// Point detail column
const pointsTablecolumns = [
  {
    title: 'Items',
    dataIndex: 'itemUnlocked',
    key: 'Items',
  },
  {
    title: 'Points Utilized',
    dataIndex: 'pointUtilised',
    key: 'PointsUtilized',
  },
  {
    title: 'Purchase Date',
    dataIndex: 'purchaseDate',
    key: 'PurchaseDate',
    render: (text, record) => <span>{text && dayjs(text)?.format('DD-MM-YYYY')}</span>,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'Category',
  },
];

// payment detail column

const paymentTablecolumns = [
  {
    title: 'Order ID',
    dataIndex: 'orderId',
    key: 'orderId',
  },
  {
    title: 'Item',
    dataIndex: 'Item',
    key: 'Item',
  },
  {
    title: 'Price',
    dataIndex: 'Price',
    key: 'Price',
  },
  {
    title: 'Purchase Date',
    dataIndex: 'PurchaseDate',
    key: 'PurchaseDate',
    render: (text) => <span>{text ? dayjs(text)?.format('DD-MM-YYYY') : '- - -'}</span>,
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
    render: (key) => (
      <span>
        {key ? (
          <span className={`${paymentStatusMaps?.[key]?.className} payment_status_common`}>
            {paymentStatusMaps?.[key]?.icon} {paymentStatusMaps?.[key]?.label}
          </span>
        ) : (
          '- - -'
        )}
      </span>
    ),
  },
  {
    title: '',
    key: 'actions',
    render: (text, record) => (
      <>
        <FilePdfOutlined style={{ color: 'red' }} /> &nbsp;
        <DownloadOutlined />
      </>
    ),
  },
];

const TransactionalDetails = () => {
  // hooks
  const dispatch = useDispatch();

  // states
  const { transactionDetails, loading, paymentDetails, paymentDetailsLoader } = useSelector((store) => store.myAccount);
  const [paymentDetailsCurrentPage, setPaymentDetailsCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });

  // handeling page change for transaction details

  const handlePaginationChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  // handeling page change for payment details
  const handlePaymentPageChange = (page) => {
    setPaymentDetailsCurrentPage(page);
    dispatch(fetchPaymentDetails({ limit: 5, page }));
  };

  useEffect(() => {
    const offset = (pagination.current - 1) * pagination.pageSize;
    dispatch(fetchTransactionDetails({ limit: 5, offset: offset }));
  }, [dispatch, pagination]);

  // fetching payment details on mounting
  useEffect(() => {
    dispatch(fetchPaymentDetails({ limit: 5, page: paymentDetailsCurrentPage }));
  }, [dispatch, paymentDetailsCurrentPage]);

  return (
    <>
      <section className="transactionalDtlsSection">
        {/* ----------POINTS DETAILS------- */}
        <Card className="transaction-card">
          <div className="card-position">
            <Title level={5} className="points-desc">
              Points Usage Details
            </Title>
            <Pagination
              className="card-position__pagination"
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={transactionDetails?.totalCount}
              onChange={handlePaginationChange}
              pagination={{ position: ['topRight'], defaultPageSize: 5, pageSize: 5 }}
            />
            <Table
              loading={loading}
              dataSource={transactionDetails?.transactions}
              columns={pointsTablecolumns}
              pagination={false}
            />
          </div>
          <div>
            <Card className="card-items">
              <Title level={5}>About Points</Title>
              <Row className="items-row">
                <Col span={24}>
                  <Space direction="vertical">
                    <Text className="item-desc">
                      Points are the currency used on our platform. Each contact or piece of data in a property or
                      company is valued in points. When you sign in, you receive an initial allocation of points. You
                      can earn more points by engaging in activities such as listing your properties or interacting with
                      the platform. For additional needs, you can purchase extra points. To acquire leads or unlock new
                      tools, you spend these points, with a conversion rate of 1 point for every 1 unit of value,
                      ensuring a straightforward and transparent system.
                    </Text>
                  </Space>
                </Col>
              </Row>
            </Card>
          </div>
        </Card>
        {/* ----------POINTS DETAILS------- */}

        {/* ----------PAYMENT DETAILS------- */}
        <div className="transaction-card__right">
          <Card className="right-card" style={{ width: '-webkit-fill-available' }}>
            <div className="right-card__position">
              <div
                style={{ display: 'flex', justifyContent: 'space-between', marginTop: '23px', alignItems: 'center' }}
              >
                {' '}
                <Title level={5} className="points-desc">
                  Payment Details
                </Title>
                <Pagination
                  className="card-position__pagination"
                  current={paymentDetailsCurrentPage}
                  pageSize={5}
                  total={paymentDetails?.totalRecords}
                  onChange={handlePaymentPageChange}
                  pagination={{ position: ['topRight'], defaultPageSize: 5, pageSize: 5 }}
                />
              </div>
              <div style={{ marginTop: '13px' }}>
                <Table
                  dataSource={paymentDetails?.transactions?.map((elem, index) => ({
                    orderId: elem?.orderId,
                    key: index,
                    Item: `${elem?.points} Coins`,
                    Price: `Rs. ${elem?.amount}`,
                    PurchaseDate: elem?.purchaseDate,
                    paymentStatus: elem?.status,
                  }))}
                  loading={paymentDetailsLoader}
                  columns={paymentTablecolumns}
                  pagination={false}
                />
              </div>
            </div>
            <div className="plan-container">
              <Card className="card-items">
                <Title
                  level={5}
                  style={{
                    font: 'normal normal 600 20px/30px Poppins',
                    letterSpacing: '0px',
                    color: '#313131',
                    opacity: '1',
                  }}
                >
                  Current Plan:
                </Title>
                <Title
                  level={5}
                  style={{
                    color: '#0081FC',
                    marginTop: '1px',
                    font: 'normal normal 600 20px/30px Poppins',
                    letterSpacing: '0px',

                    opacity: '1',
                  }}
                >
                  Pay as you GO!
                </Title>

                <Row
                  className="items-row"
                  justify="space-between"
                  align="middle"
                  style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                >
                  <Col span={24}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text className="item-desc">Unlock premium leads and gain business benefits</Text>
                      <Button type="primary" className="btn-upgrade">
                        Upgrade Plan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
            {/* <Card className="right-card__items">
              <Space direction="vertical">
                <Title level={5}>Financial Details</Title>
                <img src={cibil} alt="cibil" />
                <Text>Check your CIBIL Score</Text>
                <Button type="primary">Download CIBIL Report</Button>
              </Space>
            </Card> */}
          </Card>
        </div>
        {/* ----------PAYMENT DETAILS------- */}
      </section>
    </>
  );
};
export default TransactionalDetails;
