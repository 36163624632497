import React from 'react';

const SaveIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 15.317 17.268">
      <path
        id="Save"
        d="M35.756,28.768a1.051,1.051,0,0,1-.726-.291l-5.881-5.634L23.27,28.476a1.074,1.074,0,0,1-.734.29,1.011,1.011,0,0,1-.41-.085,1.045,1.045,0,0,1-.637-.966V12.553A1.054,1.054,0,0,1,22.543,11.5H35.756a1.053,1.053,0,0,1,1.052,1.053V27.716a1.048,1.048,0,0,1-.64.967,1.031,1.031,0,0,1-.413.085Zm-.171-.873a.251.251,0,0,0,.17.068A.249.249,0,0,0,36,27.716V12.553a.248.248,0,0,0-.247-.248H22.543a.248.248,0,0,0-.248.248V27.716a.244.244,0,0,0,.148.225l.019.008.019,0a.5.5,0,0,0,.063,0,.241.241,0,0,0,.171-.062l6.435-6.166L35.585,27.9Z"
        transform="translate(-21.49 -11.5)"
        fill={color}
      />
    </svg>
  );
};

export default SaveIcon;
