import React from 'react';

const LikeIcon = ({ color = '#aaa9a9' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 16.383 20.839">
      <g id="Like" transform="translate(-28.46 -9.01)">
        <g id="g304" transform="translate(31.805 9.01)">
          <g id="g310" transform="translate(0 6.753)">
            <g id="path312">
              <path
                id="Path_17610"
                data-name="Path 17610"
                d="M62.144,79.532a.429.429,0,0,1-.427-.424c-.008-.96-.441-1.262-1.095-1.719A4.2,4.2,0,0,1,58.846,75.3a4.759,4.759,0,0,0-.835-1.471c-.076-.1-.139-.173-.195-.256a.89.89,0,0,1,.034-1.011,1.432,1.432,0,0,1,.366-.368,1.48,1.48,0,0,1-.36-.784.918.918,0,0,1,.469-.985,16.062,16.062,0,0,0,2.551-1.571,2.993,2.993,0,0,1,1.842-.86,2.174,2.174,0,0,1,1.23.439,1.031,1.031,0,0,0,.279.124,1.911,1.911,0,0,1,.832.5.427.427,0,0,1-.3.734.415.415,0,0,1-.3-.12,1.094,1.094,0,0,0-.506-.3,1.83,1.83,0,0,1-.49-.227,1.363,1.363,0,0,0-.758-.291,2.259,2.259,0,0,0-1.3.669,17,17,0,0,1-2.7,1.669.283.283,0,0,0-.006.116.628.628,0,0,0,.5.526,1.652,1.652,0,0,0,.326.033,3.054,3.054,0,0,0,1.534-.579,2.7,2.7,0,0,1,1.313-.51,1.2,1.2,0,0,1,.83.344A5.142,5.142,0,0,1,64.259,72.3a1.238,1.238,0,0,1-.144,1.489,2.773,2.773,0,0,1-1.242,1.02,1.078,1.078,0,0,1-.283.038,2.214,2.214,0,0,1-1-.332,3.162,3.162,0,0,1-1.176-1.06,1.423,1.423,0,0,0-1.012-.7.9.9,0,0,0-.183-.019.83.83,0,0,0-.661.3.222.222,0,0,0-.032.064c.039.055.09.12.15.195A5.6,5.6,0,0,1,59.648,75a3.348,3.348,0,0,0,1.465,1.692A2.658,2.658,0,0,1,62.573,79.1a.428.428,0,0,1-.123.3.42.42,0,0,1-.3.127h0Zm-1.458-7.081a3.458,3.458,0,0,1,.418.494,2.348,2.348,0,0,0,.884.81,1.655,1.655,0,0,0,.6.235h0a.218.218,0,0,0,.062-.008c.161-.044.411-.274.811-.747.254-.3.166-.573-.834-1.493a.361.361,0,0,0-.261-.118,2.188,2.188,0,0,0-.866.389,7.015,7.015,0,0,1-.814.439Z"
                transform="translate(-57.675 -67.99)"
                fill={color}
              />
            </g>
          </g>
          <g id="g314" transform="translate(3.675 17.47)">
            <g id="path316">
              <path
                id="Path_17611"
                data-name="Path 17611"
                d="M90.2,164.949a.427.427,0,0,1-.427-.427v-2.515a.427.427,0,0,1,.427-.427h2.257a.427.427,0,0,1,0,.854h-1.83v1.66h7.317v-1.66H96.11a.427.427,0,1,1,0-.854h2.258a.427.427,0,0,1,.427.427v2.515a.427.427,0,0,1-.427.427Z"
                transform="translate(-89.77 -161.58)"
                fill={color}
              />
            </g>
          </g>
          <g id="g318" transform="translate(10.012 4.874)">
            <g id="path320">
              <path
                id="Path_17612"
                data-name="Path 17612"
                d="M147.006,65a.428.428,0,0,1-.425-.431,7.438,7.438,0,0,1,.37-1.95,6.557,6.557,0,0,0,.338-1.748V57.855l-.276-4.713c-.041-.708-.271-.708-.382-.708a.753.753,0,0,0-.547.244.4.4,0,0,0-.109.324.428.428,0,0,1-.361.484.374.374,0,0,1-.062,0,.43.43,0,0,1-.423-.365,1.246,1.246,0,0,1,.307-1.006,1.6,1.6,0,0,1,1.193-.539c.428,0,1.158.2,1.236,1.511l.277,4.726v3.055a7.291,7.291,0,0,1-.37,1.982,6.7,6.7,0,0,0-.338,1.723.429.429,0,0,1-.427.424h0Z"
                transform="translate(-145.117 -51.58)"
                fill={color}
              />
            </g>
          </g>
          <g id="g322" transform="translate(3.982 0)">
            <g id="path324">
              <path
                id="Path_17613"
                data-name="Path 17613"
                d="M97.72,18.268a.425.425,0,0,1-.425-.387,1.864,1.864,0,0,0-.466-1.1,2.376,2.376,0,0,1-.457-.832L94.734,10.46a.772.772,0,0,0-.482-.583.487.487,0,0,0-.117-.014.782.782,0,0,0-.55.266,1.149,1.149,0,0,0-.222,1.151l1.544,5.138a.429.429,0,0,1-.286.532.447.447,0,0,1-.123.018.429.429,0,0,1-.409-.3l-1.544-5.137a2.007,2.007,0,0,1,.419-1.983,1.636,1.636,0,0,1,1.173-.534,1.347,1.347,0,0,1,.311.037,1.564,1.564,0,0,1,1.1,1.17L97.2,15.722a1.588,1.588,0,0,0,.313.54,2.706,2.706,0,0,1,.636,1.539.428.428,0,0,1-.385.466l-.041,0Z"
                transform="translate(-92.448 -9.01)"
                fill={color}
              />
            </g>
          </g>
          <g id="g326" transform="translate(7.354 1.431)">
            <g id="path328">
              <path
                id="Path_17614"
                data-name="Path 17614"
                d="M125.5,30.6a.429.429,0,0,1-.424-.371l-.963-7.2c-.026-.2-.12-.655-.421-.655a.386.386,0,0,0-.05,0,1.013,1.013,0,0,0-.616.445,1.484,1.484,0,0,0-.248,1.052l.3,2.257a.427.427,0,0,1-.084.316.422.422,0,0,1-.283.164.48.48,0,0,1-.057,0,.429.429,0,0,1-.424-.371l-.3-2.257a2.355,2.355,0,0,1,.387-1.645,1.809,1.809,0,0,1,1.21-.813,1.274,1.274,0,0,1,.161-.01c.254,0,1.1.1,1.27,1.4l.963,7.2a.427.427,0,0,1-.084.316.422.422,0,0,1-.283.164.466.466,0,0,1-.057,0Z"
                transform="translate(-121.904 -21.51)"
                fill={color}
              />
            </g>
          </g>
          <g id="g330" transform="translate(7.76 17.47)">
            <g id="path332">
              <path
                id="Path_17615"
                data-name="Path 17615"
                d="M125.877,162.434a.427.427,0,1,1,.427-.427A.427.427,0,0,1,125.877,162.434Z"
                transform="translate(-125.45 -161.58)"
                fill={color}
              />
            </g>
          </g>
        </g>
        <path
          id="Path_17616"
          data-name="Path 17616"
          d="M28.887,97.474a.427.427,0,1,1,0-.854h1.59a.427.427,0,1,1,0,.854Z"
          transform="translate(0 -77.578)"
          fill={color}
        />
        <path
          id="Path_17617"
          data-name="Path 17617"
          d="M42.285,72.832a.427.427,0,0,1-.3-.125L41,71.729a.422.422,0,0,1-.125-.3.428.428,0,0,1,.729-.3l.978.978a.422.422,0,0,1,.125.3.427.427,0,0,1-.125.3A.42.42,0,0,1,42.285,72.832Z"
          transform="translate(-10.998 -54.892)"
          fill={color}
        />
        <path
          id="Path_17618"
          data-name="Path 17618"
          d="M41.307,115.522a.427.427,0,0,1-.3-.125.422.422,0,0,1-.125-.3.427.427,0,0,1,.125-.3l.978-.978a.422.422,0,0,1,.3-.125.427.427,0,0,1,.3.125.421.421,0,0,1,.125.3.427.427,0,0,1-.125.3l-.978.978A.427.427,0,0,1,41.307,115.522Z"
          transform="translate(-10.998 -92.694)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default LikeIcon;
