import React from 'react';
import './groupCard.scss';
import CommonButton from '../../common/CommonButton';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { joinSocialGroup, joinSocialGroupAPI } from '../../../../features/socialAppSlice';
import { useNavigate } from 'react-router';

const GroupCardCarousel = ({ data, setJoinGroup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const joinGroup = () => {
    dispatch(joinSocialGroup(data?._id))?.then((res) => {
      if (res) {
        setJoinGroup(true);
      }
    });
  };

  return (
    <div
      onClick={() => {
        navigate(`/user/socialApp/groupDetails/${data?._id}`);
      }}
      className="groupCardContainer groupCardCarousel mr-20 cursor-pointer"
    >
      <div style={{ background: `url(${data?.imageUrl})` }} className="groupCardImage mr-20"></div>
      <div className="d-flex d-column jc-between flex-2 ">
        <div className="d-flex d-column jc-between">
          <span className="fontDarkBlue font700 font18">{data?.name}</span>
          <span className="d-flex a-center g-5">
            {' '}
            <span className="fontOrange font12">{data?.memberCount}</span>
            <span className="fontLight-4 ">Members</span>
          </span>
        </div>
        <div className="d-flex a-center jc-between">
          <span className="fontLight-5 font13">Created on : {dayjs(data?.createdOn)?.format('DD/MM/YYYY')}</span>
          <CommonButton
            onClick={(e) => {
              joinGroup();
              e?.stopPropagation();
            }}
            style={{ padding: 10, height: 25 }}
            ghost
          >
            Join Now
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default GroupCardCarousel;
