import React from 'react';

const GenericPostIcon = ({ color = '#b4b4b4' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.118" height="24.699" viewBox="0 0 25.118 24.699">
      <g id="Post" transform="translate(0.699)">
        <g id="Group_25056" data-name="Group 25056" transform="translate(0 1.419)">
          <path
            id="Path_17664"
            data-name="Path 17664"
            d="M18.776,47.511a1.239,1.239,0,0,1-1.206-1.267V26.2a1.24,1.24,0,0,1,1.206-1.267H29.43a.6.6,0,0,1,0,1.194H18.73V46.316h21.4V34.884a.579.579,0,1,1,1.158,0V46.245a1.24,1.24,0,0,1-1.206,1.267H18.776Z"
            transform="translate(-17.57 -24.93)"
            fill={color}
          />
          <path
            id="Path_17665"
            data-name="Path 17665"
            d="M20.485,48.91a.951.951,0,0,1-.915-.982V27.912a.951.951,0,0,1,.915-.982H31.129a.315.315,0,0,1,0,.627H20.147V48.281H42.113v-11.7a.289.289,0,1,1,.576,0V47.928a.951.951,0,0,1-.915.982H20.485Z"
            transform="translate(-19.269 -26.629)"
            fill={color}
            stroke={color}
            stroke-width="2"
          />
        </g>
        <g id="Group_25057" data-name="Group 25057" transform="translate(7.624)">
          <path
            id="Path_17666"
            dataName="Path 17666"
            d="M74.92,31.875a1.345,1.345,0,0,1-1.328-1.536L73.928,28a1.354,1.354,0,0,1,.381-.761L85.193,16.348A2.713,2.713,0,0,1,87.13,15.5c.05,0,.1,0,.15,0a2.914,2.914,0,0,1,2.669,2.669,2.684,2.684,0,0,1-.844,2.088L78.218,31.149a1.327,1.327,0,0,1-.76.378l-2.344.335a1.4,1.4,0,0,1-.194.014Zm-.084-1.258,2.469-.352,10.9-10.9a1.429,1.429,0,0,0,.474-1.115,1.645,1.645,0,0,0-1.474-1.476l-.075,0a1.457,1.457,0,0,0-1.04.477l-10.9,10.9-.352,2.469Z"
            transform="translate(-73.578 -15.5)"
            fill={color}
            fillRule="evenodd"
          />
          <path
            id="Path_17667"
            dataName="Path 17667"
            d="M76.628,33.274A1.044,1.044,0,0,1,75.6,32.081l.335-2.344a1.056,1.056,0,0,1,.295-.591L87.112,18.261a2.419,2.419,0,0,1,1.724-.761c.044,0,.089,0,.134,0a2.615,2.615,0,0,1,2.386,2.386,2.389,2.389,0,0,1-.757,1.858L79.712,32.636a1.041,1.041,0,0,1-.588.293l-2.344.335a1.046,1.046,0,0,1-.152.011Zm12.21-15.106a1.743,1.743,0,0,0-1.252.564L76.612,29.705l-.424,2.965,2.965-.424L90.127,21.273a1.721,1.721,0,0,0,.563-1.345,1.95,1.95,0,0,0-1.759-1.759c-.03,0-.062,0-.093,0Z"
            transform="translate(-75.285 -17.199)"
            fill={color}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default GenericPostIcon;
