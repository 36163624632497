// Css Imports
import { Avatar, Divider, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import '../socialApp.scss';

// Slices and reducer imports
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import TopNavWithoutInteraction from '../../../../layout/leadGen/components/topNav/TopNavWithoutInteraction';
import NotFound from '../../../../NotFound';
import CommonButton from '../../../components/socialApp/common/CommonButton';
import { getSharedPostDetails } from '../../../features/socialAppSlice';
import ShareablePost from './ShareablePostDetails';
import UserCircleIcon from '../../../../assets/SocialApp/UserCircleIcon';

// Component Imports

const ShareablePostDetails = () => {
  const { pathname, search } = useLocation();
  const { postId, postType } = useParams();
  const [showLogin, setShowLogin] = useState(true);
  const pathnameUpdated = pathname?.replace('/share', '/user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // fetching on mounting
  const { user } = useSelector((store) => store.user);
  const [postDetails, setPostDetails] = useState();
  useEffect(() => {
    dispatch(
      getSharedPostDetails({
        postId,
      }),
    )?.then((res) => {
      const data = res?.payload;
      console.log('res', res);
      if (data) {
        setPostDetails(data);
      }
    });
  }, []);

  const onLoginNavigate = () => {
    navigate(`/?shareable=${encodeURIComponent(pathnameUpdated)}`);
  };
  const onRegister = () => {
    navigate(`/register?shareable=${encodeURIComponent(pathname)}`);
  };
  return (
    <>
      {false ? (
        <NotFound />
      ) : (
        <div className="socialAppContainer d-flex d-column p-20 bgGray">
          <TopNavWithoutInteraction
            onLogin={() => {
              navigate('/');
            }}
          />
          <div className="socialAppContainer__postContainer m-auto">
            {postDetails && (
              <ShareablePost
                setLoginPopup={setShowLogin}
                setAllPosts={() => {}}
                userId={user?.id}
                postDetails={postDetails}
              />
            )}
          </div>
          <Modal
            styles={{
              mask: {
                backdropFilter: 'blur(6px)',
              },
            }}
            width={500}
            footer={false}
            open={showLogin}
            onCancel={() => setShowLogin(false)}
            centered
          >
            <div className="d-flex d-column jc-around a-center" style={{ height: 450 }}>
              <div className="d-flex d-column g-20 a-center loginPopup">
                {/* <Avatar style={{ width: 80, height: 80 }} icon={<UserOutlined />} /> */}
                <UserCircleIcon />
                <span className="font30 fontDark text-center">Sign in to to see full profile</span>
                <CommonButton style={{ borderRadius: 30 }} className="font22 p-30 w-90 " onClick={onLoginNavigate}>
                  Click to Login
                </CommonButton>
              </div>
              <span className="d-flex g-2 jc-center a-center">
                <Divider style={{ backgroundColor: '#b4b4b4' }} />
                <span>or</span>
                <Divider style={{ backgroundColor: '#b4b4b4' }} />
              </span>
              <span className="font16">
                New to Trythat AI ?{' '}
                <span className="fontBlue text-underline" onClick={onRegister}>
                  Register
                </span>
              </span>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ShareablePostDetails;
