import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [width, setWidth] = useState(1);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isAbove1920 = width >= 1920;
  const isAbove1320 = width > 1320 && width < 1920;

  const isMobile = width <= 767;

  return { isMobile, isAbove1920, isAbove1320 };
};
