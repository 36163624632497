import { Col, Rate, Row, Tooltip } from 'antd';
import { isEmpty, truncate } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import locationSVG from '../../../../../assets/images/address-location.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import UnderConstruction from '../../../../../assets/images/UnderConstruction.svg';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import Ready from '../../../../../assets/images/ready.svg';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import './propertyDetails.scss';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';

const SelectedPropertyDetail = ({ propertyItem, onSaveCard, onDeleteCard, residentialData = false }) => {
  const navigateTo = useNavigate();
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [showShareModal, setShowShareModal] = useState(null);
  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();

  const dispatch = useDispatch();

  // Function to show RaiseConcern
  const handleRaiseConcernClick = () => {
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
  };

  const navigateToPropertyDetail = (e, id, page) => {
    e.stopPropagation();
    navigateTo(`/leadGen/propertyDetails/${id}?list=${page}`, { replace: true });
  };
  return (
    <>
      {' '}
      <div key={propertyItem?._id} className=" cursor-pointer">
        <div className="propertyCard">
          <Row className="inside_row">
            <div style={{ position: 'absolute', zIndex: 3, marginLeft: '20px', marginTop: '-8px' }}>
              {propertyItem?.buildingStatus === 'Ready' && <img src={Ready} alt="Ready" />}
              {propertyItem?.buildingStatus === 'Under Construction' && (
                <img src={UnderConstruction} alt="Under Construction" />
              )}
            </div>
            <Col
              span={7}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              className="propertyListContainer__div__card__row__col__row__col"
            >
              {propertyItem?.featuredImage ? (
                <img
                  src={propertyItem?.featuredImage}
                  alt="Property Img"
                  className="propertyListContainer__div__card__row__col__row__col__img"
                />
              ) : (
                <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
              )}
            </Col>
            <Col span={16} className="d-flex d-column jc-between">
              <div>
                <div className="d-flex jc-between a-center mt-10 ">
                  <h5
                    style={{ color: '#0081FC' }}
                    className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                  >
                    {propertyItem?.buildingName}
                  </h5>

                  <div className="d-flex">
                    {!propertyItem?.isSaved && (
                      <img
                        src={bookmark}
                        alt="bookmark"
                        style={{
                          // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                          cursor: 'pointer',
                          height: '25px',
                        }}
                        onClick={() => {
                          onSaveCard(
                            propertyItem?._id,
                            propertyItem?.buildingName,
                            'property',
                            ['representativeInfo'],
                            residentialData ? 'residential' : 'commercial',
                          );
                        }}
                      />
                    )}
                    {propertyItem?.isSaved && (
                      <img
                        src={bookmark_selected}
                        alt="bookmark_selected"
                        style={{
                          // visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                          cursor: 'pointer',
                          height: '25px',
                        }}
                        onClick={() => {
                          onDeleteCard(
                            propertyItem?._id,
                            propertyItem?.buildingName,
                            'property',
                            ['representativeInfo'],
                            residentialData ? 'residential' : 'commercial',
                          );
                        }}
                      />
                    )}
                    <span className="menuDots">
                      <KebabMenu
                        onShare={() =>
                          setShowShareModal(
                            `${window.location.host}/leadGen/propertyDetails/${propertyItem?._id}?shareable=true`,
                          )
                        }
                        onRaiseConcern={handleRaiseConcernClick}
                        onSubscribed={() => handleSubscribedClick(propertyItem, 'ResidentialPropertyDetails')}
                        onUnSubscribed={() => handleUnSubscribedClick(propertyItem, 'ResidentialPropertyDetails')}
                        resourceId={propertyItem}
                      />
                      {showRaiseConcern && (
                        <RaiseConcern
                          resourceDetails={{
                            resourceType: residentialData ? 'residential' : 'commercial',
                            resourceSubType: 'Property',
                            resourceName: propertyItem?.buildingName,
                            resourceId: propertyItem?._id,
                          }}
                          onClose={handleCloseRaiseConcern}
                        />
                      )}
                      {showShareModal && (
                        <ShareModal
                          shareUrl={showShareModal}
                          showShareModal={showShareModal}
                          setShowShareModal={setShowShareModal}
                        />
                      )}
                    </span>
                  </div>
                </div>
                <Rate
                  allowHalf
                  disabled
                  defaultValue={!isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0}
                />
                <div className="d-flex  d-column g-5 mt-10">
                  <div className="d-flex g-5">
                    <img
                      src={locationSVG}
                      style={{ marginTop: 3 }}
                      alt="location"
                      className="propertyListContainer__div__card__row1__col1__row__col__img"
                    />
                    <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                      <Tooltip
                        title={populateAddressInfo(
                          Array.isArray(propertyItem?.addressInfo)
                            ? propertyItem?.addressInfo?.[0]
                            : propertyItem?.addressInfo,
                        )}
                      >
                        {truncate(
                          populateAddressInfo(
                            Array.isArray(propertyItem?.addressInfo)
                              ? propertyItem?.addressInfo?.[0]
                              : propertyItem?.addressInfo,
                          ),
                          { length: 70 },
                        )}
                      </Tooltip>
                    </span>
                  </div>
                  <div className='d-flex' >
                  <div className="d-flex g-5 a-center">
                    <img
                      src={aminities}
                      alt="aminities"
                      className="propertyListContainer__div__card__row1__col1__row__col__img"
                      style={{marginLeft:'-2px'}}
                    />
                    <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                    <span  className="selectedamenities bold">Amenities: </span>
                    </p>
                  </div>
                  <div style={{ display: 'inline-flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    {propertyItem?.amenities && (
                      <div
                        className="propertyListContainer__div__card__row1__col1__amenities"
                        style={{
                          maxHeight: '200px',
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? '100%' : '145px',
                        }}
                      >
                        {propertyItem?.amenities &&
                          propertyItem?.amenities?.length > 0 &&
                          propertyItem?.amenities?.slice(0, 4)?.map((item, index) => {
                            return (
                              <Col
                                span={propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? 6 : 4}
                                className="propertyListContainer__div__card__row1__col1__div__col"
                                style={{ flex: index === 0 ? 'initial' : 'auto' }}
                              >
                                {isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                  <Tooltip title={'Default'}>
                                    <img
                                     
                                      src={AmenitiesIconEnum?.['DEFAULT']}
                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                    />
                                  </Tooltip>
                                )}
                                {!isEmpty(AmenitiesIconEnum?.[item.toLowerCase()]) && (
                                  <Tooltip title={item}>
                                    <img
                                    
                                      src={AmenitiesIconEnum?.[item.toLowerCase()]}
                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                    />
                                  </Tooltip>
                                )}
                              </Col>
                            );
                          })}
                      </div>
                    )}
                    {propertyItem?.amenities?.length > 4 ? (
                      <p
                        className="propertyListContainer__div__card__row1__col1__div__col__para"
                        style={{ verticalAlign: 'top', marginTop: '5px', fontWeight: 'bolder' }}
                      >
                        {`${propertyItem?.amenities?.length - 4} More...`}
                      </p>
                    ) : null}
                  </div>
                </div>
                </div>
              </div>
              <div className="bottomRow d-flex jc-center g-20">
                <span
                  onClick={(e) => {
                    // if (propertyItem?.vacantUnitCount) navigateToPropertyDetail(e, propertyItem?._id, 'vacantUnits');
                    // else e.stopPropagation();
                    navigateToPropertyDetail(e, propertyItem?._id, 'vacantUnits');
                    dispatch(setSelectedNav('LeadGen'));
                    // else e.stopPropagation();
                  }}
                  className={`property-hover propertyListContainer__div__card__row1__col1__row__col1__para ${
                    propertyItem?.vacantUnitCount ? 'cursor-pointer' : 'cursor-default'
                  }`}
                >
                  {`${propertyItem?.vacantUnitCount ?? 0} Unit Available`}
                </span>
                {residentialData === false && (
                  <span
                    onClick={(e) => {
                      navigateToPropertyDetail(e, propertyItem?._id, 'occupants');
                      dispatch(setSelectedNav('LeadGen'));
                    }}
                    className="property-hover propertyListContainer__div__card__row1__col1__row__col1__para bold"
                  >
                    View All Transaction
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SelectedPropertyDetail;
