import { Divider } from 'antd';
import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import EventCardImageCarousel from './EventCardImageCarousel';
import './eventCardPost.scss';
import TruncateText from '../common/imageCarousel/TruncateText';
import { useNavigate } from 'react-router';
import RepostIcon from '../../../../../../assets/SocialApp/RepostIcon';
import ReactShowMoreText from 'react-show-more-text';

const EventCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
  setLoginPopup = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  return (
    <div className="event_card_post_content ">
      {postDetails.imageUrls?.length > 0 && (
        <div className="event_post_images">
          <EventCardImageCarousel
            className="carousel-image"
            imageUrls={
              postDetails.imageUrls?.length > 0
                ? postDetails.imageUrls?.map((elem) => elem?.url)
                : ['https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai']
            }
            postDetails={postDetails}
          />
        </div>
      )}

      <div className="d-flex d-column jc-between w-100 word-break-all">
        {isViewPost && postDetails?.isReposted && (
          <div className="d-flex g-5 a-center mb-10">
            <div className="repost-icon">
              <RepostIcon className="repost-icon" />
            </div>
            <div>You Reposted</div>
          </div>
        )}
        <PostHeader
          setLoginPopup={setLoginPopup}
          handleFollow={handleFollow}
          handleUnFollow={handleUnFollow}
          postDetails={postDetails}
        />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font16 fontDark font700">{postDetails?.title}</Text>
          <div className="publisherContainer">
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.organiser) }}
            ></div>
            <Divider type="vertical" className="divider" />
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(formatDate(postDetails?.postDetails?.dateTime?.date)) }}
            ></div>
            <Divider type="vertical" className="divider" />
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: postDetails?.postDetails?.dateTime?.time }}
            ></div>
            {/* <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.place) }}
            ></div> */}
          </div>
          {isViewPost ? (
            <div
              className={`font14 postBody fontDark `}
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
            ></div>
          ) : (
            <ReactShowMoreText
              /* Default options */
              lines={4}
              more={<span className="fontBlue cursor-pointer mt-10 mb-10">See more</span>}
              className="content-css"
              anchorClass="show-more-less-clickable"
              onClick={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
              expanded={false}
              // width={'100%'}

              truncatedEndingComponent={'... '}
            >
              <div
                className={`font14 postBody fontDark ${!isViewPost && 'truncatedText'}`}
                dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
              ></div>
            </ReactShowMoreText>
          )}
          {/* {!isViewPost && (
            <span
              className="fontBlue cursor-pointer mt-10 mb-10"
              onClick={() => navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`)}
            >
              See more
            </span>
          )} */}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
          setLoginPopup={setLoginPopup}
        />
      </div>
    </div>
  );
};

export default EventCardPost;
