import { RightOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import SimilarCompaniesSkeleton from '../CompanyDetails/skeletons/SimilarCompaniesSkeleton';
import { useDispatch } from 'react-redux';
import { viewMoreProperty } from '../../../../features/searchSlice';
import './propertyDetails.scss';
import CustomRating from './CustomRating';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';

const SimilarProperties = ({ propertyData = [], searchQuery, loader, onCardClick }) => {
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const onPropertyClick = (id) => {
    onCardClick(id);
    // dispatch(viewMoreProperty(id));
  };
  return (
    <div style={{ overflow: 'scroll',height:'600px' }} className="similarPropertyContainer d-flex  g-10 d-column">
      {searchQuery?.body?.tag === 'commercialProperty' || searchQuery?.body?.tag === 'residentialProperty' ? (
        <div className="font14 font900 fontLight-3">Similar Properties</div>
      ) : (
        <div className="font14 font900 fontLight-3">Other Properties of {searchQuery?.body?.query}</div>
      )}
      <div className="similarPropertyScrollbar jc-center pr-10" style={{ maxHeight: '550px', overflow: 'scroll' }}>
        {propertyData.length === 0 ? (
          <div className="property-imgdiv">
            <LottieAnimation height={150} width={150} animationData={animationData} loop={true} />
            <span className="bold property-imgdiv__text">
              We are finding right<br></br> <span className="bold text-middleproperty">PROPERTY</span>
              <br></br>
              For you, Be with us !
            </span>
          </div>
        ) : (
          propertyData?.map((ele, index) =>
            loader ? (
              <SimilarCompaniesSkeleton />
            ) : (
              <div
                onClick={() => onPropertyClick(ele?._id)}
                key={nanoid()}
                className="d-flex a-center jc-between mt-15 p-10 similarPropertiesHover"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <span className="d-flex g-10 jc-between">
                  <Avatar className="companyAvatar" src={ele?.assetsInfo?.[0]?.featuredImage} />
                  <span className="d-flex d-column jc-between">
                    <span className="font12 font600">{ele?.buildingName}</span>
                    <span className="font10 locality-hover">
                      {ele?.addressInfo?.[0]?.locality}, {ele?.addressInfo?.[0]?.city}
                    </span>
                    <CustomRating
                      rating={!isNaN(Number(ele?.averageRating)) ? Number(ele?.averageRating) : 0}
                      hovered={hoveredIndex === index}
                    />
                  </span>
                </span>
                <RightOutlined className="fontLight-2" />
              </div>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default SimilarProperties;
