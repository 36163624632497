import React, { useEffect, useState, useCallback } from 'react';
import { Header } from 'antd/es/layout/layout';
import {
  Avatar,
  Badge,
  Button,
  Input,
  Dropdown,
  Switch,
  List,
  Menu,
  Popover,
  Progress,
  Space,
  Typography,
  Tabs,
} from 'antd';
import { BellOutlined, UserOutlined, CheckSquareOutlined } from '@ant-design/icons';
import MyAccountIcon from '../../../home/images/MyAccount';
import LogoutIcon from '../../../../assets/images/logout2.svg';
import { useNavigate, useLocation } from 'react-router';
import logo from '../../../../assets/logo.svg';
import storeImg from '../../../../assets/images/store-icon.svg';
import { setSelectedNav } from '../../../../leadgen/features/universalSidenavSlice';
import './topNav.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPoints } from '../../../../universal/features/userOnboardingSlice';
import { isEmpty } from 'lodash';
import {
  getAllNotifications,
  markAsreadNotification,
  setUniversalNotifications,
} from '../../../../universal/features/userSlice';
import { format } from 'date-fns';
import { fetchUserProfileCompletion } from '../../../../universal/features/myAccountSlice';
import { persistor } from '../../../../redux/store'; // Import persistor if it's not imported yet
import PropertyTypeToggle from './PropertyTypeToggle';
import {
  getSearchResults,
  reset_data,
  setPagination,
  setSearchContext,
  setSelectedOrganizationId,
  setpropertyToggle,
} from '../../../../leadgen/features/searchSlice';
// import { delay } from '../../../../utils'; // Import delay function if it's not imported yet
import { useWindowSize } from '../../../../utils/useWindowSize/useWindowSize';
import { commericalPropertyRadios, residentialPropertyRadios } from './topNav.utils';
import { nanoid } from '@reduxjs/toolkit';
import { useSearchParams } from 'react-router-dom';
import GroupIcon from '../../../../assets/SocialApp/GroupIcon';
import EventIcon from '../../../../assets/SocialApp/EventIcon';
import { EventSourcePolyfill } from 'event-source-polyfill';
import SocialUsersIcon from '../../../../assets/SocialApp/SocialUsersIcon';

const TopNav = ({ hideSearchBar = false }) => {
  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
  // hooks
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  //states
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [notificationContent, setNotificationContent] = useState();
  const [loading, setLoading] = useState(false);

  const { selectedNav } = useSelector((store) => store.universalSideNav);
  const { userPoints } = useSelector((store) => store.userOnboarding);
  const { user, userV2, universalNotifications, profileCompletion, accessToken } = useSelector((store) => store.user);
  const { Text } = Typography;
  const { isAbove1320, isAbove1920 } = useWindowSize();
  const [params, setSearchParams] = useSearchParams();
  const searchQueries = params?.get('searchQuery');
  const { propertyToggle, searchContext } = useSelector((store) => store.search);

  useEffect(() => {
    dispatch(getAllNotifications());
    dispatch(fetchUserPoints());
    dispatch(fetchUserProfileCompletion());
  }, [dispatch]);

  useEffect(() => {
    setNotificationContent(getContent());
  }, [universalNotifications]);

  useEffect(() => {
    if (searchQueries) {
      setSearchQuery(searchQueries);
      searchParam(searchQueries);
    }
  }, [searchQueries]);
  const getContent = () => (
    <List
      className="topnav-items"
      dataSource={universalNotifications}
      renderItem={(item) => (
        <List.Item
          key={item?._id}
          style={{ marginTop: 5, background: item.status === false ? '#DEF0FF' : 'white', padding: '10px' }}
        >
          <div className="d-flex d-column w-100">
            <div className="d-flex g-5">
              <List.Item.Meta title={item?.title} />
              <Text className="font12 ">{format(item?.createdAt, 'dd/MM/yyyy HH:mm:ss')}</Text>
            </div>
            <span className="fontExtraLight">{item?.description}</span>
            <div className="d-flex jc-between">
              <Text
                className="font14 cursor-pointer fontBlue"
                onClick={() => {
                  dispatch(markAsreadNotification(item?._id));
                  const host = window.location.host;
                  const protocol = window.location.protocol;
                  window.location.href = `${protocol}//${host}${item?.metadata?.path}`;
                }}
              >
                Click here to read more
              </Text>

              <Text
                className="font14 cursor-pointer fontBlue"
                style={{
                  visibility: item?.status === false ? 'visible' : 'hidden',
                }}
                onClick={() => {
                  dispatch(markAsreadNotification(item?._id));
                }}
              >
                Mark as read
              </Text>
            </div>
          </div>
        </List.Item>
      )}
    />
  );

  useEffect(() => {
    if (isEmpty(user?.id)) return;
    const sse_url = `${process.env.REACT_APP__BACKEND_API_URL}/notifications/${user?.id}/sse`;
    const eventSource = new EventSourcePolyfill(sse_url, { headers: { Authorization: `Bearer ${accessToken}` } });

    eventSource.addEventListener('sse', (e) => {
      const sseData = JSON.parse(e.data);
      dispatch(setUniversalNotifications(sseData));
      //add state change here
    });
    eventSource.addEventListener('error', (e) => {
      console.log('An error occurred while attempting to connect.');
    });

    return () => {
      eventSource.close();
    };
  }, [user]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const getUnreadNotificationCount = () => {
    if (isEmpty(universalNotifications)) return 0;

    return universalNotifications?.reduce((count, obj) => {
      if (obj?.status === false) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  };

  const logout = async () => {
    dispatch(setSelectedNav('Logout'));
    setLoading(true);
    await persistor.purge();
    await persistor.flush();

    localStorage.clear();
    await delay(1000);

    setLoading(false);

    window.location.replace('/');
  };

  const handleMenuClick = (e) => {
    if (e?.key === 'myaccount') {
      navigateTo('/user/myaccount');
      dispatch(setSelectedNav('MyAccount'));
    } else if (e?.key === 'Store') {
      if (profileCompletion?.overallCompletion >= 80) {
        navigateTo('/user/store');
        dispatch(setSelectedNav('Store'));
      }
    } else if (e?.key === 'logout') {
      logout();
      dispatch(setSelectedNav(false));
    }
  };

  const profileMenu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        backgroundColor: '#fff',
        padding: '17px',
        marginTop: '5px',
        display: 'flex',
        gap: '8px',
        flexDirection: 'column',
        marginRight: '-7px',
      }}
    >
      <Menu.Item
        key="myaccount"
        icon={<MyAccountIcon strokeColor={selectedNav === 'MyAccount' ? '#FFA300' : '#000'} />}
        disabled={profileCompletion?.overallCompletion <= 80}
      >
        <span style={{ color: selectedNav === 'MyAccount' ? '#FFA300' : '#000', marginLeft: '12px', fontSize: '16px' }}>
          Account Details
        </span>
      </Menu.Item>
      <Menu.Item key="Store" disabled={profileCompletion?.overallCompletion <= 80}>
        <img style={{ width: '29px' }} src={storeImg} />
        <span
          style={{
            color: selectedNav === 'Store' ? '#FFA300' : '#000',
            marginLeft: '12px',
            fontSize: '16px',
            color: '#000',
            position: 'absolute',
            marginTop: '4px',
          }}
        >
          Store
        </span>
      </Menu.Item>
      <Menu.Item key="logout">
        <img style={{ width: '29px' }} src={LogoutIcon} />
        <span style={{ marginLeft: '12px', fontSize: '16px', color: '#000', position: 'absolute', marginTop: '4px' }}>
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  const navigationPath =
    profileCompletion?.overallCompletion <= 25
      ? '/user/offerings'
      : profileCompletion?.overallCompletion > 25 && profileCompletion?.overallCompletion <= 80
      ? '/user/onboarding'
      : '/user/socialApp';

  // on Searchx
  const onSearch = () => {
    window.open(`/leadgen/search?searchQuery=${searchQuery}`, '_blank');
  };
  const searchParam = useCallback(
    (searchQuery) => {
      dispatch(
        getSearchResults({
          body: {
            query: `${searchQuery}`,
            offset: 0,
            limit: 10,
          },
        }),
      )?.then((res) => {});

      dispatch(setPagination({ offset: 0, limit: 10 }));
    },
    [dispatch],
  );
  return (
    <Header className="header">
      <div className="header__items">
        <div className="header__logo">
          <img
            src={logo}
            alt="logo"
            width="150px"
            className="header__logo-img"
            onClick={() => {
              navigateTo(navigationPath);
            }}
          />
        </div>

        <div
          style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: '-70px' }}
          className={isAbove1920 ? 'topNavBarAbove1920' : ''}
        >
          <Space size={'large'}>
            {!hideSearchBar && isAbove1920 && (
              <Switch
                className="topNavSwitch"
                style={{ marginLeft: '22px' }}
                checkedChildren={
                  <span style={{ paddingRight: 10 }} className=" p-10">
                    Commercial
                  </span>
                }
                unCheckedChildren={<span style={{ paddingRight: 10 }}>Residential</span>}
                defaultChecked
                value={propertyToggle}
                onChange={(e) => {
                  dispatch(setpropertyToggle(!propertyToggle));
                  dispatch(setSearchContext(!e ? 'residentialProperty' : 'commercialProperty'));
                  dispatch(reset_data());
                  setSearchQuery('');
                  setSearchParams('');
                  if (!pathname?.includes('/leadgen/search')) navigateTo(`/leadgen/search`);
                }}
              />
            )}
            <div className={isAbove1920 ? 'topNavBarAbove1920' : ''}>
              {!hideSearchBar && isAbove1920 && (
                <div className="d-flex a-center">
                  <div style={{ width: '100%', borderRadius: '8px' }}>
                    <div>
                      {(propertyToggle ? commericalPropertyRadios : residentialPropertyRadios)?.map((element) => (
                        <span
                          onClick={() => {
                            if (searchContext == element?.value) {
                              return;
                            }
                            dispatch(reset_data());
                            setSearchQuery('');
                            setSearchParams('');
                            if (!pathname?.includes('/leadgen/search')) navigateTo(`/leadgen/search`);
                            if (element?.disabled) return;
                            dispatch(setSearchContext(element?.value));
                          }}
                          className={`${element?.disabled ? 'disabled' : ''} ${
                            searchContext == element?.value ? 'radioBtnSelected' : ''
                          } radioBtnCustom`}
                          key={nanoid()}
                        >
                          {searchContext === element?.value && <CheckSquareOutlined />}
                          {element?.label}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <Input
                onChange={(e) => {
                  setSearchQuery(e?.target?.value);
                }}
                value={searchQuery}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchParam(e?.target?.value);
                  }
                }}
                placeholder="Show me properties in Hinjewadi"
                className="topnav-placeholder"
              />
            </div>
            <Button
              disabled={!searchQuery || searchQuery.trim().length === 0}
              type="primary"
              className="topnav-search__button"
              onClick={() => {
                if (searchQueries === searchQuery) return;
                dispatch(reset_data());
                dispatch(setSelectedOrganizationId(null));
                navigateTo(`/leadgen/search?searchQuery=${searchQuery}`);
                dispatch(setSelectedNav('LeadGen'));
              }}
            >
              Search
            </Button>
          </Space>
          {!hideSearchBar && isAbove1320 && (
            <PropertyTypeToggle setSearchParams={setSearchParams} setSearchQuery={setSearchQuery} />
          )}
        </div>

        <div style={{ marginLeft: 55 }} className="d-flex g-20">
          <span
            className="pb-10"
            onClick={() => {
              navigateTo('/user/socialApp');
            }}
            style={{
              borderBottom:
                pathname?.includes('/user/socialApp') &&
                pathname != '/user/socialApp/events' &&
                !pathname?.includes('/user/socialApp/group')
                  ? '1px solid #003FAB'
                  : 'none',
            }}
          >
            <SocialUsersIcon
              color={
                pathname?.includes('/user/socialApp') &&
                pathname != '/user/socialApp/events' &&
                !pathname?.includes('/user/socialApp/group')
                  ? '#003FAB'
                  : '#3a3a3a'
              }
            />
          </span>
          <span
            className="pb-10"
            onClick={() => {
              navigateTo('/user/socialApp/groups');
            }}
            style={{ borderBottom: pathname.includes('/user/socialApp/group') ? '1px solid #003FAB' : 'none' }}
          >
            {' '}
            <GroupIcon color={pathname?.includes('/user/socialApp/group') ? '#003FAB' : '#3a3a3a'} />
          </span>
          <span
            className="pb-10"
            onClick={() => {
              navigateTo('/user/socialApp/events');
            }}
            style={{ borderBottom: pathname.includes('/user/socialApp/events') ? '1px solid #003FAB' : 'none' }}
          >
            {' '}
            <EventIcon color={pathname?.includes('/user/socialApp/events') ? '#003FAB' : '#3a3a3a'} />
          </span>
        </div>
        <div className="header__actions">
          <Space size="large">
            <div
              className="actions-item"
              onClick={() => {
                if (profileCompletion?.overallCompletion >= 80) {
                  navigateTo(`/user/myaccount?activeTab=transactional_details`);
                }
              }}
            >
              Points <span className="point-item">{userPoints}</span>
            </div>

            <Space size="middle">
              <Popover
                content={<div>{notificationContent}</div>}
                title={<Text>Notification</Text>}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                placement="bottomRight"
                style={{ zIndex: 'auto !important' }}
              >
                <Badge className="topnav__notification" size="default" count={getUnreadNotificationCount()}>
                  <BellOutlined className="notification-item" />
                </Badge>
              </Popover>
            </Space>
            <Dropdown overlay={profileMenu} trigger={['hover']}>
              <div className="block-items">
                <Avatar size="large" src={userV2?.personalDetails?.imageUrl} icon={<UserOutlined />} />
                <div className="items-desc">
                  <Progress
                    size="small"
                    type="circle"
                    percent={profileCompletion?.overallCompletion}
                    showInfo={false}
                    strokeColor="#0081FC"
                  />
                </div>
              </div>
            </Dropdown>
          </Space>
        </div>
      </div>
      {!hideSearchBar && !isAbove1320 && !isAbove1920 && (
        <PropertyTypeToggle setSearchParams={setSearchParams} setSearchQuery={setSearchQuery} />
      )}
    </Header>
  );
};

export default TopNav;
