import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Checkbox, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSocialMembers } from '../../../../features/socialAppSlice';

const SearchGroupMember = ({ onChange, members = [] }) => {
  const dispatch = useDispatch();
  const [sortedMember, setSortedMember] = useState(members);
  const [selectedMember, setSelectedMember] = useState([]);

  // useEffect(() => {
  //   dispatch(
  //     getSocialMembers({
  //       searchBy: '',
  //     }),
  //   )?.then((res) => {
  //     const data = res?.payload?.data?.response?.data?.results;
  //     if (data) {
  //       setSortedMember(data);
  //     }
  //   });
  // }, []);

  const onSearch = (e) => {
    dispatch(
      getSocialMembers({
        searchBy: e.target.value,
      }),
    )?.then((res) => {
      const data = res?.payload?.data?.response?.data?.results;
      if (data) {
        setSortedMember(data);
      }
    });
    // setSortedMember(() =>

    // );
  };
  const onMemberSelect = (value) => {
    const selectedMembers = selectedMember?.includes(value)
      ? selectedMember?.filter((elem) => elem !== value)
      : [...selectedMember, value];
    setSelectedMember(selectedMembers);
    onChange(selectedMembers);
  };
  return (
    <div className="searchMembers ">
      <div className="fontExtraLight font16 mb-20">Select the members from below</div>
      <Input
        placeholder="Search"
        style={{ background: '#f4f4f4', height: 50, borderRadius: '10px', border: 'none' }}
        prefix={<SearchOutlined style={{ color: '#c7c7c7' }} className="font24" />}
        onChange={onSearch}
      />
      {sortedMember?.length > 0 && (
        <div className="d-flex mt-20 flex-wrap">
          {sortedMember?.map((member) => (
            <div style={{ width: '48%' }} className="d-flex a-center g-30 mb-20">
              <div className="d-flex a-center g-10">
                <Avatar icon={<UserOutlined />} />
                <span className="fontLight font18">{member?.name}</span>
              </div>
              <Checkbox onChange={() => onMemberSelect(member?.id)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchGroupMember;
