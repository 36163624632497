import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { useNavigate } from 'react-router';
import GroupAccordianCard from './GroupAccordianCard';

const GroupAccordian = ({ groups }) => {
  const navigate = useNavigate();
  console.log('group', groups);
  const onSeeAllClick = () => {
    navigate('/user/socialApp/groups');
  };
  return (
    <div className="d-flex d-column g-20 ">
      {groups?.map((elem) => (
        <GroupAccordianCard key={nanoid()} data={elem} />
      ))}

      <span className="font16  fontExtraLight cursor-pointer" onClick={onSeeAllClick}>
        See All
      </span>
    </div>
  );
};

export default GroupAccordian;
