import { Collapse, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import genIcon from '../../../../assets/LeadGen.png';

const RecentActivity = () => {
  const { Panel } = Collapse;
  const { recentActivities } = useSelector((store) => store.userDashboard);

  return (
    <Panel header="Recent Activity">
      <div className="userdashboard-activity">
        {recentActivities?.length > 0 ? (
          recentActivities?.map((el, index) => {
            return (
              <div key={index}>
                <Divider />
                <div className="userDashboard__activity-item">
                  <div className="activity-item__action">
                    <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                      <img src={genIcon} alt="" width={30} />
                    </div>
                    <span className="userDashboard__activity-message">
                      <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                    </span>
                  </div>

                  <span className="userDashboard__activity-time">
                    {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <span>No Recent Activity</span>
        )}
      </div>
    </Panel>
  );
};

export default RecentActivity;
