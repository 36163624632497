// Radio options for commercial toggle
export const commericalPropertyRadios = [
  {
    label: 'Property',
    value: 'commercialProperty',
    disabled: false,
  },
  {
    label: 'Company',
    value: 'organization',
    disabled: false,
  },
  {
    label: 'Connect',
    value: 'connect',
    disabled: true,
  },
];
// Radio options for Residential toggle

export const residentialPropertyRadios = [
  {
    label: 'Property',
    value: 'residentialProperty',
    disabled: false,
  },
];
