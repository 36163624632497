import React from 'react';

const ShareIcon = ({ size = 96.734 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.22" height="20.41" viewBox="0 0 23.22 20.41">
      <g id="Share" transform="translate(-14.283 -24.61)">
        <path
          id="Path_17688"
          data-name="Path 17688"
          d="M25.643,45.018A1.382,1.382,0,0,1,25.453,45l-.015,0a1.16,1.16,0,0,1-.937-.981L23.025,32.938,14.74,26.68a1.151,1.151,0,0,1,.693-2.07c20.179.321,21.555.375,21.874.605l.03.022.166.208-.049.253-.011.026c-.093.225-1.31,2.325-10.775,18.666l-.032.057a1.158,1.158,0,0,1-.995.573ZM15.335,25.637a.107.107,0,0,0-.062.192l8.717,6.547,1.528,11.467a.134.134,0,0,0,.135.118.13.13,0,0,0,.115-.068c1.363-2.354,8.263-14.267,10.215-17.651l.112-.195-.226-.007c-3.021-.1-12.716-.277-20.445-.4h-.091Z"
          transform="translate(0 0)"
          fill="#aaa9a9"
        />
        <rect
          id="Rectangle_9635"
          data-name="Rectangle 9635"
          width="15.569"
          height="1.045"
          transform="translate(22.962 32.361) rotate(-27.84)"
          fill="#aaa9a9"
        />
      </g>
    </svg>
  );
};

export default ShareIcon;
