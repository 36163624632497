import { Flex, Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import SideNav from './components/sideNav/SideNav';
import TopNav from './components/topNav/TopNav';
import { Outlet, useLocation } from 'react-router';
import RaiseConcern from './components/raiseConcern/RaiseConcern';
import TopNavWithoutInteraction from '../leadGen/components/topNav/TopNavWithoutInteraction';
const UnivarsalLayout = ({ loading }) => {
  const location = useLocation();
  const { pathname } = location;

  const hideTopNav = pathname?.includes('/onboarding') || pathname?.includes('/offerings');
  return (
    <div className="h-100" gap="middle" wrap="wrap">
      <Layout className="h-100 p-10">
        {!(pathname?.includes('/onboarding') || pathname?.includes('/offerings')) && <SideNav />}
        <Layout>
          {hideTopNav ? <TopNavWithoutInteraction /> : <TopNav />}
          <Content>{loading ? <Spin spinning={loading} size="large" fullscreen /> : <Outlet />}</Content>
        </Layout>
        <RaiseConcern />
      </Layout>
    </div>
  );
};

export default UnivarsalLayout;
