import React from 'react';

const PlayIcon = ({ size = 96.734 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 96.734 96.733">
      <path
        id="Icon_fa-regular-circle-play"
        data-name="Icon fa-regular-circle-play"
        d="M87.665,48.367a39.3,39.3,0,1,0-39.3,39.3A39.3,39.3,0,0,0,87.665,48.367ZM0,48.367A48.367,48.367,0,1,1,48.367,96.733,48.367,48.367,0,0,1,0,48.367ZM35.576,27.792a4.539,4.539,0,0,1,4.591.094L67.373,44.512a4.538,4.538,0,0,1,0,7.746L40.167,68.885a4.583,4.583,0,0,1-4.591.094,4.506,4.506,0,0,1-2.324-3.949V31.741A4.506,4.506,0,0,1,35.576,27.792Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PlayIcon;
